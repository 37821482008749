"use-strict";

export default {
  SET_ALL_EVENTS: "@@event/SET_ALL_EVENTS",
  SET_ALL_EVENTS_CATEGORY: "@@event/SET_ALL_EVENTS_CATEGORY",
  SET_ALL_EVENTS_TYPE: "@@event/SET_ALL_EVENTS_TYPE",
  DELETE_EVENT: "@@event/DELETE_EVENT",
  SET_SINGLE_EVENT: "@@event/SET_SINGLE_EVENT",
  SET_EVENT_KEY_VALUE: "@@event/SET_EVENT_KEY_VALUE",
  CLEAR_ALL_EVENTS: "@@event/CLEAR_ALL_EVENTS",
  CLEAR_SINGLE_EVENT: "@@event/CLEAR_SINGLE_EVENT",
};
