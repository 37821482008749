'use-strict'

import authConstants from '../authReducer/authConstants'
import constants from './sedeConstants'
import initialState from './sedeState'

const sedeReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_ALL_SEDES: {
            return {
                ...state,
                all: action.payload
            }
        }
        case constants.CLEAR_ALL_SEDES: {
            return {
                ...state,
                all: initialState.all
            }
        }
        case constants.SET_SEDE_KEY_VALUE: {
            return {
                ...state,
                single: {
                    ...state.single,
                    [action.payload.key]: action.payload.value
                }
            }
        }
        case constants.PUSH_NEW_SEDE: {
            const sedes = [...state.all]
            sedes.push(action.payload)
            return {
                ...state,
                all: sedes
            }
        }
        case constants.SET_SINGLE_SEDE: {
            return {
                ...state,
                single: action.payload
            }
        }
        case constants.REPLACE_SEDE: {
            const sedes = [...state.all]
            const index = sedes.findIndex(sede => sede.id === action.payload.id)
            sedes[index] = action.payload
            return {
                ...state,
                all: sedes
            }
        }
        case constants.DELETE_SEDE: {
            const sedes = [...state.all]
            const index = sedes.findIndex(sede => sede.id === action.sedeId)
            sedes.splice(index, 1)
            return {
                ...state,
                all: sedes
            }
        }
        case constants.SET_SELECTED_SEDE: {
            return {
                ...state,
                selected: action.sedeId
            }
        }
        case constants.CLEAR_SINGLE_SEDE: {
            return {
                ...state,
                single: initialState.single
            }
        }
        case constants.UPDATE_ARCHIVED_SEDE: {
            const sedes = [...state.all]
            const index = sedes.findIndex(sede => sede.id === action.payload.sedeId)
            sedes[index] = {
                ...sedes[index],
                archived: action.payload.archived
            }
            return {
                ...state,
                all: sedes
            }
        }
        case authConstants.LOGOUT: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default sedeReducer