import React, { useState } from "react";
import Button from "../../../../utils/components/Button";
import Table from "../../../../utils/components/Table";
import GroupModalContainer from "./GroupModalContainer";

const GroupList = ({
  groups,
  loadingFetchGroups,
  deleteGroup,
  loadingDeleteGroup,
  setGroupKeyValue,
  organizerId,
  tournamentId,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const getFilteredGroups = () =>
    groups.filter((group) => {
      const foundId =
        group.id.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
      const foundName =
        group.name.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1;
      return foundId || foundName;
    });

  const addActions = (groups) =>
    groups.map((group) => {
      return {
        ...group,
        actions: (
          <div className="btn-separated-group">
            <Button
              className="btn btn-warning"
              title={<i className="fa fa-pencil"></i>}
              withTooltip={true}
              tooltipTitle="Editar"
              onClick={() => {
                setGroupKeyValue("id", group.id);
                setIsEditing(true);
                setShowGroupModal(true);
              }}
            />
            <Button
              className="btn btn-danger"
              title={<i className="fa fa-trash"></i>}
              withTooltip={true}
              tooltipTitle="Eliminar"
              onClick={() => deleteGroup(group.id)}
              loading={loadingDeleteGroup}
              withConfirmation={true}
              confirmationTitle="Eliminar grupo"
              onConfirmText="Confirmar"
              onCancelText="Cancelar"
              onCancelClass="btn-danger pull-left"
              confirmationBody="¿Estás seguro que quieres eliminar este grupo?"
            />
          </div>
        ),
      };
    });

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        Grupos
        <Button
          title={
            <span>
              <i className={`fa fa-plus`}></i> Grupo
            </span>
          }
          className="btn btn-primary btn-xs pull-right"
          onClick={() => {
            setIsEditing(false);
            setShowGroupModal(true);
          }}
          withTooltip={true}
          tooltipTitle="Nuevo grupo"
        />
      </div>
      <div className="panel-body">
        <input
          type="text"
          className="form-control"
          placeholder="Buscar grupos..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Table
          loading={loadingFetchGroups}
          headers={[
            {
              label: "Nombre",
              ref: "name",
              className: "col-md-9 col-sm-8",
            },
            {
              label: "Acciones",
              ref: "actions",
              className: "col-md-3 col-sm-4 text-center",
            },
          ]}
          rows={addActions(getFilteredGroups())}
          notFoundMessage="No se encontraron grupos..."
        />
      </div>
      <GroupModalContainer
        show={showGroupModal}
        handleShow={setShowGroupModal}
        isEditing={isEditing}
        organizerId={organizerId}
        tournamentId={tournamentId}
      />
    </div>
  );
};

export default GroupList;
