import userActions from "../reducers/userReducer/userActions";
import userService from "../reducers/userReducer/userService";
import { model as userModel } from "../reducers/userReducer/userState";
import { setLoading } from "../reducers/loadingReducer";
import handler, { statusCodes } from "../../utils/functions/handler";
import { actions as alertActions } from "../reducers/alertReducer";
import { history } from "../store";
import without from "../../utils/functions/without";
import keepOrganizer from "../../utils/functions/keepOrganizer";

export const loadAllUsers =
  (
    page,
    pageSize,
    gender,
    deleted,
    hasFansIdConfigurated,
    nationality,
    organizations,
    adminValue,
    searchValue
  ) =>
  async (dispatch) => {
    dispatch(setLoading.true("user.loadAllUsers"));
    const [error, users] = await userService.loadAll(
      page,
      pageSize,
      gender,
      deleted,
      hasFansIdConfigurated,
      nationality,
      organizations,
      adminValue,
      searchValue
    );
    dispatch(setLoading.false("user.loadAllUsers"));

    if (error) {
      return dispatch(handler(error));
    }
    dispatch(userActions.setAllUsers(users.results.map(userModel)));
  };

export const createNewUser =
  (organizerId, image) => async (dispatch, getState) => {
    dispatch(setLoading.true("user.createNewUser"));
    const store = getState();
    const user = without(store.user.single, [
      "id",
      "image",
      "organizations",
      "password",
    ]);
    const [error, createdUser] = await userService.create(user);
    let errorImage = null;
    if (image) {
      [errorImage] = await userService.images(createdUser, image);
    }
    dispatch(setLoading.false("user.createNewUser"));
    if (error || errorImage) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
            [statusCodes.CONFLICT]: {
              msg: "Ya existe una user con este nombre.",
              alertStyle: "danger",
            },
          },
        })
      );
    }
    dispatch(userActions.pushNewUser(userModel(createdUser)));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha creado un nuevo usuario</b>.`,
        "info",
        3500
      )
    );
    history.push(keepOrganizer("/users"));
  };

export const updateUser =
  (organizerId, userId) => async (dispatch, getState) => {
    dispatch(setLoading.true("user.updateUser"));
    const store = getState();

    const user = without(store.user.single, [
      "id",
      "image",
      "organizations",
      "password",
    ]);

    const [error, updatedUser] = await userService.update(
      organizerId,
      userId,
      user
    );
    dispatch(setLoading.false("user.updateUser"));

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
            [statusCodes.CONFLICT]: {
              msg: "Ya existe una user con este nombre.",
              alertStyle: "danger",
            },
          },
        })
      );
    }
    dispatch(userActions.replaceUser(userModel(updatedUser)));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado el user <b>${updatedUser.username}</b>.`,
        "info",
        3500
      )
    );
    history.push(keepOrganizer("/users"));
  };

export const deleteUser = (userId) => async (dispatch, getState) => {
  dispatch(setLoading.true("user.deleteUser"));
  const store = getState();
  const [error] = await userService.delete(userId);
  const deletedUser = store.user.all.find((user) => user.id === userId);
  dispatch(setLoading.false("user.deleteUser"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {},
      })
    );
  }
  dispatch(userActions.deleteUser(userId));
  dispatch(
    alertActions.showFloatingAlert(
      `Se ha eliminado el usuario <b>${deletedUser.username}</b>.`,
      "info",
      3500
    )
  );
};

export const loadUser = (organizerId, userId) => async (dispatch) => {
  dispatch(setLoading.true("user.loadUser"));
  let [error, user] = await userService.loadOne(organizerId, userId);
  if (user.birthday) {
    user.birthday = user.birthday.split("T")[0];
  }
  if (user.organizations && user.organizations[0]) {
    user.admin = user.organizations[0]["id"];
  }
  dispatch(setLoading.false("user.loadUser"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {},
      })
    );
  }
  dispatch(userActions.setSingleUser(user));
};

export const clearSingleUser = () => (dispatch) =>
  dispatch(userActions.clearSingleUser());

export const archiveUser =
  (organizerId, userId, archiveValue) => async (dispatch, getState) => {
    dispatch(setLoading.true("user.archiveUser"));
    const [error, updatedUser] = await userService.update(organizerId, userId, {
      archived: archiveValue,
    });
    dispatch(setLoading.false("user.archiveUser"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }
    dispatch(
      userActions.updateArchivedUser(updatedUser.id, updatedUser.archived)
    );
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado la user <b>${updatedUser.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const loadUserOrganizations = (userId) => async (dispatch) => {
  dispatch(setLoading.true("user.loadOrganizations"));
  const [error, organizations] = await userService.loadOrganizations(userId);
  dispatch(setLoading.false("user.loadOrganizations"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {},
      })
    );
  }
  dispatch(userActions.setUserOrganizations(organizations));
};

export const loadAllCountries = () => async (dispatch) => {
  dispatch(setLoading.true("user.loadCountries"));
  const [error, countries] = await userService.loadAllCountries();

  dispatch(setLoading.false("user.loadCountries"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {},
      })
    );
  }
  dispatch(userActions.setAllCountries(countries));
};

export const loadAllOrganizers = () => async (dispatch) => {
  dispatch(setLoading.true("user.loadAllOrganizers"));
  const [error, organizers] = await userService.loadAllOrganizers();
  dispatch(setLoading.false("user.loadAllOrganizers"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {},
      })
    );
  }
  dispatch(userActions.setAllOrganizers(organizers));
};
