"use-strict";

import constants from "./tournamentConstants";

export default {
  setAllTournaments: (payload) => ({
    type: constants.SET_ALL_TOURNAMENTS,
    payload,
  }),
  setSingleTournament: (payload) => ({
    type: constants.SET_SINGLE_TOURNAMENT,
    payload,
  }),
  setTournamentKeyValue: (key, value) => ({
    type: constants.SET_TOURNAMENT_KEY_VALUE,
    payload: { key, value },
  }),
  deleteTournament: (tournamentId) => ({
    type: constants.DELETE_TOURNAMENT,
    tournamentId,
  }),
  clearAllTournaments: () => ({
    type: constants.CLEAR_ALL_TOURNAMENTS,
  }),
  clearSingleTournament: () => ({
    type: constants.CLEAR_SINGLE_TOURNAMENT,
  }),
  setTournamentRedCards: (payload) => ({
    type: constants.SET_TOURNAMENT_REDCARDS,
    payload,
  }),
  clearTournamentRedCards: () => ({
    type: constants.CLEAR_TOURNAMENT_REDCARDS,
  }),
  setTournamentYellowCards: (payload) => ({
    type: constants.SET_TOURNAMENT_YELLOWCARDS,
    payload,
  }),
  clearTournamentYellowCards: () => ({
    type: constants.CLEAR_TOURNAMENT_YELLOWCARDS,
  }),
  updateArchivedTournament: (tournamentId, archived) => ({
    type: constants.UPDATE_ARCHIVED_TOURNAMENT,
    payload: {
      tournamentId,
      archived,
    },
  }),
};
