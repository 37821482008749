'use-strict'

import authConstants from '../authReducer/authConstants'
import constants from './teamConstants'
import initialState from './teamState'

const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_ALL_TEAMS: {
            return {
                ...state,
                all: action.payload
            }
        }
        case constants.CLEAR_ALL_TEAMS: {
            return {
                ...state,
                all: initialState.all
            }
        }
        case constants.SET_TEAM_KEY_VALUE: {
            return {
                ...state,
                single: {
                    ...state.single,
                    [action.payload.key]: action.payload.value
                }
            }
        }
        case constants.PUSH_NEW_TEAM: {
            const teams = [...state.all]
            teams.push(action.payload)
            return {
                ...state,
                all: teams
            }
        }
        case constants.SET_SINGLE_TEAM: {
            return {
                ...state,
                single: action.payload
            }
        }
        case constants.REPLACE_TEAM: {
            const teams = [...state.all]
            const index = teams.findIndex(team => team.id === action.payload.id)
            teams[index] = action.payload
            return {
                ...state,
                all: teams
            }
        }
        case constants.DELETE_TEAM: {
            const teams = [...state.all]
            const index = teams.findIndex(team => team.id === action.teamId)
            teams.splice(index, 1)
            return {
                ...state,
                all: teams
            }
        }
        case constants.SET_SELECTED_TEAM: {
            return {
                ...state,
                selected: action.teamId
            }
        }
        case constants.CLEAR_SINGLE_TEAM: {
            return {
                ...state,
                single: initialState.single
            }
        }
        case authConstants.LOGOUT: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default teamReducer