import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SanctionModal from './SanctionModal'
import * as sanctionThunks from '../../../../redux/thunks/sanctionThunks'
import sanctionActions from '../../../../redux/reducers/sanctionReducer/sanctionActions'
import * as playerThunks from '../../../../redux/thunks/playerThunks'
import playerActions from '../../../../redux/reducers/playerReducer/playerActions'

const SanctionModalContainer = ({
    show,
    handleShow,
    sanction,
    players,
    isEditing,
    updateSanction,
    createNewSanction,
    clearSingleSanction,
    setSanctionKeyValue,
    loadSanction,
    loadTournamentPlayers,
    clearAllPlayers,
    organizerId,
    tournamentId,
    playerSelected,
    teamSelected,
    loading,
    lineups
}) => {
    const [isReady, setIsReady] = useState(false)

    useEffect(()=>{
        if (show){
            setIsReady(false)
            clearAllPlayers()
            clearSingleSanction()

            if(isEditing) loadSanction(organizerId, tournamentId, sanction.id)

            loadTournamentPlayers(organizerId, tournamentId)

            if(playerSelected) setSanctionKeyValue('player', playerSelected)
            if(teamSelected) setSanctionKeyValue('team', teamSelected)
            
            setIsReady(true)
        }
    },[show])

    const closeSanctionModalHandle = () => handleShow(false)

    const saveSanctionHandler = 
        () => isEditing ? 
            updateSanction(
                organizerId, 
                tournamentId, 
                sanction.id, 
                closeSanctionModalHandle
            ) : 
            createNewSanction(
                organizerId, 
                tournamentId, 
                closeSanctionModalHandle)

    const modalTitle = () => isEditing ? 'Modificar Sanción' : 'Crear Sanción'
    const buttomSaveTitle = () => isEditing ? 'Guardar' : 'Crear'

    return (
        <SanctionModal 
            show={show}
            handleShow={handleShow}
            setSanctionKeyValue={setSanctionKeyValue}
            sanction={sanction}
            players={players}
            saveSanction={saveSanctionHandler}
            isEditing = {isEditing}
            modalTitle ={modalTitle}
            buttomSaveTitle = {buttomSaveTitle}
            loading = {loading.sanction.loadSanction}
            lineups = {lineups}
        />
    )
}

const mapState = state => ({
    loading: state.loading,
    sanction: state.sanction.single,
    players: state.player.all,
    lineups: state.lineup.all,
})

const mapDispatch = dispatch => ({
    setSanctionKeyValue: (key, value) => dispatch(sanctionActions.setSanctionKeyValue(key, value)),
    clearSingleSanction: () => dispatch(sanctionActions.clearSingleSanction()),
    createNewSanction: (organizerId, tournamentId, handleCloseModal) => dispatch(sanctionThunks.createNewSanction(organizerId, tournamentId, handleCloseModal)),
    loadSanction: (organizerId, tournamentId, sanctionId) => dispatch(sanctionThunks.loadSanction(organizerId, tournamentId, sanctionId)),
    updateSanction: (organizerId, tournamentId, sanctionId, handleCloseModal) => dispatch(sanctionThunks.updateSanction(organizerId, tournamentId, sanctionId, handleCloseModal)),
    loadTournamentPlayers: (organizerId, tournamentId) => dispatch(playerThunks.loadTournamentPlayers(organizerId, tournamentId)),
    clearAllPlayers: () => dispatch(playerActions.clearAllPlayers())
})

export default withRouter(connect(mapState, mapDispatch)(SanctionModalContainer))