import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import def from '../../utils/functions/defaults'
import ConfirmationModal from '../../utils/components/ConfirmationModal'

const Button = ({
    type = 'button',
    className = def.str,
    id = 'button-id',
    onClick = def.fn,
    title = def.str,
    loading = def.false,
    disabled = def.false,
    withTooltip = def.false,
    tooltipTitle = def.str,
    tooltipPosition = 'top',
    withConfirmation = def.false,
    confirmationTitle = def.str,
    onConfirmText = def.str,
    onCancelText = def.str,
    onCancelClass = def.str,
    confirmationBody = def.str,
}) => {

    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const confirmation = () => {
        return (
            <ConfirmationModal
                show={showConfirmationModal}
                title={confirmationTitle}
                onConfirmText={onConfirmText}
                onCancelText={onCancelText}
                onCancelClass={onCancelClass}
                body={confirmationBody}
                onConfirm={() => {
                    setShowConfirmationModal(false)
                    onClick()
                }}
                onCancel={() => {
                    setShowConfirmationModal(false)
                }}
            />
        )
    }

    const withOverlay = button => {
        return (
            <OverlayTrigger
                placement={tooltipPosition}
                overlay={<Tooltip id={tooltipTitle.split(' ').join('') + 'tooltip'}>{tooltipTitle}</Tooltip>}
            >
                {button}
            </OverlayTrigger>
        )
    }

    const button = () => {
        return (
            <button
                id={id}
                type={type}
                className={className}
                onClick={(e) => {
                    if (!withConfirmation) return onClick(e)
                    setShowConfirmationModal(true)
                }}
                disabled={disabled || loading}
            >
                {loading
                    ? <i className="fa fa-spinner fa-spin"></i>
                    : title
                }

            </button>
        )
    }

    return (
        <>
            {withTooltip ? withOverlay(button()) : button()}
            {withConfirmation ? confirmation() : null}
        </>
    )
}

export default Button