'use-strict'

import httpClient from '../../../http/httpClient'

const httpTournament = (organizerId, tournamentId) => {
    return httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}`)
}

export default {
    loadAll: (organizerId, tournamentId) => httpTournament(organizerId, tournamentId).get('/tablerowcolors'),
    create: (organizerId, tournamentId, tableRowColor) => httpTournament(organizerId, tournamentId).post('/tablerowcolors', tableRowColor),
    delete: (organizerId, tournamentId, tableRowColorId) => httpTournament(organizerId, tournamentId).delete(`/tablerowcolors/${tableRowColorId}`),
    load: (organizerId, tournamentId, tableRowColorId) => httpTournament(organizerId, tournamentId).get(`/tablerowcolors/${tableRowColorId}`),
    update: (organizerId, tournamentId, tableRowColorId, tableRowColor) => httpTournament(organizerId, tournamentId).patch(`/tablerowcolors/${tableRowColorId}`, tableRowColor)
}