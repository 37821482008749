'use-strict'

import httpClient from '../../../http/httpClient'


const httpTournament = (organizerId, tournamentId) => {
    return httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}`)
}

export default {
    loadAll: (organizerId, tournamentId) => 
        httpTournament(organizerId, tournamentId).get('/sanctions'),
    create: (organizerId, tournamentId, sanction) => 
        httpTournament(organizerId, tournamentId).post('/sanctions', sanction),
    delete: (organizerId, tournamentId, sanctionId) => 
        httpTournament(organizerId, tournamentId).delete(`/sanctions/${sanctionId}`),
    loadOne: (organizerId, tournamentId, sanctionId) =>
        httpTournament(organizerId, tournamentId).get(`/sanctions/${sanctionId}`),
    update: (organizerId, tournamentId, sanctionId, sanction) => 
        httpTournament(organizerId, tournamentId).patch(`/sanctions/${sanctionId}`, sanction)
}