import React from 'react'
import WorksheetWrapper from '../components/WorksheetWrapper'

const WorksheetIncidenceType = ({
    types,
    onClickIncidence
}) => {
    return (
        <WorksheetWrapper 
            withGoBack 
            gridItems={types}
            gridAction={onClickIncidence}    
        />
    )
}

export default WorksheetIncidenceType