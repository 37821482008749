'use-strict'

export default {
    // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1ODQ4OTI5NjAsImV4cCI6MTU4NzQ4NDk2MCwicm9sZXMiOlsiUk9MRV9PV05FUiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6Ijk1Nzc0Nzc5In0.lzH5p-zXdkwTCIfWT2gMR7JD4uGcNty9mb9FnexRbpl00v0Ze0OOjRYorjY7VizzHD_jQDHDP0DzUWTOpEsJ6H41KbhSLVHwBprr9XDeWYn71_9YjV1NgIuusmQAzCsEEdJZJ04klrkNt9xxMhK6zfKgux6sqzWb_VWdPz1qOkrVPDfuS7ePJF7dYZMmfDJd5pb6jtYAnVglQ2sJl4Veraq54Qus3Irk8F_KttcXuP35faeQw9VxnsZVNV4NizJr23_m8LvYEv1_crHp1toFWML3IycVOjikgG9S8hJQl2eponNS8I2aZBp1Yy7mqO0-h81iv_Q6oMg5egVjupToUDAZ0okzn_3VmwfLTTOuDYVXlKyt6-dqTidHPAvCUgTM4cExGw7U9-Eoqgny6pD-NRyyN7kqmuu2yfgaeuJ0tsuHAgUsC6JiGgOfvC_D1VDcqheLQjtMLICv-JWhHe2q4LKTBlsaGcETL3ULodPJZToZUociQ80_gqw4rknbwQOqMHkNHRnZ89eHezC8-u_uIT_Gmh1gdEdcBvUMU5V8bIOL9KZ5MyL3YRy1vCZhg6rjsV_u4GgF1593E8Yz9t6TDCaTUiHUBkBDgfYQ0Hmm1OWOjRNdINg4UptxGlK5eiixQvPB4jxV811t5sw2vql6VWTTNbLErwzyYlc_-Be25gs',
    // user: {
    //     "roles": [
    //         "ROLE_OWNER",
    //         "ROLE_STAFF",
    //         "ROLE_USER"
    //     ],
    //     "username": "egdev",
    //     "firstName": null,
    //     "lastName": null,
    //     "id": "9a8210a9-5bd9-11ea-b292-0a2b6424056b",
    // },
    token: '',
    user: {
        id: '',
        username: '',
        firstName: '',
        lastName: '',
        roles: []
    },
    permissions: ['DEFAULT_PERMISSION'],
    navigation: [],
    isAuthenticated: false,
}