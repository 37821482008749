import React, { useState } from "react";
import teamImage from "../../assets/images/team.png";

const CustomPhone = ({
  colorState,
  imageBgNextMatchCard,
  imageCardsCard,
  logo,
  logoEnabled,
}) => {
  const [isFirstActive, setFirstActive] = useState(true);
  const [isSecondActive, setSecondActive] = useState(false);

  const toggleClasses = () => {
    setFirstActive(!isFirstActive);
    setSecondActive(!isSecondActive);
  };

  return (
    <div
      style={{
        backgroundColor: colorState.backGroundPrimaryColor,
        width: 280,
        height: 580,
        borderRadius: 20,
        borderWidth: 2,
        border: "8px solid black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <header
        style={{
          display: "flex",
          gap: 10,
          backgroundColor: colorState.backGroundSecondaryColor,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          fontSize: 24,
          marginBottom: 20,
          justifyContent: "space-between",
          padding: "5px 10px 0px 10px",
        }}
      >
        {logoEnabled ? (
          <div
            style={{
              width: 30,
              height: 30,
              marginBottom: 10,
              backgroundSize: "cover",
              backgroundImage: `url(${logo})`,
            }}
          />
        ) : (
          <div />
        )}
        <div
          style={{
            width: 30,
            height: 30,
            marginBottom: 10,
            color: colorState.textQuaternaryColor,
            backgroundColor: colorState.textQuaternaryColor,
            borderRadius: 10,
          }}
        />
      </header>
      <div
        style={{
          backgroundImage: `url(${imageBgNextMatchCard})`,
          color: "white",
          border: "none",
          padding: "35px 20px",
          borderRadius: 5,
          margin: "10px 20px",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            fontSize: 14,
            color: colorState.textPrimaryColor,
          }}
        >
          PRIMERA A
        </h2>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <h3 style={{ fontSize: 12, color: colorState.textQuaternaryColor }}>
            Lun. 25/12
          </h3>
          <h3 style={{ fontSize: 12, color: colorState.textQuaternaryColor }}>
            Cancha: Numero 5
          </h3>
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <img
            src={teamImage}
            alt="shield logo"
            style={{ width: 30, height: 30 }}
          />
          <span style={{ color: colorState.textTertiaryColor }}>0</span>
          <span style={{ color: colorState.textQuaternaryColor }}>-</span>
          <span style={{ color: colorState.textTertiaryColor }}>0</span>
          <img
            src={teamImage}
            alt="shield logo"
            style={{ width: 30, height: 30 }}
          />
        </div>
      </div>
      <button
        style={{
          backgroundColor: colorState.backGroundTertiaryColor,
          backgroundImage: `url(${imageCardsCard})`,
          color: colorState.textPrimaryColor,
          border: "none",
          padding: "20px 20px",
          borderRadius: 5,
          margin: "10px 20px",
        }}
      >
        Fixtures
      </button>
      <button
        style={{
          backgroundColor: colorState.backGroundTertiaryColor,
          color: colorState.textPrimaryColor,
          backgroundImage: `url(${imageCardsCard})`,
          border: "none",
          padding: 15,
          borderRadius: 5,
          margin: "10px 20px",
        }}
      >
        Posiciones
      </button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0px 15px",
        }}
      >
        <button
          style={{
            flex: 1,
            backgroundColor: colorState.backGroundTertiaryColor,
            color: colorState.textPrimaryColor,
            backgroundImage: `url(${imageCardsCard})`,
            border: "none",
            padding: 15,
            borderRadius: 5,
            margin: "5px 5px 5px 5px",
            maxWidth: 105,
          }}
        >
          Goleadores
        </button>
        <button
          style={{
            flex: 1,
            backgroundColor: colorState.backGroundTertiaryColor,
            color: colorState.textPrimaryColor,
            backgroundImage: `url(${imageCardsCard})`,
            border: "none",
            padding: 15,
            borderRadius: 5,
            margin: "5px 5px 5px 5px",
            maxWidth: 105,
          }}
        >
          Fair Play
        </button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0px 15px",
        }}
      >
        <button
          style={{
            flex: 1,
            backgroundColor: colorState.backGroundTertiaryColor,
            color: colorState.textPrimaryColor,
            backgroundImage: `url(${imageCardsCard})`,
            border: "none",
            padding: 15,
            borderRadius: 5,
            margin: "5px 5px 5px 5px",
            maxWidth: 105,
          }}
        >
          Suspendidos
        </button>
        <button
          style={{
            flex: 1,
            backgroundColor: colorState.backGroundTertiaryColor,
            color: colorState.textPrimaryColor,
            backgroundImage: `url(${imageCardsCard})`,
            border: "none",
            padding: 15,
            borderRadius: 5,
            margin: "5px 5px 5px 5px",
          }}
        >
          Tarjetas
        </button>
      </div>
      <footer
        style={{
          display: "flex",
          gap: 10,
          backgroundColor: colorState.backGroundSecondaryColor,
          borderBottomRightRadius: 5,
          borderBottomLeftRadius: 5,
          fontSize: 24,
          justifyContent: "space-evenly",
          padding: "10px 10px 0px 10px",
          position: "relative",
          bottom: 0,
        }}
      >
        {" "}
        <div
          onClick={toggleClasses}
          style={{
            cursor: "pointer",
            width: 30,
            height: 30,
            marginBottom: 10,
            color: isFirstActive
              ? colorState.textSecondaryColor
              : colorState.textQuaternaryColor,
            backgroundColor: isFirstActive
              ? colorState.textSecondaryColor
              : colorState.textQuaternaryColor,
            borderRadius: 10,
          }}
        />
        <div
          onClick={toggleClasses}
          style={{
            cursor: "pointer",
            width: 30,
            height: 30,
            marginBottom: 10,
            color: isFirstActive
              ? colorState.textQuaternaryColor
              : colorState.textSecondaryColor,
            backgroundColor: isFirstActive
              ? colorState.textQuaternaryColor
              : colorState.textSecondaryColor,
            borderRadius: 10,
          }}
        />
      </footer>
    </div>
  );
};

export default CustomPhone;
