import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TournamentList from "./TournamentList";
import * as tournamentThunks from "../../redux/thunks/tournamentThunks";
import tournamentActions from "../../redux/reducers/tournamentReducer/tournamentActions";
import fileDownloader from "../../utils/functions/fileDownloader";

const TournamentListContainer = ({
  deleteTournament,
  tournaments,
  organizers,
  loading,
  clearAllTournaments,
  loadAllTournaments,
  archiveTournament,
  match,
}) => {
  const organizerId = match.params.organizerId;
  const organizer = organizers.find(
    (organizer) => organizer.id === organizerId
  );
  const fileName = `${organizer ? `${organizer.name}_` : ""}lineups.csv`;

  useEffect(() => {
    clearAllTournaments();
    loadAllTournaments(organizerId);
  }, [organizerId]);

  const deleteTournamentHandler = (tournamentId) =>
    deleteTournament(organizerId, tournamentId);
  const archiveTournamentHandler = (tournamentId, archived) =>
    archiveTournament(organizerId, tournamentId, archived);
  const downloadHandler = async () =>
    await fileDownloader(
      `/organizers/${organizerId}/lineups/players/csv`,
      fileName
    );

  return (
    <TournamentList
      deleteTournament={deleteTournamentHandler}
      tournaments={tournaments}
      loadingFetchTournaments={loading.loadAllTournaments}
      loadingDeleteTournament={loading.deleteTournament}
      archiveTournament={archiveTournamentHandler}
      loadingArchiveTournament={loading.archivingTournament}
      downloadHandler={downloadHandler}
    />
  );
};

const mapState = (state) => ({
  organizers: state.organizer.all,
  tournaments: state.tournament.all,
  loading: state.loading.tournament,
});

const mapDispatch = (dispatch) => ({
  loadAllTournaments: (organizerId) =>
    dispatch(tournamentThunks.loadAllTournaments(organizerId)),
  clearAllTournaments: () => dispatch(tournamentActions.clearAllTournaments()),
  archiveTournament: (organizerId, tournamentId, archivedValue) =>
    dispatch(
      tournamentThunks.archiveTournament(
        organizerId,
        tournamentId,
        archivedValue
      )
    ),
  deleteTournament: (organizerId, tournamentId) =>
    dispatch(tournamentThunks.deleteTournament(organizerId, tournamentId)),
});

export default withRouter(
  connect(mapState, mapDispatch)(TournamentListContainer)
);
