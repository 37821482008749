import React, { useState, useEffect } from 'react'
import Breadcrumbs from '../../utils/components/Breadcrumbs'
import Button from '../../utils/components/Button'
import Table from '../../utils/components/Table'
import { history } from '../../redux/store'
import keepOrganizer from '../../utils/functions/keepOrganizer'
import SpecificAlert from '../../utils/components/SpecificAlert/SpecificAlert'

const PlayerList = ({
    deletePlayer,
    players,
    hideSpecificAlert,
    loadingFetchPlayers,
    loadingDeletePlayer,
    downloadHandler,
}) => {

    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        hideSpecificAlert()
    }, [])

    const getFilteredPlayers = () => players.filter(player => {
        const foundName = player.firstName.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        const foundLastName = player.lastName.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        const foundDocument = player.document.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        const foundShirtNumber = (player.shirtNumber || '').toString().trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        return foundName || foundDocument || foundLastName || foundShirtNumber
    })

    const addActions = (players) => players.map(player => {
        return {
            ...player,
            actions: (
                <div className="btn-separated-group">
                    <Button
                        className="btn btn-warning"
                        title={<i className="fa fa-pencil"></i>}
                        withTooltip={true}
                        tooltipTitle='Editar'
                        onClick={() => history.push(keepOrganizer(`/players/${player.id}/edit`))}
                    />
                    <Button
                        className="btn btn-danger"
                        title={<i className="fa fa-trash"></i>}
                        withTooltip={true}
                        tooltipTitle='Eliminar'
                        onClick={() => deletePlayer(player.id)}
                        loading={loadingDeletePlayer}
                        withConfirmation={true}
                        confirmationTitle='Eliminar jugador'
                        onConfirmText='Confirmar'
                        onCancelText='Cancelar'
                        onCancelClass='btn-danger pull-left'
                        confirmationBody='¿Estás seguro que quieres eliminar este jugador?'
                    />
                </div>
            )
        }
    })

    return (
        <>
            <Breadcrumbs
                title='Jugadores'
                opts={[{
                    title: 'Inicio',
                    path: keepOrganizer('/')
                }, {
                    title: 'Jugadores',
                    active: true
                }]}
            />
            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox m-t">
                        <div className="ibox-title">
                            <div className="row">
                                <div className="col-md-4 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Buscar jugadores..."
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-offset-6 col-md-2 col-xs-offset-2 col-xs-4">
                                    <Button
                                        className="btn btn-primary pull-right"
                                        title={<span><i className="fa fa-download"></i> Descargar</span>}
                                        withTooltip={true}
                                        tooltipTitle='Descargar jugadores'
                                        onClick={downloadHandler}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ibox-content">
                            <SpecificAlert />
                            <Table
                                loading={loadingFetchPlayers}
                                headers={[{
                                    label: 'Documento',
                                    ref: 'document',
                                    className: 'col-md-3 col-sm-4'
                                },{
                                    label: 'Nombre',
                                    ref: 'firstName',
                                    className: 'col-md-2 hidden-xs'
                                },{
                                    label: 'Apellido',
                                    ref: 'lastName',
                                    className: 'col-md-3 col-sm-4'
                                },{
                                    label: 'Camiseta',
                                    ref: 'shirtNumber',
                                    className: 'col-md-1 hidden-xs text-center'
                                },{
                                    label: 'Acciones',
                                    ref: 'actions',
                                    className: 'col-md-3 col-sm-4 text-center'
                                }]}
                                rows={addActions(getFilteredPlayers())}
                                notFoundMessage='No se encontraron jugadores...'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlayerList

