"use-strict";

import authConstants from "../authReducer/authConstants";
import constants from "./groupConstants";
import initialState from "./groupState";

const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_ALL_GROUPS: {
      return {
        ...state,
        all: action.payload,
      };
    }
    case constants.SET_GROUP_KEY_VALUE: {
      return {
        ...state,
        single: {
          ...state.single,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case constants.PUSH_NEW_GROUP: {
      const groups = [...state.all];
      groups.push(action.payload);
      return {
        ...state,
        all: groups,
      };
    }
    case constants.DELETE_GROUP: {
      const groups = [...state.all];
      const index = groups.findIndex((group) => group.id === action.groupId);
      groups.splice(index, 1);
      return {
        ...state,
        all: groups,
      };
    }
    case constants.CLEAR_ALL_GROUPS: {
      return {
        ...state,
        all: initialState.all,
      };
    }
    case constants.CLEAR_ALL_LINEUPS_BY_GROUPS: {
      return {
        ...state,
        lineupsByGroup: [],
      };
    }
    case constants.CLEAR_SINGLE_GROUP: {
      return {
        ...state,
        single: initialState.single,
      };
    }
    case constants.SET_SINGLE_GROUP: {
      return {
        ...state,
        single: action.payload,
      };
    }
    case constants.PUSH_LINEUPS_GROUP: {
      const { lineupsByGroup } = state;
      const groupLineups = action.payload;

      groupLineups.forEach((group) => {
        const { id: groupId, name } = group;

        let isAlreadyAdded = false;
        for (let i = 0; i < lineupsByGroup.length; i++) {
          if (lineupsByGroup[i].id === groupId) {
            isAlreadyAdded = true;
            break;
          }
        }

        if (!isAlreadyAdded) {
          lineupsByGroup.push({ id: groupId, name });
        }
      });

      return {
        ...state,
        lineupsByGroup: [...lineupsByGroup],
      };
    }
    case constants.REPLACE_GROUP: {
      const groups = [...state.all];
      const index = groups.findIndex((group) => group.id === action.payload.id);
      groups[index] = action.payload;
      return {
        ...state,
        all: groups,
      };
    }
    case authConstants.LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default groupReducer;
