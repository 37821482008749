import React, { useState } from "react";
import Button from "../../../../utils/components/Button";
import Table from "../../../../utils/components/Table";
import SanctionModalContainer from "./SanctionModalContainer";
import moment from "moment";
import * as env from "../../../../config/env";

const SanctionList = ({
  sanctions,
  loadingFetchSanctions,
  setSanctionKeyValue,
  deleteSanction,
  loadingDeleteSanction,
  organizerId,
  tournamentId,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [showSanctionModal, setShowSanctionModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const getFilteredSanction = () =>
    sanctions.filter((sanction) => {
      const foundPlayerDocument =
        sanction.player.document
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      const foundDatetime =
        moment(sanction.dateEnd)
          .utc()
          .format(env.DATE_FORMAT)
          .indexOf(searchValue) !== -1;
      const foundPlayerFirstName =
        sanction.player.firstName
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      const foundPlayerLastName =
        sanction.player.lastName
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;

      return (
        foundPlayerDocument ||
        foundDatetime ||
        foundPlayerFirstName ||
        foundPlayerLastName
      );
    });

  const addActions = (sanctions) =>
    sanctions.map((sanction) => {
      return {
        ...sanction,
        dateEnd: moment.utc(sanction.dateEnd).local().format(env.DATE_FORMAT),
        teamName: sanction.team ? sanction.team.name : "-",
        actions: (
          <div className="btn-separated-group">
            <Button
              className="btn btn-warning"
              title={<i className="fa fa-pencil"></i>}
              withTooltip={true}
              tooltipTitle="Editar"
              onClick={() => {
                setSanctionKeyValue("id", sanction.id);
                setIsEditing(true);
                setShowSanctionModal(true);
              }}
            />
            <Button
              className="btn btn-danger"
              title={<i className="fa fa-trash"></i>}
              withTooltip={true}
              tooltipTitle="Eliminar"
              onClick={() => deleteSanction(sanction.id)}
              loading={loadingDeleteSanction}
              withConfirmation={true}
              confirmationTitle="Eliminar sanción"
              onConfirmText="Confirmar"
              onCancelText="Cancelar"
              onCancelClass="btn-danger pull-left"
              confirmationBody="¿Estás seguro que quieres eliminar esta sanción?"
            />
          </div>
        ),
      };
    });

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        Sanciones
        <Button
          title={
            <span>
              <i className={`fa fa-plus`}></i> Sanción
            </span>
          }
          className="btn btn-primary btn-xs pull-right"
          onClick={() => {
            setIsEditing(false);
            setShowSanctionModal(true);
          }}
          withTooltip={true}
          tooltipTitle="Nueva sanción"
        />
      </div>
      <div className="panel-body">
        <input
          type="text"
          className="form-control"
          placeholder="Buscar sanción..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Table
          loading={loadingFetchSanctions}
          headers={[
            {
              label: "Documento",
              ref: "player.document",
              className: "col-md-1 hidden-xs",
            },
            {
              label: "Doc.",
              ref: "player.document",
              className: "col-sm-2 visible-xs",
            },
            {
              label: "Nombre",
              ref: "player.firstName",
              className: "col-md-2 hidden-xs",
            },
            {
              label: "Apellido",
              ref: "player.lastName",
              className: "col-md-2 col-sm-1",
            },
            {
              label: "Equipo",
              ref: "teamName",
              className: "col-md-1 col-sm-1 hidden-xs",
            },
            {
              label: "Fechas sancionado",
              ref: "matches",
              className: "col-md-2 col-sm-2 text-center",
            },
            {
              label: "Fechas restantes",
              ref: "remainingMatches",
              className: "col-md-2 col-sm-2 text-center  hidden-xs",
            },
            {
              label: "Acciones",
              ref: "actions",
              className: "col-md-4 col-sm-2 text-center",
            },
          ]}
          rows={addActions(getFilteredSanction())}
          notFoundMessage="No se encontraron sanciones..."
        />
        <SanctionModalContainer
          show={showSanctionModal}
          handleShow={setShowSanctionModal}
          isEditing={isEditing}
          organizerId={organizerId}
          tournamentId={tournamentId}
        />
      </div>
    </div>
  );
};

export default SanctionList;
