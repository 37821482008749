'use-strict'

import React from 'react'
import { Modal } from 'react-bootstrap'
import def from '../functions/defaults'

const ConfirmationModal = ({
    show = def.false,
    title = def.str,
    body = def.str,
    onConfirmText = def.str,
    onCancelText = def.str,
    onConfirmClass = 'btn-primary',
    onCancelClass = 'btn-success',
    onCancel = def.fn,
    onConfirm = def.fn
}) => {
    
    return (
        <Modal bsSize={'small'} backdrop={'static'} show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{title.toUpperCase()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: body }}></div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className={`btn ${onCancelClass}`}
                    onClick={onCancel}
                >{onCancelText}
                </button>
                <button
                    type="button"
                    className={`btn ${onConfirmClass}`}
                    onClick={onConfirm}
                >{onConfirmText}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal