"use-strict";

import authConstants from "../authReducer/authConstants";
import constants from "./tournamentConstants";
import initialState from "./tournamentState";

const tournamentReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_ALL_TOURNAMENTS: {
      return {
        ...state,
        all: action.payload,
      };
    }
    case constants.SET_SINGLE_TOURNAMENT: {
      return {
        ...state,
        single: action.payload,
      };
    }
    case constants.SET_TOURNAMENT_REDCARDS: {
      return {
        ...state,
        redCards: action.payload,
      };
    }
    case constants.CLEAR_TOURNAMENT_REDCARDS: {
      return {
        ...state,
        redCards: initialState.redCards,
      };
    }
    case constants.SET_TOURNAMENT_YELLOWCARDS: {
      return {
        ...state,
        yellowCards: action.payload,
      };
    }
    case constants.CLEAR_TOURNAMENT_YELLOWCARDS: {
      return {
        ...state,
        yellowCards: initialState.yellowCards,
      };
    }
    case constants.SET_TOURNAMENT_KEY_VALUE: {
      return {
        ...state,
        single: {
          ...state.single,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case constants.CLEAR_ALL_TOURNAMENTS: {
      return {
        ...state,
        all: initialState.all,
      };
    }
    case constants.DELETE_TOURNAMENT: {
      const tournaments = [...state.all];
      const index = tournaments.findIndex(
        (tournament) => tournament.id === action.tournamentId
      );
      tournaments.splice(index, 1);
      return {
        ...state,
        all: tournaments,
      };
    }
    case constants.CLEAR_SINGLE_TOURNAMENT: {
      return {
        ...state,
        single: initialState.single,
      };
    }
    case constants.UPDATE_ARCHIVED_TOURNAMENT: {
      const tournaments = [...state.all];
      const index = tournaments.findIndex(
        (tournament) => tournament.id === action.payload.tournamentId
      );
      tournaments[index] = {
        ...tournaments[index],
        archived: action.payload.archived,
      };
      return {
        ...state,
        all: tournaments,
      };
    }
    case authConstants.LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default tournamentReducer;
