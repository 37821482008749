import React, { useState } from "react";
import Table from "../../../utils/components/Table";
import Button from "../../../utils/components/Button";
import Breadcrumbs from "../../../utils/components/Breadcrumbs";
import keepOrganizer from "../../../utils/functions/keepOrganizer";
import NewCheckInModal from "./NewCheckInModal";

const CheckInView = () => {
  const [showCheckInModal, setShowCheckInModal] = useState(false);
  return (
    <div>
      <Breadcrumbs
        title="Check In"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Check In",
            active: true,
          },
        ]}
      />
      <div className="ibox-title">
        <div className="row">
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              placeholder="Buscar registro ..."
              // onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "1rem",
            }}
            className="col-md-3"
          >
            <Button
              className="btn btn-warning pull-right"
              title={
                <span>
                  <i className="fa fa-plus-circle"></i> Check In
                </span>
              }
              withTooltip={true}
              tooltipTitle="Nuevo check in"
              onClick={() => setShowCheckInModal(true)}
            />
          </div>
          <div className="ibox-content">
            <Table
              // loading={loadingMatches}
              headers={[
                {
                  label: "DNI",
                  ref: "dni",
                  className: "col-sm-2 hidden-xs",
                },
                {
                  label: "Nombre",
                  ref: "name",
                  className: "col-sm-2 hidden-xs",
                },
                {
                  label: "Horario",
                  ref: "schedule",
                  className: "col-xs-2 hidden-xs",
                },
              ]}
              // rows={getRowsWithActions(getFilteredMatches())}
              notFoundMessage="No se encontraron invitados en este evento..."
              // itemsInPage={matches.length}
              // defaultPage={currentPage}
              // defaultPageSize={currentPageSize}
              // onChangePage={(page, pageSize) => {
              //   setCurrentPage(page);
              //   setCurrentPageSize(pageSize);
              //   onRefreshMatches(
              //     page,
              //     pageSize,
              //     removeEmptyValueFromObject(matchFilters)
              //   );
              // }}
            />
          </div>
        </div>
      </div>
      <NewCheckInModal
        show={showCheckInModal}
        handleShow={setShowCheckInModal}
      />
    </div>
  );
};

export default CheckInView;
