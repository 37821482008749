'use-strict'

import authConstants from '../authReducer/authConstants'

const initialState = {
    tournament: {
        showLineupPlayersModal: false
    }
}

export const constants = {
    SHOW_LINEUP_PLAYERS_MODAL: '@@ui/SHOW_LINEUP_PLAYERS_MODAL',
    HIDE_LINEUP_PLAYERS_MODAL: '@@ui/HIDE_LINEUP_PLAYERS_MODAL'
}

export const actions = {
    showLineupPlayersModal: () => ({
        type: constants.SHOW_LINEUP_PLAYERS_MODAL
    }),
    hideLineupPlayersModal: () => ({
        type: constants.HIDE_LINEUP_PLAYERS_MODAL
    })
}

export default (state = initialState, action) => {
    switch (action.type) {
        case constants.SHOW_LINEUP_PLAYERS_MODAL: {
            return {
                ...state,
                tournament: {
                    ...state.tournament,
                    showLineupPlayersModal: true
                }
            }
        }
        case constants.HIDE_LINEUP_PLAYERS_MODAL: {
            return {
                ...state,
                tournament: {
                    ...state.tournament,
                    showLineupPlayersModal: false
                }
            }
        }
        case authConstants.LOGOUT: {
            return initialState
        }
        default:
            return state
    }
}
