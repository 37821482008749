import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as tableRowColorThunks from "../../../../redux/thunks/tableRowColorThunks";
import tableRowColorActions from "../../../../redux/reducers/tableRowColorReducer/tableRowColorActions";
import TableRowColorList from "./tableRowColorList";
import TableRowColorModalContainer from "./tableRowColorModalContainer";

const TableRowColorListContainer = ({
  tournamentEditing,
  organizerId,
  tournamentId,
  loading,
  tableRowColors,
  loadTableRowColors,
  clearAllTableRowColors,
  deleteTableRowColor,
  setTableRowColorKeyValue,
  show,
}) => {
  const [showTableRowColorModal, setShowTableRowColorModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    clearAllTableRowColors();
    tournamentEditing && loadTableRowColors(organizerId, tournamentId);
  }, []);

  const handleTableRowColorEdit = (tableRowColorId) => {
    setTableRowColorKeyValue("id", tableRowColorId);
    setIsEditing(true);
    setShowTableRowColorModal(true);
  };

  const handleTableRowColorCreate = () => {
    setIsEditing(false);
    setShowTableRowColorModal(true);
  };

  const handleTableRowColorDelete = (tableRowColorId) => {
    deleteTableRowColor(organizerId, tournamentId, tableRowColorId);
  };

  return (
    show && (
      <>
        <TableRowColorList
          tableRowColors={tableRowColors}
          loadingFetch={loading.loadTableRowColors}
          loadingDelete={loading.deleteTableRowColor}
          handleTableRowColorEdit={handleTableRowColorEdit}
          handleTableRowColorCreate={handleTableRowColorCreate}
          handleTableRowColorDelete={handleTableRowColorDelete}
        />
        <TableRowColorModalContainer
          show={showTableRowColorModal}
          handleShow={setShowTableRowColorModal}
          isEditing={isEditing}
          organizerId={organizerId}
          tournamentId={tournamentId}
        />
      </>
    )
  );
};

const mapState = (state) => ({
  loading: state.loading.tableRowColor,
  tableRowColors: state.tableRowColor.all,
});

const mapDispatch = (dispatch) => ({
  loadTableRowColors: (organizerId, tournamentId) =>
    dispatch(tableRowColorThunks.loadTableRowColors(organizerId, tournamentId)),
  clearAllTableRowColors: () =>
    dispatch(tableRowColorActions.clearAllTableRowColors()),
  deleteTableRowColor: (organizerId, tournamentId, tableRowColorId) =>
    dispatch(
      tableRowColorThunks.deleteTableRowColor(
        organizerId,
        tournamentId,
        tableRowColorId
      )
    ),
  setTableRowColorKeyValue: (key, value) =>
    dispatch(tableRowColorActions.setTableRowColorKeyValue(key, value)),
});

export default withRouter(
  connect(mapState, mapDispatch)(TableRowColorListContainer)
);
