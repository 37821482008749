'use-strict'

import only from "../../../utils/functions/only"
import incidenceCategories from "./hardcoded/incidenceCategories"

export default {
    isReady: false,
    isFullScreen: false,
    match: {
        id: '',
        localTeam: {
            id: '',
            lineupId: '',
            name: '',
            shortName: '',
            players: [],
            checkedInPlayers: [],
            incidencesSummary: {
                blueCards: 0,
                defPenaltiesGoals: 0,
                goals: 0,
                redCards: 0,
                yellowCards: 0
            }
        },
        visitingTeam: {
            id: '',
            lineupId: '',
            name: '',
            shortName: '',
            players: [],
            checkedInPlayers: [],
            incidencesSummary: {
                blueCards: 0,
                defPenaltiesGoals: 0,
                goals: 0,
                redCards: 0,
                yellowCards: 0
            }
        }
    },
    roadmap: [],
    incidences: [],
    intervalIds: [],
    incidenceCategories
}

const modelAttributes = [

]

export const model = (entity) => only(entity, modelAttributes)