import React, { useState } from "react";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import Button from "../../utils/components/Button";
import Table from "../../utils/components/Table";
import removeEmptyValueFromObject from "../../utils/functions/removeEmptyValueFromObject";
import * as env from "../../config/env";
import { history } from "../../redux/store";
import moment from "moment";

const EventsList = ({
  loadingFetchEvents,
  loadingDeleteEvents,
  deleteEvent,
  events,
  organizerId,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const getFilteredEvents = () => {
    const filteredEvents = events.filter(
      (event) => event.organizer.id === organizerId
    );

    if (filteredEvents.length === 0) {
      return [];
    }

    const currentDate = new Date();

    const filteredAndSortedEvents = filteredEvents.filter((event) => {
      const eventEndDate = new Date(event.event_date_finish);
      return eventEndDate >= currentDate;
    });

    const sortedEvents = filteredAndSortedEvents.sort((a, b) => {
      const dateA = new Date(a.event_date_finish);
      const dateB = new Date(b.event_date_finish);
      return dateA - dateB;
    });

    const eventSearch = sortedEvents.filter((event) => {
      return (
        event.name.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1
      );
    });

    return eventSearch;
  };
  const addActions = (events) =>
    events.map((event) => {
      return {
        ...event,
        datetime: event.event_date
          ? moment.utc(event.event_date).local().format(env.DATE_FORMAT)
          : "-",
        actions: (
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              className="btn btn-warning"
              title={<i className="fa fa-pencil"></i>}
              withTooltip={true}
              tooltipTitle="Editar / Ver"
              onClick={() =>
                history.push(keepOrganizer(`/events/${event.id}/edit`))
              }
            />
            <Button
              className="btn btn-danger"
              title={<i className="fa fa-trash"></i>}
              withTooltip={true}
              tooltipTitle="Eliminar"
              onClick={() => deleteEvent(event.id)}
              loading={loadingDeleteEvents}
              withConfirmation={true}
              confirmationTitle="Eliminar evento"
              onConfirmText="Confirmar"
              onCancelText="Cancelar"
              onCancelClass="btn-danger pull-left"
              confirmationBody="¿Estás seguro que quieres eliminar este evento?"
            />
          </div>
        ),
      };
    });

  return (
    <div>
      <Breadcrumbs
        title="Eventos"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Eventos",
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div className="ibox-title">
              <div className="row">
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar eventos en esta página..."
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: "1rem",
                  }}
                  className="col-md-3"
                >
                  <Button
                    className="btn btn-info pull-right"
                    title={
                      <span>
                        <i className="fa fa-plus-circle"></i> Nuevo
                      </span>
                    }
                    onClick={() =>
                      history.push(keepOrganizer("/events/create"))
                    }
                  />
                </div>
              </div>
            </div>
            <div className="ibox-content">
              <Table
                loading={loadingFetchEvents}
                headers={[
                  {
                    label: "Fecha",
                    ref: "datetime",
                    className: "col-sm-2 hidden-xs",
                  },
                  {
                    label: "Sede",
                    ref: "event_sede.name",
                    className: "col-sm-2 hidden-xs",
                  },
                  {
                    label: "Nombre",
                    ref: "name",
                    className: "col-xs-2 hidden-xs",
                  },
                  {
                    label: "Participantes",
                    ref: "event_invite_cant",
                    className: "col-sm-2 hidden-xs",
                  },
                  {
                    label: "Partidos",
                    ref: "matches.length",
                    className: "col-xs-2 hidden-xs",
                  },
                  {
                    label: "Acciones",
                    ref: "actions",
                    className: "col-sm-2 col-xs-3",
                  },
                ]}
                rows={addActions(getFilteredEvents())}
                notFoundMessage="No se encontraron eventos disponibles..."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsList;
