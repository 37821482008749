import React, { useEffect, useState, createRef } from "react";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import Button from "../../utils/components/Button";
import { history } from "../../redux/store";
import TeamImage from "../../assets/images/team.png";
import SpecificAlert from "../../utils/components/SpecificAlert/SpecificAlert";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import BrandImageUploader from "../../utils/components/ImageUploader/BrandImageUploader";

const TeamForm = ({
  team,
  setTeamKeyValue,
  hideSpecificAlert,
  loadingFetchTeam,
  loadingSaveTeam,
  saveTeam,
  saveButtonTitle,
  panelTitle,
  isEdit,
  teamStats,
}) => {
  const brandUploaderRef = createRef();
  const [nameError, setNameError] = useState(false);
  const [shortNameError, setShortNameError] = useState(false);

  useEffect(() => {
    hideSpecificAlert();
  }, []);

  const saveTeamHandler = () => {
    let hasError = false;
    if (team.name === "" || team.name.length > 30) {
      hasError = true;
      setNameError(true);
    } else {
      setNameError(false);
    }
    if (team.shortName === "") {
      hasError = true;
      setShortNameError(true);
    } else {
      setShortNameError(false);
    }
    if (hasError) return;

    const brandImage = brandUploaderRef.current.getFormData();

    saveTeam(brandImage);
  };

  return (
    <>
      <Breadcrumbs
        title="Equipos"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Equipos",
            active: false,
            path: keepOrganizer("/teams"),
          },
          {
            title: team.name,
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div
              className="ibox-title"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn btn-info"
                title={
                  <span>
                    <i className="fa fa-arrow-left"></i> Volver
                  </span>
                }
                onClick={() => history.push(keepOrganizer("/teams"))}
              />
            </div>
            <div className="ibox-content">
              <SpecificAlert />
              <div className="row">
                <div className="col-md-4">
                  <BrandImageUploader
                    id="image"
                    enableUploader={isEdit}
                    ref={brandUploaderRef}
                    title={panelTitle}
                    loading={loadingFetchTeam}
                    image={
                      team.image && team.image.team_big
                        ? team.image.team_big
                        : TeamImage
                    }
                    validExtensions={["jpg", "jpeg", "png"]}
                    minWidth={200}
                    minHeight={200}
                    aspectRatio={1}
                    confirmationTitle={
                      "¿Estás seguro que quieres eliminar la imagen de este jugador?"
                    }
                    confirmationBody={"Eliminar imagen del jugador"}
                  />
                </div>
                <div className="col-md-8">
                  <div className="panel panel-default">
                    <div className="panel-heading">Datos</div>
                    <div className="panel-body">
                      <div className={`form-group ${nameError && "has-error"}`}>
                        <label className="col-md-3 col-sm-2 control-label">
                          Nombre *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team.name}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue("name", e.target.value);
                            }}
                          />
                          {nameError && (
                            <span className="help-block">
                              El nombre es requerido.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          shortNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Nombre corto *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team.shortName}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue("shortName", e.target.value);
                            }}
                          />
                          {shortNameError && (
                            <span className="help-block">
                              El nombre corto es requerido.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <Button
                        className="btn btn-primary pull-right"
                        title={saveButtonTitle}
                        loading={loadingSaveTeam}
                        onClick={saveTeamHandler}
                      />
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading">Datos Historicos</div>
                    <div className="panel-body">
                      <div className={`form-group ${nameError && "has-error"}`}>
                        <label className="col-md-3 col-sm-2 control-label">
                          Partidos en total{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team?.historymatch}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue("historymatch", e.target.value);
                            }}
                          />
                          {nameError && (
                            <span className="help-block">
                              Los partidos son requeridos.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          shortNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Torneos totales{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team?.historytournament}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue(
                                "historytournament",
                                e.target.value
                              );
                            }}
                          />
                          {shortNameError && (
                            <span className="help-block">
                              El nombre corto es requerido.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          shortNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Partidos ganados{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team?.historymatchwin}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue(
                                "historymatchwin",
                                e.target.value
                              );
                            }}
                          />
                          {shortNameError && (
                            <span className="help-block">
                              Los partidos son requeridos.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          shortNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Partidos perdidos{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team?.historymatchlost}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue(
                                "historymatchlost",
                                e.target.value
                              );
                            }}
                          />
                          {shortNameError && (
                            <span className="help-block">
                              Los partidos son requeridos.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          shortNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Partidos empatados{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team?.historymatchtied}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue(
                                "historymatchtied",
                                e.target.value
                              );
                            }}
                          />
                          {shortNameError && (
                            <span className="help-block">
                              El nombre corto es requerido.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          shortNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Goles realizados{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team?.historygoals}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue("historygoals", e.target.value);
                            }}
                          />
                          {shortNameError && (
                            <span className="help-block">
                              La cantidad de goles son requeridos.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          shortNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Goles recibidos{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team?.historygoalsreceived}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue(
                                "historygoalsreceived",
                                e.target.value
                              );
                            }}
                          />
                          {shortNameError && (
                            <span className="help-block">
                              La cantidad de goles son requeridos.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          shortNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Tarjetas rojas{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team?.historycardred}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue("historycardred", e.target.value);
                            }}
                          />
                          {shortNameError && (
                            <span className="help-block">
                              Las tarjetas son requeridas.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          shortNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Tarjetas amarillas{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team?.historycardyellow}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue(
                                "historycardyellow",
                                e.target.value
                              );
                            }}
                          />
                          {shortNameError && (
                            <span className="help-block">
                              Las tarjetas son requeridas
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          shortNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Total de puntos{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={team?.historypoints}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue("historypoints", e.target.value);
                            }}
                          />
                          {shortNameError && (
                            <span className="help-block">
                              Los puntos son requeridos
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          shortNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Historial hasta la fecha{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="date"
                            className="form-control"
                            value={team?.historydate}
                            disabled={loadingFetchTeam}
                            onChange={(e) => {
                              setTeamKeyValue("historydate", e.target.value);
                            }}
                          />
                          {shortNameError && (
                            <span className="help-block">
                              La fecha es requerida
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <Button
                        className="btn btn-primary pull-right"
                        title={saveButtonTitle}
                        loading={loadingSaveTeam}
                        onClick={saveTeamHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamForm;
