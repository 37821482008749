import React, { useState } from "react";
import keepOrganizer from "../../../utils/functions/keepOrganizer";
import Button from "../../../utils/components/Button";
import Table from "../../../utils/components/Table";
import ParticipantsModalContainer from "./ParticipantsModalContainer";
import LineupModalContainer from "./LineupModalContainer";
import ParticipantConfigModal from "./ParticipantConfigModal";
import TournamentModalContainer from "./TournamentModalContainer";

const Participants = ({
  participants,
  loading,
  eventId,
  event,
  deleteParticipant,
  tournaments,
  organizerId,
  teams,
  setParticipantKeyValue,
  updateParticpant,
  defaultPage,
  defaultPageSize,
  onRefreshUsers,
  setEventKeyValue,
  updateConfigEvent,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showParticipantModal, setShowParticipantModal] = useState(false);
  const [showLineupModal, setShowLineupModal] = useState(false);
  const [showTournamentModal, setShowTournamentModal] = useState(false);
  const [showParticipantConfigModal, setShowParticipantConfigModal] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);

  const getRowsWithActions = (participants) =>
    participants.map((participant) => {
      return {
        ...participant,
        name: participant.name || participant.username,
        actions: (
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              className="btn btn-warning"
              title={<i className="fa fa-pencil"></i>}
              withTooltip={true}
              tooltipTitle="Editar"
              onClick={() => {
                setParticipantKeyValue("document", participant.document);
                setIsEditing(true);
                setShowParticipantModal(true);
              }}
            />
            <Button
              className="btn btn-danger"
              title={<i className="fa fa-trash"></i>}
              withTooltip={true}
              tooltipTitle="Eliminar"
              withConfirmation={true}
              onClick={() => deleteParticipant(participant.document)}
              // loading={loadingDeleteGroup}
              confirmationTitle="Eliminar participante"
              onConfirmText="Confirmar"
              onCancelText="Cancelar"
              onCancelClass="btn-danger pull-left"
              confirmationBody="¿Estás seguro que quieres eliminar este participante?"
            />
          </div>
        ),
      };
    });

  const getFilteredParticipants = () => {
    return participants.filter((participant) => {
      const nameMatch = participant.name
        ? participant.name.toLowerCase().includes(searchText.toLowerCase())
        : false;

      const documentMatch = participant.document
        ? participant.document.toLowerCase().includes(searchText.toLowerCase())
        : false;

      return nameMatch || documentMatch;
    });
  };

  return (
    <div>
      <div className="ibox-title">
        <div className="row">
          <div className="col-md-7">
            <input
              type="text"
              className="form-control"
              placeholder="Buscar participantes..."
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "1rem",
            }}
            className="col-md-5"
          >
            <Button
              className="btn btn-warning pull-right"
              title={
                <span>
                  <i className="fa fa-plus-circle"></i> Participante
                </span>
              }
              withTooltip={true}
              tooltipTitle="Añadir participante"
              onClick={() => setShowParticipantModal(true)}
            />
            <Button
              className="btn btn-primary pull-right"
              title={
                <span>
                  <i className="fa fa-plus-circle"></i> Plantel
                </span>
              }
              withTooltip={true}
              tooltipTitle="Añadir plantel"
              onClick={() => setShowLineupModal(true)}
            />
            <Button
              className="btn btn-info pull-right"
              title={
                <span>
                  <i className="fa fa-plus-circle"></i> Torneo
                </span>
              }
              withTooltip={true}
              tooltipTitle="Añadir torneos"
              onClick={() => setShowTournamentModal(true)}
            />
            <Button
              className="btn btn-success pull-right"
              title={
                <span>
                  <i className="fa fa-plus-circle"></i> Config
                </span>
              }
              withTooltip={true}
              tooltipTitle="Cambiar configuración"
              onClick={() => setShowParticipantConfigModal(true)}
            />
          </div>
          <div className="ibox-content">
            <Table
              loading={loading}
              headers={[
                {
                  label: "DNI",
                  ref: "document",
                  className: "col-sm-2",
                },
                {
                  label: "Nombre",
                  ref: "name",
                  className: "col-sm-2",
                },
                {
                  label: "Tipo",
                  ref: "type",
                  className: "col-xs-2 hidden-xs",
                },
                // {
                //   label: "Responsable",
                //   ref: "user_invite",
                //   className: "col-sm-2 hidden-xs",
                // },
                {
                  label: "Invitado por",
                  ref: "user_invite",
                  className: "col-xs-2 hidden-xs",
                },
                {
                  label: "Accion",
                  ref: "actions",
                  className: "col-sm-2 col-xs-3",
                },
              ]}
              rows={getRowsWithActions(getFilteredParticipants())}
              notFoundMessage="No se encontraron invitados en este evento..."
              itemsInPage={participants.length}
              defaultPage={currentPage}
              defaultPageSize={currentPageSize}
              onChangePage={(page, pageSize) => {
                setCurrentPage(page);
                setCurrentPageSize(pageSize);
                onRefreshUsers(page, pageSize);
              }}
            />
          </div>
        </div>
      </div>
      <ParticipantsModalContainer
        show={showParticipantModal}
        handleShow={setShowParticipantModal}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        eventId={eventId}
        event={event}
        currentPageSize={currentPageSize}
      />
      <LineupModalContainer
        show={showLineupModal}
        handleShow={setShowLineupModal}
        organizerId={organizerId}
        tournaments={tournaments}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        eventId={eventId}
        event={event}
      />
      <TournamentModalContainer
        show={showTournamentModal}
        handleShow={setShowTournamentModal}
        organizerId={organizerId}
        tournaments={tournaments}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        eventId={eventId}
        event={event}
      />
      <ParticipantConfigModal
        show={showParticipantConfigModal}
        handleShow={setShowParticipantConfigModal}
        eventId={eventId}
        event={event}
        setEventKeyValue={setEventKeyValue}
        updateConfigEvent={updateConfigEvent}
      />
    </div>
  );
};

export default Participants;
