"use-strict";

import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import def from "../functions/defaults";

const MyModal = ({
  show = def.false,
  handleShow = def.fn,
  title = def.null,
  body = def.null,
  buttons = def.null,
  backdrop = def.true,
  closeButton = def.true,
  handleCloseButton = def.fn,
}) => {
  return (
    <Modal show={show} onHide={() => handleShow(false)} backdrop={backdrop}>
      <Modal.Header onClick={handleCloseButton} closeButton={closeButton}>
        <Modal.Title>{title.toUpperCase()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{buttons}</Modal.Footer>
    </Modal>
  );
};

export default MyModal;
