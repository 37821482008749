"use-strict";

import only from "../../../utils/functions/only";
import moment from "moment";

export default {
  all: [],
  single: {
    name: "",
    year: new Date().getFullYear(),
    category: "",
    type: "",
    copyLineUps: null,
    teams: [],
    firstDate: moment(new Date()).utc(),
    daysBetweenDates: 0,
    playerManagementDeadline: "",
    maxPlayers: "",
    canRemoveLineUpPlayers: false,
    showDates: false,
    archived: false,
    priority: 0,
  },
  redCards: [],
  yellowCards: [],
};

const modelAttributes = [
  "id",
  "name",
  "category",
  "year",
  "type",
  "pointWinLocal",
  "pointsTieLocal",
  "pointsLoseLocal",
  "pointsWilVisiting",
  "pointsTieVisiting",
  "pointsLoseVisiting",
  "pointsYellowCard",
  "pointsRedCard",
  "pointsBlueCard",
  "playerManagementDeadline",
  "maxPlayers",
  "canRemoveLineUpPlayers",
  "showDates",
  "archived",
  "priority",
];

export const model = (entity) => only(entity, modelAttributes);
