import tableRowColorActions from '../reducers/tableRowColorReducer/tableRowColorActions'
import tableRowColorService from '../reducers/tableRowColorReducer/tableRowColorService'
import { setLoading } from '../reducers/loadingReducer'
import handler, { statusCodes } from '../../utils/functions/handler'
import { actions as alertActions } from '../reducers/alertReducer'
import without from '../../utils/functions/without'

export const loadTableRowColors = (organizerId, tournamentId) => async dispatch => {
    dispatch(setLoading.true('tableRowColor.loadTableRowColors'))
    const [error, tableRowColors] = await tableRowColorService.loadAll(organizerId, tournamentId)
    dispatch(setLoading.false('tableRowColor.loadTableRowColors'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                
            }
        }))
    }

    dispatch(tableRowColorActions.setAllTableRowColors(tableRowColors))
}

export const deleteTableRowColor = (organizerId, tournamentId, tableRowColorId) => async dispatch => {
    dispatch(setLoading.true('tableRowColor.deleteTableRowColor'))
    const [error] = await tableRowColorService.delete(organizerId, tournamentId, tableRowColorId)
    dispatch(setLoading.false('tableRowColor.deleteTableRowColor'))

    if (error) {
        return dispatch(handler({
            ...error,
            handler: {

            }
        }))
    }

    dispatch(tableRowColorActions.deleteTableRowColor(tableRowColorId))
    dispatch(alertActions.showFloatingAlert(
        `Se ha eliminado el color indicado.`, 'info', 3500
    ))
}

export const createTableRowColor = (organizerId, tournamentId, callback) => async (dispatch, getState) => {
    dispatch(setLoading.true('tableRowColor.createTableRowColor'))
    const store = getState()
    const tableRowColor = without(store.tableRowColor.single, ['id'])
    const [error, createdTableRowColor] = await tableRowColorService.create(organizerId, tournamentId, tableRowColor)
    dispatch(setLoading.false('tableRowColor.createTableRowColor'))

    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                
            }
        }))
    }

    dispatch(tableRowColorActions.addNewTableRowColor(createdTableRowColor))
    callback()
    dispatch(alertActions.showFloatingAlert(
        `Se ha creado un nuevo color para la posición nº: <b>${tableRowColor.rowNum}</b>.`, 'info', 3500
    ))
}

export const updateTableRowColor = (organizerId, tournamentId, tableRowColorId, callback) => async (dispatch, getState) => {
    dispatch(setLoading.true('tableRowColor.updateTableRowColor'))
    const store = getState()
    const tableRowColor = without(store.tableRowColor.single, ['id'])
    const [error, updatedTableRowColor] = await tableRowColorService.update(organizerId, tournamentId, tableRowColorId, tableRowColor)
    dispatch(setLoading.false('tableRowColor.updateTableRowColor'))

    if (error) {
        return dispatch(handler({
            ...error,
            handler: {

            }
        }))
    }

    dispatch(tableRowColorActions.updateTableRowColor(updatedTableRowColor))
    callback()
    dispatch(alertActions.showFloatingAlert(
        `Se ha actualizado el color para la posición nº ${tableRowColor.rowNum}`, 'info', 3500
    ))
}

export const loadTableRowColor = (organizerId, tournamentId, tableRowColorId) => async dispatch => {
    dispatch(setLoading.true('tableRowColor.loadTableRowColor'))
    const [error, tableColorRowLoaded] = await tableRowColorService.load(organizerId, tournamentId, tableRowColorId)
    dispatch(setLoading.false('tableRowColor.loadTableRowColor'))

    if (error) {
        return dispatch(handler({
            ...error,
            handler: {

            }
        }))
    }
    
    dispatch(tableRowColorActions.setSingleTableColorRow(tableColorRowLoaded))
}