import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as participantsThunks from "../../../redux/thunks/participantsThunks";
import participantsActions from "../../../redux/reducers/participantsReducer/participantsActions";
import NewParticipantModal from "./NewParticipantModal";
import defaults from "../../../utils/functions/defaults";

const ParticipantsModalContainer = ({
  show,
  handleShow,
  isEditing,
  participant,
  setIsEditing,
  createNewParticipant,
  setParticipantKeyValue,
  eventId,
  loading,
  event,
  eventsRoles,
  clearSingleParticipant,
  updateParticpant,
  loadOneParticipantEvent,
  currentPageSize,
  participants,
}) => {
  useEffect(() => {
    if (!isEditing && show) clearSingleParticipant();

    if (isEditing && show) {
      loadOneParticipantEvent(eventId);
    }
    if (isEditing && !show) {
      clearSingleParticipant(), setIsEditing(false);
    }
  }, [show]);

  const saveParticipantHandler = () =>
    isEditing
      ? updateParticpant(event, eventId)
      : createNewParticipant(
          event,
          (handleShow = defaults.fn),
          currentPageSize,
          participants
        );

  const modalTitle = () =>
    isEditing ? "Editar participante" : "Nuevo participante";

  const buttomSaveTitle = () => (isEditing ? "Guardar" : "Agregar");

  return (
    <NewParticipantModal
      show={show}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      participant={participant}
      handleShow={handleShow}
      setParticipantKeyValue={setParticipantKeyValue}
      saveParticipantHandler={saveParticipantHandler}
      modalTitle={modalTitle}
      buttomSaveTitle={buttomSaveTitle}
      loading={loading.createNewParticipant || loading.updateParticipant}
      event={event}
      eventsRoles={eventsRoles}
      clearSingleParticipant={clearSingleParticipant}
      updateParticpant={updateParticpant}
    />
  );
};

const mapState = (state) => ({
  participants: state.participant.all,
  participant: state.participant.single,
  loading: state.loading.participants,
  eventsRoles: state.participant.events_roles,
});

const mapDispatch = (dispatch) => ({
  createNewParticipant: (
    event,
    handleShow = defaults.fn,
    currentPageSize,
    participants
  ) =>
    dispatch(
      participantsThunks.createParticipantsFromPlayersOrSingleParticipant(
        event,
        undefined,
        (handleShow = defaults.fn),
        currentPageSize,
        participants
      )
    ),
  setParticipantKeyValue: (key, value) =>
    dispatch(participantsActions.setParticipantKeyValue(key, value)),
  clearSingleParticipant: () =>
    dispatch(participantsActions.clearSingleParticipant()),
  updateParticpant: (participant, eventId) =>
    dispatch(participantsThunks.updateParticipant(participant, eventId)),
  loadOneParticipantEvent: (eventId) =>
    dispatch(participantsThunks.loadOneParticipantEvent(eventId)),
});

export default withRouter(
  connect(mapState, mapDispatch)(ParticipantsModalContainer)
);
