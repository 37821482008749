import React, {useState} from "react"
import Button from "../../utils/components/Button"

const LoginForm = ({
    login,
    loginLoading
}) => {
    const [identifier, setIdentifier] = useState('')
    const [password, setPassword] = useState('')

    const [identifierError, setIdentifierError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)

    const onPressHandle = () => {
        let formInError =  false

        if(!identifier){
            formInError = true
            setIdentifierError(true)
        }

        if(!password){
            formInError = true
            setPasswordError(true)
        }

        if(formInError) return

        login({
            identifier,
            password
        })
    }

    return (
        <>
            <div className={`form-group ${identifierError && 'has-error'}`}>
                <label htmlFor="identifier">DNI / Email</label>
                <input
                    id="identifier"
                    name="identifier"
                    type="text"
                    className="form-control"
                    placeholder="DNI / Email"
                    required={true}
                    value={identifier}
                    onChange={(e) => {
                        const {value} = e.target
                        setIdentifierError(!value)
                        setIdentifier(value)
                    }}
                    autoFocus
                />
                {identifierError && <span className="help-block">El DNI / email es un valor requerido</span>}
            </div>
            <div className={`form-group ${passwordError && 'has-error'}`}>
                <label htmlFor="password">Contraseña</label>
                <input
                    id="password"
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="contraseña"
                    required={true}
                    value={password}
                    onChange={(e) => {
                        const {value} = e.target
                        setPasswordError(!value)
                        setPassword(value)
                    }}
                />
                {passwordError && <span className="help-block">La contraseña es un valor requerido</span>}
            </div>
            <Button
                type="button"
                className="btn btn-primary block full-width m-b"
                onClick={onPressHandle}
                title={<b>Iniciar sesión</b>}
                loading={loginLoading}
            />
        </>
    )
}

export default LoginForm