"use-strict";

import authConstants from "../authReducer/authConstants";
import constants from "./matchConstants";
import initialState from "./matchState";

const matchReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_ALL_MATCHES: {
      return {
        ...state,
        all: action.payload,
      };
    }
    case constants.CLEAR_ALL_MATCHES: {
      return {
        ...state,
        all: initialState.all,
      };
    }
    case constants.CLEAR_ALL_MATCHES_GROUPS: {
      return {
        ...state,
        MatchsGroups: [],
      };
    }
    case constants.SET_MATCH_KEY_VALUE: {
      return {
        ...state,
        single: {
          ...state.single,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case constants.CLEAR_SINGLE_MATCH: {
      return {
        ...state,
        single: initialState.single,
      };
    }
    case constants.PUSH_NEW_MATCH: {
      const matches = [...state.all];
      matches.push(action.payload);
      return {
        ...state,
        all: matches,
      };
    }
    case constants.DELETE_MATCH: {
      const matches = [...state.all];
      const index = matches.findIndex((match) => match.id === action.matchId);
      matches.splice(index, 1);
      return {
        ...state,
        all: matches,
      };
    }
    case constants.REPLACE_MATCH: {
      const matches = [...state.all];
      const index = matches.findIndex(
        (match) => match.id === action.payload.id
      );
      matches[index] = action.payload;
      return {
        ...state,
        all: matches,
      };
    }
    case constants.SET_SINGLE_MATCH: {
      return {
        ...state,
        single: action.payload,
      };
    }
    case constants.SET_ORGANIZER_MATCHES: {
      return {
        ...state,
        organizerMatches: action.payload,
      };
    }
    case constants.CLEAR_ORGANIZER_MATCHES: {
      return {
        ...state,
        organizerMatches: initialState.organizerMatches,
      };
    }
    case authConstants.LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default matchReducer;
