'use-strict'

import constants from './sanctionConstants'

export default {
    setAllSanctions: (payload) => ({
        type: constants.SET_ALL_SANCTIONS,
        payload
    }),
    clearAllSanctions: () => ({
        type: constants.CLEAR_ALL_SANCTIONS
    }),
    setSanctionKeyValue: (key, value) => ({
        type: constants.SET_SANCTION_KEY_VALUE,
        payload: { key, value }
    }),
    clearSingleSanction: () => ({
        type: constants.CLEAR_SINGLE_SANCTION
    }),
    pushNewSanction: (payload) => ({
        type: constants.PUSH_NEW_SANCTION,
        payload
    }),
    setSingleSanction: (payload) => ({
        type: constants.SET_SINGLE_SANCTION,
        payload
    }),
    deleteSanction: (matchId) => ({
        type: constants.DELETE_SANCTION,
        matchId
    }),
    replaceSanction: (payload) => ({
        type: constants.REPLACE_SANCTION,
        payload
    })
}