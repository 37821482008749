import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from '../../../redux/store'
import WorksheetIncidenceList from './WorksheetIncidenceList'
import * as worksheetThunks from '../../../redux/thunks/worksheetThunks'
import keepOrganizer from '../../../utils/functions/keepOrganizer'

const WorksheetIncidenceListContainer = ({
    match,
    worksheet,
    deleteIncidence,
    loading
}) => {

    const organizerId = match.params.organizerId
    const tournamentId = match.params.tournamentId
    const matchId = match.params.matchId

    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        if (!worksheet.isReady) {
            history.push(
                `/organizers/${organizerId}/tournaments/${tournamentId}/matches/${matchId}/worksheet`
            )
        }
        setIsMounted(true)
    }, [])

    useEffect(() => {
        if (isMounted) history.push(keepOrganizer(''))
    }, [organizerId])

    const deleteIncidenceHandler = (incidenceId) => deleteIncidence(organizerId, tournamentId, matchId, incidenceId)

    return (
        <WorksheetIncidenceList
            incidences={worksheet.incidences}
            incidenceCategories={worksheet.incidenceCategories}
            deleteIncidence={deleteIncidenceHandler}
            loadingDeleteIncidence={loading.deleteIncidence}
            match={worksheet.match}
        />
    )
}

const mapState = state => ({
    worksheet: state.worksheet,
    loading: state.loading.worksheet
})

const mapDispatch = dispatch => ({
    deleteIncidence: (organizerId, tournamentId, matchId, incidenceId) => dispatch(worksheetThunks.deleteIncidence(organizerId, tournamentId, matchId, incidenceId)) 
})

export default withRouter(connect(mapState, mapDispatch)(WorksheetIncidenceListContainer))