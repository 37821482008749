import React from "react";
import Button from "../../../utils/components/Button";
import MyModal from "../../../utils/components/Modal";

const NewCheckInModal = ({ show, handleShow }) => {
  return (
    <MyModal
      show={show}
      title="Nuevo Check In"
      handleShow={handleShow}
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12 m-b-1">
            <div className={`form-group` /* ${nameError && 'has-error'}*/}>
              <label className="col-md-3 col-sm-2 control-label">DNI * </label>
              <div className="col-md-9 col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  // value={player.firstName}
                  // disabled={disable()}
                  // onChange={(e) => {
                  //     setPlayerKeyValue('firstName', e.target.value)
                  // }}
                />
                {/* {nameError && <span className="help-block">El nombre es requerido.</span>} */}
              </div>
            </div>
          </div>
          <div className="col-md-12 m-b-1">
            <div className={`form-group ` /*${lastNameError && 'has-error'} */}>
              <label className="col-md-3 col-sm-2 control-label">
                Hora Actual{" "}
              </label>
              <div className="col-md-9 col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  // value={player.lastName}
                  // disabled={disable()}
                  // onChange={(e) => {
                  //     setPlayerKeyValue('lastName', e.target.value)
                  // }}
                />
                {/* {lastNameError && <span className="help-block">El apellido es requerido.</span>} */}
              </div>
            </div>
          </div>

          <div className="col-md-12 m-b-1">
            <Button
              title={"Guardar"}
              className="btn btn-primary pull-right"
              // onClick={createNewPlayerHandler}
              // loading={disable()}
            />
          </div>
        </div>
      }
      buttons={
        <Button
          title="Finalizar"
          className="btn btn-primary"
          onClick={() => handleShow(false)}
        />
      }
    />
  );
};

export default NewCheckInModal;
