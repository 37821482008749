"use-strict";

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import def from "../../utils/functions/defaults";
import ConfirmationModal from "../../utils/components/ConfirmationModal";
import { history } from "../../redux/store";

const OrganizerSwitch = ({
  organizers = def.arr,
  location,
  match,
  setSelectedOrganizer,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [clickedOrganizer, setClickedOrganizer] = useState("");

  const getSelectedOrganizer = () => {
    const organizerId = localStorage.getItem("organizerId");
    const organizer = organizers.find(
      (organizer) => organizer.id === organizerId
    );
    if (!organizer) return "";
    return organizer.name;
  };

  return (
    <div className="input-group-btn">
      <button
        data-toggle="dropdown"
        className="btn btn-white dropdown-toggle"
        type="button"
        aria-expanded="false"
      >
        {getSelectedOrganizer()} <span className="caret"></span>
      </button>
      <ul className="dropdown-menu pull-right">
        {organizers.map((organizer, key) => (
          <li key={key + "organizer"}>
            <a
              onClick={() => {
                setShowConfirmationModal(true);
                setClickedOrganizer(organizer.id);
              }}
            >
              {organizer.name}
            </a>
          </li>
        ))}
      </ul>
      <ConfirmationModal
        show={showConfirmationModal}
        title="Cambiar empresa"
        onConfirmText="Confirmar"
        onCancelText="Cancelar"
        onCancelClass="btn-danger pull-left"
        body="¿Estás seguro que querés cambiar de empresa?.<br><br>Podés perder cambios si estás <b>creando/actualizando</b> algún registro."
        onConfirm={() => {
          const path = location.pathname.split("/");
          path.splice(0, 3);
          // se guarda en localStorage para cambio de ruta sincrono
          localStorage.setItem("organizerId", clickedOrganizer);
          // se guarda en redux para cambio de paths en menu asincrono y reactivo
          setSelectedOrganizer(clickedOrganizer);
          // solo se reemplaza en la ruta si estamos parados en alguna que dependa
          // de un organizer
          if (match.params.organizerId) {
            history.push(`/organizers/${clickedOrganizer}/${path.join("/")}`);
          }
          setClickedOrganizer("");
          setShowConfirmationModal(false);
        }}
        onCancel={() => {
          setClickedOrganizer("");
          setShowConfirmationModal(false);
        }}
      />
    </div>
  );
};

export default withRouter(OrganizerSwitch);
