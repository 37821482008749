import { actions as alertActions } from "../../redux/reducers/alertReducer";

("use-strict");

import React, { useEffect } from "react";
import organizerActions from "../../redux/reducers/organizerReducer/organizerActions";
import * as organizerThunks from "../../redux/thunks/organizerThunks";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const Home = ({
  organizer,
  organizerSelectedId,
  loadOrganizer,
  clearSingleOrganizer,
}) => {
  useEffect(() => {
    const organizerId = localStorage.getItem("organizerId");

    if (!organizerId) return;

    loadOrganizer(organizerId);

    return () => clearSingleOrganizer();
  }, [organizerSelectedId]);

  return (
    <>
      {organizer.datastudio ? (
        <iframe
          width="100%"
          height="1700"
          src={organizer.datastudio}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      ) : (
        ""
      )}
    </>
  );
};

const mapState = (state) => ({
  organizer: state.organizer.single,
  organizerSelectedId: state.organizer.selected,
});

const mapDispatch = (dispatch) => ({
  loadOrganizer: (organizerId) =>
    dispatch(organizerThunks.loadOrganizer(organizerId)),
  clearSingleOrganizer: () => dispatch(organizerActions.clearSingleOrganizer()),
});

export default withRouter(connect(mapState, mapDispatch)(Home));
