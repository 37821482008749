'use-strict'

import httpClient from '../../../http/httpClient'

const httpOrganizer = (organizerId) => httpClient(`/organizers/${organizerId}`)

export default {
    loadAll: (organizerId) => httpOrganizer(organizerId).get('/sedes'),
    create: (organizerId, sede) => httpOrganizer(organizerId).post('/sedes', sede),
    loadOne: (organizerId, sedeId) => httpOrganizer(organizerId).get(`/sedes/${sedeId}`),
    update: (organizerId, sedeId, sede) => httpOrganizer(organizerId).patch(`/sedes/${sedeId}`, sede),
    delete: (organizerId, sedeId) => httpOrganizer(organizerId).delete(`/sedes/${sedeId}`),
    images: (organizerId, sedeId, data) => httpOrganizer(organizerId).post(`/sedes/${sedeId}/images`, data)
}