import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TournamentView from "./TournamentView";
import { actions as alertActions } from "../../redux/reducers/alertReducer";
import { history } from "../../redux/store";
import keepOrganizer from "../../utils/functions/keepOrganizer";

const TournamentViewContainer = ({ match, hideSpecificAlert, tournament }) => {
  const organizerId = match.params.organizerId;
  const tournamentId = match.params.tournamentId;
  const isEdit = /edit/.test(location.pathname);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    hideSpecificAlert();
  }, []);

  useEffect(() => {
    if (isMounted) history.push(keepOrganizer("/tournaments"));
  }, [organizerId]);

  return (
    <TournamentView
      tournamentEditing={isEdit}
      organizerId={organizerId}
      tournamentId={tournamentId}
      tournamentType={tournament.type}
      tournamentName={tournament.name}
    />
  );
};

const mapState = (state) => ({
  tournament: state.tournament.single,
});

const mapDispatch = (dispatch) => ({
  hideSpecificAlert: () => dispatch(alertActions.hideSpecificAlert()),
});

export default withRouter(
  connect(mapState, mapDispatch)(TournamentViewContainer)
);
