import dateActions from '../reducers/dateReducer/dateActions'
import dateService from '../reducers/dateReducer/dateService'
import { model as dateModel } from '../reducers/dateReducer/dateState'
import { setLoading } from '../reducers/loadingReducer'
import handler, { statusCodes } from '../../utils/functions/handler'
import { actions as alertActions } from '../reducers/alertReducer'
import without from '../../utils/functions/without'
import moment from 'moment'
import * as env from '../../config/env'

export const loadAllDates = (organizerId, tournamentId) => async dispatch => {
    dispatch(setLoading.true('date.loadAllDates'))
    const [error, dates] = await dateService.loadAll(organizerId, tournamentId)
    dispatch(setLoading.false('date.loadAllDates'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                
            }
        }))
    }
    dispatch(dateActions.setAllDates(dates.map(dateModel)))
}

export const createNewDate = (organizerId, tournamentId, handleCloseModal) => async (dispatch, getState) => {
    dispatch(setLoading.true('date.createNewDate'))
    const store = getState()
    const date = without(store.date.single, ['id'])
    const [error, createdDate] = await dateService.create(organizerId, tournamentId, date)
    dispatch(setLoading.false('date.createNewDate'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                
            }
        }))
    }

    dispatch(dateActions.pushNewDate(dateModel(createdDate)))
    if (handleCloseModal) handleCloseModal()
    dispatch(alertActions.showFloatingAlert(
        `Se ha creado la jornada: <b>${createdDate.name}</b>.`, 'info', 3500
    ))
}

export const deleteDate = (organizerId, tournamentId, dateId) => async (dispatch, getState) => {
    dispatch(setLoading.true('date.deleteDate'))
    const store = getState()
    const dates = store.date.all
    const deletedDate = dates.find(date => date.id === dateId)
    const [error] = await dateService.delete(organizerId, tournamentId, dateId)
    dispatch(setLoading.false('date.deleteDate'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                [statusCodes.CONFLICT]: {
                    msg: 'Antes de eliminar esta jornada, asegurate de que no tenga partidos.',
                    alertStyle: 'danger'
                }
            }
        }))
    }
    dispatch(dateActions.deleteDate(dateId))
    dispatch(alertActions.showFloatingAlert(
        `Se ha eliminado la jornada: <b>${deletedDate.name}</b>.`, 'info', 3500
    ))
}

export const loadDate = (organizerId, tournamentId, dateId) => async(dispatch, getState) => {
    dispatch(setLoading.true('date.loadDate'))
    const [error, dateLoaded] = await dateService.loadOne(organizerId, tournamentId, dateId)
    dispatch(setLoading.false('date.loadDate'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {

            }
        }))
    }

    dateLoaded.datetime = moment(dateLoaded.datetime).utc().format(env.DATE_FORMAT)
    dispatch(dateActions.setSingleDate(dateLoaded))
}

export const updateDate = (organizerId, tournamentId, dateId, handleCloseModal) => async(dispatch, getState) => {
    dispatch(setLoading.true('date.updateDate'))
    const store = getState()
    const date = without(store.date.single, ['id'])
    const [error, updatedDate] = await dateService.update(organizerId, tournamentId, dateId, date)
    dispatch(setLoading.false('date.updateDate'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                [statusCodes.BAD_REQUEST]: {
                    msg: 'Ocurrió un error formando los datos. Por favor intenta de nuevo.'
                }
            }
        }))
    }

    dispatch(dateActions.replaceDate(updatedDate))
    if (handleCloseModal) handleCloseModal()
    dispatch(alertActions.showFloatingAlert(
        `Se ha actualizado la jornada ${date.name}`, 'info', 3500
    ))
}

export const displaceDate = (organizerId, tournamentId, dateId, displace, handleCloseModal) => async(dispatch, getState) => {
    dispatch(setLoading.true('date.displaceDate'))
    const [error, dateDisplaced] = await dateService.displace(organizerId, tournamentId, dateId, displace)
    dispatch(setLoading.false('date.displaceDate'))

    if(error){
        return dispatch(handler({
            ...error,
            handler: {
                [statusCodes.BAD_REQUEST]: {
                    msg: 'Ocurrió un error al desplazar la fecha. Por favor intenta de nuevo.'
                }
            }
        }))
    }

    dispatch(dateActions.displaceDate(dateDisplaced))
    if (handleCloseModal) handleCloseModal()
    dispatch(alertActions.showFloatingAlert(
        `Se actualizado la fecha la jornada`, 'info', 3500
    ))
}