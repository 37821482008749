'use-strict'

import httpClient from '../../../http/httpClient'

const httpTournament = (organizerId, tournamentId) => httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}`)

export default {
    loadOneMatch: (organizerId, tournamentId, matchId) => {
        return httpTournament(organizerId, tournamentId).get(`/matches/${matchId}`)
    }
}