'use-strict'

import httpClient from '../../../http/httpClient'

const httpMatch = (organizerId, tournamentId, matchId) => {
    return httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}/matches/${matchId}`)
}

export default {
    createIncidence: (organizerId, tournamentId, matchId, incidence) => {
        return httpMatch(organizerId, tournamentId, matchId).post('/incidences', incidence)
    },
    loadIncidences: (organizerId, tournamentId, matchId) => {
        return httpMatch(organizerId, tournamentId, matchId).get('/incidences')
    },
    deleteIncidence: (organizerId, tournamentId, matchId, incidenceId) => {
        return httpMatch(organizerId, tournamentId, matchId).delete(`/incidences/${incidenceId}`)
    }
}