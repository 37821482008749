import Breadcrumbs from "../../utils/components/Breadcrumbs";

'use-strict'

import React from 'react'
import {history} from "../../redux/store";
import Button from "../../utils/components/Button";

const Help = () => {
    return (
        <>
            <Breadcrumbs
                title='Ayuda'
                opts={[{
                    title: 'Inicio',
                    path: '/'
                }, {
                    title: 'Ayuda',
                    active: true
                }]}
            />
            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox m-t">
                        <div className="ibox-title">
                            <div className="row">
                                <div className="col-xs-6">
                                    <p>Video Tutoriales</p>
                                </div>
                                <div className="col-xs-6">
                                    <Button
                                        className="btn btn-info pull-right"
                                        title={<span>No encuentro lo que busco <i className="fa fa-question-circle-o"></i> </span>}
                                        onClick={() => window.location.href = "mailto:info@fansbury.com?subject=AyudaAdmin&body=Mensaje..."}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ibox-content">
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="card">
                                        <video controls>
                                            <source src="https://fansbury-prod-media.s3.amazonaws.com/admin+videos/WhatsApp+Video+2020-05-23+at+8.32.36+PM+(1).mp4" type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                        <div className="card-body">
                                            <p className="card-text">
                                                Como cargar partidos, jornadas y grupos.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4  col-sm-6 col-xs-12">
                                    <div className="card">
                                        <video controls>
                                            <source src="https://fansbury-prod-media.s3.amazonaws.com/admin+videos/WhatsApp+Video+2020-05-23+at+8.32.37+PM.mp4" type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                        <div className="card-body">
                                            <p className="card-text">
                                                Como cargar lineups
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4  col-sm-6 col-xs-12">
                                    <div className="card">
                                        <video controls>
                                            <source src="https://fansbury-prod-media.s3.amazonaws.com/admin+videos/WhatsApp+Video+2020-05-23+at+8.33.58+PM.mp4" type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                        <div className="card-body">
                                            <p className="card-text">
                                                Como cargar un torneo
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Help
