import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import OrganizerList from './OrganizerList'
import * as organizerThunks from '../../redux/thunks/organizerThunks'

const OrganizerListContainer = ({
    loadAllOrganizers,
    clearAllOrganizers,
    deleteOrganizer,
    organizers,
    loading,
}) => {

    useEffect(() => {
        if (organizers === []){
            clearAllOrganizers()
            loadAllOrganizers()
        }
    },[])

    const deleteOrganizerHandler = (organizerId) => deleteOrganizer(organizerId)

    return (
        <OrganizerList 
            deleteOrganizer={deleteOrganizerHandler}
            organizers={organizers}
            loadingFetchOrganizers={loading.loadAllOrganizers}
            loadingDeleteOrganizer={loading.deleteOrganizer}
        />
    )
}

const mapState = state => ({
    organizers: state.organizer.all,
    loading: state.loading.organizer
})

const mapDispatch = dispatch => ({
    loadAllOrganizers: () => dispatch(organizerThunks.loadAllOrganizers()),
    clearAllOrganizers: () => dispatch(organizerThunks.clearAllOrganizers()),
    deleteOrganizer: (organizerId) => dispatch(organizerThunks.deleteOrganizer(organizerId))
})

export default withRouter(connect(mapState, mapDispatch)(OrganizerListContainer))