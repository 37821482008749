"use-strict";

import httpClient from "../../../http/httpClient";

const httpOrganizer = (organizerId) => httpClient(`/organizers/${organizerId}`);

export default {
  loadAll: (organizerId) =>
    httpOrganizer(organizerId).get("/tournaments?includeArchived=1"),
  loadOne: (organizerId, tournamentId) =>
    httpOrganizer(organizerId).get(`/tournaments/${tournamentId}`),
  create: (organizerId, tournament) =>
    httpOrganizer(organizerId).post("/tournaments", tournament),
  update: (organizerId, tournamentId, tournament) =>
    httpOrganizer(organizerId).patch(
      `/tournaments/${tournamentId}`,
      tournament
    ),
  delete: (organizerId, tournamentId) =>
    httpOrganizer(organizerId).delete(`/tournaments/${tournamentId}`),
  loadRedCards: (organizerId, tournamentId) =>
    httpOrganizer(organizerId).get(
      `/tournaments/${tournamentId}/incidences?type=RedCard`
    ),
  loadYellowCards: (organizerId, tournamentId) =>
    httpOrganizer(organizerId).get(
      `/tournaments/${tournamentId}/incidences?type=YellowCard`
    ),
};
