import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import "metismenu";
import navigation from "../../config/navigation";
import Link from "../../utils/components/Link";
import addOrganizer from "../../utils/functions/addOrganizer";

const Navigation = ({ location, user, organizers }) => {
  const menuReference = React.createRef();
  const formatMenu = (pathname, nav_id) => {
    // si se agrega un menu con segundo nivel hay que modificar este componente
    // no quedó preparado para tener childrens
    const inMenu = [].find((nav) => nav.id === nav_id) || {};
    const newMenu = [];
    navigation.forEach((nav) => {
      if (!nav.parent_id && nav.roles.some((role) => role())) {
        const childs = navigation.filter((child) => child.parent_id === nav.id);
        const item = {};
        item.id = nav.id;
        item.route = nav.route;
        item.title = nav.title;
        item.icon = nav.icon;
        item.order = nav.order;
        item.active = !!(
          (addOrganizer(nav.route) === pathname &&
            nav.id === nav_id &&
            !inMenu.active) ||
          childs.find(
            (child) => child.route === pathname && child.id === nav_id
          )
        );
        item.childs = childs.map((child) => {
          const item = {};
          item.id = child.id;
          item.route = child.route;
          item.title = child.title;
          item.order = child.order;
          item.active = child.route === pathname && child.id === nav_id;
          return item;
        });
        newMenu.push(item);
      }
    });
    return newMenu;
  };
  const lists = () => {
    const currentRoute =
      navigation.find((nav) => addOrganizer(nav.route) === location.pathname) ||
      {};
    return formatMenu(location.pathname, currentRoute.id).map((nav, i) => {
      let arrow = nav.childs.length ? <span className="fa arrow"></span> : null;
      let icon = <i className={nav.icon}></i>;
      let link = (
        <Link to={nav.route}>
          {icon}
          <span className="nav-label">{nav.title}</span>
          {arrow}
        </Link>
      );
      if (nav.route === "/#")
        link = (
          <a href="#" onClick={(e) => e.preventDefault()}>
            {icon}
            <span className="nav-label">{nav.title}</span>
            {arrow}
          </a>
        );
      return (
        <li
          key={`list-${nav.route}${i}`}
          className={`${nav.active ? "active" : ""}`}
        >
          {link}
          {nav.childs.length ? (
            <ul
              className={`nav nav-second-level collapse ${
                nav.active ? "in" : ""
              }`}
            >
              {nav.childs.map((child, j) => {
                let link = <Link to={child.route}>{child.title}</Link>;
                if (child.route === "#")
                  link = (
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      {child.title}
                    </a>
                  );
                return (
                  <li
                    key={`sublist-${child.route}${i}`}
                    className={`${child.active ? "active" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {link}
                  </li>
                );
              })}
            </ul>
          ) : null}
        </li>
      );
    });
  };
  const getSelectedOrganizer = () => {
    const organizerId = localStorage.getItem("organizerId");
    const organizer = organizers.find(
      (organizer) => organizer.id === organizerId
    );
    if (!organizer) return "";
    return organizer;
  };

  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <ul className="nav metismenu" id="side-menu" ref={menuReference}>
        <li className="nav-header">
          <div className="dropdown profile-element">
            <a data-toggle="dropdown" className="dropdown-toggle">
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
                className="clear"
              >
                <div>
                  <span className="block m-t-xs">
                    <strong className="font-bold">{user.username}</strong>
                  </span>
                  <span className="text-muted text-xs block">
                    {getSelectedOrganizer().name}
                  </span>
                </div>

                <img
                  src={getSelectedOrganizer().image?.organizer_logo_big}
                  style={{ width: 35, height: 35 }}
                />
              </span>
            </a>
          </div>
          <div className="logo-element">FS+</div>
        </li>
        {lists()}
      </ul>
    </nav>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    organizers: state.organizer.all,
  };
}

export default withRouter(connect(mapStateToProps)(Navigation));
