'use-strict'

import authConstants from '../authReducer/authConstants'
import constants from './dateConstants'
import initialState from './dateState'

const dateReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_ALL_DATES: {
            return {
                ...state,
                all: action.payload
            }
        }
        case constants.PUSH_NEW_DATE: {
            const dates = [...state.all]
            dates.push(action.payload)
            return {
                ...state,
                all: dates
            }
        }
        case constants.SET_DATE_KEY_VALUE: {
            return {
                ...state,
                single: {
                    ...state.single,
                    [action.payload.key]: action.payload.value
                }
            }
        }
        case constants.DELETE_DATE: {
            const dates = [...state.all]
            const index = dates.findIndex(date => date.id === action.dateId)
            dates.splice(index, 1)
            return {
                ...state,
                all: dates
            }
        }
        case constants.CLEAR_ALL_DATES: {
            return {
                ...state,
                all: initialState.all
            }
        }
        case constants.CLEAR_SINGLE_DATE: {
            return {
                ...state,
                single: initialState.single
            }
        }
        case constants.SET_SINGLE_DATE: {
            return {
                ...state,
                single: action.payload
            }
        }
        case constants.REPLACE_DATE: {
            const dates = [...state.all]
            const index = dates.findIndex(date => date.id === action.payload.id)
            dates[index] = action.payload
            return {
                ...state,
                all: dates
            }
        }
        case constants.DISPLACE_DATE: {
            const dates = [...state.all]
            const index = dates.findIndex(date => date.id === action.payload.id)
            dates[index] = {...dates[index], datetime: action.payload.datetime}
            return {
                ...state,
                all: dates
            }
        }
        case authConstants.LOGOUT: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default dateReducer