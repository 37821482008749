'use-strict'

import authConstants from '../authReducer/authConstants'
import constants from './tableRowColorConstants'
import initialState from './tableRowColorState'

const tableRowColorReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_ALL_TABLE_ROW_COLORS: {
            return {
                ...state,
                all: action.payload
            }
        }
        case constants.SET_TABLE_ROW_COLOR_KEY_VALUE: {
            return {
                ...state,
                single: {
                    ...state.single,
                    [action.payload.key]: action.payload.value
                }
            }
        }
        case constants.CLEAR_ALL_TABLE_ROW_COLORS: {
            return {
                ...state,
                all: initialState.all
            }
        }
        case constants.CLEAR_SINGLE_TABLE_ROW_COLOR: {
            return {
                ...state,
                single: initialState.single
            }
        }
        case constants.DELETE_TABLE_ROW_COLOR: {
            const tableRowColors = [...state.all]
            const index = tableRowColors.findIndex(tableRowColor => tableRowColor.id === action.tableColorRowId)
            tableRowColors.splice(index, 1)
            return {
                ...state,
                all: tableRowColors
            }
        }
        case constants.SET_SINGLE_TABLE_COLOR_ROW: {
            return {
                ...state,
                single: action.payload
            }
        }
        case constants.UPDATE_TABLE_ROW_COLOR: {
            const tableRowColors = [...state.all]
            const index = tableRowColors.findIndex(tableRowColor => tableRowColor.id === action.payload.id)
            tableRowColors[index] = action.payload
            return {
                ...state,
                all: tableRowColors
            }
        }
        case constants.ADD_NEW_TABLE_ROW_COLOR: {
            const tableRowColor = [...state.all]
            tableRowColor.push(action.payload)
            return {
                ...state,
                all: tableRowColor
            }
        }
        case authConstants.LOGOUT: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default tableRowColorReducer