import React, { useState } from "react";
import MyModal from "../../../utils/components/Modal";
import Button from "../../../utils/components/Button";

const NewCheckInModal = ({
  show,
  handleShow,
  setCheckInKeyValue,
  checkIn,
  createNewCheckIn,
  clearSingleCheckIn,
  event,
  checkInAll,
  currentPageSize,
}) => {
  const [documentError, setDocumentError] = useState(false);

  // const disable = () => loading;

  const createNewValidation = () => {
    let hasError = false;

    if (checkIn.document === "") {
      setDocumentError(true);
      hasError = true;
    }

    createNewCheckIn(event.id, checkInAll, currentPageSize);
    clearSingleCheckIn();
    // handleShow(false)
  };

  return (
    <MyModal
      show={show}
      handleShow={handleShow}
      title="Nuevo Check In"
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12 m-b-1">
            <div className={`form-group ${documentError && "has-error"}`}>
              <label className="col-md-3 col-sm-2 control-label">DNI * </label>
              <div className="col-md-9 col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={checkIn?.document}
                  // disabled={disable()}
                  onChange={(e) => {
                    setCheckInKeyValue("document", e.target.value);
                  }}
                />
                {documentError && (
                  <span className="help-block">El DNI es requerido.</span>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      buttons={
        <Button
          title={"Validar"}
          className="btn btn-primary pull-right"
          onClick={createNewValidation}
          // loading={disable()}
        />
      }
    />
  );
};

export default NewCheckInModal;
