import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppForm from "./AppForm";
import * as organizerThunks from "../../../redux/thunks/organizerThunks";
import { actions as alertActions } from "../../../redux/reducers/alertReducer";
import organizerActions from "../../../redux/reducers/organizerReducer/organizerActions";
import keepOrganizer from "../../../utils/functions/keepOrganizer";
import { history } from "../../../redux/store";

const AppFormContainer = ({
  organizer,
  setOrganizerKeyValue,
  clearSingleOrganizer,
  updateOrganizerAppConfig,
  loadOrganizer,
  hideSpecificAlert,
  match,
  loading,
}) => {
  const organizerId = match.params.organizerId;
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    clearSingleOrganizer();
    loadOrganizer(organizerId);
    setIsReady(true);
  }, []);

  useEffect(() => {
    clearSingleOrganizer();
    loadOrganizer(organizerId);
    if (isReady) history.push(keepOrganizer("/application"));
  }, [organizerId]);

  const updateOrganizerAppConfigHandler = (images) =>
    updateOrganizerAppConfig(organizerId, images);

  const setColorConfig = (color, variable) => {
    if (variable == "PrimaryColor") {
      setOrganizerKeyValue("backGroundPrimaryColor", color.hex);
      setOrganizerKeyValue("backGroundSecondaryColor", color.hex);
      setOrganizerKeyValue("inputSecondaryColor", color.hex);
    } else if (variable == "SecondaryColor") {
      setOrganizerKeyValue("textPrimaryColor", color.hex);
      setOrganizerKeyValue("textTertiaryColor", color.hex);
      setOrganizerKeyValue("inputPrimaryColor", color.hex);
    } else if (variable == "TertiaryColor") {
      setOrganizerKeyValue("backGroundTertiaryColor", color.hex);
      setOrganizerKeyValue("textSecondaryColor", color.hex);
      setOrganizerKeyValue("textQuaternaryColor", color.hex);
    } else setOrganizerKeyValue(variable, color.hex);
  };

  const getColorConfig = (variable) => {
    if (variable == "PrimaryColor") return organizer.backGroundPrimaryColor;
    if (variable == "SecondaryColor") return organizer.textPrimaryColor;
    if (variable == "TertiaryColor") return organizer.backGroundTertiaryColor;

    return organizer[variable];
  };

  const configColorVariablesFull = [
    { name: "backGroundPrimaryColor", title: "Color de fondo principal" },
    { name: "backGroundSecondaryColor", title: "Color de fondo secundario" },
    { name: "backGroundTertiaryColor", title: "Color de fondo terciario" },
    { name: "textPrimaryColor", title: "Color de texto principal" },
    { name: "textSecondaryColor", title: "Color de texto secundario" },
    { name: "textTertiaryColor", title: "Color de texto terciario" },
    { name: "textQuaternaryColor", title: "Color de text cuaternario" },
    { name: "inputPrimaryColor", title: "Color de input principal" },
    { name: "inputSecondaryColor", title: "Color de input secundario" },
  ];

  const configColorVariablesDefault = [
    { name: "PrimaryColor", title: "Color principal" },
    { name: "SecondaryColor", title: "Color secundario" },
    { name: "TertiaryColor", title: "Color terciario" },
  ];

  const configColorVariables =
    organizer.tier == "TIER_GOLD"
      ? configColorVariablesDefault
      : configColorVariablesFull;

  const panelTitle = organizer.name;

  return (
    <AppForm
      setOrganizerKeyValue={setOrganizerKeyValue}
      saveOrganizerAppConfig={updateOrganizerAppConfigHandler}
      hideSpecificAlert={hideSpecificAlert}
      organizer={organizer}
      loadingSaveOrganizer={loading.updateOrganizer}
      loadingLoadOrganizer={loading.loadOrganizer}
      setColorConfig={setColorConfig}
      getColorConfig={getColorConfig}
      configColorVariables={configColorVariables}
      panelTitle={panelTitle}
    />
  );
};

const mapState = (state) => ({
  organizer: state.organizer.single,
  loading: state.loading.organizer,
});

const mapDispatch = (dispatch) => ({
  hideSpecificAlert: () => dispatch(alertActions.hideSpecificAlert()),
  setOrganizerKeyValue: (key, value) =>
    dispatch(organizerActions.setOrganizerKeyValue(key, value)),
  clearSingleOrganizer: () => dispatch(organizerActions.clearSingleOrganizer()),
  loadOrganizer: (organizerId) =>
    dispatch(organizerThunks.loadOrganizer(organizerId)),
  updateOrganizerAppConfig: (organizerId, images) =>
    dispatch(organizerThunks.updateOrganizerAppConfig(organizerId, images)),
});

export default withRouter(connect(mapState, mapDispatch)(AppFormContainer));
