import React, { useState } from "react";
import WorksheetWrapper from "../components/WorksheetWrapper";
import Table from "../../../utils/components/Table";
import Button from "../../../utils/components/Button";
import moment from "moment";
import _ from "lodash";
import calculateMinsDiff from "../../../utils/functions/calculateMinsDiff";
import mapIncidenceTypes from "../../../utils/functions/mapIncidenceTypes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as env from "../../../config/env";
import MyModal from "../../../utils/components/Modal";

const WorksheetIncidenceList = ({
  incidences,
  incidenceCategories,
  deleteIncidence,
  loadingDeleteIncidence,
  match,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState("");

  const getIncidenceRows = () => {
    const rows = addActions(incidences);

    if (match.localTeam.additionalGoals > 0)
      rows.push({
        datetime: "-",
        player: "-",
        team: `${match.localTeam.name}`,
        type: `GOL BONUS (+${match.localTeam.additionalGoals})`,
      });

    if (match.visitingTeam.additionalGoals > 0)
      rows.push({
        datetime: "-",
        player: "-",
        team: `${match.visitingTeam.name}`,
        type: `GOL BONUS (+${match.visitingTeam.additionalGoals})`,
      });

    return rows;
  };

  const addActions = (incidences) =>
    incidences.map((incidence) => {
      return {
        ...getPlayerAndTeam(incidence.player, incidence.substitute),
        ...getDatetimeAndType(incidence.type, incidence.datetime),
        actions: (
          <div style={{ display: "flex", gap: 10, justifyContent: "center" }}>
            <Button
              className="btn btn-success"
              title={
                <i
                  className={`fa fa-eye
                  `}
                ></i>
              }
              withTooltip={true}
              tooltipTitle="Ver comentarios"
              onClick={() => {
                setComment(incidence.comment), setShowModal(true);
              }}
            />
            <Button
              title={<i className="fa fa-trash" />}
              className="btn btn-danger"
              withTooltip
              tooltipTitle="Eliminar"
              withConfirmation
              confirmationTitle="Eliminar incidencia"
              confirmationBody="¿Estás seguro que deseas eliminar esta incidencia?"
              onConfirmText="Confirmar"
              onCancelText="Cancelar"
              onCancelClass="btn btn-danger pull-left"
              loading={loadingDeleteIncidence}
              onClick={() => deleteIncidence(incidence.id)}
            />
          </div>
        ),
      };
    });

  const getDatetimeAndType = (type, datetime) => {
    const data = {
      type: mapIncidenceTypes(type, incidenceCategories),
      datetime: "-",
    };
    const timeRegex = /time/i;
    const isTimeIncidence = timeRegex.test(type);
    console.log("Data", data);
    if (isTimeIncidence) {
      data.datetime = (
        <b>{moment(datetime).utc().format(env.DATETIME_FORMAT)}</b>
      );
    } else {
      const timeIncidences = incidences.filter((incidence) =>
        timeRegex.test(incidence.type)
      );
      const calculatedTimeIncidences = timeIncidences.map((incidence) => {
        const minsdiff = calculateMinsDiff(datetime, incidence.datetime);
        return { ...incidence, minsdiff };
      });
      const sortedTimeIncidences = calculatedTimeIncidences.sort(
        (a, b) => a.minsdiff - b.minsdiff
      );
      const previousTimeIncidence = sortedTimeIncidences.find(
        (incidence) => incidence.minsdiff > 0
      );
      if (!previousTimeIncidence) {
        data.datetime = moment(datetime).utc().format(env.DATETIME_FORMAT);
      } else {
        data.datetime = `${previousTimeIncidence.minsdiff}" ${mapIncidenceTypes(
          previousTimeIncidence.type,
          incidenceCategories
        )}`;
      }
    }
    return data;
  };

  const getPlayerAndTeam = (pl, subs) => {
    const data = { player: "-", team: "-" };
    if (pl) {
      let player = match.localTeam.checkedInPlayers.find(
        (player) => player.id === pl.id
      );
      let substitute;
      if (player) {
        data.team = match.localTeam.name;
        if (subs) {
          substitute = match.localTeam.checkedInPlayers.find(
            (player) => player.id === subs.id
          );
        }
      } else {
        player = match.visitingTeam.checkedInPlayers.find(
          (player) => player.id === pl.id
        );
        if (subs) {
          substitute = match.visitingTeam.checkedInPlayers.find(
            (player) => player.id === subs.id
          );
        }
        data.team = match.visitingTeam.name;
      }
      if (player) data.player = player.shirtNumber;
      if (substitute) {
        data.player = (
          <span>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="substitute">Entra</Tooltip>}
            >
              <span>{substitute.shirtNumber}</span>
            </OverlayTrigger>
            <i
              style={{
                marginLeft: "10px",
                marginRight: "10px",
              }}
              className="fa fa-exchange"
            />
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="substitute">Sale</Tooltip>}
            >
              <span>{data.player}</span>
            </OverlayTrigger>
          </span>
        );
      }
    }
    return data;
  };

  return (
    <WorksheetWrapper withGoBack>
      <div className="worksheet-white-container">
        <div className="worksheet-table">
          <h2 className="good-faith-list-title">Incidencias</h2>
          <Table
            headers={[
              {
                ref: "datetime",
                className: "col-sm-3",
                label: "Minuto",
              },
              {
                ref: "type",
                className: "col-sm-2 text-center",
                label: "Incidencia",
              },
              {
                ref: "player",
                className: "col-sm-2 text-center",
                label: "Camiseta",
              },
              {
                ref: "team",
                className: "col-sm-3 text-center",
                label: "Equipo",
              },
              {
                ref: "actions",
                className: "col-sm-2 text-center",
                label: "Acciones",
              },
            ]}
            rows={getIncidenceRows()}
            notFoundMessage="No se encontraron incidencias para este partido."
            loading={false}
          />
          <MyModal
            show={showModal}
            title={"Comentario"}
            handleShow={setShowModal}
            backdrop="static"
            body={
              <div className="row">
                <div className="col-md-12">
                  <div className="col-md-9 col-sm-10">
                    {comment !== null
                      ? comment
                      : "No hay comentarios que mostrar"}
                  </div>
                </div>
              </div>
            }
            buttons={
              <Button
                title={"Cerrar"}
                className="btn btn-primary"
                onClick={() => setShowModal(false)}
              />
            }
          />
        </div>
      </div>
    </WorksheetWrapper>
  );
};

export default WorksheetIncidenceList;
