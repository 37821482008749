'use-strict'

import React from 'react'
import { history } from '../../redux/store'

const Breadcrumbs = ({ title = '', opts = [] }) => {
    const getActive = (opt) => opt.active ? <strong>{opt.title}</strong> : <a>{opt.title}</a>
    return (
        <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
                <h2>{title}</h2>
                <ol className="breadcrumb">
                    {opts.map((opt, key) => (
                        <li key={key + 'bread'} className={`${opt.active ? 'active' : ''}`}>
                            {opt.path ? <a onClick={() => history.push(opt.path)}>{opt.title}</a> : getActive(opt)}
                        </li>
                    ))}
                </ol>
            </div>
            <div className="col-lg-2">

            </div>
        </div>
    )
}

export default Breadcrumbs