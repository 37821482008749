import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import UserList from "./UserList";
import * as userThunks from "../../redux/thunks/userThunks";
import userActions from "../../redux/reducers/userReducer/userActions";
import fileDownloader from "../../utils/functions/fileDownloader";

const UserListContainer = ({
  loadAllUsers,
  clearAllUsers,
  clearSingleUser,
  deleteUser,
  users,
  organizers,
  loading,
  match,
  loadAllOrganizers,
  loadAllCountries,
  clearAllCountries,
  clearAllOrganizers,
  countries,
}) => {
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 0;

  const organizerId = match.params.organizerId;

  const organizer = organizers.find(
    (organizer) => organizer.id === organizerId
  );
  const fileName = `${organizer ? `${organizer.name}_` : ""}users.csv`;

  useEffect(() => {
    clearAllUsers();
    clearSingleUser();
    clearAllOrganizers();
    clearAllCountries();

    loadAllUsers(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
    loadAllOrganizers();
    loadAllCountries();
  }, [organizerId]);

  const onRefreshUsers = (page, pageSize) => {
    clearAllUsers();
    clearSingleUser();
    // loadAllUsers(page, pageSize);
  };

  const deleteUserHandler = (userId) => deleteUser(userId);

  const downloadHandler = async (
    search,
    gender,
    fans,
    org,
    deleted,
    countrie
  ) =>
    await fileDownloader(
      `/userscsv?search=${search}&gender=${gender}&fans=${fans}&org=${org}&countrie=${countrie}&deleted=${deleted}`,
      fileName
    );

  return (
    <UserList
      deleteUser={deleteUserHandler}
      users={users}
      loadingFetchUsers={loading.loadAllUsers}
      loadingDeleteUser={loading.deleteUser}
      downloadHandler={downloadHandler}
      countries={countries}
      organizers={organizers}
      defaultPage={DEFAULT_PAGE}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      onRefreshUsers={onRefreshUsers}
      loadAllUsers={loadAllUsers}
    />
  );
};

const mapState = (state) => ({
  organizers: state.user.organizers,
  users: state.user.all,
  loading: state.loading.user,
  countries: state.user.countries,
});

const mapDispatch = (dispatch) => ({
  loadAllUsers: (
    page,
    pageSize,
    gender,
    deleted,
    hasFansIdConfigurated,
    nationality,
    organizationsValue,
    adminValue,
    searchValue
  ) =>
    dispatch(
      userThunks.loadAllUsers(
        page,
        pageSize,
        gender,
        deleted,
        hasFansIdConfigurated,
        nationality,
        organizationsValue,
        adminValue,
        searchValue
      )
    ),
  clearAllUsers: () => dispatch(userActions.clearAllUsers()),
  clearSingleUser: () => dispatch(userActions.clearSingleUser()),
  deleteUser: (organizerId, userId) =>
    dispatch(userThunks.deleteUser(organizerId, userId)),
  archiveUser: (organizerId, userId, archivedValue) =>
    dispatch(userThunks.archiveUser(organizerId, userId, archivedValue)),
  loadAllOrganizers: () => dispatch(userThunks.loadAllOrganizers()),
  loadAllCountries: () => dispatch(userThunks.loadAllCountries()),
  clearAllCountries: () => dispatch(userActions.clearAllCountries()),
  clearAllOrganizers: () => dispatch(userActions.clearAllOrganizers()),
});

export default withRouter(connect(mapState, mapDispatch)(UserListContainer));
