import axios from "axios";
import configureStore from "../utils/functions/configureStore";
import authActions from "../redux/reducers/authReducer/authActions";
import * as env from "../config/env";

const httpClient = (path, secured = true) => {
  // instance authStore
  const store = configureStore("auth");
  
  const headers = {};

  // console.log("store.data.token", store.data.token);

  if (secured) {
    // if the initialization of the httpClient is secured, must be add [Authorization] header
    headers.Authorization = `Bearer ${store.data.token}`;
    // headers.append("Access-Control-Allow-Origin", "http://localhost:4005");
    // headers.append("Access-Control-Allow-Credentials", "true");
  }
  // generate new api reference
  const instance = axios.create({ baseURL: env.API, headers });

  // added new path for the rest api
  instance.defaults.baseURL += path;

  // transform http response on [error, response] pattern
  instance.interceptors.response.use(
    (response) => {
      return Promise.resolve([null, response.data]);
    },
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(authActions.logout());
      }
      return Promise.resolve([error, null]);
    }
  );

  return instance;
};

export default httpClient;
