import React, { useState } from "react";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import Button from "../../utils/components/Button";
import Table from "../../utils/components/Table";
import { history } from "../../redux/store";
import keepOrganizer from "../../utils/functions/keepOrganizer";

const TeamList = ({
  deleteTeam,
  teams,
  loadingFetchTeams,
  loadingDeleteTeam,
  downloadHandler,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const getFilteredTeams = () =>
    teams.filter((team) => {
      const foundId =
        team.id.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
      const foundName =
        team.name.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1;
      const foundShortName =
        team.shortName
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      return foundId || foundName || foundShortName;
    });

  const addActions = (teams) =>
    teams.map((team) => {
      return {
        ...team,
        actions: (
          <div className="btn-separated-group">
            <Button
              className="btn btn-warning"
              title={<i className="fa fa-pencil"></i>}
              withTooltip={true}
              tooltipTitle="Editar"
              onClick={() =>
                history.push(keepOrganizer(`/teams/${team.id}/edit`))
              }
            />
            <Button
              className="btn btn-danger"
              title={<i className="fa fa-trash"></i>}
              withTooltip={true}
              tooltipTitle="Eliminar"
              onClick={() => deleteTeam(team.id)}
              loading={loadingDeleteTeam}
              withConfirmation={true}
              confirmationTitle="Eliminar equipo"
              onConfirmText="Confirmar"
              onCancelText="Cancelar"
              onCancelClass="btn-danger pull-left"
              confirmationBody="¿Estás seguro que quieres eliminar este equipo?"
            />
          </div>
        ),
      };
    });

  return (
    <>
      <Breadcrumbs
        title="Equipos"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Equipos",
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div className="ibox-title">
              <div className="row">
                <div className="col-md-4 col-xs-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar equipos..."
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
                <div className="col-md-8 col-xs-6">
                  <Button
                    className="btn btn-primary pull-right m-l-5"
                    title={
                      <span>
                        <i className="fa fa-download"></i> Descargar
                      </span>
                    }
                    withTooltip={true}
                    tooltipTitle="Descargar equipos"
                    onClick={downloadHandler}
                  />
                  <Button
                    className="btn btn-info pull-right"
                    title={
                      <span>
                        <i className="fa fa-plus-circle"></i> Nuevo
                      </span>
                    }
                    onClick={() => history.push(keepOrganizer("/teams/create"))}
                  />
                </div>
              </div>
            </div>
            <div className="ibox-content">
              <Table
                loading={loadingFetchTeams}
                headers={[
                  {
                    label: "Nombre",
                    ref: "name",
                    className: "col-md-6 col-sm-5",
                  },
                  {
                    label: "Nombre corto",
                    ref: "shortName",
                    className: "col-md-4 col-sm-4",
                  },
                  {
                    label: "Acciones",
                    ref: "actions",
                    className: "col-md-2 col-sm-3 text-center",
                  },
                ]}
                rows={addActions(getFilteredTeams())}
                notFoundMessage="No se encontraron equipos..."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamList;
