"use-strict";

export default {
  SET_ALL_PARTICIPANTS: "@@participant/SET_ALL_PARTICIPANTS",
  SET_ALL_EVENTS_ROLES: "@@participant/SET_ALL_EVENTS_ROLES",
  SET_SINGLE_PARTICIPANT: "@@participant/SET_SINGLE_PARTICIPANT",
  REPLACE_PARTICIPANT: "@@participant/REPLACE_PARTICIPANT",
  SET_PARTICIPANT_KEY_VALUE: "@@participant/SET_PARTICIPANT_KEY_VALUE",
  DELETE_PARTICIPANT: "@@participant/DELETE_PARTICIPANT",
  CLEAR_ALL_PARTICIPANTS: "@@participant/CLEAR_ALL_PARTICIPANTS",
  CLEAR_SINGLE_PARTICIPANT: "@@participant/CLEAR_SINGLE_PARTICIPANT",
  PUSH_NEW_PARTICIPANT: "@@participant/PUSH_NEW_PARTICIPANT",
};
