import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GroupModal from "./GroupModal";
import * as groupThunks from "../../../../redux/thunks/groupThunks";
import groupActions from "../../../../redux/reducers/groupReducer/groupActions";
import { actions as alertActions } from "../../../../redux/reducers/alertReducer";
import { is } from "immutable";

const GroupModalContainer = ({
  show,
  handleShow,
  group,
  isEditing,
  updateGroup,
  createNewGroup,
  clearSingleGroup,
  setGroupKeyValue,
  loadGroup,
  organizerId,
  tournamentId,
  floatingAlert,
  lineups,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [copyToClipboardState, setCopyToClipboardState] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [lineupsError, setLineupsError] = useState(false);

  useEffect(() => {
    if (copyToClipboardState)
      floatingAlert("El ID ha sido copiado al portapapeles", "info", 3500);
  }, [copyToClipboardState]);

  useEffect(() => {
    if (show) {
      setIsReady(false);
      clearSingleGroup();
      setNameError(false);
      setLineupsError(false);
      if (isEditing) loadGroup(organizerId, tournamentId, group.id);
      setIsReady(true);
    }
  }, [show]);

  const closeGroupModalHandle = () => handleShow(false);

  const modalTitle = () =>
    isEditing ? `Modificar Grupo (ID: ${group.id})` : "Crear Grupo";
  const buttomSaveTitle = () => (isEditing ? "Guardar" : "Crear");

  const saveGroupHandler = () => {
    if (group.name === "") {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }

    if (group.lineups.length <= 0) {
      setLineupsError(true);
      return;
    } else {
      setLineupsError(false);
    }

    if (isEditing) {
      updateGroup(organizerId, tournamentId, group.id, closeGroupModalHandle);
    } else {
      createNewGroup(organizerId, tournamentId, closeGroupModalHandle);
    }
  };

  return (
    <GroupModal
      show={show}
      handleShow={handleShow}
      setGroupKeyValue={setGroupKeyValue}
      group={group}
      saveGroupHandler={saveGroupHandler}
      isReady={isReady}
      modalTitle={modalTitle}
      buttomSaveTitle={buttomSaveTitle}
      isEditing={isEditing}
      copyToClipboardStateHandle={setCopyToClipboardState}
      lineups={lineups}
      nameError={nameError}
      lineupsError={lineupsError}
    />
  );
};

const mapState = (state) => ({
  loading: state.loading,
  group: state.group.single,
  lineups: state.lineup.all,
});

const mapDispatch = (dispatch) => ({
  setGroupKeyValue: (key, value) =>
    dispatch(groupActions.setGroupKeyValue(key, value)),
  clearSingleGroup: () => dispatch(groupActions.clearSingleGroup()),
  createNewGroup: (organizerId, tournamentId, handleCloseModal) =>
    dispatch(
      groupThunks.createNewGroup(organizerId, tournamentId, handleCloseModal)
    ),
  loadGroup: (organizerId, tournamentId, groupId) =>
    dispatch(groupThunks.loadGroup(organizerId, tournamentId, groupId)),
  updateGroup: (organizerId, tournamentId, groupId, handleCloseModal) =>
    dispatch(
      groupThunks.updateGroup(
        organizerId,
        tournamentId,
        groupId,
        handleCloseModal
      )
    ),
  floatingAlert: (message, type, timer) =>
    dispatch(alertActions.showFloatingAlert(message, type, timer)),
});

export default withRouter(connect(mapState, mapDispatch)(GroupModalContainer));
