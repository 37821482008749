"use-strict";

import constants from "./participantsConstants";

export default {
  setAllParticipants: (payload) => ({
    type: constants.SET_ALL_PARTICIPANTS,
    payload,
  }),
  setAllEventsRoles: (payload) => ({
    type: constants.SET_ALL_EVENTS_ROLES,
    payload,
  }),
  setSingleParticipant: (payload) => ({
    type: constants.SET_SINGLE_PARTICIPANT,
    payload,
  }),
  setParticipantKeyValue: (key, value) => ({
    type: constants.SET_PARTICIPANT_KEY_VALUE,
    payload: { key, value },
  }),
  replaceParticipant: (payload) => ({
    type: constants.REPLACE_PARTICIPANT,
    payload,
  }),
  deleteParticipant: (participantId) => ({
    type: constants.DELETE_PARTICIPANT,
    participantId,
  }),
  clearAllParticipants: () => ({
    type: constants.CLEAR_ALL_PARTICIPANTS,
  }),
  clearSingleParticipant: () => ({
    type: constants.CLEAR_SINGLE_PARTICIPANT,
  }),
  pushNewParticipant: (payload) => ({
    type: constants.PUSH_NEW_PARTICIPANT,
    payload,
  }),
};
