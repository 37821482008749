import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div id="footer" className="footer">
                <div className="pull-right">
                    Copyright <strong>Fansbury Sports</strong> © 2020
                </div>
            </div>
        )
    }
}

export default Footer