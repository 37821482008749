'use-strict'

export default {
    SET_ALL_SEDES: '@@sede/SET_ALL_SEDES',
    SET_SEDE_KEY_VALUE: '@@sede/SET_SEDE_KEY_VALUE',
    SET_SINGLE_SEDE: '@@sede/SET_SINGLE_SEDE',
    PUSH_NEW_SEDE: '@@sede/PUSH_NEW_SEDE',
    REPLACE_SEDE: '@@sede/REPLACE_SEDE',
    DELETE_SEDE: '@@sede/DELETE_SEDE',
    SET_SELECTED_SEDE: '@@sede/SET_SELECTED_SEDE',
    CLEAR_ALL_SEDES: '@@sede/CLEAR_ALL_SEDES',
    CLEAR_SINGLE_SEDE: '@@sede/CLEAR_SINGLE_SEDE',
    UPDATE_ARCHIVED_SEDE:'@@sede/UPDATE_ARCHIVED_SEDE',
}