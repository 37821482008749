"use-strict";

import httpClient from "../../../http/httpClient";

const httpTournament = (organizerId, tournamentId) => {
  return httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}`);
};

export default {
  loadTournamentGroups: (organizerId, tournamentId) =>
    httpTournament(organizerId, tournamentId).get("/groups"),
  create: (organizerId, tournamentId, group) =>
    httpTournament(organizerId, tournamentId).post("/groups", group),
  delete: (organizerId, tournamentId, groupId) =>
    httpTournament(organizerId, tournamentId).delete(`/groups/${groupId}`),
  loadOne: (organizerId, tournamentId, groupId) =>
    httpTournament(organizerId, tournamentId).get(`/groups/${groupId}`),
  update: (organizerId, tournamentId, groupId, group) =>
    httpTournament(organizerId, tournamentId).patch(
      `/groups/${groupId}`,
      group
    ),
};
