'use-strict'

import httpClient from '../../../http/httpClient'

const httpOrganizer = (organizerId) => httpClient(`/organizers/${organizerId}`)

export default {
    loadAll: (organizerId) => httpOrganizer(organizerId).get('/teams'),
    create: (organizerId, team) => httpOrganizer(organizerId).post('/teams', team),
    loadOne: (organizerId, teamId) => httpOrganizer(organizerId).get(`/teams/${teamId}`),
    update: (organizerId, teamId, team) => httpOrganizer(organizerId).patch(`/teams/${teamId}`, team),
    delete: (organizerId, teamId) => httpOrganizer(organizerId).delete(`/teams/${teamId}`),
    images: (organizerId, teamId, data) => httpOrganizer(organizerId).post(`/teams/${teamId}/images`, data)
}