import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as checkInThunks from "../../../redux/thunks/checkInThunks";
import CheckIn from "./CheckIn";
import checkInActions from "../../../redux/reducers/checkInReducer/checkInActions";

const CheckInContainer = ({
  organizerId,
  eventId,
  loadAllCheckInsForEvent,
  clearAllCheckIn,
  checkInAll,
  deleteCheckIn,
}) => {
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 0;

  useEffect(() => {
    clearAllCheckIn();
    loadAllCheckInsForEvent(eventId, DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
  }, []);

  const onRefreshUsers = (page, pageSize) => {
    clearAllCheckIn();
    loadAllCheckInsForEvent(eventId, page, pageSize);
  };

  return (
    <CheckIn
      checkInAll={checkInAll}
      deleteCheckIn={deleteCheckIn}
      defaultPage={DEFAULT_PAGE}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      onRefreshUsers={onRefreshUsers}
    />
  );
};

const mapState = (state) => ({
  event: state.event.single,
  checkInAll: state.checkIn.all,
  loading: state.loading,
});

const mapDispatch = (dispatch) => ({
  loadAllCheckInsForEvent: (eventId, page, pageSize) =>
    dispatch(checkInThunks.loadAllCheckInsForEvent(eventId, page, pageSize)),
  loadOneCheckIn: () => dispatch(checkInThunks.loadOneCheckIn()),
  deleteCheckIn: (checkAccessId) =>
    dispatch(checkInThunks.deleteCheckIn(checkAccessId)),
  clearAllCheckIn: () => dispatch(checkInActions.clearAllCheckIn()),
});

export default withRouter(connect(mapState, mapDispatch)(CheckInContainer));
