'use-strict'

import only from "../../../utils/functions/only"

export default {
    all: [],
    single: {
        id: '',
        name: '',
        datetime: ''
    }
}

export const model = (entity) => only(entity, ['id', 'name', 'datetime'])