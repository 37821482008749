import React, { useState } from "react";
import Button from "../../../../utils/components/Button";
import Table from "../../../../utils/components/Table";
import LineUpModal from "./LineUpModal";
import PlayersModal from "./PlayersModal";
import LineUpConfigModal from "./LineUpConfigModal";
import LineUpCaptainModal from "./LineUpCaptainModal";
import LineUpBonusModal from "./LineUpBonusModal";
import UploadFileButton from "../../../../utils/components/UploadFileButton";
import roles from "../../../../config/roles";

const LineUpList = ({
  lineups,
  loadingFetchLineups,
  teams,
  createNewLineup,
  deleteLineup,
  showPlayersModal,
  hidePlayersModal,
  player,
  players,
  clearSinglePlayer,
  setPlayerKeyValue,
  addPlayerToLineup,
  setSinglePlayer,
  loadingDeleteLineup,
  loadingAddPlayerToLineup,
  openLineupPlayersModal,
  updatePlayer,
  clearAllPlayers,
  loadingFetchPlayers,
  loadingUpdatePlayer,
  loadingDeletePlayer,
  deletePlayerHandler,
  showDeleteButton,
  deletePlayerImageHandler,
  addLineupCaptain,
  updateTournament,
  uploadMasiveLinupPlayersHandler,
  refreshLineups,
  downloadLineupsAndPlayersHandler,
  addLineupBonus,
  floatingAlert,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [lineupIdValue, setLineupIdValue] = useState("");
  const [showLineupModal, setShowLineupModal] = useState(false);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showCaptainModal, setShowCaptainModal] = useState(false);
  const [selectedLineup, setSelectedLineup] = useState(null);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [showBonusModal, setShowBonusModal] = useState(false);

  const getFilteredLineups = () =>
    lineups.filter((lineup) => {
      const foundId =
        lineup.id.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1;
      const foundTeamName =
        lineup.team.name
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      return foundId || foundTeamName;
    });

  const addActions = (lineups) =>
    lineups.map((lineup) => {
      return {
        ...lineup,
        actions: (
          <div className="btn-separated-group">
            <Button
              className="btn btn-primary"
              title={<b>B</b>}
              withTooltip={true}
              tooltipTitle="Bonus"
              onClick={() => {
                setShowBonusModal(true);
                setSelectedLineup(lineup);
              }}
              loading={loadingDeleteLineup}
            />
            <Button
              className="btn btn-success"
              title={<b>C</b>}
              withTooltip={true}
              tooltipTitle="Capitán"
              onClick={() => {
                setShowCaptainModal(true);
                setSelectedLineup(lineup);
              }}
              loading={loadingDeleteLineup}
            />
            <Button
              className="btn btn-warning"
              title={<i className="fa fa-male"></i>}
              withTooltip={true}
              tooltipTitle="Jugadores"
              onClick={() => {
                openLineupPlayersModal(lineup.id), setLineupIdValue(lineup.id);
              }}
            />
            {showDeleteButton && (
              <Button
                className="btn btn-danger"
                title={<i className="fa fa-trash"></i>}
                withTooltip={true}
                tooltipTitle="Eliminar"
                onClick={() => deleteLineup(lineup.id)}
                loading={loadingDeleteLineup}
                withConfirmation={true}
                confirmationTitle="Eliminar lineup"
                onConfirmText="Confirmar"
                onCancelText="Cancelar"
                onCancelClass="btn-danger pull-left"
                confirmationBody="¿Estás seguro que quieres eliminar este lineup?"
              />
            )}
          </div>
        ),
      };
    });

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        LineUps
        <div className="btn-group-separated pull-right">
          {roles.ROLE_MASSIVE_OPERATIONS() && (
            <UploadFileButton
              text="CSV"
              icon={<i className={`fa fa-file-text `}></i>}
              tooltipText="Alta masiva de lineups y jugadores"
              handler={async (file) => {
                setLoadingUploadFile(true);
                await uploadMasiveLinupPlayersHandler(file);
                await refreshLineups();
                setLoadingUploadFile(false);
              }}
            />
          )}
          <Button
            title={
              <span>
                <i className={`fa fa-download`}></i> Descargar
              </span>
            }
            className="btn btn-info btn-xs"
            onClick={() => downloadLineupsAndPlayersHandler()}
            withTooltip={true}
            tooltipTitle="Descargar CSV de lineups y jugadores"
          />
          <Button
            title={
              <span>
                <i className={`fa fa-wrench`}></i> Config
              </span>
            }
            className="btn btn-success btn-xs"
            onClick={() => setShowConfigModal(true)}
            withTooltip={true}
            tooltipTitle="Configuración de lineups"
          />
          <Button
            title={
              <span>
                <i className={`fa fa-plus`}></i> Lineup
              </span>
            }
            className="btn btn-primary btn-xs"
            onClick={() => setShowLineupModal(true)}
            withTooltip={true}
            tooltipTitle="Nuevo lineup"
          />
        </div>
      </div>
      <div className="panel-body">
        <input
          type="text"
          className="form-control"
          placeholder="Buscar lineups..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Table
          loading={loadingFetchLineups || loadingUploadFile}
          headers={[
            {
              label: "Nombre",
              ref: "team.name",
              className: "col-sm-8",
            },
            {
              label: "Acciones",
              ref: "actions",
              className: "col-sm-4 text-center",
            },
          ]}
          rows={loadingUploadFile ? [] : addActions(getFilteredLineups())}
          notFoundMessage="No se encontraron lineups..."
        />
      </div>
      <LineUpModal
        show={showLineupModal}
        handleShow={setShowLineupModal}
        teams={teams}
        createNewLineup={createNewLineup}
      />
      <LineUpConfigModal
        show={showConfigModal}
        handleShow={setShowConfigModal}
        updateTournament={updateTournament}
      />
      <LineUpCaptainModal
        show={showCaptainModal}
        handleShow={setShowCaptainModal}
        lineup={selectedLineup}
        addLineupCaptain={addLineupCaptain}
      />
      <LineUpBonusModal
        show={showBonusModal}
        handleShow={setShowBonusModal}
        lineup={selectedLineup}
        addLineupBonus={addLineupBonus}
      />

      <PlayersModal
        show={showPlayersModal}
        handleShow={hidePlayersModal}
        teams={teams}
        player={player}
        players={players}
        clearSinglePlayer={clearSinglePlayer}
        setPlayerKeyValue={setPlayerKeyValue}
        addPlayerToLineup={addPlayerToLineup}
        setSinglePlayer={setSinglePlayer}
        loadingAddPlayerToLineup={loadingAddPlayerToLineup}
        updatePlayer={updatePlayer}
        clearAllPlayers={clearAllPlayers}
        loadingFetchPlayers={loadingFetchPlayers}
        loadingUpdatePlayer={loadingUpdatePlayer}
        loadingDeletePlayer={loadingDeletePlayer}
        deletePlayerHandler={deletePlayerHandler}
        deletePlayerImageHandler={deletePlayerImageHandler}
        floatingAlert={floatingAlert}
        lineupIdValue={lineupIdValue}
      />
    </div>
  );
};

export default LineUpList;
