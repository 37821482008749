import React from "react";
import ReactSwitch from "react-switch";

const CardSettings = ({
  setOrganizerKeyValue,
  getImage,
  index,
  organizer = {},
  onChangeInput,
  preview = {},
  ...card
}) => {
  const { enabledKey, textKey, priorityKey, sizeKey, imageKey, linkKey } = card;

  const getTextInput = () =>
    card.editable ? (
      <input
        type="text"
        className="form-control"
        disabled={!organizer[enabledKey]}
        value={
          !card.editable || !organizer[textKey] ? card.text : organizer[textKey]
        }
        onChange={(e) => setOrganizerKeyValue(textKey, e.target.value)}
      />
    ) : (
      <label>{card.text}</label>
    );

  const getLinkInput = () =>
    card.editable ? (
      <input
        type="text"
        className="form-control"
        disabled={!organizer[enabledKey]}
        value={
          !card.editable || !organizer[linkKey] ? card.text : organizer[linkKey]
        }
        onChange={(e) => setOrganizerKeyValue(linkKey, e.target.value)}
      />
    ) : (
      <label>{card.text}</label>
    );

  return (
    <tr key={index}>
      <td>
        {card.editable && (
          <ReactSwitch
            onChange={() =>
              setOrganizerKeyValue(enabledKey, !organizer[enabledKey])
            }
            checked={!!organizer[enabledKey]}
          />
        )}
      </td>
      <td>
        {card.editable && !card.hideSize && (
          <select
            disabled={!organizer[enabledKey]}
            className="form-control"
            value={organizer[sizeKey]}
            onChange={(e) => setOrganizerKeyValue(sizeKey, e.target.value)}
          >
            <option value="">[Seleccione]</option>
            {card.availableSizes?.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        )}
      </td>
      <td>
        {card.editable && (
          <input
            type="number"
            disabled={!organizer[enabledKey]}
            value={organizer[priorityKey]}
            className="form-control"
            onChange={(e) => {
              const toNumber = e.target.value !== "" ? e.target.value : "";
              setOrganizerKeyValue(priorityKey, toNumber);
            }}
          />
        )}
      </td>
      <td>
        {card.textKey && getTextInput()}
        {card.linkKey && getLinkInput()}
      </td>
      <td>
        <input
          type="file"
          className="custom-file-input"
          id={card.id}
          onChange={onChangeInput}
        />
      </td>
      <td>
        <div
          style={{
            border: "1px solid #e4e4e4",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              width: card.width,
              height: card.imageHeight,
              backgroundImage: `url(${preview[card.id] || getImage(imageKey)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </div>
      </td>
    </tr>
  );
};

export default CardSettings;
