'use-strict'

import authConstants from '../authReducer/authConstants'
import constants from './sanctionConstants'
import initialState from './sanctionState'

const sanctionReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_ALL_SANCTIONS: {
            return {
                ...state,
                all: action.payload
            }
        }
        case constants.CLEAR_ALL_SANCTIONS: {
            return {
                ...state,
                all: initialState.all
            }
        }
        case constants.SET_SANCTION_KEY_VALUE: {
            return {
                ...state,
                single: {
                    ...state.single,
                    [action.payload.key]: action.payload.value
                }
            }
        }
        case constants.CLEAR_SINGLE_SANCTION: {
            return {
                ...state,
                single: initialState.single
            }
        }
        case constants.PUSH_NEW_SANCTION: {
            const sanctions = [...state.all]
            sanctions.push(action.payload)
            return {
                ...state,
                all: sanctions
            }
        }
        case constants.DELETE_SANCTION: {
            const sanctions = [...state.all]
            const index = sanctions.findIndex(sanction => sanction.id === action.sanctionId)
            sanctions.splice(index, 1)
            return {
                ...state,
                all: sanctions
            }
        }
        case constants.REPLACE_SANCTION: {
            const sanctions = [...state.all]
            const index = sanctions.findIndex(sanction => sanction.id === action.payload.id)
            sanctions[index] = action.payload
            return {
                ...state,
                all: sanctions
            }
        }
        case constants.SET_SINGLE_SANCTION: {
            return {
                ...state,
                single: action.payload
            }
        }
        case authConstants.LOGOUT: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default sanctionReducer