'use-strict'

import authConstants from '../authReducer/authConstants'
import constants from './organizerConstants'
import initialState from './organizerState'

const organizerReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_SELECTED_ORGANIZER: {
            return {
                ...state,
                selected: action.payload
            }
        }
        case constants.SET_ALL_ORGANIZERS: {
            return {
                ...state,
                all: action.payload
            }
        }
        case constants.CLEAR_ALL_ORGANIZERS: {
            return {
                ...state,
                all: initialState.all
            }
        }
        case authConstants.LOGOUT: {
            return initialState
        }
        case constants.SET_SINGLE_ORGANIZER: {
            return {
                ...state,
                single: action.payload
            }
        }
        case constants.CLEAR_SINGLE_ORGANIZER: {
            return {
                ...state,
                single: initialState.single
            }
        }
        case constants.PUSH_NEW_ORGANIZER: {
            const organizers = [...state.all]
            organizers.push(action.payload)
            return {
                ...state,
                all: organizers
            }
        }
        case constants.SET_ORGANIZER_KEY_VALUE: {
            return {
                ...state,
                single: {
                    ...state.single,
                    [action.payload.key]: action.payload.value
                }
            }
        }
        case constants.REPLACE_ORGANIZER: {
            const organizers = [...state.all]
            const index = organizers.findIndex(organizer => organizer.id === action.payload.id)
            organizers[index] = action.payload
            return {
                ...state,
                all: organizers
            }
        }
        case constants.DELETE_ORGANIZER: {
            const organizers = [...state.all]
            const index = organizers.findIndex(organizer => organizer.id === action.organizerId)
            organizers.splice(index, 1)
            return {
                ...state,
                all: organizers
            }
        }
        default: {
            return state
        }
    }
}

export default organizerReducer