import teamActions from "../reducers/teamReducer/teamActions";
import teamService from "../reducers/teamReducer/teamService";
import { model as teamModel } from "../reducers/teamReducer/teamState";
import { setLoading } from "../reducers/loadingReducer";
import handler, { statusCodes } from "../../utils/functions/handler";
import { actions as alertActions } from "../reducers/alertReducer";
import { history } from "../store";
import without from "../../utils/functions/without";
import keepOrganizer from "../../utils/functions/keepOrganizer";

export const loadAllTeams = (organizerId) => async (dispatch) => {
  dispatch(setLoading.true("team.loadAllTeams"));
  const [error, teams] = await teamService.loadAll(organizerId);
  dispatch(setLoading.false("team.loadAllTeams"));
  if (error) {
    return dispatch(handler(error));
  }
  dispatch(teamActions.setAllTeams(teams.map(teamModel)));
};

export const createNewTeam = (organizerId) => async (dispatch, getState) => {
  dispatch(setLoading.true("team.createNewTeam"));
  const store = getState();
  const team = without(store.team.single, ["id"]);
  const [error, createdTeam] = await teamService.create(organizerId, team);
  dispatch(setLoading.false("team.createNewTeam"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {
          [statusCodes.BAD_REQUEST]: {
            msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
          },
          [statusCodes.CONFLICT]: {
            msg: "Ya existe un equipo con este nombre.",
            alertStyle: "danger",
          },
        },
      })
    );
  }
  dispatch(teamActions.pushNewTeam(teamModel(createdTeam)));
  dispatch(
    alertActions.showFloatingAlert(
      `Se ha creado el equipo <b>${createdTeam.name}</b>.`,
      "info",
      3500
    )
  );
  history.push(keepOrganizer("/teams"));
};

export const updateTeam =
  (organizerId, teamId, image) => async (dispatch, getState) => {
    dispatch(setLoading.true("team.updateTeam"));
    const store = getState();
    const team = without(store.team.single, ["id", "image"]);
    const [error, updatedTeam] = await teamService.update(
      organizerId,
      teamId,
      team
    );

    let errorImage = null;
    if (image) {
      [errorImage] = await teamService.images(organizerId, teamId, image);
    }

    dispatch(setLoading.false("team.updateTeam"));

    if (error || errorImage) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
            [statusCodes.CONFLICT]: {
              msg: "Ya existe un equipo con este nombre.",
              alertStyle: "danger",
            },
          },
        })
      );
    }
    dispatch(teamActions.replaceTeam(teamModel(updatedTeam)));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado el equipo <b>${updatedTeam.name}</b>.`,
        "info",
        3500
      )
    );
    history.push(keepOrganizer("/teams"));
  };

export const deleteTeam =
  (organizerId, teamId) => async (dispatch, getState) => {
    dispatch(setLoading.true("team.deleteTeam"));
    const store = getState();
    const [error] = await teamService.delete(organizerId, teamId);
    const deletedTeam = store.team.all.find((team) => team.id === teamId);
    dispatch(setLoading.false("team.deleteTeam"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(teamActions.deleteTeam(teamId));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha eliminado el equipo <b>${deletedTeam.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const loadTeam = (organizerId, teamId) => async (dispatch) => {
  dispatch(setLoading.true("team.loadTeam"));
  const [error, team] = await teamService.loadOne(organizerId, teamId);

  dispatch(setLoading.false("team.loadTeam"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {},
      })
    );
  }
  dispatch(teamActions.setSingleTeam(team));
};

export const clearSingleTeam = () => (dispatch) =>
  dispatch(teamActions.clearSingleTeam());
