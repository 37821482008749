"use-strict";

import constants from "./matchConstants";

export default {
  setAllMatches: (payload) => ({
    type: constants.SET_ALL_MATCHES,
    payload,
  }),
  clearAllMatches: () => ({
    type: constants.CLEAR_ALL_MATCHES,
  }),
  clearAllMatchesGroups: () => ({
    type: constants.CLEAR_ALL_MATCHES_GROUPS,
  }),
  setMatchKeyValue: (key, value) => ({
    type: constants.SET_MATCH_KEY_VALUE,
    payload: { key, value },
  }),
  clearSingleMatch: () => ({
    type: constants.CLEAR_SINGLE_MATCH,
  }),
  pushNewMatch: (payload) => ({
    type: constants.PUSH_NEW_MATCH,
    payload,
  }),
  setSingleMatch: (payload) => ({
    type: constants.SET_SINGLE_MATCH,
    payload,
  }),
  deleteMatch: (matchId) => ({
    type: constants.DELETE_MATCH,
    matchId,
  }),
  replaceMatch: (payload) => ({
    type: constants.REPLACE_MATCH,
    payload,
  }),
  setOrganizerMatches: (payload) => ({
    type: constants.SET_ORGANIZER_MATCHES,
    payload,
  }),
  clearOrganizerMatches: () => ({
    type: constants.CLEAR_ORGANIZER_MATCHES,
  }),
};
