'use-strict'

import constants from './worksheetConstants'

export default {
    setMatch: (match) => ({
        type: constants.SET_MATCH,
        match
    }),
    setIsReady: () => ({
        type: constants.SET_IS_READY
    }),
    setIsFullScreen: (isFullScreen) => ({
        type: constants.SET_IS_FULL_SCREEN,
        isFullScreen
    }),
    pushNewRoadmapItem: (item) => ({
        type: constants.PUSH_NEW_ROADMAP_ITEM,
        item
    }),
    addTeamPlayers: (teamId, players) => ({
        type: constants.ADD_TEAM_PLAYERS,
        teamId,
        players
    }),
    addTeamPlayer: (teamId, player) => ({
        type: constants.ADD_TEAM_PLAYER,
        teamId,
        player
    }),
    setPlayerKeyValue: (teamId, playerId, key, value) => ({
        type: constants.SET_PLAYER_KEY_VALUE,
        teamId,
        playerId,
        key,
        value
    }),
    replacePlayerOnTeam: (teamId, player) => ({
        type: constants.REPLACE_PLAYER_ON_TEAM,
        teamId,
        player
    }),
    removeRoadmapLastItem: () => ({
        type: constants.REMOVE_ROADMAP_LAST_ITEM,
    }),
    removeIncidenceItem: (incidenceId) => ({
        type: constants.REMOVE_INCIDENCE_ITEM,
        incidenceId
    }),
    pushNewIntervalId: (id) => ({
        type: constants.PUSH_NEW_INTERVAL_ID,
        id
    }),
    clearAllIntervalIds: () => ({
        type: constants.CLEAR_ALL_INTERVAL_IDS
    }),
    addTeamCheckedInPlayer: (teamId, player) => ({
        type: constants.ADD_TEAM_CHECKED_IN_PLAYER,
        teamId,
        player
    }),
    setAllIncidences: (incidences) => ({
        type: constants.SET_ALL_INCIDENCES,
        incidences
    }),
    pushNewIncidence: (incidence) => ({
        type: constants.PUSH_NEW_INCIDENCE,
        incidence
    }),
    removeTeamCheckedInPlayer: (teamId, playerId) => ({
        type: constants.REMOVE_TEAM_CHECKED_IN_PLAYER,
        teamId,
        playerId
    }),
    clearMatch: () => ({
        type: constants.CLEAR_MATCH
    }),
    clearIsReady: () => ({
        type: constants.CLEAR_IS_READY
    }),
    clearRoadMap: () => ({
        type: constants.CLEAR_ROADMAP
    }),
    clearAllIncidences: () => ({
        type: constants.CLEAR_ALL_INCIDENCES
    }),
    clearTeamPlayers: (teamId) => ({
        type: constants.CLEAR_TEAM_PLAYERS,
        teamId
    }),
    replacePlayerImageOnTeam: (payload) => ({
        type: constants.REPLACE_PLAYER_IMAGE_ON_TEAM,
        payload
    })
}