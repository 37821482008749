'use-strict'

import constants from './dateConstants'

export default {
    setAllDates: (payload) => ({
        type: constants.SET_ALL_DATES,
        payload
    }),
    pushNewDate: (payload) => ({
        type: constants.PUSH_NEW_DATE,
        payload
    }),
    setDateKeyValue: (key, value) => ({
        type: constants.SET_DATE_KEY_VALUE,
        payload: { key, value }
    }),
    deleteDate: (dateId) => ({
        type: constants.DELETE_DATE,
        dateId
    }),
    clearAllDates: () => ({
        type: constants.CLEAR_ALL_DATES
    }),
    clearSingleDate: () => ({
        type: constants.CLEAR_SINGLE_DATE
    }),
    setSingleDate: (payload) => ({
        type: constants.SET_SINGLE_DATE,
        payload
    }),
    replaceDate: (payload) => ({
        type: constants.REPLACE_DATE,
        payload
    }),
    displaceDate: (payload) => ({
        type: constants.DISPLACE_DATE,
        payload
    })
}