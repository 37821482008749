'use-strict'

import constants from './lineupConstants'

export default {
    setAllLineups: (payload) => ({
        type: constants.SET_ALL_LINEUPS,
        payload
    }),
    pushNewLineup: (payload) => ({
        type: constants.PUSH_NEW_LINEUP,
        payload
    }),
    deleteLineup: (lineupId) => ({
        type: constants.DELETE_LINEUP,
        lineupId
    }),
    setSelectedLineup: (lineupId) => ({
        type: constants.SET_SELECTED_LINEUP,
        lineupId
    }),
    clearAllLineups: () => ({
        type: constants.CLEAR_ALL_LINEUPS
    }),
    updateLineupCaptain: (lineupId, captain) => ({
        type: constants.UPDATE_LINEUP_CAPTAIN,
        payload: {
            lineupId,
            captain
        }
    }),
    updateLineupBonus: (lineupId, bonusData) => ({
        type: constants.UPDATE_LINEUP_BONUS,
        payload: {
            lineupId,
            bonusData
        }
    }),
}