'use-strict'

export default {
    SET_ALL_DATES: '@@date/SET_ALL_DATES',
    PUSH_NEW_DATE: '@@date/PUSH_NEW_DATE',
    SET_DATE_KEY_VALUE: '@@date/SET_DATE_KEY_VALUE',
    DELETE_DATE: '@@date/DELETE_DATE',
    CLEAR_ALL_DATES: '@@date/CLEAR_ALL_DATES',
    CLEAR_SINGLE_DATE: '@@date/CLEAR_SINGLE_DATE',
    SET_SINGLE_DATE: '@@date/SET_SINGLE_DATE',
    REPLACE_DATE: '@@date/REPLACE_DATE',
    DISPLACE_DATE: '@@date/DISPLACE_DATE'
}