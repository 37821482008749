import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as worksheetThunks from '../../redux/thunks/worksheetThunks'
import worksheetActions from '../../redux/reducers/worksheetReducer/worksheetActions' 
import WorksheetOffline from './WorksheetOffline'
import { history } from '../../redux/store'

const WorksheetOfflineContainer = ({
    worksheetOffline,
    loading,
    loadMatch,
    loadIncidences,
    clearMatch,
    clearAllIncidences,
    saveOfflineIncidences,
    match,
}) => {
    const organizerId = match.params.organizerId
    const tournamentId = match.params.tournamentId
    const matchId = match.params.matchId

    useEffect(() => {
        clearMatch()
        clearAllIncidences()
        loadMatch(organizerId, tournamentId, matchId)
        loadIncidences(organizerId, tournamentId, matchId)
    }, [matchId])

    const saveOfflineIncidencesHandler = (incidencesToCreate, incidencesToDelete, additionalGoals) => {saveOfflineIncidences(organizerId, tournamentId, matchId, incidencesToCreate, incidencesToDelete, additionalGoals)}

    const goToCheckInHandler = (teamId, lineupId) => {
        history.push(
            `/organizers/${organizerId}/tournaments/${tournamentId}/matches/${matchId}/worksheet/lineups/${lineupId}/teams/${teamId}/checkin`
        )
    }

    const goBackHandler = () => {history.goBack()}

    return (
        <WorksheetOffline
            worksheetOffline={worksheetOffline}
            goToCheckIn={goToCheckInHandler}
            goBack={goBackHandler}
            saveOfflineIncidences={saveOfflineIncidencesHandler}
            loadingSaveOfflineIncidences={loading.saveOfflineIncidences}
        />
    )
}

const mapState = state => ({
    worksheetOffline: state.worksheet,
    loading: state.loading.worksheet,
})

const mapDispatch =  dispatch => ({
    loadMatch: (organizerId, tournamentId, matchId) => dispatch(worksheetThunks.loadMatch(organizerId, tournamentId, matchId, true)),
    loadIncidences: (organizerId, tournamentId, matchId) => dispatch(worksheetThunks.loadIncidences(organizerId, tournamentId, matchId, true)),
    clearMatch: () => dispatch(worksheetActions.clearMatch()),
    clearAllIncidences: () => dispatch(worksheetActions.clearAllIncidences()),
    saveOfflineIncidences: (organizerId, tournamentId, matchId, incidencesToCreate, incidencesToDelete, additionalGoals) => dispatch(worksheetThunks.saveOfflineIncidences(organizerId, tournamentId, matchId, incidencesToCreate, incidencesToDelete, additionalGoals)),
})

export default withRouter(connect(mapState, mapDispatch)(WorksheetOfflineContainer))

