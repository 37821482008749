"use-strict";

import only from "../../../utils/functions/only";

export default {
  all: [],
  single: {
    id: "",
    name: "",
    enabled: true,
    lineups: [],
  },
  lineupsByGroup: [],
};

export const model = (entity) => only(entity, ["id", "name", "lineups"]);
