import handler, { statusCodes } from "../../utils/functions/handler";
import eventsActions from "../reducers/eventsReducer/eventsActions";
import eventsService from "../reducers/eventsReducer/eventsService";
import { model as eventsModel } from "../reducers/eventsReducer/eventsState";
import { actions as alertActions } from "../reducers/alertReducer";
import { setLoading } from "../reducers/loadingReducer";
import only from "../../utils/functions/only";
import { history } from "../store";
import keepOrganizer from "../../utils/functions/keepOrganizer";

export const loadAllEvents = (organizerId) => async (dispatch) => {
  dispatch(setLoading.true("event.loadAllEvents"));
  const [error, events] = await eventsService.loadAllEvents(organizerId);
  dispatch(setLoading.false("event.loadAllEvents"));
  if (error) {
    return dispatch(
      handler({
        ...error,
      })
    );
  }

  dispatch(eventsActions.setAllEvents(events));
};

export const loadOneEvent = (eventId) => async (dispatch) => {
  dispatch(setLoading.true("event.loadOneEvent"));
  const [error, oneEvent] = await eventsService.loadOneEvent(eventId);
  dispatch(setLoading.false("event.loadOneEvent"));
  if (error) {
    return dispatch(
      handler({
        ...error,
      })
    );
  }
  dispatch(eventsActions.setSingleEvent(oneEvent.event));
};

export const loadAllEventsType = () => async (dispatch) => {
  dispatch(setLoading.true("event.loadAllEventsType"));
  const [error, eventsType] = await eventsService.loadAllEventType();
  dispatch(setLoading.false("event.loadAllEventsType"));

  if (error) {
    return dispatch(
      handler({
        ...error,
      })
    );
  }
  dispatch(eventsActions.setAllEventsType(eventsType.map(eventsModel)));
};

export const loadAllEventsCategory = () => async (dispatch) => {
  dispatch(setLoading.true("event.loadAllEventsCategory"));
  const [error, eventsCategory] = await eventsService.loadAllEventsCategory();
  dispatch(setLoading.false("event.loadAllEventsCategory"));

  if (error) {
    return dispatch(
      handler({
        ...error,
      })
    );
  }
  dispatch(eventsActions.setAllEventsCategory(eventsCategory.map(eventsModel)));
};

export const createNewEvent = (event) => async (dispatch, getState) => {
  dispatch(setLoading.true("event.createNewEvent"));
  const [error, createdEvent] = await eventsService.createEvent(event);
  dispatch(setLoading.false("event.createNewEvent"));

  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {
          [statusCodes.BAD_REQUEST]: {
            msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
          },
        },
      })
    );
  }

  dispatch(
    alertActions.showFloatingAlert(
      `Se ha creado el evento <b>${createdEvent.name}</b>.`,
      "info",
      3500
    )
  );

  history.push(keepOrganizer(`/events/${createdEvent.id}/edit`));
};

export const updateEvent =
  (eventId, event, image, imageBg) => async (dispatch, getState) => {
    dispatch(setLoading.true("event.updateEvent"));
    const [error, updateEvent] = await eventsService.updateOneEvent(
      eventId,
      event
    );
    dispatch(setLoading.false("event.updateEvent"));

    let errorImage = null;
    let errorImageBg = null;

    if (image) {
      [errorImage] = await eventsService.uploadImage(eventId, image);
    }

    if (imageBg) {
      [errorImageBg] = await eventsService.uploadImageBg(eventId, imageBg);
    }

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }

    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado el evento <b>${updateEvent.name}</b>.`,
        "info",
        3500
      )
    );

    history.push(keepOrganizer(`/events/${updateEvent.id}/edit`));
  };

export const updateConfigEvent =
  (eventId, config, handleShow) => async (dispatch, getState) => {
    dispatch(setLoading.true("event.updateEvent"));
    const [error, updateConfigEvent] = await eventsService.configEvent(
      eventId,
      config
    );
    dispatch(setLoading.false("event.updateEvent"));

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }

    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado el la configuracion del evento.`,
        "info",
        3500
      )
    );

    handleShow(false);
  };

export const deleteEvent = (eventId) => async (dispatch, getState) => {
  dispatch(setLoading.true("event.deleteEvent"));
  const [error, deleteEvent] = await eventsService.deleteEvent(eventId);
  dispatch(setLoading.false("event.deleteEvent"));

  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {
          [statusCodes.BAD_REQUEST]: {
            msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
          },
        },
      })
    );
  }

  dispatch(eventsActions.deleteEvent(eventId));
  dispatch(
    alertActions.showFloatingAlert(`Se ha eliminado el evento.`, "info", 3500)
  );
};
