import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DateModal from './DateModal'
import * as dateThunks from '../../../../redux/thunks/dateThunks'
import dateActions from '../../../../redux/reducers/dateReducer/dateActions'
import { actions as alertActions } from '../../../../redux/reducers/alertReducer'

const DateModalContainer = ({
    show,
    handleShow,
    date,
    isEditing,
    updateDate,
    createNewDate,
    clearSingleDate,
    setDateKeyValue,
    loadDate,
    organizerId,
    tournamentId,
    loading,
    floatingAlert
}) => {
    const [copyToClipboardState, setCopyToClipboardState] = useState(false)

    useEffect(() => {
        if(copyToClipboardState)
        floatingAlert("El ID ha sido copiado al portapapeles", 'info', 3500)
    }, [copyToClipboardState])

    useEffect(()=>{
        if (show){
            clearSingleDate()
            if(isEditing) loadDate(organizerId, tournamentId, date.id)
        }
    },[show])

    const closeDateModalHandle = () => handleShow(false)

    const saveDateHandler = 
        () => isEditing ? 
            updateDate(
                organizerId, 
                tournamentId, 
                date.id, 
                closeDateModalHandle
            ) : 
            createNewDate(
                organizerId, 
                tournamentId, 
                closeDateModalHandle)

    const modalTitle = () => isEditing ? `Modificar Jornada (ID: ${date.id})` : 'Crear Jornada'
    const buttomSaveTitle = () => isEditing ? 'Guardar' : 'Crear'

    return (
        <DateModal 
            show={show}
            handleShow={handleShow}
            setDateKeyValue={setDateKeyValue}
            date={date}
            saveDate={saveDateHandler}
            loading = {loading.date.loadDate}
            modalTitle ={modalTitle}
            buttomSaveTitle = {buttomSaveTitle}
            isEditing = {isEditing}
            copyToClipboardStateHandle = {setCopyToClipboardState}
        />
    )
}

const mapState = state => ({
    loading: state.loading,
    date: state.date.single,
})

const mapDispatch = dispatch => ({
    setDateKeyValue: (key, value) => dispatch(dateActions.setDateKeyValue(key, value)),
    clearSingleDate: () => dispatch(dateActions.clearSingleDate()),
    createNewDate: (organizerId, tournamentId, handleCloseModal) => dispatch(dateThunks.createNewDate(organizerId, tournamentId, handleCloseModal)),
    loadDate: (organizerId, tournamentId, dateId) => dispatch(dateThunks.loadDate(organizerId, tournamentId, dateId)),
    updateDate: (organizerId, tournamentId, dateId, handleCloseModal) => dispatch(dateThunks.updateDate(organizerId, tournamentId, dateId, handleCloseModal)),
    floatingAlert: (message, type, timer) => dispatch(alertActions.showFloatingAlert(message, type, timer))
})

export default withRouter(connect(mapState, mapDispatch)(DateModalContainer))