import React from 'react'
import WorksheetWrapper from '../components/WorksheetWrapper'

const WorksheetStatus = ({
    statuses,
    onClickStatus
}) => {
    return (
        <WorksheetWrapper 
            withGoBack
            gridItems={statuses}
            gridAction={onClickStatus}
        />
    )
}

export default WorksheetStatus