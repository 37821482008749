'use-strict'

export default {
    SET_SELECTED_ORGANIZER: '@@organizer/SET_SELECTED_ORGANIZER',
    SET_ALL_ORGANIZERS: '@@organizer/SET_ALL_ORGANIZERS',
    CLEAR_ALL_ORGANIZERS: '@@organizer/CLEAR_ALL_ORGANIZERS',
    SET_ORGANIZER_KEY_VALUE: '@@organizer/SET_ORGANIZER_KEY_VALUE',
    CLEAR_SINGLE_ORGANIZER: '@@organizer/CLEAR_SINGLE_ORGANIZER',
    PUSH_NEW_ORGANIZER:'@@organizer/PUSH_NEW_ORGANIZER',
    SET_SINGLE_ORGANIZER:'@@organizer/SET_SINGLE_ORGANIZER',
    REPLACE_ORGANIZER: '@@organizer/REPLACE_ORGANIZER',
    DELETE_ORGANIZER: '@@organizer/DELETE_ORGANIZER'
}