"use-strict";

import authConstants from "../authReducer/authConstants";

const initialState = {
  auth: {
    login: false,
  },
  organizer: {
    loadAllOrganizers: false,
    deleteOrganizer: false,
    createNewOrganizer: false,
    loadOrganizer: false,
    updateOrganizer: false,
  },
  team: {
    loadAllTeams: false,
    createNewTeam: false,
    loadTeam: false,
    updateTeam: false,
    deleteTeam: false,
  },
  tournament: {
    loadAllTournaments: false,
    deleteTournament: false,
    loadTournament: false,
    createNewTournament: false,
    updateTournament: false,
    loadTournamentRedCards: false,
    archivingTournament: false,
  },
  event: {
    loadAllEvents: false,
    loadAllEventsCategory: false,
    loadAllEventsType: false,
    loadOneEvent: false,
    loadOneEventCategory: false,
    loadOneEventType: false,
    deleteEvent: false,
    createNewEvent: false,
    updateEvent: false,
  },
  participants: {
    loadAllParticipants: false,
    createNewParticipant: false,
    deleteParticipant: false,
    updateParticipant: false,
    loadEventsRoles: false,
    addTournament: false,
  },
  checkIn: {
    loadAllCheckIn: false,
    loadOneCheckIn: false,
    createNewCheckIn: false,
    deleteCheckIn: false,
    updateCheckIn: false,
  },
  lineup: {
    loadAllLineups: false,
    createNewLineup: false,
    deleteLineup: false,
    loadLineup: false,
    addLineupCaptain: false,
    uploadMasiveLinupPlayers: false,
    downloadLineupsAndPlayers: false,
    addLineupBonus: false,
  },
  player: {
    addPlayerToLineup: false,
    updatePlayer: false,
    deletePlayer: false,
    loadAllPlayers: false,
    loadPlayer: false,
    deletePlayerImage: false,
    deletePlayerMedicalRecord: false,
  },
  group: {
    loadTournamentGroups: false,
    createNewGroup: false,
    deleteGroup: false,
    loadGroup: false,
    updateGroup: false,
  },
  date: {
    loadAllDates: false,
    createNewDate: false,
    deleteDate: false,
    loadDate: false,
    updateDate: false,
    displaceDate: false,
  },
  match: {
    loadTournamentMatches: false,
    loadMatch: false,
    createNewMatch: false,
    deleteMatch: false,
    updateMatch: false,
    uploadMasiveMatches: false,
    loadOrganizerMatches: false,
    downloadMatchLineupsList: false,
  },
  sanction: {
    loadSanctions: false,
    loadSanction: false,
    createNewSanction: false,
    deleteSanction: false,
    updateSanction: false,
  },
  worksheet: {
    loadMatch: false,
    loadPlayers: false,
    updatePlayer: false,
    checkInPlayer: false,
    checkOutPlayer: false,
    checkInGuestPlayer: false,
    createNewIncidence: false,
    loadIncidences: false,
    deleteIncidence: false,
    saveOfflineIncidences: false,
  },
  stadium: {
    loadAllStadiums: false,
    updateStadium: false,
    saveNewStadium: false,
  },
  tableRowColor: {
    loadTableRowColors: false,
    createTableRowColor: false,
    deleteTableRowColor: false,
    loadTableRowColor: false,
    updateTableRowColor: false,
  },
  sede: {
    loadAllSedes: false,
    createNewSede: false,
    loadSede: false,
    updateSede: false,
    deleteSede: false,
    loadAllSedeStadiums: false,
    archiveSede: false,
  },
  user: {
    loadAllUsers: false,
    createNewUser: false,
    loadUser: false,
    updateUser: false,
    deleteUser: false,
    loadOrganizations: false,
    archiveUser: false,
    loadCountries: false,
    loadAllOrganizers: false,
  },
};

export const constants = {
  SET_LOADING: "SET_LOADING",
};

export const actions = {
  [constants.SET_LOADING]: (target, boolean) => ({
    type: constants.SET_LOADING,
    target,
    boolean,
  }),
};

export const setLoading = {
  true: (target) => actions[constants.SET_LOADING](target, true),
  false: (target) => actions[constants.SET_LOADING](target, false),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_LOADING: {
      const [reducer, loadingType] = action.target.split(".");
      return {
        ...state,
        [reducer]: {
          ...state[reducer],
          [loadingType]: action.boolean,
        },
      };
    }
    case authConstants.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};
