import React, { useState } from "react";
import Button from "../../../../utils/components/Button";
import Table from "../../../../utils/components/Table";

const TableRowColorList = ({
  tableRowColors,
  loadingFetch,
  loadingDelete,
  handleTableRowColorEdit,
  handleTableRowColorCreate,
  handleTableRowColorDelete,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const getFilteredTableRowColors = () =>
    tableRowColors.filter((tableRowColor) => {
      const foundId =
        tableRowColor.id
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      const foundPosition =
        tableRowColor.rowNum.toString().indexOf(searchValue.toLowerCase()) !==
        -1;
      return foundId || foundPosition;
    });

  const getRowColorStyle = (tableRowColor) => {
    return {
      backgroundColor: `${tableRowColor.color}`,
      width: 25,
      height: 15,
    };
  };

  const addActions = (tableRowColors) =>
    tableRowColors.map((tableRowColor) => {
      return {
        rowNum: tableRowColor.rowNum,
        rowColor: <div style={getRowColorStyle(tableRowColor)}></div>,
        actions: (
          <div className="btn-separated-group">
            <Button
              className="btn btn-warning"
              title={<i className="fa fa-pencil"></i>}
              withTooltip={true}
              tooltipTitle="Editar"
              onClick={() => {
                handleTableRowColorEdit(tableRowColor.id);
              }}
            />
            <Button
              className="btn btn-danger"
              title={<i className="fa fa-trash"></i>}
              withTooltip={true}
              tooltipTitle="Eliminar"
              onClick={() => {
                handleTableRowColorDelete(tableRowColor.id);
              }}
              loading={loadingDelete}
              withConfirmation={true}
              confirmationTitle="Eliminar color"
              onConfirmText="Confirmar"
              onCancelText="Cancelar"
              onCancelClass="btn-danger pull-left"
              confirmationBody="¿Estás seguro que quieres eliminar este color?"
            />
          </div>
        ),
      };
    });

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        Posiciones (Background)
        <Button
          title={
            <span>
              <i className={`fa fa-plus`}></i> color
            </span>
          }
          className="btn btn-primary btn-xs pull-right"
          onClick={handleTableRowColorCreate}
          withTooltip={true}
          tooltipTitle="Nuevo color"
        />
      </div>
      <div className="panel-body">
        <input
          type="text"
          className="form-control"
          placeholder="Buscar colores..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Table
          loading={loadingFetch}
          headers={[
            {
              label: "Nº Posición",
              ref: "rowNum",
              className: "col-md-4 col-sm-4",
            },
            {
              label: "Color",
              ref: "rowColor",
              className: "col-md-4 col-sm-4",
            },
            {
              label: "Acciones",
              ref: "actions",
              className: "col-md-4 col-sm-4 text-center",
            },
          ]}
          rows={addActions(getFilteredTableRowColors())}
          notFoundMessage="No se encontraron colores cargados..."
        />
      </div>
    </div>
  );
};

export default TableRowColorList;
