import sedeActions from "../reducers/sedeReducer/sedeActions";
import sedeService from "../reducers/sedeReducer/sedeService";
import { model as sedeModel } from "../reducers/sedeReducer/sedeState";
import { setLoading } from "../reducers/loadingReducer";
import handler, { statusCodes } from "../../utils/functions/handler";
import { actions as alertActions } from "../reducers/alertReducer";
import { history } from "../store";
import without from "../../utils/functions/without";
import keepOrganizer from "../../utils/functions/keepOrganizer";

export const loadAllSedes = (organizerId) => async (dispatch) => {
  dispatch(setLoading.true("sede.loadAllSedes"));
  const [error, sedes] = await sedeService.loadAll(organizerId);

  dispatch(setLoading.false("sede.loadAllSedes"));
  if (error) {
    return dispatch(handler(error));
  }
  dispatch(sedeActions.setAllSedes(sedes.map(sedeModel)));
};

export const createNewSede = (organizerId) => async (dispatch, getState) => {
  dispatch(setLoading.true("sede.createNewSede"));
  const store = getState();
  const sede = without(store.sede.single, ["id"]);
  const [error, createdSede] = await sedeService.create(organizerId, sede);
  dispatch(setLoading.false("sede.createNewSede"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {
          [statusCodes.BAD_REQUEST]: {
            msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
          },
          [statusCodes.CONFLICT]: {
            msg: "Ya existe una sede con este nombre.",
            alertStyle: "danger",
          },
        },
      })
    );
  }
  dispatch(sedeActions.pushNewSede(sedeModel(createdSede)));
  dispatch(
    alertActions.showFloatingAlert(
      `Se ha creado la sede <b>${createdSede.name}</b>.`,
      "info",
      3500
    )
  );
  history.push(keepOrganizer("/sedes"));
};

export const updateSede =
  (organizerId, sedeId, image) => async (dispatch, getState) => {
    dispatch(setLoading.true("sede.updateSede"));
    const store = getState();
    const sede = without(store.sede.single, ["id", "image"]);
    const [error, updatedSede] = await sedeService.update(
      organizerId,
      sedeId,
      sede
    );

    let errorImage = null;
    if (image) {
      [errorImage] = await sedeService.images(organizerId, sedeId, image);
    }

    dispatch(setLoading.false("sede.updateSede"));

    if (error || errorImage) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
            [statusCodes.CONFLICT]: {
              msg: "Ya existe una sede con este nombre.",
              alertStyle: "danger",
            },
          },
        })
      );
    }
    dispatch(sedeActions.replaceSede(sedeModel(updatedSede)));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado el sede <b>${updatedSede.name}</b>.`,
        "info",
        3500
      )
    );
    history.push(keepOrganizer("/sedes"));
  };

export const deleteSede =
  (organizerId, sedeId) => async (dispatch, getState) => {
    dispatch(setLoading.true("sede.deleteSede"));
    const store = getState();
    const [error] = await sedeService.delete(organizerId, sedeId);
    const deletedSede = store.sede.all.find((sede) => sede.id === sedeId);
    dispatch(setLoading.false("sede.deleteSede"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(sedeActions.deleteSede(sedeId));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha eliminado la sede <b>${deletedSede.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const loadSede = (organizerId, sedeId) => async (dispatch) => {
  dispatch(setLoading.true("sede.loadSede"));
  const [error, sede] = await sedeService.loadOne(organizerId, sedeId);
  dispatch(setLoading.false("sede.loadSede"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {},
      })
    );
  }
  dispatch(sedeActions.setSingleSede(sede));
};

export const clearSingleSede = () => (dispatch) =>
  dispatch(sedeActions.clearSingleSede());

export const archiveSede =
  (organizerId, sedeId, archiveValue) => async (dispatch, getState) => {
    dispatch(setLoading.true("sede.archiveSede"));
    const [error, updatedSede] = await sedeService.update(organizerId, sedeId, {
      archived: archiveValue,
    });
    dispatch(setLoading.false("sede.archiveSede"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }
    dispatch(
      sedeActions.updateArchivedSede(updatedSede.id, updatedSede.archived)
    );
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado la sede <b>${updatedSede.name}</b>.`,
        "info",
        3500
      )
    );
  };
