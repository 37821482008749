import worksheetActions from "../reducers/worksheetReducer/worksheetActions";
import worksheetService from "../reducers/worksheetReducer/worksheetService";
import { setLoading } from "../reducers/loadingReducer";
import handler, { statusCodes } from "../../utils/functions/handler";
import { actions as alertActions } from "../reducers/alertReducer";
import lineupService from "../reducers/lineupReducer/lineupService";
import playerService from "../reducers/playerReducer/playerService";
import incidenceService from "../reducers/playerReducer/incidenceService";
import matchService from "../reducers/matchReducer/matchService";
import * as env from "../../config/env";

const timer = (callback) =>
  setInterval(callback, env.WORKSHEET_RELOAD_TIME * 60000);

export const loadMatch =
  (organizerId, tournamentId, matchId, isOffline = false) =>
  async (dispatch) => {
    dispatch(setLoading.true("worksheet.loadMatch"));
    const [error, match] = await worksheetService.loadOneMatch(
      organizerId,
      tournamentId,
      matchId
    );

    dispatch(setLoading.false("worksheet.loadMatch"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }

    dispatch(
      worksheetActions.setMatch({
        id: match.id,
        localTeam: {
          id: match.localTeam.team.id,
          lineupId: match.localTeam.id,
          name: match.localTeam.team.name,
          shortName: match.localTeam.team.shortName,
          players: [],
          checkedInPlayers: match.localPlayers,
          incidencesSummary: match.incidencesSummary.local,
          additionalGoals: match.localAdditionalGoals,
        },
        visitingTeam: {
          id: match.visitingTeam.team.id,
          lineupId: match.visitingTeam.id,
          name: match.visitingTeam.team.name,
          shortName: match.visitingTeam.team.shortName,
          players: [],
          checkedInPlayers: match.visitingPlayers,
          incidencesSummary: match.incidencesSummary.visiting,
          additionalGoals: match.visitingAdditionalGoals,
        },
      })
    );

    if (!isOffline) {
      dispatch(worksheetActions.setIsReady());

      // Se recarga el planillero en intervalos
      const intervalId = timer(() =>
        dispatch(loadMatch(organizerId, tournamentId, matchId))
      );
      dispatch(worksheetActions.pushNewIntervalId(intervalId));
    }
  };

export const loadPlayers =
  (organizerId, tournamentId, teamId) => async (dispatch) => {
    dispatch(setLoading.true("worksheet.loadPlayers"));
    const [error, lineup] = await lineupService.loadOne(
      organizerId,
      tournamentId,
      teamId
    );
    dispatch(setLoading.false("worksheet.loadPlayers"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(worksheetActions.addTeamPlayers(lineup.team.id, lineup.players));
  };

export const updatePlayer =
  (organizerId, teamId, playerId, shirtNumber, image) => async (dispatch) => {
    dispatch(setLoading.true("worksheet.updatePlayer"));

    let errorImage = null;

    if (image) {
      [errorImage] = await playerService.playerImages(
        organizerId,
        playerId,
        image
      );
    }

    const player = { shirtNumber };
    const [error, updatedPlayer] = await playerService.updatePlayer(
      organizerId,
      playerId,
      player
    );
    dispatch(setLoading.false("worksheet.updatePlayer"));
    if (error || errorImage) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }
    dispatch(worksheetActions.replacePlayerOnTeam(teamId, updatedPlayer));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado el jugador <b>${updatedPlayer.firstName} ${updatedPlayer.lastName}</b>.`,
        "info",
        3500
      )
    );
  };

export const checkInPlayer =
  (organizerId, tournamentId, matchId, teamId, playerId, teamType) =>
  async (dispatch) => {
    dispatch(setLoading.true("worksheet.checkInPlayer"));
    const [error, checkedInPlayer] = await playerService.checkIn(
      organizerId,
      tournamentId,
      matchId,
      playerId,
      teamType
    );
    dispatch(setLoading.false("worksheet.checkInPlayer"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(worksheetActions.addTeamCheckedInPlayer(teamId, checkedInPlayer));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha asignado el jugador <b>${checkedInPlayer.firstName} ${checkedInPlayer.lastName}</b>.`,
        "info",
        3500
      )
    );
  };

export const checkOutPlayer =
  (organizerId, tournamentId, matchId, teamId, playerId) =>
  async (dispatch) => {
    dispatch(setLoading.true("worksheet.checkOutPlayer"));
    const [error, checkedOutPlayer] = await playerService.checkOut(
      organizerId,
      tournamentId,
      matchId,
      playerId
    );
    dispatch(setLoading.false("worksheet.checkOutPlayer"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(
      worksheetActions.removeTeamCheckedInPlayer(teamId, checkedOutPlayer.id)
    );
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha desasignado el jugador <b>${checkedOutPlayer.firstName} ${checkedOutPlayer.lastName}</b>.`,
        "info",
        3500
      )
    );
  };

export const checkInGuestPlayer =
  (organizerId, tournamentId, matchId, teamId, teamType, player) =>
  async (dispatch) => {
    dispatch(setLoading.true("worksheet.checkInGuestPlayer"));
    const payload = {
      ...player,
      team: teamType,
    };
    const [error, checkedInPlayer] = await playerService.checkInGuest(
      organizerId,
      tournamentId,
      matchId,
      payload
    );
    dispatch(setLoading.false("worksheet.checkInGuestPlayer"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(worksheetActions.addTeamCheckedInPlayer(teamId, checkedInPlayer));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha asignado el jugador <b>${checkedInPlayer.firstName} ${checkedInPlayer.lastName}</b>.`,
        "info",
        3500
      )
    );
  };

export const createNewIncidence =
  (organizerId, tournamentId, matchId, incidence, isOffline = false) =>
  async (dispatch) => {
    dispatch(setLoading.true("worksheet.createNewIncidence"));
    const [error, createdIncidence] = await incidenceService.createIncidence(
      organizerId,
      tournamentId,
      matchId,
      incidence
    );
    dispatch(setLoading.false("worksheet.createNewIncidence"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(worksheetActions.pushNewIncidence(createdIncidence));
    if (!isOffline) {
      dispatch(
        alertActions.showFloatingAlert(
          `Se ha creado una incidencia.`,
          "info",
          3500
        )
      );
    }
  };

export const loadIncidences =
  (organizerId, tournamentId, matchId, isOffline = false) =>
  async (dispatch) => {
    dispatch(setLoading.true("worksheet.loadIncidences"));
    const [error, incidences] = await incidenceService.loadIncidences(
      organizerId,
      tournamentId,
      matchId
    );
    dispatch(setLoading.false("worksheet.loadIncidences"));

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(worksheetActions.setAllIncidences(incidences));

    if (!isOffline) {
      // Se recarga el planillero en intervalos
      const intervalId = timer(() =>
        dispatch(loadIncidences(organizerId, tournamentId, matchId))
      );
      dispatch(worksheetActions.pushNewIntervalId(intervalId));
    }
  };

export const deleteIncidence =
  (organizerId, tournamentId, matchId, incidenceId, isOffline = false) =>
  async (dispatch) => {
    dispatch(setLoading.true("worksheet.deleteIncidence"));
    const [error] = await incidenceService.deleteIncidence(
      organizerId,
      tournamentId,
      matchId,
      incidenceId
    );
    dispatch(setLoading.false("worksheet.deleteIncidence"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(worksheetActions.removeIncidenceItem(incidenceId));
    if (!isOffline) {
      dispatch(
        alertActions.showFloatingAlert(
          `Se ha eliminado una incidencia.`,
          "info",
          3500
        )
      );
    }
  };

export const deletePlayerImage =
  (organizerId, playerId, teamId) => async (dispatch, getState) => {
    dispatch(setLoading.true("player.deletePlayerImage"));
    const [error, updatedPlayer] = await playerService.deletePlayerImage(
      organizerId,
      playerId
    );
    dispatch(setLoading.false("player.deletePlayerImage"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.CONFLICT]: {
              msg: "Error al eliminar la imagen del jugador.",
            },
          },
        })
      );
    }

    const store = getState();
    const player = store.player.single;

    dispatch(
      worksheetActions.replacePlayerImageOnTeam({
        media: updatedPlayer.media,
        id: playerId,
        teamId: teamId,
      })
    );
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha eliminado la imagen del jugador <b>${player.firstName} ${player.lastName}</b>.`,
        "info",
        3500
      )
    );
  };

export const saveOfflineIncidences =
  (
    organizerId,
    tournamentId,
    matchId,
    incidencesToCreate,
    incidencesToDelete,
    additionalGoals
  ) =>
  async (dispatch, getState) => {
    dispatch(setLoading.true("worksheet.saveOfflineIncidences"));
    if (incidencesToDelete && incidencesToDelete.length > 0) {
      await incidencesToDelete.map(
        async (incidenceId) =>
          await dispatch(
            deleteIncidence(
              organizerId,
              tournamentId,
              matchId,
              incidenceId,
              true
            )
          )
      );
    }

    if (incidencesToCreate && incidencesToCreate.length > 0) {
      await incidencesToCreate.map(
        async (incidence) =>
          await dispatch(
            createNewIncidence(
              organizerId,
              tournamentId,
              matchId,
              incidence,
              true
            )
          )
      );
    }

    await matchService.update(
      organizerId,
      tournamentId,
      matchId,
      additionalGoals
    );

    dispatch(setLoading.false("worksheet.saveOfflineIncidences"));

    dispatch(
      alertActions.showFloatingAlert(
        `Se han creado correctamente todas las incidencias.`,
        "info",
        3500
      )
    );
  };
