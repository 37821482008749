"use-strict";

export default {
  SET_ALL_GROUPS: "@@group/SET_ALL_GROUPS",
  SET_GROUP_KEY_VALUE: "@@group/SET_GROUP_KEY_VALUE",
  PUSH_NEW_GROUP: "@@group/PUSH_NEW_GROUP",
  PUSH_LINEUPS_GROUP: "@@group/PUSH_LINEUPS_GROUP",
  DELETE_GROUP: "@@group/DELETE_GROUP",
  REMOVE_LINEUPS_GROUP: "@@group/REMOVE_LINEUPS_GROUP",
  CLEAR_ALL_GROUPS: "@@group/CLEAR_ALL_GROUPS",
  CLEAR_ALL_LINEUPS_BY_GROUPS: "@@group/CLEAR_ALL_LINEUPS_BY_GROUPS",
  CLEAR_SINGLE_GROUP: "@@group/CLEAR_SINGLE_GROUP",
  SET_SINGLE_GROUP: "@@group/SET_SINGLE_GROUP",
  REPLACE_GROUP: "@@group/REPLACE_GROUP",
};
