import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PlayerList from './PlayerList'
import * as playerThunks from '../../redux/thunks/playerThunks'
import playerActions from '../../redux/reducers/playerReducer/playerActions'
import { actions as alertActions } from '../../redux/reducers/alertReducer'
import fileDownloader from '../../utils/functions/fileDownloader'

const PlayerListContainer = ({
    loadAllPlayers,
    clearAllPlayers,
    deletePlayer,
    players,
    organizers,
    loading,
    hideSpecificAlert,
    match
}) => {
    const organizerId = match.params.organizerId
    const organizer = organizers.find(organizer => organizer.id === organizerId)
    const fileName = `${organizer ? `${organizer.name}_` : ''}jugadores.csv`

    useEffect(()=>{
        clearAllPlayers();
        loadAllPlayers(organizerId)
    }, [organizerId])

    const deletePlayerHandler =  (playerId) => deletePlayer(organizerId, playerId)

    const downloadHandler = async () => await fileDownloader(`/organizers/${organizerId}/players/csv`, fileName)

    return (
        <PlayerList
            deletePlayer={deletePlayerHandler}
            players={players}
            loadingFetchPlayers={loading.loadAllPlayers}
            loadingDeletePlayer={loading.deletePlayer}
            hideSpecificAlert = {hideSpecificAlert}
            downloadHandler={downloadHandler}
        />
    )
}

const mapState = state => ({
    organizers: state.organizer.all,
    players: state.player.all,
    loading: state.loading.player
})

const mapDispatch = dispatch => ({
    hideSpecificAlert: () => dispatch(alertActions.hideSpecificAlert()),
    loadAllPlayers: (organizerId) => dispatch(playerThunks.loadAllPlayers(organizerId)),
    clearAllPlayers: () => dispatch(playerActions.clearAllPlayers()),
    deletePlayer: (organizerId, playerId) => dispatch(playerThunks.deletePlayer(organizerId, playerId))
})

export default withRouter(connect(mapState, mapDispatch)(PlayerListContainer))