import React, { useState, useEffect } from 'react'
import Button from '../../../../utils/components/Button'
import MyModal from '../../../../utils/components/Modal'
import DatetimePicker from '../../../../utils/components/DatetimePicker'

const SanctionModal = ({
    show,
    handleShow,
    saveSanction,
    sanction,
    players,
    setSanctionKeyValue,
    modalTitle,
    buttomSaveTitle,
    loading,
    isEditing,
    lineups,
}) => {

    const [teamError, setTeamError] = useState(false)
    const [playerError, setPlayerError] = useState(false)
    const [displaceError, setDisplaceError] = useState(false)
    const [blacklistError, setBlacklistError] = useState(false)

    const saveSanctionHandler = () => {
        let onError = false;

        if (sanction.team === '') {
            setTeamError(true)
            onError = true;
        } else {
            setTeamError(false)
        }

        if (sanction.player === '') {
            setPlayerError(true)
            onError = true;
        } else {
            setPlayerError(false)
        }

        if (!sanction.matches) {
            setDisplaceError(true)
            onError = true;
        } else {
            setDisplaceError(false)
        }

        if (sanction.blacklist === '') {
            setBlacklistError(true)
            onError = true;
        } else {
            setBlacklistError(false)
        }

        if(onError) return

        saveSanction()
    }

    return (
        <MyModal
            show={show}
            title={modalTitle()}
            handleShow={handleShow}
            backdrop='static'
            body={
                <div className="row">
                    <div className="col-md-12">
                        <div className={`form-group ${teamError && 'has-error'}`}>
                            <label className="col-md-3 col-sm-2 control-label">Equipo * </label>
                            <div className="col-md-9 col-sm-10">
                                <select
                                    name="team"
                                    id="team"
                                    className="form-control"
                                    disabled={isEditing}
                                    value={sanction.team}
                                    onChange={(e) => {
                                        setSanctionKeyValue('team', e.target.value)
                                    }}
                                >
                                    <option value="">Seleccione</option>
                                    {lineups.map((lineup, key) => (
                                        <option value={lineup.team.id} key={key + 'team'}>{lineup.team.name}</option>
                                    ))}
                                </select>
                                {teamError && <span className="help-block">El equipo es requerido.</span>}
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="hr-line-dashed"></div>
                        <div className={`form-group ${playerError && 'has-error'}`}>
                            <label className="col-md-3 col-sm-2 control-label">Jugador * </label>
                            <div className="col-md-9 col-sm-10">
                                <select
                                    name="player"
                                    id="player"
                                    className="form-control"
                                    disabled={isEditing}
                                    value={sanction.player}
                                    onChange={(e) => {
                                        setSanctionKeyValue('player', e.target.value)
                                    }}
                                >
                                    <option value="">Seleccione</option>
                                    {players.map((player, key) => (
                                        <option value={player.id} key={key + 'player'}>{player.document} - {player.lastName} {player.firstName}</option>
                                    ))}
                                </select>
                                {playerError && <span className="help-block">El jugador es requerido.</span>}
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="hr-line-dashed"></div>
                        <div className={`form-group ${displaceError && 'has-error'}`}>
                            <label className="col-md-3 col-sm-2 control-label">Partidos de sanción </label>
                            <div className="col-md-9 col-sm-10">
                                <input
                                    type="number"
                                    className="form-control"
                                    min="0"
                                    value={sanction.matches}
                                    onChange={(e) => {
                                        setSanctionKeyValue('matches',e.target.value)
                                    }} />
                                {displaceError && <span className="help-block">Debe indicar la cantidad de partidos de sanción.</span>}
                            </div>
                        </div>

                        {isEditing && 
                            <>
                                <div className="clearfix"></div>
                                <div className="hr-line-dashed"></div>
                                <div className="form-group">
                                    <label className="col-md-4 col-sm-4 control-label">Partidos por cumplir sanción </label>
                                    <div className="col-md-8 col-sm-8">
                                        <input
                                            type="number"
                                            min="0"
                                            className="form-control"
                                            value={sanction.remainingMatches}
                                            onChange={(e) => {
                                                setSanctionKeyValue('remainingMatches',e.target.value)
                                            }}/>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="clearfix"></div>
                        <div className="hr-line-dashed"></div>
                        <div className='form-group'>
                            <label className="col-md-3 col-sm-2 control-label">Comentarios </label>
                            <div className="col-md-9 col-sm-10">
                                <textarea 
                                    className="form-control"
                                    value={sanction.comments}
                                    disabled={loading}
                                    onChange={(e) => {
                                        setSanctionKeyValue('comments', e.target.value)
                                    }} 
                                >
                                    {sanction.comments}
                                </textarea>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="hr-line-dashed"></div>
                        <div className={`form-group ${blacklistError && 'has-error'}`}>
                            <label className="col-md-3 col-sm-2 control-label">Agregar a lista negra * </label>
                            <div className="col-md-9 col-sm-10">
                                <select
                                    name="tier"
                                    id="tier"
                                    className="form-control"
                                    disabled={loading}
                                    value={sanction.blacklist}
                                    onChange={(e) => {
                                        setSanctionKeyValue('blacklist', e.target.value.toLowerCase() == "true")
                                    }}
                                >
                                    <option value="true">Sí</option>
                                    <option value="false">No</option>
                                </select>
                                {blacklistError && <span className="help-block">Se debe seleccionar al menos un valor.</span>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            buttons={
                <Button
                    title={buttomSaveTitle()}
                    className="btn btn-primary"
                    onClick={saveSanctionHandler}
                />
            }
        />
    )
}

export default SanctionModal