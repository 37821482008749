import React, { useState, useEffect } from "react";
import Button from "../../utils/components/Button";
import MyModal from "../../utils/components/Modal";
import DatetimePicker from "../../utils/components/DatetimePicker";
import moment from "moment";
import * as env from "../../config/env";

const MatchFiltersModal = ({
  show,
  handleShow,
  stadiums,
  filters,
  applyFilterHandler,
  loading,
}) => {
  const closeModalHandle = () => handleShow(false);

  const [filtersValues, setFiltersValues] = useState(filters);
  const [datetimeStartError, setDatetimeStartError] = useState(false);
  const [datetimeEndError, setDatetimeEndError] = useState(false);

  useEffect(() => {
    setFiltersValues(filters);
  }, [filters]);

  useEffect(() => {
    if (filtersValues.datetimeEnd && filtersValues.datetimeStart) {
      setDatetimeEndError(
        moment(filtersValues.datetimeEnd).isBefore(
          moment(filtersValues.datetimeStart)
        )
      );
      setDatetimeStartError(
        moment(filtersValues.datetimeStart).isAfter(
          moment(filtersValues.datetimeEnd)
        )
      );
    }
  }, [filtersValues]);

  const setFiltersValuesHandler = (filters) => {
    setFiltersValues({
      ...filtersValues,
      ...filters,
    });
  };

  const onClickHandle = () => {
    if (!validateDates()) return;

    applyFilterHandler(filtersValues);
    closeModalHandle();
  };

  const validateDates = () => {
    let isOnError = false;

    if (filtersValues.datetimeEnd && filtersValues.datetimeStart) {
      const isOnErrorEndDate = moment(filtersValues.datetimeEnd).isBefore(
        moment(filtersValues.datetimeStart)
      );
      setDatetimeEndError(isOnErrorEndDate);

      const isOnErrorStartDate = moment(filtersValues.datetimeStart).isAfter(
        moment(filtersValues.datetimeEnd)
      );
      setDatetimeStartError(isOnErrorStartDate);

      isOnError = isOnErrorStartDate || isOnErrorEndDate;
    }

    return !isOnError;
  };

  return (
    <MyModal
      show={show}
      title="Filtros de partidos"
      handleShow={handleShow}
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="col-md-3 col-sm-2 control-label">Cancha </label>
              <div className="col-md-9 col-sm-10">
                <select
                  name="stadium"
                  id="stadium"
                  className="form-control"
                  disabled={loading}
                  value={filtersValues.stadium}
                  onChange={(e) => {
                    setFiltersValuesHandler({ stadium: e.target.value });
                  }}
                >
                  <option value="">Seleccione</option>
                  {stadiums.map((stadium, key) => (
                    <option value={stadium.id} key={key + "stadium"}>
                      {stadium.name} - {stadium.sede_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className={`form-group ${datetimeStartError && "has-error"}`}>
              <label className="col-md-3 col-sm-2 control-label">
                Fecha desde{" "}
              </label>
              <div className="col-md-9 col-sm-10">
                <DatetimePicker
                  onChange={(value) => {
                    const valueFormat = !isNaN(Date.parse(value))
                      ? moment(value)
                          .utc()
                          .subtract(1, "day")
                          .format(env.DATE_FORMAT)
                      : "";
                    setFiltersValuesHandler({ datetimeStart: valueFormat });
                  }}
                  value={
                    filtersValues.datetimeStart
                      ? moment(filtersValues.datetimeStart)
                          .utc()
                          .add(3, "hours")
                          .format(env.DATETIME_FORMAT)
                      : filtersValues.datetimeStart
                  }
                  disable={loading}
                  showTime={false}
                />
                {datetimeStartError && (
                  <span className="help-block">
                    La fecha desde debe ser menor a la fecha hasta.
                  </span>
                )}
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className={`form-group ${datetimeEndError && "has-error"}`}>
              <label className="col-md-3 col-sm-2 control-label">
                Fecha hasta{" "}
              </label>
              <div className="col-md-9 col-sm-10">
                <DatetimePicker
                  onChange={(value) => {
                    const valueFormat = !isNaN(Date.parse(value))
                      ? moment(value)
                          .utc()
                          .subtract(1, "day")
                          .format(env.DATE_FORMAT)
                      : "";
                    setFiltersValuesHandler({ datetimeEnd: valueFormat });
                  }}
                  value={
                    filtersValues.datetimeEnd
                      ? moment(filtersValues.datetimeEnd)
                          .utc()
                          .add(3, "hours")
                          .format(env.DATETIME_FORMAT)
                      : filtersValues.datetimeEnd
                  }
                  disable={loading}
                  showTime={false}
                />
                {datetimeEndError && (
                  <span className="help-block">
                    La fecha hasta debe ser mayor a la fecha desde.
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      buttons={
        <Button
          title="Aplicar"
          className="btn btn-primary"
          onClick={onClickHandle}
        />
      }
    />
  );
};

export default MatchFiltersModal;
