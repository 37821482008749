import React, { useEffect, useState } from "react";
import Button from "../../../../utils/components/Button";
import MyModal from "../../../../utils/components/Modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactSwitch from "react-switch";
import Select from "react-select";

const GroupModal = ({
  show,
  handleShow,
  saveGroupHandler,
  group,
  setGroupKeyValue,
  isReady,
  modalTitle,
  buttomSaveTitle,
  isEditing,
  copyToClipboardStateHandle,
  lineups,
  nameError,
  lineupsError,
}) => {
  const [selectedLineups, setSelectedLineups] = useState([]);

  useEffect(() => {
    setSelectedLineups(
      group?.lineups?.map((lineup) => ({
        value: lineup.value || lineup.id,
        label: lineup.label || lineup.name,
      }))
    );
  }, [group]);

  return (
    <MyModal
      show={show}
      title={modalTitle()}
      handleShow={handleShow}
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12">
            <div className={`form-group ${nameError && "has-error"}`}>
              <label className="col-md-3 col-sm-2 control-label">
                Nombre *{" "}
              </label>
              <div className="col-md-9 col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  disabled={!isReady}
                  value={group.name}
                  onChange={(e) => {
                    setGroupKeyValue("name", e.target.value);
                  }}
                />
                {nameError && (
                  <span className="help-block">El nombre es requerido.</span>
                )}
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className={`form-group`}>
              <label className="col-md-3 col-sm-2 control-label">
                Habilitado
              </label>
              <div className="col-md-9 col-sm-10">
                <ReactSwitch
                  onChange={() => setGroupKeyValue("enabled", !group.enabled)}
                  disabled={false}
                  checked={group.enabled}
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className={`form-group  ${lineupsError && "has-error"}`}>
              <label className="col-md-3 col-sm-2 control-label">
                Equipos del grupo *
              </label>
              <div className="col-md-9 col-sm-10">
                <Select
                  isMulti
                  value={selectedLineups}
                  onChange={(selectedOptions) => {
                    setSelectedLineups(selectedOptions),
                      setGroupKeyValue("lineups", selectedOptions);
                  }}
                  options={lineups?.map((lineup) => ({
                    value: lineup.id,
                    label: lineup.team.name,
                  }))}
                  isSearchable
                  placeholder="Seleccione"
                  closeMenuOnSelect={false}
                />
                {lineupsError && (
                  <span className="help-block">
                    Los equipos son requeridos.
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      buttons={
        <>
          {isEditing && (
            <CopyToClipboard
              text={group.id}
              onCopy={() => copyToClipboardStateHandle(true)}
            >
              <Button title="Copiar ID" className="btn btn-light" />
            </CopyToClipboard>
          )}
          <Button
            title={buttomSaveTitle()}
            className="btn btn-primary"
            onClick={saveGroupHandler}
          />
        </>
      }
    />
  );
};

export default GroupModal;
