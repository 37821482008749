'use-strict'

export default {
    SET_MATCH: '@@worksheet/SET_MATCH',
    SET_IS_READY: '@@workseet/SET_IS_READY',
    SET_IS_FULL_SCREEN: '@@worksheet/SET_IS_FULL_SCREEN',
    PUSH_NEW_ROADMAP_ITEM: '@@worksheet/PUSH_NEW_ROADMAP_ITEM',
    REMOVE_ROADMAP_LAST_ITEM: '@@worksheet/REMOVE_ROADMAP_LAST_ITEM',
    REMOVE_INCIDENCE_ITEM: '@@worksheet/REMOVE_INCIDENCE_ITEM',
    ADD_TEAM_PLAYERS: '@@worksheet/ADD_TEAM_PLAYERS',
    ADD_TEAM_PLAYER: '@@worksheet/ADD_TEAM_PLAYER',
    SET_PLAYER_KEY_VALUE: '@@worksheet/SET_PLAYER_KEY_VALUE',
    SET_ALL_INCIDENCES: '@@worksheet/SET_ALL_INCIDENCES',
    PUSH_NEW_INCIDENCE: '@@worksheet/PUSH_NEW_INCIDENCE',
    REPLACE_PLAYER_ON_TEAM: '@@worksheet/REPLACE_PLAYER_ON_TEAM',
    ADD_TEAM_CHECKED_IN_PLAYER: '@@worksheet/ADD_TEAM_CHECKED_IN_PLAYER',
    REMOVE_TEAM_CHECKED_IN_PLAYER: '@@worksheet/REMOVE_TEAM_CHECKED_IN_PLAYER',
    PUSH_NEW_INTERVAL_ID: '@@worksheet/PUSH_NEW_INTERVAL_ID',
    CLEAR_MATCH: '@@worksheet/CLEAR_MATCH',
    CLEAR_IS_READY: '@@worksheet/CLEAR_IS_READY',
    CLEAR_ROADMAP: '@@worksheet/CLEAR_ROADMAP',
    CLEAR_TEAM_PLAYERS: '@@worksheet/CLEAR_TEAM_PLAYERS',
    CLEAR_ALL_INCIDENCES: '@@worksheet/SET_ALL_INCIDENCES',
    CLEAR_ALL_INTERVAL_IDS: '@@worksheet/CLEAR_ALL_INTERVAL_IDS',
    REPLACE_PLAYER_IMAGE_ON_TEAM: '@@worksheet/REPLACE_PLAYER_IMAGE_ON_TEAM'
}