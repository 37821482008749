import React, { useEffect, useState } from "react";

const TablePagination = ({
  onChangePage,
  defaultPage,
  defaultPageSize,
  itemsInPage,
}) => {
  const PAGES_TO_DISPLAY = 5;
  const FIRST_PAGE = 0;

  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);
  const [pagesToDisplay, setPagesToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [maxPageDisplayed, setMaxPageDisplayed] = useState(defaultPage);
  const [hasMorePages, setHasMorePages] = useState(true);

  useEffect(() => {
    setPagesToDisplay(getPagesToDisplay());
  }, [maxPageDisplayed]);

  useEffect(() => {
    setHasMorePages(itemsInPage === defaultPageSize);
  }, [itemsInPage, defaultPageSize]);

  useEffect(() => {
    setCurrentPage(defaultPage);
    setMaxPageDisplayed(defaultPage);
  }, [defaultPage]);

  const getPagesToDisplay = () => {
    const pages = [];

    for (
      let i = maxPageDisplayed - PAGES_TO_DISPLAY + 1;
      i <= maxPageDisplayed;
      i++
    ) {
      if (i >= 0) pages.push(i);
    }

    return pages;
  };

  const isFirstPage = () => currentPage === FIRST_PAGE;

  const hasPreviousPages = () => {
    return pagesToDisplay.findIndex((page) => page === FIRST_PAGE) === -1;
  };

  const handleClickPrevious = () => {
    const previousPage = pagesToDisplay[0] - 1;
    setCurrentPage(previousPage);
    setMaxPageDisplayed(maxPageDisplayed - 1);
    onChangePage(previousPage, currentPageSize);
  };

  const handleClickNext = () => {
    setCurrentPage(maxPageDisplayed + 1);
    setMaxPageDisplayed(maxPageDisplayed + 1);
    onChangePage(maxPageDisplayed + 1, currentPageSize);
  };

  const handleClickPage = (page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      onChangePage(page, currentPageSize);
    }
  };

  const handleClickFirst = () => {
    setCurrentPage(FIRST_PAGE);
    setMaxPageDisplayed(FIRST_PAGE);
    onChangePage(FIRST_PAGE, currentPageSize);
  };

  const handlePageSize = (pageSize) => {
    if (pageSize !== currentPageSize) {
      setCurrentPageSize(pageSize);
      setCurrentPage(FIRST_PAGE);
      setMaxPageDisplayed(FIRST_PAGE);
      onChangePage(FIRST_PAGE, pageSize);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-10">
          <ul className="pagination float-right">
            {!isFirstPage() && (
              <li className="footable-page-arrow disabled">
                <a data-page="first" onClick={handleClickFirst}>
                  «
                </a>
              </li>
            )}
            {hasPreviousPages() && (
              <li className="footable-page-arrow disabled">
                <a data-page="prev" onClick={handleClickPrevious}>
                  ‹
                </a>
              </li>
            )}
            {pagesToDisplay.map((page, key) => {
              return (
                <li
                  className={`footable-page ${
                    page === currentPage ? "active" : ""
                  }`}
                  key={key}
                >
                  <a
                    data-page={`${page}`}
                    onClick={() => handleClickPage(page)}
                  >
                    {page + 1}
                  </a>
                </li>
              );
            })}
            {hasMorePages && (
              <li className="footable-page-arrow">
                <a data-page="next" onClick={handleClickNext}>
                  ›
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="col-xs-2 table-size-selector-container">
          <select
            name="page-size-selector"
            id="page-size-selector"
            className="form-control table-size-selector pull-right"
            disabled={false}
            value={currentPageSize}
            onChange={(e) => handlePageSize(parseInt(e.target.value))}
          >
            <option value="10" key={"10"}>
              10
            </option>
            <option value="25" key={"25"}>
              25
            </option>
            <option value="50" key={"50"}>
              50
            </option>
            <option value="75" key={"75"}>
              75
            </option>
            <option value="100" key={"100"}>
              100
            </option>
          </select>
        </div>
      </div>
    </>
  );
};

export default TablePagination;
