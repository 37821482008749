import React, { useState } from "react";
import MyModal from "../../../utils/components/Modal";
import Button from "../../../utils/components/Button";
import Switch from "react-switch";

const NewParticipantModal = ({
  show,
  handleShow,
  isEditing,
  setIsEditing,
  participant,
  setParticipantKeyValue,
  saveParticipantHandler,
  modalTitle,
  buttomSaveTitle,
  loading,
  event,
  eventsRoles,
  clearSingleParticipant,
}) => {
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [firstAndLastNameError, setFirstAndLastNameError] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [responsabilityError, setResponsabilityError] = useState(false);

  const disable = () => loading;

  const createNewParticipantHandler = () => {
    let hasError = false;

    if (isEditing) {
      if (participant?.name === "") {
        setFirstAndLastNameError(true);
        hasError = true;
      }
    }

    if (participant.firstNameError === "") {
      setFirstNameError(true);
      hasError = true;
    }

    if (participant.lastNameError === "") {
      setLastNameError(true);
      hasError = true;
    }

    if (participant.document === "" || participant.document.includes(" ")) {
      setDocumentError(true);
      hasError = true;
    } else {
      setDocumentError(false);
    }

    if (
      participant.type === "" ||
      participant.type === "Invitado" ||
      participant.type === "Responsable"
    ) {
      setResponsabilityError(true);
      hasError = true;
    } else {
      setResponsabilityError(false);
    }

    if (hasError) return;
    if (isEditing) handleShow(false);
    setIsEditing(false);
    saveParticipantHandler();
  };

  return (
    <MyModal
      show={show}
      title={modalTitle()}
      handleShow={handleShow}
      closeButton
      backdrop="static"
      body={
        <div className="row">
          {!isEditing && (
            <div className="col-md-12">
              <div className={`alert alert-info`}>
                Podés ir agregando varios participantes con el boton "Agregar"
              </div>
            </div>
          )}

          <div className="col-md-12 m-b-1">
            <div className={`form-group ${documentError && "has-error"}`}>
              <label className="col-md-3 col-sm-2 control-label">DNI * </label>
              <div className="col-md-9 col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={participant?.document}
                  disabled={isEditing || disable()}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
                    setParticipantKeyValue("document", value);
                  }}
                />
                {documentError && (
                  <span className="help-block">
                    El DNI es requerido sin espacios.
                  </span>
                )}
              </div>
            </div>
          </div>
          {isEditing ? (
            <div className="col-md-12 m-b-1">
              <div
                className={`form-group ${
                  firstAndLastNameError && "has-error"
                } `}
              >
                <label className="col-md-3 col-sm-2 control-label">
                  Nombre y Apellido *{" "}
                </label>
                <div className="col-md-9 col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    value={participant?.name}
                    disabled={disable()}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^a-zA-Z]/g, "");
                      setParticipantKeyValue("name", value);
                    }}
                  />
                  {firstAndLastNameError && (
                    <span className="help-block">
                      El nombre y apellido es requerido.
                    </span>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="col-md-12 m-b-1">
                <div className={`form-group ${firstNameError && "has-error"} `}>
                  <label className="col-md-3 col-sm-2 control-label">
                    Nombre *{" "}
                  </label>
                  <div className="col-md-9 col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      value={participant.firstName}
                      disabled={disable()}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^a-zA-Z]/g, "");
                        setParticipantKeyValue("firstName", value);
                      }}
                    />
                    {firstNameError && (
                      <span className="help-block">
                        El nombre es requerido.
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 m-b-1">
                <div className={`form-group ${lastNameError && "has-error"} `}>
                  <label className="col-md-3 col-sm-2 control-label">
                    Apellido *{" "}
                  </label>
                  <div className="col-md-9 col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      value={participant.lastName}
                      disabled={disable()}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^a-zA-Z]/g, "");
                        setParticipantKeyValue("lastName", value);
                      }}
                    />
                    {lastNameError && (
                      <span className="help-block">
                        El apellido es requerido.
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="col-md-12 m-b-2">
            <div
              className={`form-group  ${responsabilityError && "has-error"} `}
            >
              <label className="col-md-3 col-sm-2 control-label">Tipo * </label>
              {console.log("participant", participant)}
              <div className="col-md-9 col-sm-10">
                <select
                  type="type"
                  maxLength={60}
                  className="form-control"
                  value={participant.type}
                  disabled={disable()}
                  onChange={(e) => {
                    setParticipantKeyValue("type", e.target.value);
                  }}
                >
                  {!isEditing && <option value="">Seleccione</option>}
                  {eventsRoles.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                {responsabilityError && (
                  <span className="help-block">El tipo es requerido.</span>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      buttons={
        <Button
          title={buttomSaveTitle()}
          className="btn btn-primary pull-right"
          onClick={createNewParticipantHandler}
          loading={disable()}
        />
      }
    />
  );
};

export default NewParticipantModal;
