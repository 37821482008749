import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { AppContainer } from 'react-hot-loader'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './redux/store'
import App from './App'

import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/font-awesome/css/font-awesome.css'
import './../node_modules/animate.css/animate.min.css'

const render = (Component) => {
    ReactDOM.render(
        <Provider store={configureStore.store} >
            <PersistGate loading={null} persistor={configureStore.persistor}>
                <ConnectedRouter history={history}>
                    <Router history={history}>
                        <AppContainer><Component /></AppContainer>
                    </Router>
                </ConnectedRouter>
            </PersistGate>
        </Provider>,
        document.getElementById('root')
    )
}

render(App)

if (module.hot) {
    module.hot.accept('./App', () => {
        const App = require('./App').default;
        render(App)
    })
}
