import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './specificAlert.scss'
import def from '../../functions/defaults'
import scrollTo from '../../functions/scrollTo'

const SpecificAlert = ({
    show = def.false,
    message = def.str,
    type = def.str,
    classes = def.str
}) => {

    useEffect(() => {
        if (!show) return
        
        scrollTo(window,0)
    }, [show])

    return (
        <div className={`${show?'show':'hide'} ${classes}`}>
            <div className={`alert alert-${type}`}>
                {message}
            </div>
        </div>
    )
}

const mapState = state => ({
    ...state.alert.specific
})

const mapDispatch = dispatch => ({ })

export default connect(mapState, mapDispatch)(SpecificAlert)