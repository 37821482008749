import React from 'react'
import '../worksheet.scss'

const Input = ({
    onChange,
    name,
    value,
    error
}) => {
    return (
        <div className="wrapper">
            <span 
                className="arrow"
                onClick={() => onChange(Number(value) + 1)}>
                <i className="fa fa-angle-up"></i>
            </span>
            <input
                type="number"
                name={name}
                value={value}
                className={`input-clock ${error}`}
                onChange={(e) => onChange(e.target.value)} />
            <span 
                className="arrow"
                onClick={() => onChange(Number(value) - 1)}>
                <i className="fa fa-angle-down"></i>
            </span>
        </div>
    )
}

const Clock = ({
    hour,
    minutes,
    setHour,
    setMinutes,
    hourError,
    minutesError
}) => {

    return (
        <div className="time-manager">
            <Input 
                onChange={(hour) => {
                    if (hour < 0 || hour > 23) return
                    if (hour.toString().length > 2) return
                    setHour(hour.toString())
                }}
                name='hour'
                value={hour}
                error={hourError}
            />
            <span className="double-points">:</span>
            <Input 
                onChange={(minutes) => {
                    if (minutes < 0 || minutes > 59) return
                    if (minutes.toString().length > 2) return
                    setMinutes(minutes.toString())
                }}
                name='minutes'
                value={minutes}
                error={minutesError}
            />
        </div>
    )
}

export default Clock