import React, { useState, createRef } from "react";
import Button from "../../../../utils/components/Button";
import UserImage from "../../../../assets/images/user.png";
import BrandImageUploader from "../../../../utils/components/ImageUploader/BrandImageUploader";

const PlayersModalForm = ({
  addPlayerToLineup,
  setPlayerKeyValue,
  player,
  clearSinglePlayer,
  editingPlayer,
  setEditingPlayer,
  loadingAddPlayerToLineup,
  updatePlayer,
  selectedPlayer,
  setSelectedPlayer,
  loadingUpdatePlayer,
  deletePlayerImageHandler,
}) => {
  const playerUploaderRef = createRef();
  const [nameError, setNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [documentError, setDocumentError] = useState(false);

  const disable = () => loadingAddPlayerToLineup || loadingUpdatePlayer;

  const deletePlayerImage = () => {
    deletePlayerImageHandler(player.id);
    clearSinglePlayer();
    setEditingPlayer(false);
    setSelectedPlayer("");
  };

  const hasPlayerImage = player.media && player.media.user_big;

  const hasUserImage =
    player.user && player.user.media && player.user.media.user_big;

  const createNewPlayerHandler = () => {
    let hasError = false;
    if (player.firstName === "") {
      setNameError(true);
      hasError = true;
    } else {
      setNameError(false);
      hasError = false;
    }
    if (player.lastName === "") {
      setLastNameError(true);
      hasError = true;
    } else {
      setLastNameError(false);
      hasError = false;
    }
    if (player.document === "") {
      setDocumentError(true);
      hasError = true;
    } else {
      setDocumentError(false);
      hasError = false;
    }
    if (hasError) return;

    if (editingPlayer) {
      const playerImage = playerUploaderRef.current.getFormData();
      updatePlayer(selectedPlayer, playerImage);
    } else {
      addPlayerToLineup();
    }

    clearSinglePlayer();
    setEditingPlayer(false);
    setSelectedPlayer("");
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className={`alert alert-info`}>
          {editingPlayer
            ? "Podés actualizar al jugador."
            : "Podés agregar jugadores al equipo, ahora o luego."}
        </div>
      </div>
      {editingPlayer && (
        <div className="col-md-12">
          <BrandImageUploader
            id="image"
            enableUploader={true}
            ref={playerUploaderRef}
            title={`${player.firstName} ${player.lastName}`}
            loading={loadingUpdatePlayer}
            image={
              hasPlayerImage
                ? player.media.user_big
                : hasUserImage
                ? player.user.media.user_big
                : UserImage
            }
            validExtensions={["jpg", "jpeg", "png"]}
            minWidth={200}
            minHeight={200}
            aspectRatio={1}
            deleteImage={deletePlayerImage}
            showDeleteImageButtom={hasPlayerImage || hasUserImage}
            confirmationTitle={
              "¿Estás seguro que quieres eliminar la imagen de este jugador?"
            }
            confirmationBody={"Eliminar imagen del jugador"}
            verifiedImage={hasPlayerImage}
          />
        </div>
      )}
      <div className="col-md-12 m-b-1">
        <div className={`form-group ${nameError && "has-error"}`}>
          <label className="col-md-3 col-sm-2 control-label">Nombre * </label>
          <div className="col-md-9 col-sm-10">
            <input
              type="text"
              className="form-control"
              value={player.firstName}
              disabled={disable()}
              onChange={(e) => {
                setPlayerKeyValue("firstName", e.target.value);
              }}
            />
            {nameError && (
              <span className="help-block">El nombre es requerido.</span>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-12 m-b-1">
        <div className={`form-group ${lastNameError && "has-error"}`}>
          <label className="col-md-3 col-sm-2 control-label">Apellido * </label>
          <div className="col-md-9 col-sm-10">
            <input
              type="text"
              className="form-control"
              value={player.lastName}
              disabled={disable()}
              onChange={(e) => {
                setPlayerKeyValue("lastName", e.target.value);
              }}
            />
            {lastNameError && (
              <span className="help-block">El apellido es requerido.</span>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-12 m-b-2">
        <div className={`form-group ${documentError && "has-error"}`}>
          <label className="col-md-3 col-sm-2 control-label">
            Documento *{" "}
          </label>
          <div className="col-md-9 col-sm-10">
            <input
              type="text"
              className="form-control"
              value={player.document}
              disabled={editingPlayer || disable()}
              onChange={(e) => {
                setPlayerKeyValue("document", e.target.value);
              }}
            />
            {documentError && (
              <span className="help-block">El documento es requerido.</span>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-12 m-b-1">
        <Button
          title={editingPlayer ? "Guardar" : "Agregar"}
          className="btn btn-primary pull-right"
          onClick={createNewPlayerHandler}
          loading={disable()}
        />
      </div>
    </div>
  );
};

export default PlayersModalForm;
