import React, { useState, useEffect } from "react";
import Button from "../../../../utils/components/Button";
import Table from "../../../../utils/components/Table";
import MatchModal from "./MatchModal";
import MatchFiltersModal from "./MatchFiltersModal";
import mapIncidenceTypes from "../../../../utils/functions/mapIncidenceTypes";
import incidencesCategories from "../../../../redux/reducers/worksheetReducer/hardcoded/incidenceCategories";
import moment from "moment";
import * as env from "../../../../config/env";
import UploadFileButton from "../../../../utils/components/UploadFileButton";
import roles from "../../../../config/roles";

const MatchList = ({
  matches,
  loadingFetchMatches,
  setMatchKeyValue,
  clearSingleMatch,
  clearAllLineupsByGroups,
  match,
  lineups,
  lineupsByGroup,
  dates,
  groups,
  stadiums,
  createNewMatch,
  deleteMatch,
  loadingDeleteMatch,
  loadMatch,
  updateMatch,
  onClickGoToWorksheet,
  loadingLoadMatch,
  showCreateButton,
  showDeleteButton,
  isPlayoff,
  uploadMasiveMatchesHandler,
  refreshMatches,
  onClickGotoWorksheetOfflineHandler,
  downloadMatchLineupsListHandler,
  loadGroup,
  organizerId,
  tournamentId,
  removeLineupsGroup,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showMatchFiltersModal, setShowMatchFiltersModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [matchIdSelected, setMatchIdSelected] = useState(matchIdSelected);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [matchGeneralFilters, setMatchGeneralFilters] = useState({
    group: "",
    date: "",
    datetime: "",
    stadium: "",
    state: "",
  });

  useEffect(() => {
    if (match.group_id && !isEditing) {
      match.group_id.forEach((group) => {
        loadGroup(organizerId, tournamentId, group.value);
      });
    }
  }, [match.group_id]);

  useEffect(() => {
    const storedFilters = localStorage.getItem("matchsFilters");
    if (storedFilters) {
      setMatchGeneralFilters(JSON.parse(storedFilters));
    }
  }, []);

  useEffect(() => {
    clearSingleMatch();
    clearAllLineupsByGroups();

    if (isEditing) loadMatch(matchIdSelected);
  }, [showMatchModal]);

  const setFilterKeyValueHandle = (filter) => {
    localStorage.setItem("matchsFilters", JSON.stringify(filter));
    setMatchGeneralFilters({
      ...matchGeneralFilters,
      ...filter,
    });
  };

  const hasMatchFilter = () => {
    return (
      matchGeneralFilters.group ||
      matchGeneralFilters.stadium ||
      matchGeneralFilters.date ||
      matchGeneralFilters.datetime ||
      matchGeneralFilters.state
    );
  };

  const closeMatchModalHandle = () => setShowMatchModal(false);
  const saveMatchHandler = () =>
    isEditing
      ? updateMatch(matchIdSelected, isPlayoff, closeMatchModalHandle)
      : createNewMatch(closeMatchModalHandle);
  const modalTitle = () => (isEditing ? "Modificar Partido" : "Crear Partido");
  const buttomSaveTitle = () => (isEditing ? "Guardar" : "Crear");

  const matchGeneralFilterIsOk = (match) => {
    // const foundGroup =
    //   match.group_id.id
    //     .trim()
    //     .toLowerCase()
    //     .indexOf(matchGeneralFilters.group.toLowerCase()) !== -1;
    const foundDate =
      match.date.id
        .trim()
        .toLowerCase()
        .indexOf(matchGeneralFilters.date.toLowerCase()) !== -1;
    const foundStadium =
      (!match.stadium && !matchGeneralFilters.stadium) ||
      (match.stadium &&
        match.stadium.id
          .trim()
          .toLowerCase()
          .indexOf(matchGeneralFilters.stadium.toLowerCase()) !== -1);
    const foundDatetime = matchGeneralFilters.datetime
      ? match.datetime.substring(0, 10) === matchGeneralFilters.datetime
      : true;
    const foundState =
      getMatchState(match.incidencesSummary)
        .trim()
        .toLowerCase()
        .indexOf(matchGeneralFilters.state.toLowerCase()) !== -1;

    return (
      /*foundGroup &&*/ foundDatetime && foundDate && foundStadium && foundState
    );
  };

  const getFilteredMatches = () =>
    matches.filter((match) => {
      if (!matchGeneralFilterIsOk(match)) return false;

      const foundLocalTeamName =
        match.localTeam &&
        match.localTeam.team.name
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      const foundLocalTeamShortName =
        match.localTeam &&
        match.localTeam.team.shortName
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      const foundVisitingTeamName =
        match.visitingTeam &&
        match.visitingTeam.team.name
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      const foundVisitingTeamShortName =
        match.visitingTeam &&
        match.visitingTeam.team.shortName
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      // const foundGroupName =
      //   match.group_id.name
      //     .trim()
      //     .toLowerCase()
      //     .indexOf(searchValue.toLowerCase()) !== -1;
      const foundDatetime =
        moment(match.datetime)
          .utc()
          .format(env.DATETIME_FORMAT)
          .indexOf(searchValue) !== -1;
      const foundState =
        getMatchState(match.incidencesSummary)
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;

      return (
        foundLocalTeamName ||
        foundLocalTeamShortName ||
        foundVisitingTeamName ||
        foundVisitingTeamShortName ||
        /*foundGroupName ||*/
        foundDatetime ||
        foundState
      );
    });

  const getMatchState = (incidencesSummary) => {
    if (incidencesSummary.lastTimeIncidence)
      return mapIncidenceTypes(
        incidencesSummary.lastTimeIncidence,
        incidencesCategories
      );

    return "Sin comenzar";
  };

  const addFakeTeam = (team) =>
    team ? team : { team: { name: "-", shortName: "-" } };

  const addActions = (matches) =>
    matches.map((match) => {
      return {
        ...match,
        matchState: getMatchState(match.incidencesSummary),
        localTeam: addFakeTeam(match.localTeam),
        visitingTeam: addFakeTeam(match.visitingTeam),
        datetime: match.datetime
          ? moment.utc(match.datetime).local().format(env.DATETIME_FORMAT)
          : "-",
        actions: (
          <div className="btn-separated-group">
            <Button
              className="btn btn-warning"
              title={<i className="fa fa-pencil"></i>}
              withTooltip={true}
              tooltipTitle="Editar"
              onClick={() => {
                setMatchIdSelected(match.id);
                setIsEditing(true);
                setShowMatchModal(true);
              }}
            />
            {showCreateButton && (
              <Button
                className="btn btn-danger"
                title={<i className="fa fa-trash"></i>}
                withTooltip={true}
                tooltipTitle="Eliminar"
                onClick={() => deleteMatch(match.id)}
                loading={loadingDeleteMatch}
                withConfirmation={true}
                confirmationTitle="Eliminar partido"
                onConfirmText="Confirmar"
                onCancelText="Cancelar"
                onCancelClass="btn-danger pull-left"
                confirmationBody="¿Estás seguro que quieres eliminar este partido?"
              />
            )}
            <Button
              className="btn btn-info"
              title={<i className="fa fa-play"></i>}
              withTooltip={true}
              tooltipTitle="Planillero"
              onClick={() => onClickGoToWorksheet(match.id, true)}
              disabled={!match.localTeam || !match.visitingTeam}
            />
            <Button
              className="btn btn-success"
              title={<i className="fa fa-file"></i>}
              withTooltip={true}
              tooltipTitle="Planillero offline"
              onClick={() => onClickGotoWorksheetOfflineHandler(match.id)}
              disabled={!match.localTeam || !match.visitingTeam}
            />
            <Button
              className="btn btn-primary"
              title={<i className="fa fa-download"></i>}
              withTooltip={true}
              tooltipTitle="Ficha de partido"
              onClick={() => {
                downloadMatchLineupsListHandler(match.id);
              }}
              disabled={!match.localTeam || !match.visitingTeam}
            />
          </div>
        ),
      };
    });

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        Partidos
        {showDeleteButton && (
          <Button
            title={
              <span>
                <i className={`fa fa-plus`}></i> Partido
              </span>
            }
            className="btn btn-primary btn-xs pull-right"
            onClick={() => {
              setIsEditing(false);
              setShowMatchModal(true);
            }}
            withTooltip={true}
            tooltipTitle="Nuevo partido"
          />
        )}
        {roles.ROLE_MASSIVE_OPERATIONS() && (
          <UploadFileButton
            text="CSV"
            className="pull-right btn-warning m-r-xs"
            icon={<i className={`fa fa-file-text`}></i>}
            tooltipText="Alta masiva de partidos"
            handler={async (file) => {
              setLoadingUploadFile(true);
              await uploadMasiveMatchesHandler(file);
              await refreshMatches();
              setLoadingUploadFile(false);
            }}
          />
        )}
        {hasMatchFilter() && (
          <Button
            title={
              <span>
                <i className={`fa fa-filter`}></i>{" "}
                <i className={`fa fa-times`}></i>
              </span>
            }
            className="btn btn-danger btn-xs pull-right m-r-xs"
            onClick={() => {
              setFilterKeyValueHandle({
                group: "",
                date: "",
                datetime: "",
                stadium: "",
                state: "",
              });
              localStorage.removeItem("matchsFilters");
            }}
            withTooltip={true}
            tooltipTitle="Limpiar filtros"
          />
        )}
        <Button
          title={
            <span>
              <i className={`fa fa-filter`}></i>
            </span>
          }
          className="btn btn-primary btn-xs pull-right m-r-xs"
          onClick={() => setShowMatchFiltersModal(true)}
          withTooltip={true}
          tooltipTitle="Aplicar filtros"
        />
      </div>
      <div className="panel-body">
        <input
          type="text"
          className="form-control"
          placeholder="Buscar partido..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Table
          loading={loadingFetchMatches}
          headers={[
            {
              label: "Local",
              ref: "localTeam.team.name",
              className: "col-md-2 visible-md visible-lg",
            },
            {
              label: "Local",
              ref: "localTeam.team.shortName",
              className: "col-xs-2 col-sm-2 visible-sm visible-xs",
            },
            {
              label: "Visitante",
              ref: "visitingTeam.team.name",
              className: "col-md-2 visible-md visible-lg",
            },
            {
              label: "Visitante",
              ref: "visitingTeam.team.shortName",
              className: "col-xs-2 col-sm-2 visible-sm visible-xs",
            },
            {
              label: "Fecha y hora",
              ref: "datetime",
              className: "col-md-2 col-sm-2 hidden-xs",
            },
            {
              label: "Estado",
              ref: "matchState",
              className: "col-md-2 col-sm-2 hidden-xs",
            },
            {
              label: "Acciones",
              ref: "actions",
              className: "col-xs-8 col-sm-4 text-center",
            },
          ]}
          rows={loadingUploadFile ? [] : addActions(getFilteredMatches())}
          notFoundMessage="No se encontraron partidos..."
        />
        <MatchModal
          show={showMatchModal}
          handleShow={setShowMatchModal}
          setMatchKeyValue={setMatchKeyValue}
          clearSingleMatch={clearSingleMatch}
          match={match}
          lineups={lineups}
          lineupsByGroup={lineupsByGroup}
          dates={dates}
          groups={groups}
          stadiums={stadiums}
          saveMatch={saveMatchHandler}
          loading={loadingLoadMatch}
          isEditing={isEditing}
          matchIdSelected={matchIdSelected}
          modalTitle={modalTitle}
          buttomSaveTitle={buttomSaveTitle}
          isPlayoff={isPlayoff}
          clearAllLineupsByGroups={clearAllLineupsByGroups}
          removeLineupsGroup={removeLineupsGroup}
        />
        <MatchFiltersModal
          show={showMatchFiltersModal}
          handleShow={setShowMatchFiltersModal}
          groups={groups}
          stadiums={stadiums}
          dates={dates}
          filters={matchGeneralFilters}
          setFiltersKeyValue={setFilterKeyValueHandle}
          loading={loadingLoadMatch}
        />
      </div>
    </div>
  );
};

export default MatchList;
