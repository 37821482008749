"use-strict";

import only from "../../../utils/functions/only";
import moment from "moment";

export default {
  all: [],
  single: {
    name: "",
    description: "",
    event_date_start: "",
    event_date_finish: "",
    event_date: "",
    event_sede: "",
    event_description: "",
    event_type: "",
    event_category: "",
    event_image: {
      unlink: true,
    },
    event_background: {
      unlink: true,
    },
    event_inscription_start: "",
    event_inscription_finish: "",
    event_invite_cant: 0,
    event_update_by: "",
    event_update_date: "",
    event_create_by: "",
    event_create_date: "",
    organizer: "",
    status: 1,
    is_free: 1,
  },
  eventsCategory: [],
  eventsType: [],
};

const modelAttributes = [
  "id",
  "name",
  "event_date_start",
  "event_date_finish",
  "event_date",
  "event_sede",
  "event_description",
  "event_type",
  "event_category",
  "event_inscription_start",
  "event_inscription_finish",
  "event_invite_cant",
  "event_update_by",
  "event_update_date",
  "event_create_by",
  "event_create_date",
  "organizer",
  "status",
  "is_free",
];

export const model = (entity) => only(entity, modelAttributes);
