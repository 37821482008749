import React, { useState, memo, useCallback } from "react";
import Button from "../../../../utils/components/Button";
import MyModal from "../../../../utils/components/Modal";
import DatetimePicker from "../../../../utils/components/DatetimePicker";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import tournamentActions from "../../../../redux/reducers/tournamentReducer/tournamentActions";

const LineUpConfigModal = memo(({ show, handleShow, updateTournament }) => {
  const [playerManagementDeadlineError, setPlayerManagementDeadlineError] =
    useState(false);
  const dispatch = useDispatch();
  const { playerManagementDeadline, maxPlayers, canRemoveLineUpPlayers } =
    useSelector(({ tournament }) => tournament.single);

  const setValue = useCallback(
    (key, value) =>
      dispatch(tournamentActions.setTournamentKeyValue(key, value)),
    []
  );

  const updateTournamentHandler = useCallback(() => {
    if (playerManagementDeadline === "") {
      setPlayerManagementDeadlineError(true);
      return;
    }

    setPlayerManagementDeadlineError(false);
    handleShow(false);
    updateTournament();
  }, [playerManagementDeadline]);

  return (
    <MyModal
      show={show}
      title="Configuración de lineups"
      handleShow={handleShow}
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12">
            <div className={`alert alert-info`}>
              Establecer limitaciones para la gestión de lineups
            </div>
            <div
              className={`form-group ${
                playerManagementDeadlineError && "has-error"
              }`}
            >
              <label className="col-md-6 col-sm-6 control-label">
                Límite de tiempo *{" "}
              </label>
              <div className="col-md-6 col-sm-6">
                <DatetimePicker
                  onChange={(date) => {
                    setValue("playerManagementDeadline", date);
                  }}
                  showTime={true}
                  value={playerManagementDeadline}
                  disable={false}
                />
                {playerManagementDeadlineError && (
                  <span className="help-block">
                    El límite de tiempo es requerido.
                  </span>
                )}
              </div>
              <div className="clearfix" />
            </div>
            <div className={`form-group`}>
              <label className="col-md-6 col-sm-6 control-label">
                Límite de cantidad{" "}
              </label>
              <div className="col-md-6 col-sm-6">
                <input
                  type="number"
                  className="form-control"
                  value={maxPlayers ? maxPlayers : ""}
                  disabled={false}
                  onChange={(e) => setValue("maxPlayers", e.target.value)}
                />
              </div>
              <div className="clearfix" />
            </div>
            <div className={`form-group`}>
              <label className="col-md-6 col-sm-6 control-label">
                Habilitar borrado de jugadores *{" "}
              </label>
              <div className="col-md-6 col-sm-6">
                <Switch
                  onChange={() =>
                    setValue("canRemoveLineUpPlayers", !canRemoveLineUpPlayers)
                  }
                  checked={canRemoveLineUpPlayers}
                />
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      }
      buttons={
        <Button
          title="Siguiente"
          className="btn btn-primary"
          onClick={updateTournamentHandler}
        />
      }
    />
  );
});

export default LineUpConfigModal;
