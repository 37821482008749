import playerActions from '../reducers/playerReducer/playerActions'
import playerService from '../reducers/playerReducer/playerService'
import { setLoading } from '../reducers/loadingReducer'
import handler, { statusCodes } from '../../utils/functions/handler'
import { actions as alertActions } from '../reducers/alertReducer'
import without from '../../utils/functions/without'
import keepOrganizer from '../../utils/functions/keepOrganizer'
import { history } from '../store'
import moment from 'moment'
import * as env from '../../config/env'

export const loadAllPlayers = (organizerId) => async (dispatch, getState) => {
    dispatch(setLoading.true('player.loadAllPlayers'))
    const [error, players] = await playerService.loadAll(organizerId)
    dispatch(setLoading.false('player.loadAllPlayers'))
    if (error) {
        return dispatch(handler(error))
    }
    dispatch(playerActions.setAllPlayers(players))
}

export const deletePlayer = (organizerId, playerId) => async (dispatch, getState) => {
    dispatch(setLoading.true('player.deletePlayer'))
    const store = getState()
    const [error] = await playerService.deletePlayer(organizerId, playerId)
    const deletedPlayer = store.player.all.find(player => player.id === playerId)
    dispatch(setLoading.false('player.deletePlayer'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                [statusCodes.CONFLICT]: {
                    msg: 'No se puede eliminar un jugador que pertenece a un lineup o tiene incidencias asociadas.'
                }
            }
        }))
    }
    dispatch(playerActions.deletePlayer(playerId))
    dispatch(alertActions.showFloatingAlert(
        `Se ha eliminado el jugador <b>${deletedPlayer.lastName} ${deletedPlayer.lastName}</b>.`, 'info', 3500
    ))
}

export const loadPlayer = (organizerId, playerId) => async (dispatch, getState) => {
    dispatch(setLoading.true('player.loadPlayer'))
    const [error, player] = await playerService.loadOne(organizerId, playerId)
    dispatch(setLoading.false('player.loadPlayer'))
    if (error) {
        return dispatch(handler(error))
    }
    if(!!player.medicalRecordExp)
        player.medicalRecordExp = moment(player.medicalRecordExp).utc().format(env.DATE_FORMAT)
    dispatch(playerActions.setSinglePlayer(player))
}

export const updatePlayer = (organizerId, playerId, image, medicalRecord) => async (dispatch, getState) => {
    dispatch(setLoading.true('player.updatePlayer'))
    const store = getState()
    const fieldsToExclude =  
        ['id', 'document', 'isAssociatedToUser', 'user', 'media', 'medicalRecord', ...(!!store.player.single.medicalRecordExp ? [] : ['medicalRecordExp'])] 
    const player = without(store.player.single, fieldsToExclude)
    const [error, updatedPlayer] = await playerService.updatePlayer(organizerId, playerId, player)
    
    let errorImage = null;

    if (image) {
        [errorImage] = await playerService.playerImages(organizerId, playerId, image)
    }

    let errorMedicalRecord = null;

    if(medicalRecord) {
        [errorMedicalRecord] = await playerService.playerMedicalRecord(organizerId, playerId, medicalRecord)
    }
    
    dispatch(setLoading.false('player.updatePlayer'))

    if (error || errorImage || errorMedicalRecord) {
        return dispatch(handler({
            ...error,
            handler: {
                [statusCodes.BAD_REQUEST]: {
                    msg: 'Ocurrió un error formando los datos. Por favor intenta de nuevo.'
                }
            }
        }))
    }
    dispatch(playerActions.replacePlayer(without(store.player.single, ['id'])))
    dispatch(alertActions.showFloatingAlert(
        `Se ha actualizado el jugador <b>${updatedPlayer.firstName} ${updatedPlayer.lastName}</b>.`, 'info', 3500
    ))
    history.push(keepOrganizer('/players'))
}

export const loadTournamentPlayers = (organizerId, tournamentId) => async(dispatch, getState) => {
    dispatch(setLoading.true('player.loadAllPlayers'))
    const [error, players] = await playerService.loadAllInTournament(organizerId, tournamentId)
    dispatch(setLoading.false('player.loadAllPlayers'))
    if (error) {
        return dispatch(handler(error))
    }
    dispatch(playerActions.setAllPlayers(players))
}

export const deletePlayerImage = (organizerId, playerId) => async(dispatch, getState) => {
    dispatch(setLoading.true('player.deletePlayerImage'))
    const [error, updatedPlayer] = await playerService.deletePlayerImage(organizerId, playerId)
    dispatch(setLoading.false('player.deletePlayerImage'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                [statusCodes.CONFLICT]: {
                    msg: 'Error al eliminar la imagen del jugador.'
                }
            }
        }))
    }

    const store = getState()
    const player = store.player.single
    
    dispatch(alertActions.showFloatingAlert(
        `Se ha eliminado la imagen del jugador <b>${player.firstName} ${player.lastName}</b>.`, 'info', 3500
    ))

    history.push(keepOrganizer('/players'))
} 

export const deletePlayerMedicalRecord = (organizerId, playerId) => async(dispatch, getState) => {
    dispatch(setLoading.true('player.deletePlayerMedicalRecord'))
    const [error, updatedPlayer] = await playerService.deletePlayerMedicalRecord(organizerId, playerId)
    dispatch(setLoading.false('player.deletePlayerMedicalRecord'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                [statusCodes.CONFLICT]: {
                    msg: 'Error al eliminar la ficha médica del jugador.'
                }
            }
        }))
    }

    const store = getState()
    const player = store.player.single
    
    dispatch(alertActions.showFloatingAlert(
        `Se ha eliminado la ficha médica del jugador <b>${player.firstName} ${player.lastName}</b>.`, 'info', 3500
    ))

    history.push(keepOrganizer('/players'))
} 
