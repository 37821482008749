import React, { useEffect, useState, createRef } from "react";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import Button from "../../utils/components/Button";
import { history } from "../../redux/store";
import TeamImage from "../../assets/images/team.png";
import SpecificAlert from "../../utils/components/SpecificAlert/SpecificAlert";
import {
  urlValidator,
  emailValidator,
} from "../../utils/functions/inputValidator";
import BrandImageUploader from "../../utils/components/ImageUploader/BrandImageUploader";
import ReactSwitch from "react-switch";
import "./OrganizerForm.scss";
import keepOrganizer from "../../utils/functions/keepOrganizer";

const OrganizerForm = ({
  setOrganizerKeyValue,
  saveOrganizer,
  hideSpecificAlert,
  organizer,
  loadingFetchOrganizer,
  loadingSaveOrganizer,
  saveButtonTitle,
  panelTitle,
  isEdit,
}) => {
  const brandUploaderRef = createRef();
  const [nameError, setNameError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [facebookUrlError, setFacebookUrlError] = useState(false);
  const [instagramUrlError, setInstagramUrlError] = useState(false);
  const [twitterUrlError, setTwitterUrlError] = useState(false);
  const [whatsappError, setWhatsappError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [rulesPdfUrlError, setRulesPdfUrlError] = useState(false);

  console.log("organizer desde form container", organizer.events_enabled);

  useEffect(() => {
    hideSpecificAlert();
  }, []);

  const saveOrganizerHandler = () => {
    let hasError = false;
    if (organizer.name === "") {
      hasError = true;
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (organizer.type === "") {
      hasError = true;
      setTypeError(true);
    } else {
      setTypeError(false);
    }

    if (
      organizer.facebookUrl &&
      organizer.facebookUrl.trim() !== "" &&
      !urlValidator(organizer.facebookUrl)
    ) {
      hasError = true;
      setFacebookUrlError(true);
    } else {
      setFacebookUrlError(false);
    }

    if (
      organizer.instagramUrl &&
      organizer.instagramUrl.trim() !== "" &&
      !urlValidator(organizer.instagramUrl)
    ) {
      hasError = true;
      setInstagramUrlError(true);
    } else {
      setInstagramUrlError(false);
    }

    if (
      organizer.twitterUrl &&
      organizer.twitterUrl.trim() !== "" &&
      !urlValidator(organizer.twitterUrl)
    ) {
      hasError = true;
      setTwitterUrlError(true);
    } else {
      setTwitterUrlError(false);
    }

    if (
      organizer.rulesPdfUrl &&
      organizer.rulesPdfUrl.trim() !== "" &&
      !urlValidator(organizer.rulesPdfUrl)
    ) {
      hasError = true;
      setRulesPdfUrlError(true);
    } else {
      setRulesPdfUrlError(false);
    }

    if (
      organizer.email &&
      organizer.email.trim() !== "" &&
      !emailValidator(organizer.email)
    ) {
      hasError = true;
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (
      organizer.whatsapp &&
      (organizer.whatsapp.toString().length > 15 ||
        organizer.whatsapp.toString().length < 10)
    ) {
      hasError = true;
      setWhatsappError(true);
    } else {
      setWhatsappError(false);
    }

    if (hasError) return;

    const brandImage = brandUploaderRef.current.getFormData();

    saveOrganizer(brandImage);
  };

  return (
    <>
      <Breadcrumbs
        title="Empresas"
        opts={[
          {
            title: "Inicio",
            path: "/",
          },
          {
            title: "Empresas",
            path: keepOrganizer("/organizers"),
            active: false,
          },
          {
            title: organizer.name,
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div
              className="ibox-title"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn btn-info"
                title={
                  <span>
                    <i className="fa fa-arrow-left"></i> Volver
                  </span>
                }
                onClick={() => history.push("/organizers")}
              />
            </div>
            <div className="ibox-content">
              <SpecificAlert />
              <div className="row">
                <div className="col-md-4">
                  <BrandImageUploader
                    id="logo"
                    enableUploader={isEdit}
                    ref={brandUploaderRef}
                    title={panelTitle}
                    loading={loadingFetchOrganizer}
                    image={
                      organizer.image && organizer.image.organizer_logo_big
                        ? organizer.image.organizer_logo_big
                        : TeamImage
                    }
                    validExtensions={["jpg", "jpeg", "png"]}
                    minWidth={200}
                    minHeight={200}
                    aspectRatio={1}
                  />
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel panel-default">
                        <div className="panel-heading">Datos</div>
                        <div className="panel-body">
                          <div
                            className={`form-group ${nameError && "has-error"}`}
                          >
                            <label className="col-md-3 col-sm-2 control-label">
                              Nombre *{" "}
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <input
                                type="text"
                                className="form-control"
                                value={organizer.name}
                                disabled={loadingFetchOrganizer}
                                onChange={(e) => {
                                  setOrganizerKeyValue("name", e.target.value);
                                }}
                              />
                              {nameError && (
                                <span className="help-block">
                                  El nombre es requerido.
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div
                            className={`form-group ${typeError && "has-error"}`}
                          >
                            <label className="col-md-3 col-sm-2 control-label">
                              Tipo *{" "}
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <select
                                name="tier"
                                id="tier"
                                className="form-control"
                                disabled={false}
                                value={organizer.tier}
                                onChange={(e) => {
                                  setOrganizerKeyValue("tier", e.target.value);
                                }}
                              >
                                <option value="TIER_GOLD">Gold</option>
                                <option value="TIER_PLATINUM">Platinum</option>
                              </select>
                              {typeError && (
                                <span className="help-block">
                                  El tipo es requerido.
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          {isEdit && (
                            <>
                              <div
                                className={`form-group ${
                                  facebookUrlError && "has-error"
                                }`}
                              >
                                <label className="col-md-3 col-sm-2 control-label">
                                  Facebook{" "}
                                </label>
                                <div className="col-md-9 col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={organizer.facebookUrl}
                                    disabled={loadingFetchOrganizer}
                                    onChange={(e) => {
                                      setOrganizerKeyValue(
                                        "facebookUrl",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {facebookUrlError && (
                                    <span className="help-block">
                                      El valor ingresado no es una URL válida
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="hr-line-dashed"></div>
                              <div
                                className={`form-group ${
                                  instagramUrlError && "has-error"
                                }`}
                              >
                                <label className="col-md-3 col-sm-2 control-label">
                                  Instagram{" "}
                                </label>
                                <div className="col-md-9 col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={organizer.instagramUrl}
                                    disabled={loadingFetchOrganizer}
                                    onChange={(e) => {
                                      setOrganizerKeyValue(
                                        "instagramUrl",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {instagramUrlError && (
                                    <span className="help-block">
                                      El valor ingresado no es una URL válida
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="hr-line-dashed"></div>
                              <div
                                className={`form-group ${
                                  twitterUrlError && "has-error"
                                }`}
                              >
                                <label className="col-md-3 col-sm-2 control-label">
                                  Twitter{" "}
                                </label>
                                <div className="col-md-9 col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={organizer.twitterUrl}
                                    disabled={loadingFetchOrganizer}
                                    onChange={(e) => {
                                      setOrganizerKeyValue(
                                        "twitterUrl",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {twitterUrlError && (
                                    <span className="help-block">
                                      El valor ingresado no es una URL válida
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="hr-line-dashed"></div>
                              <div
                                className={`form-group ${
                                  whatsappError && "has-error"
                                }`}
                              >
                                <label className="col-md-3 col-sm-2 control-label">
                                  Whatsapp{" "}
                                </label>
                                <div className="col-md-9 col-sm-10">
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={organizer.whatsapp}
                                    disabled={loadingFetchOrganizer}
                                    placeholder="Ej: 5491112345678"
                                    onChange={(e) => {
                                      setOrganizerKeyValue(
                                        "whatsapp",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {whatsappError && (
                                    <span className="help-block">
                                      El número debe estar entre 10 y 15 digitos
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="hr-line-dashed"></div>
                              <div
                                className={`form-group ${
                                  emailError && "has-error"
                                }`}
                              >
                                <label className="col-md-3 col-sm-2 control-label">
                                  Email{" "}
                                </label>
                                <div className="col-md-9 col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={organizer.email}
                                    disabled={loadingFetchOrganizer}
                                    onChange={(e) => {
                                      setOrganizerKeyValue(
                                        "email",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {emailError && (
                                    <span className="help-block">
                                      El email no es válido
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="hr-line-dashed"></div>
                              <div
                                className={`form-group ${
                                  rulesPdfUrlError && "has-error"
                                }`}
                              >
                                <label className="col-md-3 col-sm-2 control-label">
                                  Reglamento{" "}
                                </label>
                                <div className="col-md-9 col-sm-10">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={organizer.rulesPdfUrl}
                                    disabled={loadingFetchOrganizer}
                                    onChange={(e) => {
                                      setOrganizerKeyValue(
                                        "rulesPdfUrl",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {rulesPdfUrlError && (
                                    <span className="help-block">
                                      El valor ingresado no es una URL válida
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="hr-line-dashed"></div>
                              <div
                                className={`form-group ${
                                  rulesPdfUrlError && "has-error"
                                }`}
                              >
                                <label className="col-xs-3 control-label">
                                  Control de Ads{" "}
                                </label>
                                <div className="col-xs-3 switch-box">
                                  <label className="control-label">Ad1 </label>
                                  <ReactSwitch
                                    onChange={() =>
                                      setOrganizerKeyValue(
                                        "ad1Available",
                                        !organizer.ad1Available
                                      )
                                    }
                                    disabled={loadingFetchOrganizer}
                                    checked={!!organizer.ad1Available}
                                  />
                                </div>
                                <div className="col-xs-3 switch-box">
                                  <label className="control-label">Ad2 </label>
                                  <ReactSwitch
                                    onChange={() =>
                                      setOrganizerKeyValue(
                                        "ad2Available",
                                        !organizer.ad2Available
                                      )
                                    }
                                    disabled={loadingFetchOrganizer}
                                    checked={!!organizer.ad2Available}
                                  />
                                </div>
                                <div className="col-xs-3 switch-box">
                                  <label className="control-label">Ad3 </label>
                                  <ReactSwitch
                                    onChange={() =>
                                      setOrganizerKeyValue(
                                        "ad3Available",
                                        !organizer.ad3Available
                                      )
                                    }
                                    disabled={loadingFetchOrganizer}
                                    checked={!!organizer.ad3Available}
                                  />
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="hr-line-dashed"></div>
                              <div
                                className={`form-group ${
                                  rulesPdfUrlError && "has-error"
                                }`}
                              >
                                <label className="col-md-3 col-sm-2 control-label">
                                  Habilitar eventos{" "}
                                </label>
                                <div className="col-md-9 col-sm-10">
                                  <ReactSwitch
                                    onChange={() =>
                                      setOrganizerKeyValue(
                                        "events_enabled",
                                        !organizer.events_enabled
                                      )
                                    }
                                    disabled={loadingFetchOrganizer}
                                    checked={!!organizer.events_enabled}
                                  />
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="hr-line-dashed"></div>
                            </>
                          )}
                          <Button
                            className="btn btn-primary pull-right"
                            title={saveButtonTitle}
                            loading={loadingSaveOrganizer}
                            onClick={saveOrganizerHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizerForm;
