"use-strict";

import only from "../../../utils/functions/only";
import moment from "moment";

export default {
  all: [],
  single: {
    event: "",
    document: "",
    email: "",
    firstName: "",
    lastName: "",
    type: "",
    name: "",
    user_id: "",
    user_invite: "",
  },
};

const modelAttributes = [
  "event",
  "document",
  "email",
  "type",
  "name",
  "user_id",
  "user_invite",
];

export const model = (entity) => only(entity, modelAttributes);
