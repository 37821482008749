import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SedeList from "./SedeList";
import * as sedeThunks from "../../redux/thunks/sedeThunks";
import sedeActions from "../../redux/reducers/sedeReducer/sedeActions";
import fileDownloader from "../../utils/functions/fileDownloader";

const SedeListContainer = ({
  loadAllSedes,
  clearAllSedes,
  deleteSede,
  sedes,
  organizers,
  loading,
  match,
  archiveSede,
}) => {
  const organizerId = match.params.organizerId;
  const organizer = organizers.find(
    (organizer) => organizer.id === organizerId
  );
  const fileName = `${organizer ? `${organizer.name}_` : ""}sedes.csv`;

  useEffect(() => {
    clearAllSedes();
    loadAllSedes(organizerId);
  }, [organizerId]);

  const deleteSedeHandler = (sedeId) => deleteSede(organizerId, sedeId);
  const archiveSedeHandler = (sedeId, archived) =>
    archiveSede(organizerId, sedeId, archived);
  const downloadHandler = async () =>
    await fileDownloader(`/organizers/${organizerId}/sedes/csv`, fileName);

  return (
    <SedeList
      deleteSede={deleteSedeHandler}
      sedes={sedes}
      loadingFetchSedes={loading.loadAllSedes}
      loadingDeleteSede={loading.deleteSede}
      downloadHandler={downloadHandler}
      archiveSede={archiveSedeHandler}
      loadingArchiveSede={loading.archiveSede}
    />
  );
};

const mapState = (state) => ({
  organizers: state.organizer.all,
  sedes: state.sede.all,
  loading: state.loading.sede,
});

const mapDispatch = (dispatch) => ({
  loadAllSedes: (organizerId) => dispatch(sedeThunks.loadAllSedes(organizerId)),
  clearAllSedes: () => dispatch(sedeActions.clearAllSedes()),
  deleteSede: (organizerId, sedeId) =>
    dispatch(sedeThunks.deleteSede(organizerId, sedeId)),
  archiveSede: (organizerId, sedeId, archivedValue) =>
    dispatch(sedeThunks.archiveSede(organizerId, sedeId, archivedValue)),
});

export default withRouter(connect(mapState, mapDispatch)(SedeListContainer));
