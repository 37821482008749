import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import * as authThunks from '../../redux/thunks/authThunks'
import SpecificAlert from '../../utils/components/SpecificAlert/SpecificAlert'
import { actions as alertActions } from '../../redux/reducers/alertReducer'
import LoginForm from './LoginForm'

const Login = ({
    isAuthenticated,
    loading,
    login,
    hideSpecificAlert
}) => {
    if (isAuthenticated) {
        return <Redirect to={{ pathname: '/' }} />
    }

    useEffect(() => {
        document.getElementsByTagName('body')[0].className += ' gray-bg'
        hideSpecificAlert()
    }, [])

    return (
        <div className="middle-box loginscreen animated fadeInDown">
            <div>
                <div className="text-center">
                    <h1 className="logo-name">FS+</h1>
                </div>
                <div className="m-t">
                    <SpecificAlert classes="text-center" />
                    <LoginForm
                        loginLoading={loading.login}
                        login={login}
                    />
                </div>
            </div>
        </div>
    )
}

const mapState = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.loading.auth
})

const mapDispatch = dispatch => ({
    login: (credentials) => dispatch(authThunks.login(credentials)),
    hideSpecificAlert: () => dispatch(alertActions.hideSpecificAlert()),
})

export default withRouter(connect(mapState, mapDispatch)(Login))
