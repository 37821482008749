import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import * as participantsThunks from "../../../redux/thunks/participantsThunks";
import * as lineupThunks from "../../../redux/thunks/lineupThunks";
import playerActions from "../../../redux/reducers/playerReducer/playerActions";
import participantsActions from "../../../redux/reducers/participantsReducer/participantsActions";
import { actions } from "../../../redux/reducers/alertReducer";
import MyModal from "../../../utils/components/Modal";
import Button from "../../../utils/components/Button";
import Table from "../../../utils/components/Table";

const LineupModalContainer = ({
  show,
  handleShow,
  isEditing,
  participant,
  setIsEditing,
  createNewParticipant,
  setParticipantKeyValue,
  eventId,
  loading,
  event,
  eventsRoles,
  tournaments,
  loadAllLineups,
  organizerId,
  lineups,
  loadLineup,
  players,
  clearAllPlayers,
}) => {
  const dispatch = useDispatch();

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTournamentId, setSelectedTournamentId] = useState("");
  const [selectedLineupId, setSelectedLineupId] = useState("");
  const [filteredTournaments, setFilteredTournaments] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState(players.map(() => []));

  useEffect(() => {
    if (show) {
      setSelectedYear("");
      setSelectedCategory("");
      setSelectedTournamentId("");
      setSelectedLineupId("");
      setSelectedRoles(players.map(() => []));
      clearAllPlayers();
    }
  }, [show]);

  useEffect(() => {
    const filtered = tournaments.filter(
      (tournament) =>
        !tournament.archived &&
        (!selectedYear || tournament.year === parseInt(selectedYear))
    );
    setFilteredTournaments(filtered);
    const categories = Array.from(
      new Set(filtered.map((tournament) => tournament.category))
    );
    setFilteredCategories(categories);
  }, [selectedYear, tournaments]);

  useEffect(() => {
    if (selectedTournamentId) {
      loadAllLineups(organizerId, selectedTournamentId);
    }
    if (selectedLineupId) {
      loadLineup(organizerId, selectedTournamentId, selectedLineupId);
    }
  }, [selectedTournamentId, selectedLineupId, organizerId]);

  const showAlert = (msg, type) =>
    dispatch(actions.showFloatingAlert(msg, type, 5000));

  const InputCheckbox = ({ checked, onChangeValue }) => {
    return (
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChangeValue()}
      />
    );
  };

  const handleRoleChange = (playerIndex, roleId, roleName) => {
    const updatedRoles = [...selectedRoles];
    const playerRoles = updatedRoles[playerIndex] || [];

    const isRoleSelected = playerRoles.includes(roleId);
    const responsableRoleId = eventsRoles.find(
      (role) => role.name === "Responsable"
    )?.id;
    const invitadoRoleId = eventsRoles.find(
      (role) => role.name === "Invitado"
    )?.id;

    if (isRoleSelected) {
      updatedRoles[playerIndex] = playerRoles.filter((id) => id !== roleId);
    } else {
      if (roleName === "Responsable") {
        updatedRoles[playerIndex] = playerRoles
          .filter((id) => id !== invitadoRoleId)
          .concat(roleId);
      } else if (roleName === "Invitado") {
        updatedRoles[playerIndex] = playerRoles
          .filter((id) => id !== responsableRoleId)
          .concat(roleId);
      } else {
        updatedRoles[playerIndex] = [...playerRoles, roleId];
      }
    }

    setSelectedRoles(updatedRoles);
  };

  const handleAddLineup = () => {
    const hasSelectedRole = selectedRoles.some((roleIds) => roleIds.length > 0);

    if (hasSelectedRole) {
      const selectedPlayers = players
        .filter((player, index) => selectedRoles[index].length > 0)
        .map((player, index) => ({
          ...player,
          roles: selectedRoles[index],
        }));
      createNewParticipant(event, selectedPlayers, handleShow);
    } else {
      showAlert(
        "Debes seleccionar al menos un participante y un rol antes de agregar.",
        "warning"
      );
    }
  };

  const handleSelectAllInvitados = () => {
    const invitadoRole = eventsRoles.find((role) => role.name === "Invitado");
    if (invitadoRole) {
      const updatedRoles = players.map(() => [invitadoRole.id]);
      setSelectedRoles(updatedRoles);
    }
  };

  const getRowsWithActions = () =>
    players.map((player, index) => {
      const roles = eventsRoles.map((role) => (
        <div
          style={{ display: "flex", alignItems: "flex-start", gap: 5 }}
          key={role.id}
        >
          <InputCheckbox
            checked={(selectedRoles[index] || []).includes(role.id)}
            onChangeValue={() => handleRoleChange(index, role.id, role.name)}
          />
          <p>{role.name}</p>
        </div>
      ));

      return {
        ...player,
        roles: roles,
      };
    });

  return (
    <MyModal
      show={show}
      title="Importar plantel de invitados"
      handleShow={handleShow}
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12">
            <div className={`alert alert-info`}>
              Podés agregar jugadores del equipo.
            </div>
            <div style={{ display: "flex", gap: 5 }} className={`form-group`}>
              <label className="col-md-5 col-sm-4 control-label">
                Seleccione un año *{" "}
              </label>
              <div className="col-md-7 col-sm-8">
                <select
                  name="type"
                  id="type"
                  className="form-control"
                  disabled={false || loading.participants.createNewParticipant}
                  value={selectedYear}
                  onChange={(e) => {
                    setSelectedYear(e.target.value);
                    setSelectedCategory("");
                    setSelectedTournamentId("");
                    setSelectedLineupId("");
                  }}
                >
                  <option value="">Seleccione</option>
                  {Array.from(
                    new Set(
                      tournaments
                        .filter((t) => !t.archived)
                        .map((tournament) => tournament.year)
                    )
                  ).map((year, key) => (
                    <option value={year} key={key + "year"}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div style={{ display: "flex", gap: 5 }} className={`form-group`}>
              <label className="col-md-5 col-sm-4 control-label">
                Seleccione una categoria *{" "}
              </label>
              <div className="col-md-7 col-sm-8">
                <select
                  name="type"
                  id="type"
                  className="form-control"
                  disabled={
                    !selectedYear || loading.participants.createNewParticipant
                  }
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                    setSelectedTournamentId("");
                    setSelectedLineupId("");
                  }}
                >
                  <option value="">Seleccione</option>
                  {filteredCategories.map((category, key) => (
                    <option value={category} key={key + "category"}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div style={{ display: "flex", gap: 5 }} className={`form-group`}>
              <label className="col-md-5 col-sm-4 control-label">
                Seleccione un torneo *{" "}
              </label>
              <div className="col-md-7 col-sm-8">
                <select
                  name="type"
                  id="type"
                  className="form-control"
                  disabled={
                    !selectedYear ||
                    !selectedCategory ||
                    loading.participants.createNewParticipant
                  }
                  value={selectedTournamentId}
                  onChange={(e) => {
                    setSelectedTournamentId(e.target.value);
                    setSelectedLineupId("");
                  }}
                >
                  <option value="">Seleccione</option>
                  {filteredTournaments
                    .filter(
                      (tournament) => tournament.category === selectedCategory
                    )
                    .map((tournament, key) => (
                      <option value={tournament.id} key={key + "tournament"}>
                        {tournament.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div style={{ display: "flex", gap: 5 }} className={`form-group`}>
              <label className="col-md-5 col-sm-4 control-label">
                Plantel a agregar *{" "}
              </label>
              <div className="col-md-7 col-sm-8">
                <select
                  name="type"
                  id="type"
                  className="form-control"
                  disabled={
                    !selectedYear ||
                    !selectedTournamentId ||
                    loading.participants.createNewParticipant
                  }
                  value={selectedLineupId}
                  onChange={(e) => {
                    setSelectedLineupId(e.target.value);
                  }}
                >
                  <option value="">Seleccione</option>
                  {lineups.map((lineup, key) => (
                    <option value={lineup.id} key={key + "lineup"}>
                      {lineup.team.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "25px 0px",
              }}
            >
              {players.length > 0 && (
                <Button
                  title="Seleccionar a todos"
                  className="btn btn-warning"
                  onClick={handleSelectAllInvitados}
                />
              )}
            </div>
            <div>
              <Table
                loading={loading.lineup.loadLineup}
                headers={[
                  {
                    label: "DNI",
                    ref: "document",
                    className: "col-sm-2",
                  },
                  {
                    label: "Nombre",
                    ref: "firstName",
                    className: "col-sm-2",
                  },
                  {
                    label: "Apellido",
                    ref: "lastName",
                    className: "col-xs-2 hidden-xs",
                  },
                  {
                    label: "Rol",
                    ref: "roles",
                    className: "col-sm-2 col-xs-3",
                  },
                ]}
                rows={getRowsWithActions()}
                notFoundMessage="No se encontraron jugadores en este equipo..."
              />
            </div>
          </div>
        </div>
      }
      buttons={
        <Button
          title="Agregar"
          className="btn btn-primary"
          onClick={handleAddLineup}
          disabled={loading.participants.createNewParticipant}
        />
      }
    />
  );
};

const mapState = (state) => ({
  participant: state.participant.single,
  loading: state.loading,
  lineups: state.lineup.all,
  players: state.player.all,
  eventsRoles: state.participant.events_roles,
});

const mapDispatch = (dispatch) => ({
  createNewParticipant: (event, players, handleShow) =>
    dispatch(
      participantsThunks.createParticipantsFromPlayersOrSingleParticipant(
        event,
        players,
        handleShow
      )
    ),
  setParticipantKeyValue: (key, value) =>
    dispatch(participantsActions.setParticipantKeyValue(key, value)),
  clearSingleParticipant: () =>
    dispatch(participantsActions.clearSingleParticipant()),
  loadAllLineups: (organizerId, tournamentId) =>
    dispatch(lineupThunks.loadAllLineups(organizerId, tournamentId)),
  loadLineup: (organizerId, tournamentId, lineupId) =>
    dispatch(lineupThunks.loadLineup(organizerId, tournamentId, lineupId)),
  clearAllPlayers: () => dispatch(playerActions.clearAllPlayers()),
});

export default withRouter(connect(mapState, mapDispatch)(LineupModalContainer));
