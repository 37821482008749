import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import Button from "../../utils/components/Button";
import { history } from "../../redux/store";
import SpecificAlert from "../../utils/components/SpecificAlert/SpecificAlert";
import LineUpListContainer from "./cards/lineup/LineUpListContainer";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import TournamentFormContainer from "./cards/tournament/TournamentFormContainer";
import GroupListContainer from "./cards/group/GroupListContainer";
import PointPositionListContainer from "./cards/point/PointPositionListContainer";
import PointFairPlayListContainer from "./cards/point/PointFairPlayListContainer";
import DateListContainer from "./cards/date/DateListContainer";
import MatchListContainer from "./cards/matches/MatchListContainer";
import SanctionListContainer from "./cards/sanctions/SanctionListContainer";
import CardsListContainer from "./cards/Cards/CardsListContainer";
import TableRowColorListContainer from "./cards/tableRowColor/tableRowColorListContainer";

const TournamentView = ({
  organizerId,
  tournamentId,
  tournamentEditing,
  tournamentType,
  tournamentName,
}) => {
  const [isPlayoff, setIsPlayoff] = useState(false);
  const [activeSection, setActiveSection] = useState("datos");

  const tournamentTypes = {
    FUTBOL: "FUTBOL",
    // PLAYOFF: "PLAYOFF",
    // FRIENDLY: "FRIENDLY",
  };

  useEffect(() => {
    const storedActiveSection = localStorage.getItem("activeSection");
    if (storedActiveSection) {
      setActiveSection(storedActiveSection);
    }
  }, []);

  const changeActiveSection = (section) => {
    setActiveSection(section);
    localStorage.setItem("activeSection", section);
  };

  useEffect(() => {
    setIsPlayoff(tournamentType === tournamentTypes.PLAYOFF);
  }, [tournamentType]);

  return (
    <>
      <Breadcrumbs
        title="Torneos"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Torneos",
            path: keepOrganizer("/tournaments"),
            active: false,
          },
          {
            title: tournamentName,
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <ul className="nav nav-tabs nav-justified">
              <li
                role="presentation"
                style={{
                  backgroundColor: activeSection === "datos" ? "#1ab394" : "",
                }}
                className={activeSection === "datos" && "active"}
                onClick={() => changeActiveSection("datos")}
              >
                <a
                  style={{
                    color: activeSection === "datos" ? "#ffffff" : "#3a3737",
                  }}
                >
                  Datos
                </a>
              </li>
              <li
                onClick={() => changeActiveSection("lineUps")}
                role="presentation"
                className={activeSection === "lineUps" ? "active" : ""}
                style={{
                  backgroundColor: activeSection === "lineUps" ? "#1ab394" : "",
                }}
              >
                <a
                  style={{
                    color: activeSection === "lineUps" ? "#ffffff" : "#3a3737",
                  }}
                >
                  Line Ups
                </a>
              </li>
              <li
                onClick={() => changeActiveSection("settings")}
                role="presentation"
                className={activeSection === "settings" ? "active" : ""}
                style={{
                  backgroundColor:
                    activeSection === "settings" ? "#1ab394" : "",
                }}
              >
                <a
                  style={{
                    color: activeSection === "settings" ? "#ffffff" : "#3a3737",
                  }}
                >
                  Configuración
                </a>
              </li>
              <li
                onClick={() => changeActiveSection("matchs")}
                role="presentation"
                className={activeSection === "matchs" ? "active" : ""}
                style={{
                  backgroundColor: activeSection === "matchs" ? "#1ab394" : "",
                }}
              >
                <a
                  style={{
                    color: activeSection === "matchs" ? "#ffffff" : "#3a3737",
                  }}
                >
                  Partidos
                </a>
              </li>
              <li
                className={activeSection === "court" ? "active" : ""}
                style={{
                  backgroundColor: activeSection === "court" ? "#1ab394 " : "",
                }}
                onClick={() => changeActiveSection("court")}
                role="presentation"
              >
                <a
                  style={{
                    color: activeSection === "court" ? "#ffffff" : "#3a3737",
                  }}
                >
                  Tribunal
                </a>
              </li>
            </ul>
            <div
              className="ibox-title"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn btn-info"
                title={
                  <span>
                    <i className="fa fa-arrow-left"></i> Volver
                  </span>
                }
                onClick={() => history.push(keepOrganizer("/tournaments"))}
              />
            </div>
            <div className="ibox-content">
              <SpecificAlert />
              <div className="row">
                <div className="col-sm-12">
                  <TournamentFormContainer
                    tournamentEditing={tournamentEditing}
                    organizerId={organizerId}
                    tournamentId={tournamentId}
                    isPlayoff={isPlayoff}
                    tournamentTypes={tournamentTypes}
                    show={activeSection === "datos"}
                  />
                </div>
                {tournamentEditing && tournamentId && (
                  <>
                    {!isPlayoff && (
                      <div className="col-sm-12">
                        <PointPositionListContainer
                          organizerId={organizerId}
                          tournamentId={tournamentId}
                          show={activeSection === "settings"}
                        />
                      </div>
                    )}
                    <div className="col-sm-12">
                      <PointFairPlayListContainer
                        organizerId={organizerId}
                        tournamentId={tournamentId}
                        show={activeSection === "settings"}
                      />
                    </div>
                    <div className="col-sm-12">
                      <LineUpListContainer
                        tournamentEditing={tournamentEditing}
                        organizerId={organizerId}
                        tournamentId={tournamentId}
                        showDeleteButton={!isPlayoff}
                        show={activeSection === "lineUps"}
                      />
                    </div>
                    {!isPlayoff && (
                      <div className="col-md-6 col-sm-12">
                        <GroupListContainer
                          tournamentEditing={tournamentEditing}
                          organizerId={organizerId}
                          tournamentId={tournamentId}
                          show={activeSection === "settings"}
                        />
                      </div>
                    )}
                    {!isPlayoff && (
                      <div className="col-md-6 col-sm-12">
                        <TableRowColorListContainer
                          tournamentEditing={tournamentEditing}
                          organizerId={organizerId}
                          tournamentId={tournamentId}
                          show={activeSection === "settings"}
                        />
                      </div>
                    )}
                    <div className="col-sm-12">
                      <DateListContainer
                        tournamentEditing={tournamentEditing}
                        organizerId={organizerId}
                        tournamentId={tournamentId}
                        showCreateButton={!isPlayoff}
                        showDeleteButton={!isPlayoff}
                        show={activeSection === "settings"}
                      />
                    </div>
                    <>
                      <div className="col-sm-12">
                        <CardsListContainer
                          tournamentEditing={tournamentEditing}
                          organizerId={organizerId}
                          tournamentId={tournamentId}
                          typeCard={"RedCard"}
                          show={activeSection === "court"}
                        />
                        <CardsListContainer
                          tournamentEditing={tournamentEditing}
                          organizerId={organizerId}
                          tournamentId={tournamentId}
                          typeCard={"YellowCard"}
                          show={activeSection === "court"}
                        />
                      </div>
                      <div className="col-sm-12">
                        <SanctionListContainer
                          tournamentEditing={tournamentEditing}
                          organizerId={organizerId}
                          tournamentId={tournamentId}
                          show={activeSection === "court"}
                        />
                      </div>
                    </>
                    <div className="col-sm-12">
                      <MatchListContainer
                        tournamentEditing={tournamentEditing}
                        organizerId={organizerId}
                        tournamentId={tournamentId}
                        showCreateButton={!isPlayoff}
                        showDeleteButton={!isPlayoff}
                        isPlayoff={isPlayoff}
                        show={activeSection === "matchs"}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TournamentView;
