import React, { useState } from 'react'
import Breadcrumbs from '../../utils/components/Breadcrumbs'
import Button from '../../utils/components/Button'
import Table from '../../utils/components/Table'
import { history } from '../../redux/store'
import keepOrganizer from '../../utils/functions/keepOrganizer'

const SedeList = ({
    deleteSede,
    sedes,
    loadingFetchSedes,
    loadingDeleteSede,
    downloadHandler,
    archiveSede,
    loadingArchiveSede,
}) => {

    const [searchValue, setSearchValue] = useState('')

    const getFilteredSedes = () => sedes.filter(sede => {
        const foundId = sede.id.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        const foundName = sede.name.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        const foundDescription = sede.description.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        return foundId || foundName || foundDescription
    })

    const addActions = (sedes) => sedes.map(sede => {
        return {
            ...sede,
            actions: (
                <div className="btn-separated-group">
                    <Button
                        className="btn btn-success"
                        title={<i className={`fa ${sede.archived ? 'fa-eye-slash' : 'fa-eye'}`}></i>}
                        withTooltip={true}
                        loading={loadingArchiveSede}
                        tooltipTitle='Esconder sede'
                        onClick={() => archiveSede(sede.id, !sede.archived)}
                    />
                    <Button
                        className="btn btn-warning"
                        title={<i className="fa fa-pencil"></i>}
                        withTooltip={true}
                        tooltipTitle='Editar'
                        onClick={() => history.push(keepOrganizer(`/sedes/${sede.id}/edit`))}
                    />
                    <Button
                        className="btn btn-danger"
                        title={<i className="fa fa-trash"></i>}
                        withTooltip={true}
                        tooltipTitle='Eliminar'
                        onClick={() => deleteSede(sede.id)}
                        loading={loadingDeleteSede}
                        withConfirmation={true}
                        confirmationTitle='Eliminar sede'
                        onConfirmText='Confirmar'
                        onCancelText='Cancelar'
                        onCancelClass='btn-danger pull-left'
                        confirmationBody='¿Estás seguro que quieres eliminar esta sede?'
                    />
                </div>
            )
        }
    })

    return (
        <>
            <Breadcrumbs
                title='Sedes'
                opts={[{
                    title: 'Inicio',
                    path: keepOrganizer('/')
                }, {
                    title: 'Sedes',
                    active: true
                }]}
            />
            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox m-t">
                        <div className="ibox-title">
                            <div className="row">
                                <div className="col-md-4 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Buscar sedes..."
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-8 col-xs-6">
                                    <Button
                                        className="btn btn-primary pull-right m-l-5"
                                        title={<span><i className="fa fa-download"></i> Descargar</span>}
                                        withTooltip={true}
                                        tooltipTitle='Descargar sedes'
                                        onClick={downloadHandler}
                                    />
                                    <Button
                                        className="btn btn-info pull-right"
                                        title={<span><i className="fa fa-plus-circle"></i> Nuevo</span>}
                                        onClick={() => history.push(keepOrganizer('/sedes/create'))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ibox-content">
                            <Table
                                loading={loadingFetchSedes}
                                headers={[{
                                    label: 'Nombre',
                                    ref: 'name',
                                    className: 'col-md-6 col-sm-5'
                                }, {
                                    label: 'Descripción',
                                    ref: 'description',
                                    className: 'col-md-4 col-sm-4'
                                }, {
                                    label: 'Acciones',
                                    ref: 'actions',
                                    className: 'col-md-2 col-sm-3 text-center'
                                }]}
                                rows={addActions(getFilteredSedes())}
                                notFoundMessage='No se encontraron sedes...'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SedeList