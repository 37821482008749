import { createStore, applyMiddleware, compose } from "redux";
import mainReducer from "./reducers/index";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import { persistStore } from "redux-persist";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

let store;

if (process.env.NODE_ENV !== "test") {
  store = createStore(
    mainReducer(history),
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
    compose(
      applyMiddleware(
        thunkMiddleware,
        routerMiddleware(history)
        // createLogger({
        //     predicate: () => process.env.NODE_ENV !== 'production',
        //     collapsed: true
        // })
      )
    )
  );
} else {
  store = createStore(
    mainReducer(history),
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
    compose(applyMiddleware(thunkMiddleware, routerMiddleware(history)))
  );
}

let persistor = persistStore(store, null, () => {
  store.getState(); // if you want to get restoredState
});

export default { store, persistor };
