import React, {useState, useEffect} from 'react'
import Button from '../../../../utils/components/Button'
import MyModal from '../../../../utils/components/Modal'

const DateDisplaceModal = ({
    show,
    handleShow,
    saveDateDisplace,
    date,
    loading
}) => {

    const [displaceError, setDisplaceError] = useState(false)
    const [displace, setDisplace] = useState(0)

    const saveDateDisplaceHandler = () => {
        let hasError = !displace
        setDisplaceError(hasError);

        if(hasError)
            return

        saveDateDisplace(displace)
    }

    useEffect(()=>{
        setDisplace(0)
    },[show])

    return (
        <MyModal
            show={show}
            title={`Desplazar fecha (Jornada: ${date.name} )`}
            handleShow={handleShow}
            backdrop='static'
            body={
                <div className="row">
                    <div className="col-md-12">
                        <div className={`form-group ${displaceError && 'has-error'}`}>
                            <label className="col-md-4 col-sm-4 control-label">Días a desplazar </label>
                            <div className="col-md-8 col-sm-8">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={displace}
                                    onChange={(e) => {
                                        setDisplace(e.target.value)
                                    }} />
                                {displaceError && <span className="help-block">Debe indicar la cantidad de días a desplazar.</span>}
                            </div>
                            <div className="clearfix m-b-1"></div>
                        </div>
                    </div>
                </div>
            }
            buttons={
                <>
                    <Button
                        title= "Guardar"
                        className= "btn btn-primary"
                        onClick= {saveDateDisplaceHandler}
                        loading= {loading}
                    />
                </>
            }
        />
    )
}

export default DateDisplaceModal