import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import Button from "../Button"
import ConfirmationModal from "../ConfirmationModal"

const UploadFileButton = ({ text, tooltipText, icon, handler, className }) => {
    const ref = useRef()
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [file, setFile] = useState(null)

    useEffect(() => {
        ref.current.addEventListener("change", function (e) {
            const files = e.target.files
            if (files.length) {
                setShowConfirmation(true)
                setFile(e.target.files[0])
            }
        })
    }, [])

    const resetFile = () => {
        setFile(null)
        ref.current.value = ""
    }

    return (
        <>
            <Button
                title={
                    <span>
                        {icon} {text}
                    </span>
                }
                className={`btn btn-primary btn-xs ${className}`}
                onClick={() => ref.current.click()}
                withTooltip={!!tooltipText}
                tooltipTitle={tooltipText}
            />
            <input type="file" ref={ref} style={{ display: "none" }} />
            <ConfirmationModal
                show={showConfirmation}
                title="Gestión de archivos"
                body={`Estás seguro de que quieres cargar el archivo <b>${file?.name}</b>?`}
                onConfirmText="Confirmar"
                onCancelText="Cancelar"
                onCancelClass="btn-danger pull-left"
                onCancel={() => {
                    setShowConfirmation(false)
                    resetFile()
                }}
                onConfirm={() => {
                    handler(file)
                    setShowConfirmation(false)
                    resetFile()
                }}
            />
        </>
    )
}

UploadFileButton.propTypes = {
    text: PropTypes.string.isRequired,
    tooltipText: PropTypes.string,
    icon: PropTypes.node,
    handler: PropTypes.func.isRequired,
    className: PropTypes.string,
}

UploadFileButton.defaultProps = {
    tooltipText: "",
    icon: null,
    className: "",
}

export default UploadFileButton
