import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MatchList from "./MatchList";
import * as matchThunks from "../../../../redux/thunks/matchThunks";
import matchActions from "../../../../redux/reducers/matchReducer/matchActions";
import { history } from "../../../../redux/store";
import path from "path";
import stadiumActions from "../../../../redux/reducers/stadiumReducer/stadiumActions";
import * as stadiumThunks from "../../../../redux/thunks/stadiumThunks";
import groupActions from "../../../../redux/reducers/groupReducer/groupActions";
import * as groupThunks from "../../../../redux/thunks/groupThunks";

const MatchListContainer = ({
  tournamentEditing,
  organizerId,
  tournamentId,
  loading,
  loadTournamentMatches,
  matches,
  clearAllMatches,
  setMatchKeyValue,
  clearSingleMatch,
  match,
  lineups,
  dates,
  groups,
  stadiums,
  createNewMatch,
  deleteMatch,
  updateMatch,
  loadMatch,
  location,
  loadAllStadiums,
  clearAllStadiums,
  showCreateButton,
  showDeleteButton,
  isPlayoff,
  uploadMasiveMatches,
  downloadMatchLineupsList,
  show,
  loadGroup,
  lineupsByGroup,
  clearAllLineupsByGroups,
  removeLineupsGroup,
}) => {
  useEffect(() => {
    clearAllMatches();
    clearAllStadiums();
    if (tournamentEditing) {
      loadTournamentMatches(organizerId, tournamentId);
      loadAllStadiums(organizerId);
    }
  }, []);

  const createNewMatchHandler = (handleCloseModal) =>
    createNewMatch(organizerId, tournamentId, handleCloseModal);
  const loadMatchHandler = (matchId) =>
    loadMatch(organizerId, tournamentId, matchId);
  const deleteMatchHandler = (matchId) =>
    deleteMatch(organizerId, tournamentId, matchId);
  const updateMatchHandler = (matchId, isPlayoff, handleCloseModal) =>
    updateMatch(
      organizerId,
      tournamentId,
      matchId,
      isPlayoff,
      handleCloseModal
    );

  const onClickGoToWorksheetHandler = (matchId, fromTournament = false) => {
    let newPath = path.resolve(
      path.resolve(location.pathname, "..", "matches", matchId, "worksheet")
    );

    if (fromTournament) {
      newPath += "?fromTournament=true";
    }

    history.push(newPath);
  };

  const onClickGotoWorksheetOfflineHandler = (matchId) =>
    history.push(
      path.resolve(
        location.pathname,
        "..",
        "matches",
        matchId,
        "worksheetOffline"
      )
    );

  const uploadMasiveMatchesHandler = (file) =>
    uploadMasiveMatches(organizerId, tournamentId, file);
  const downloadMatchLineupsListHandler = (matchId) =>
    downloadMatchLineupsList(organizerId, tournamentId, matchId);

  return (
    show && (
      <MatchList
        matches={matches}
        setMatchKeyValue={setMatchKeyValue}
        clearSingleMatch={clearSingleMatch}
        deleteMatch={deleteMatchHandler}
        createNewMatch={createNewMatchHandler}
        loadMatch={loadMatchHandler}
        updateMatch={updateMatchHandler}
        onClickGoToWorksheet={onClickGoToWorksheetHandler}
        match={match}
        lineups={lineups}
        lineupsByGroup={lineupsByGroup}
        dates={dates}
        clearAllLineupsByGroups={clearAllLineupsByGroups}
        groups={groups}
        stadiums={stadiums}
        loadingFetchMatches={loading.match.loadTournamentMatches}
        loadingNewMatch={loading.match.createNewMatch}
        loadingDeleteMatch={loading.match.deleteMatch}
        loadingLoadMatch={loading.match.loadMatch}
        showCreateButton={showCreateButton}
        showDeleteButton={showDeleteButton}
        isPlayoff={isPlayoff}
        uploadMasiveMatchesHandler={uploadMasiveMatchesHandler}
        onClickGotoWorksheetOfflineHandler={onClickGotoWorksheetOfflineHandler}
        refreshMatches={() => loadTournamentMatches(organizerId, tournamentId)}
        downloadMatchLineupsListHandler={downloadMatchLineupsListHandler}
        organizerId={organizerId}
        tournamentId={tournamentId}
        loadGroup={loadGroup}
        removeLineupsGroup={removeLineupsGroup}
      />
    )
  );
};

const mapState = (state) => ({
  loading: state.loading,
  matches: state.match.all,
  match: state.match.single,
  lineups: state.lineup.all,
  dates: state.date.all,
  groups: state.group.all,
  lineupsByGroup: state.group.lineupsByGroup,
  stadiums: state.stadium.all,
});

const mapDispatch = (dispatch) => ({
  loadTournamentMatches: (organizerId, tournamentId) =>
    dispatch(matchThunks.loadTournamentMatches(organizerId, tournamentId)),
  clearAllMatches: () => dispatch(matchActions.clearAllMatches()),
  clearAllLineupsByGroups: () =>
    dispatch(groupActions.clearAllLineupsByGroups()),
  setMatchKeyValue: (key, value) =>
    dispatch(matchActions.setMatchKeyValue(key, value)),
  createNewMatch: (organizerId, tournamentId, handleCloseModal) =>
    dispatch(
      matchThunks.createNewMatch(organizerId, tournamentId, handleCloseModal)
    ),
  deleteMatch: (organizerId, tournamentId, matchId) =>
    dispatch(matchThunks.deleteMatch(organizerId, tournamentId, matchId)),
  clearSingleMatch: () => dispatch(matchActions.clearSingleMatch()),
  loadMatch: (organizerId, tournamentId, matchId) =>
    dispatch(matchThunks.loadMatch(organizerId, tournamentId, matchId)),
  updateMatch: (
    organizerId,
    tournamentId,
    matchId,
    isPlayoff,
    handleCloseModal
  ) =>
    dispatch(
      matchThunks.updateMatch(
        organizerId,
        tournamentId,
        matchId,
        isPlayoff,
        handleCloseModal
      )
    ),
  loadAllStadiums: (organizerId) =>
    dispatch(stadiumThunks.loadAllStadiums(organizerId)),
  clearAllStadiums: () => dispatch(stadiumActions.clearAllStadiums()),
  uploadMasiveMatches: (organizerId, tournamentId, file) =>
    dispatch(matchThunks.uploadMasiveMatches(organizerId, tournamentId, file)),
  downloadMatchLineupsList: (organizerId, tournamentId, matchId) =>
    dispatch(
      matchThunks.downloadMatchLineupsList(organizerId, tournamentId, matchId)
    ),
  loadGroup: (organizerId, tournamentId, groupId) =>
    dispatch(groupThunks.loadGroup(organizerId, tournamentId, groupId)),
  removeLineupsGroup: (groupDelete) =>
    dispatch(groupActions.removeLineupsGroup(groupDelete)),
});

export default withRouter(connect(mapState, mapDispatch)(MatchListContainer));
