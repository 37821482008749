"use-strict";

import httpClient from "../../../http/httpClient";

const httpTournament = (organizerId, tournamentId) => {
  return httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}`);
};

const httpOrganizer = (organizerId) => {
  return httpClient(`/organizers/${organizerId}`);
};

export default {
  loadAll: (organizerId, tournamentId) =>
    httpTournament(organizerId, tournamentId).get("/matches"),
  create: (organizerId, tournamentId, match) =>
    httpTournament(organizerId, tournamentId).post("/matches", match),
  createMatchGroups: (groups) => httpClient("").post("/matchs/groups", groups),
  delete: (organizerId, tournamentId, matchId) =>
    httpTournament(organizerId, tournamentId).delete(`/matches/${matchId}`),
  loadOne: (organizerId, tournamentId, matchId) =>
    httpTournament(organizerId, tournamentId).get(`/matches/${matchId}`),
  update: (organizerId, tournamentId, matchId, match) =>
    httpTournament(organizerId, tournamentId).patch(
      `/matches/${matchId}`,
      match
    ),
  uploadMasiveMatches: (organizerId, tournamentId, file) => {
    const form = new FormData();
    form.append("attachment", file);
    return httpTournament(organizerId, tournamentId).post(
      `/matches/csvs`,
      form
    );
  },
  loadOrganizerMatches: (organizerId, page, pageSize, filters) =>
    httpOrganizer(organizerId).get(`/matches`, {
      params: { page, page_size: pageSize, ...filters },
    }),
  downloadMatchLineupsList: (organizerId, tournamentId, matchId, timeZone) =>
    httpTournament(organizerId, tournamentId).get(
      `/matches/${matchId}/pdf?ZonaHoraria=${timeZone}`
    ),
};
