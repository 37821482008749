'use-strict'

import only from "../../../utils/functions/only"

export default {
    selected: '',
    all: [],
    single: {
        id: '',
        name: '',
        description: '',
        location:'',
        linkSede:'',
        linkBar:'',
        archived:false
    }
}

export const model = (entity) => only(entity, ['id','name','description', 'image','location','linkSede','linkBar','archived'])