export default [{
    ref: 'Goal',
    name: 'Gol',
    player: true,
    substitute: false,
    byTeam: true,
    types: [{
        ref: 'PenaltyGoal',
        name: 'De Penal',
        color: 'default'
    }, {
        ref: 'FreeKickGoal',
        name: 'De Tiro Libre'
    }, {
        ref: 'HeadGoal',
        name: 'De Cabeza'
    }, {
        ref: 'PlayingGoal',
        name: 'De Jugada'
    }, {
        ref: 'DefPenaltyGoal',
        name: 'En Penales',
        color: 'default'
    }]
}, {
    ref: 'Card',
    name: 'Tarjeta',
    player: true,
    substitute: false,
    byTeam: true,
    types: [{
        ref: 'YellowCard',
        name: 'Amarilla',
        color: '#dbc718'
    }, {
        ref: 'RedCard',
        name: 'Roja',
        color: '#ba4646'
    }, {
        ref: 'BlueCard',
        name: 'Azul',
        color: '#0f659e'
    }]
}, {
    ref: 'SubstitutePlayer',
    player: true,
    substitute: true,
    byTeam: true,
    name: 'Cambio',
    types: []
}, {
    ref: 'StarPlayer',
    player: true,
    substitute: false,
    byTeam: true,
    name: 'Mejor Jugador',
    types: []
}, {
    ref: 'Time',
    player: false,
    substitute: false,
    byTeam: false,
    name: 'Tiempo',
    types: [{
        ref: 'FirstTime',
        name: '1T',
        fullname: 'Primer tiempo',
    }, {
        ref: 'SecondTime',
        name: '2T',
        fullname: 'Segundo tiempo',
    }, {
        ref: 'FirstExtraTime',
        name: 'A1',
        fullname: 'Primer tiempo extra',
    }, {
        ref: 'SecondExtraTime',
        name: 'A2',
        fullname: 'Segundo tiempo extra',
    }, {
        ref: 'DefPenaltyTime',
        name: 'Pen',
        fullname: 'Definición por penales',
    }, {
        ref: 'SuspendedTime',
        name: 'Susp',
        fullname: 'Suspendido',
    }, {
        ref: 'FinalizedTime',
        name: 'Finalizado',
        fullname: 'Finalizado',
    }]
}]