import React, { useState, useEffect } from "react";
import Button from "../../../../utils/components/Button";
import MyModal from "../../../../utils/components/Modal";
import DatetimePicker from "../../../../utils/components/DatetimePicker";
import Switch from "react-switch";
import Select from "react-select";

const MatchModal = ({
  show,
  handleShow,
  saveMatch,
  match,
  lineupsByGroup,
  lineups,
  dates,
  groups,
  stadiums,
  setMatchKeyValue,
  modalTitle,
  buttomSaveTitle,
  loading,
  isEditing,
  isPlayoff,
  clearAllLineupsByGroups,
}) => {
  const [localTeamError, setLocalTeamError] = useState(false);
  const [visitingTeamError, setVisitingTeamError] = useState(false);
  const [stadiumError, setStadiumError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [datetimeError, setDatetimeError] = useState(false);
  const [groupError, setGroupError] = useState(false);

  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    setSelectedGroups(
      match?.group_id?.map((lineup) => ({
        value: lineup.value || lineup.id,
        label: lineup.label || lineup.name,
      }))
    );
  }, [match]);

  useEffect(() => {
    setLocalTeamError(false);
    setVisitingTeamError(false);
    setDateError(false);
    setDatetimeError(false);
    setGroupError(false);
  }, [show]);

  const saveMatchHandler = () => {
    if (!isPlayoff && match.date === "") {
      setDateError(true);
      return;
    } else {
      setDateError(false);
    }

    if (match.datetime === "" || !match.datetime) {
      setDatetimeError(true);
      return;
    } else {
      setDatetimeError(false);
    }

    if (!isPlayoff && match.group_id.length <= 0) {
      setGroupError(true);
      return;
    } else {
      setGroupError(false);
    }

    if (!isPlayoff && !isEditing && match.localTeam === "") {
      setLocalTeamError(true);
      return;
    } else {
      setLocalTeamError(false);
    }

    if (!isPlayoff && !isEditing && match.visitingTeam === "") {
      setVisitingTeamError(true);
      return;
    } else {
      setVisitingTeamError(false);
    }

    if (match.stadium === "" || !match.stadium) {
      setStadiumError(true);
      return;
    } else {
      setStadiumError(false);
    }

    saveMatch();
  };

  const getLocalTeamsValid = () => {
    if (isEditing) {
      return lineups.filter((lineup) => {
        return lineup.id !== match.visitingTeam;
      });
    } else {
      return lineupsByGroup.filter((lineup) => {
        return lineup.id !== match.visitingTeam;
      });
    }
  };

  const getVisitingTeamsValid = () => {
    if (isEditing) {
      return lineups.filter((lineup) => {
        return lineup.id !== match.localTeam;
      });
    } else {
      return lineupsByGroup.filter((lineup) => {
        return lineup.id !== match.localTeam;
      });
    }
  };

  return (
    <MyModal
      show={show}
      title={modalTitle()}
      handleShow={handleShow}
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12">
            {!isPlayoff && (
              <>
                <div className={`form-group ${dateError && "has-error"}`}>
                  <label className="col-md-3 col-sm-2 control-label">
                    Jornada *{" "}
                  </label>
                  <div className="col-md-9 col-sm-10">
                    <select
                      name="date"
                      id="date"
                      className="form-control"
                      disabled={loading}
                      value={match.date}
                      onChange={(e) => {
                        const selectedDateId = e.target.value;
                        const selectedDate = dates.find(
                          (date) => date.id === selectedDateId
                        );
                        if (selectedDate) {
                          setMatchKeyValue("date", selectedDateId);
                          setMatchKeyValue("datetime", selectedDate.datetime);
                        }
                      }}
                    >
                      <option value="">Seleccione</option>
                      {dates.map((date, key) => (
                        <option value={date.id} key={key + "date"}>
                          {date.name}
                        </option>
                      ))}
                    </select>
                    {dateError && (
                      <span className="help-block">
                        La jornada es requerida.
                      </span>
                    )}
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="hr-line-dashed"></div>
              </>
            )}
            <div className={`form-group ${datetimeError && "has-error"}`}>
              <label className="col-md-3 col-sm-2 control-label">
                Fecha *{" "}
              </label>
              <div className="col-md-9 col-sm-10">
                <DatetimePicker
                  onChange={(value) => setMatchKeyValue("datetime", value)}
                  showTime={true}
                  value={match.datetime}
                  disable={loading}
                />
                {datetimeError && (
                  <span className="help-block">
                    La fecha y la hora es requerida.
                  </span>
                )}
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            {!isPlayoff && (
              <>
                <div className={`form-group ${groupError && "has-error"}`}>
                  <label className="col-md-3 col-sm-2 control-label">
                    Grupo *
                  </label>
                  <div className="col-md-9 col-sm-10">
                    <Select
                      name="group"
                      id="group"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #e5e6e7",
                          borderRadius: "1px",
                          transition:
                            "border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s",
                        }),
                      }}
                      closeMenuOnSelect={false}
                      value={selectedGroups}
                      onChange={(selectedOptions) => {
                        setSelectedGroups(selectedOptions);
                        setMatchKeyValue("group_id", selectedOptions);
                        if (!selectedOptions || selectedOptions.length <= 0) {
                          clearAllLineupsByGroups();
                        }
                      }}
                      isSearchable
                      isMulti
                      placeholder={"Seleccione"}
                      options={groups?.map((group) => ({
                        label: group.name,
                        value: group.id,
                      }))}
                    />

                    {groupError && (
                      <span className="help-block">El grupo es requerido.</span>
                    )}
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="hr-line-dashed"></div>
                <div className={`form-group ${localTeamError && "has-error"}`}>
                  <label className="col-md-3 col-sm-2 control-label">
                    Equipo Local *{" "}
                  </label>
                  <div className="col-md-9 col-sm-10">
                    <select
                      name="localTeam"
                      id="localTeam"
                      className="form-control"
                      disabled={isEditing}
                      value={match.localTeam}
                      onChange={(e) => {
                        setMatchKeyValue("localTeam", e.target.value);
                      }}
                    >
                      {isEditing ? "" : <option value="">Seleccione</option>}
                      {getLocalTeamsValid().map((lineup, key) => (
                        <option value={lineup.id} key={key + "team"}>
                          {isEditing ? lineup.team.name : lineup.name}
                        </option>
                      ))}
                    </select>
                    {localTeamError && (
                      <span className="help-block">
                        El equipo local es requerido.
                      </span>
                    )}
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="hr-line-dashed"></div>
                <div
                  className={`form-group ${visitingTeamError && "has-error"}`}
                >
                  <label className="col-md-3 col-sm-2 control-label">
                    Equipo Visitante *{" "}
                  </label>
                  <div className="col-md-9 col-sm-10">
                    <select
                      name="visitingTeam"
                      id="visitingTeam"
                      className="form-control"
                      disabled={isEditing}
                      value={match.visitingTeam}
                      onChange={(e) => {
                        setMatchKeyValue("visitingTeam", e.target.value);
                      }}
                    >
                      {isEditing ? "" : <option value="">Seleccione</option>}
                      {getVisitingTeamsValid().map((lineup, key) => (
                        <option value={lineup.id} key={key + "team"}>
                          {isEditing ? lineup.team.name : lineup.name}
                        </option>
                      ))}
                    </select>
                    {visitingTeamError && (
                      <span className="help-block">
                        El equipo visitante es requerido.
                      </span>
                    )}
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="hr-line-dashed"></div>
              </>
            )}
            <div className={`form-group ${stadiumError && "has-error"}`}>
              <label className="col-md-3 col-sm-2 control-label">Cancha </label>
              <div className="col-md-9 col-sm-10">
                <select
                  name="stadium"
                  id="stadium"
                  className="form-control"
                  disabled={loading}
                  value={match.stadium}
                  onChange={(e) => {
                    setMatchKeyValue("stadium", e.target.value);
                  }}
                >
                  <option value="">Seleccione</option>
                  {stadiums.map((stadium, key) => (
                    <option value={stadium.id} key={key + "stadium"}>
                      {stadium.name} - {stadium.sede_name}
                    </option>
                  ))}
                </select>
                {stadiumError && (
                  <span className="help-block">La cancha es requerida.</span>
                )}
              </div>
            </div>
            {isEditing && (
              <div>
                <div className="clearfix"></div>
                <div className="hr-line-dashed"></div>
                <div className="form-group">
                  <label className="col-md-3 col-sm-2 control-label">
                    Comentarios{" "}
                  </label>
                  <div className="col-md-9 col-sm-10">
                    <textarea
                      className="form-control"
                      value={match.comments}
                      disabled={loading}
                      onChange={(e) => {
                        setMatchKeyValue("comments", e.target.value);
                      }}
                    >
                      {match.comments}
                    </textarea>
                  </div>
                </div>
              </div>
            )}
            {isEditing && (
              <div>
                <div className="clearfix"></div>
                <div className="hr-line-dashed"></div>
                <div className="form-group">
                  <label className="col-md-3 col-sm-2 control-label">
                    Contabilizar puntos
                  </label>
                  <div className="col-md-8 col-sm-9">
                    <Switch
                      onChange={() =>
                        setMatchKeyValue("pointsCount", !match.pointsCount)
                      }
                      checked={match.pointsCount}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      }
      buttons={
        <Button
          title={buttomSaveTitle()}
          className="btn btn-primary"
          onClick={saveMatchHandler}
        />
      }
    />
  );
};

export default MatchModal;
