import React, { useEffect, useState, createRef } from "react";
import { useDispatch } from "react-redux";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import Button from "../../utils/components/Button";
import { history } from "../../redux/store";
import SpecificAlert from "../../utils/components/SpecificAlert/SpecificAlert";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import UserImage from "../../assets/images/user.png";
import BrandImageUploader from "../../utils/components/ImageUploader/BrandImageUploader";
import DatetimePicker from "../../utils/components/DatetimePicker";
import { actions } from "../../redux/reducers/alertReducer";
import moment from "moment";
import * as env from "../../config/env";

const PlayerForm = ({
  setPlayerKeyValue,
  savePlayer,
  hideSpecificAlert,
  player,
  loadingFetchPlayer,
  loadingSavePlayer,
  saveButtonTitle,
  panelTitle,
  deletePlayerImage,
  deletePlayerMedicalRecord,
  loadingDeleteMedicalRecord,
}) => {
  const playerUploaderRef = createRef();
  const [file, setFile] = useState(null);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const dispatch = useDispatch();

  const showAlert = (msg) =>
    dispatch(actions.showFloatingAlert(msg, "danger", 5000));

  useEffect(() => {
    hideSpecificAlert();
  }, []);

  const savePlayerHandler = () => {
    let hasError = false;
    if (player.firstName === "" || player.firstName.length > 30) {
      hasError = true;
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (player.lastName === "" || player.lastName.length > 30) {
      hasError = true;
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (hasError) return;

    const playerImage = playerUploaderRef.current.getFormData();
    const playerMedicalRecord = getMedicalRecordFormData();

    savePlayer(playerImage, playerMedicalRecord);
  };

  const onChangeInput = (e) => {
    const [file] = e.target.files;
    const { id } = e.target;
    const isValid = file.type && file.type.includes("pdf");

    if (!isValid) {
      document.getElementById(id).value = "";
      showAlert(`Unicamente se admiten archivos en formato pdf.`);
      return;
    }

    setFile(file);
  };

  const getMedicalRecordFormData = () => {
    if (!file) return null;
    const formData = new FormData();
    formData.append("medicalRecord", file);
    return formData;
  };

  const hasPlayerImage = player.media && player.media.user_big;

  const hasUserImage =
    player.user && player.user.media && player.user.media.user_big;

  const pruebaDescarga = (url, fileName) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      });
    });
  };

  return (
    <>
      <Breadcrumbs
        title="Jugadores"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Jugadores",
            active: false,
            path: keepOrganizer("/players"),
          },
          {
            title: `${player.firstName} ${player.lastName}`,
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div
              className="ibox-title"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn btn-info"
                title={
                  <span>
                    <i className="fa fa-arrow-left"></i> Volver
                  </span>
                }
                onClick={() => history.push(keepOrganizer("/players"))}
              />
            </div>
            <div className="ibox-content">
              <SpecificAlert />
              <div className="row">
                <div className="col-md-4">
                  <BrandImageUploader
                    id="image"
                    enableUploader={true}
                    ref={playerUploaderRef}
                    title={panelTitle}
                    loading={loadingFetchPlayer}
                    image={
                      hasPlayerImage
                        ? player.media.user_big
                        : hasUserImage
                        ? player.user.media.user_big
                        : UserImage
                    }
                    validExtensions={["jpg", "jpeg", "png"]}
                    minWidth={200}
                    minHeight={200}
                    aspectRatio={1}
                    deleteImage={deletePlayerImage}
                    showDeleteImageButtom={hasPlayerImage || hasUserImage}
                    verifiedImage={hasPlayerImage}
                    confirmationTitle={
                      "¿Estás seguro que quieres eliminar la imagen de este jugador?"
                    }
                    confirmationBody={"Eliminar imagen del jugador"}
                  />
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel panel-default">
                        <div className="panel-heading">Datos</div>
                        <div className="panel-body">
                          <div
                            className={`form-group ${
                              firstNameError && "has-error"
                            }`}
                          >
                            <label className="col-md-3 col-sm-2 control-label">
                              Nombre *{" "}
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <input
                                type="text"
                                className="form-control"
                                value={player.firstName}
                                disabled={loadingFetchPlayer}
                                onChange={(e) => {
                                  setPlayerKeyValue(
                                    "firstName",
                                    e.target.value
                                  );
                                }}
                              />
                              {firstNameError && (
                                <span className="help-block">
                                  El nombre es requerido.
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div
                            className={`form-group ${
                              lastNameError && "has-error"
                            }`}
                          >
                            <label className="col-md-3 col-sm-2 control-label">
                              Apellido *{" "}
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <input
                                type="text"
                                className="form-control"
                                value={player.lastName}
                                disabled={loadingFetchPlayer}
                                onChange={(e) => {
                                  setPlayerKeyValue("lastName", e.target.value);
                                }}
                              />
                              {lastNameError && (
                                <span className="help-block">
                                  El apellido es requerido.
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div className={"form-group"}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Documento *{" "}
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <input
                                type="number"
                                className="form-control"
                                value={player.document}
                                disabled={true}
                                onChange={(e) => {
                                  setPlayerKeyValue("document", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div className={"form-group"}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Número de camiseta{" "}
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <input
                                type="number"
                                className="form-control"
                                value={player.shirtNumber}
                                disabled={loadingFetchPlayer}
                                onChange={(e) => {
                                  setPlayerKeyValue(
                                    "shirtNumber",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div className={"form-group"}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Ficha medica{" "}
                            </label>
                            <div className="col-md-9 col-sm-10">
                              {!!player.medicalRecord ? (
                                <div className="btn-separated-group">
                                  <Button
                                    className="btn btn-link pull-left"
                                    title={`ficha_medica_${player.firstName}_${player.lastName}.pdf`}
                                    withTooltip={true}
                                    tooltipTitle="descargar"
                                    onClick={() => {
                                      pruebaDescarga(
                                        player.medicalRecord,
                                        `ficha_medica_${player.firstName}_${player.lastName}.pdf`
                                      );
                                    }}
                                    disabled={false}
                                  />
                                  <Button
                                    className="btn btn-danger"
                                    title={<i className="fa fa-trash"></i>}
                                    withTooltip={true}
                                    tooltipTitle="Eliminar"
                                    onClick={deletePlayerMedicalRecord}
                                    loading={loadingDeleteMedicalRecord}
                                    withConfirmation={true}
                                    confirmationTitle="Eliminar ficha médica"
                                    onConfirmText="Confirmar"
                                    onCancelText="Cancelar"
                                    onCancelClass="btn-danger pull-left"
                                    confirmationBody="¿Estás seguro que quieres eliminar la ficha médica de este jugador?"
                                  />
                                </div>
                              ) : (
                                <div>
                                  <input
                                    type="file"
                                    id="playerMedicalRecord"
                                    onChange={onChangeInput}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div className={"form-group"}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Vencimiento ficha medica{" "}
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <DatetimePicker
                                onChange={(value) => {
                                  const valueFormat = !isNaN(Date.parse(value))
                                    ? moment(value)
                                        .utc()
                                        .subtract(1, "day")
                                        .format(env.DATE_FORMAT)
                                    : "";
                                  setPlayerKeyValue(
                                    "medicalRecordExp",
                                    valueFormat
                                  );
                                }}
                                showTime={false}
                                value={
                                  player.medicalRecordExp
                                    ? moment(player.medicalRecordExp)
                                        .utc()
                                        .add(3, "hours")
                                        .format(env.DATETIME_FORMAT)
                                    : player.medicalRecordExp
                                }
                                disable={loadingFetchPlayer}
                              />
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <Button
                            className="btn btn-primary pull-right"
                            title={saveButtonTitle}
                            loading={loadingSavePlayer}
                            onClick={savePlayerHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayerForm;
