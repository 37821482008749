import React, { useState } from "react";
import Table from "../../utils/components/Table";
import Button from "../../utils/components/Button";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import { history } from "../../redux/store";

const CheckIn = () => {
  return (
    <div>
      <Breadcrumbs
        title="Check In"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Check In",
            active: true,
          },
        ]}
      />
      <div className="ibox-title">
        <div className="row">
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              placeholder="Buscar registro por DNI ..."
              // onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "1rem",
            }}
            className="col-md-3"
          >
            <Button
              className="btn btn-warning pull-right"
              title={
                <span>
                  <i className="fa fa-plus-circle"></i> Nuevo
                </span>
              }
              withTooltip={true}
              tooltipTitle="Nuevo check in"
              onClick={() => history.push(keepOrganizer("/checkIn/create"))}
            />
          </div>
          <div className="ibox-content">
            <Table
              // loading={loadingMatches}
              headers={[
                {
                  label: "Fecha",
                  ref: "date",
                  className: "col-sm-2 hidden-xs",
                },
                {
                  label: "Sede",
                  ref: "seat",
                  className: "col-sm-2 hidden-xs",
                },
                {
                  label: "Nombre",
                  ref: "name",
                  className: "col-xs-3 hidden-xs",
                },
                {
                  label: "Acciones",
                  ref: "actions",
                  className: "col-sm-2 hidden-xs",
                },
              ]}
              // rows={getRowsWithActions(getFilteredMatches())}
              notFoundMessage="No se encontraron invitados en este evento..."
              // itemsInPage={matches.length}
              // defaultPage={currentPage}
              // defaultPageSize={currentPageSize}
              // onChangePage={(page, pageSize) => {
              //   setCurrentPage(page);
              //   setCurrentPageSize(pageSize);
              //   onRefreshMatches(
              //     page,
              //     pageSize,
              //     removeEmptyValueFromObject(matchFilters)
              //   );
              // }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckIn;
