import handler, { statusCodes } from "../../utils/functions/handler";
import checkInActions from "../reducers/checkInReducer/checkInActions";
import checkInServices from "../reducers/checkInReducer/checkInService";
import { model as participantsModel } from "../reducers/participantsReducer/participantsState";
import { actions as alertActions } from "../reducers/alertReducer";
import { setLoading } from "../reducers/loadingReducer";
import only from "../../utils/functions/only";
import { history } from "../store";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import without from "../../utils/functions/without";
import { getCurrentDateTime } from "../../utils/functions/currentDateTime";

export const loadAllCheckInsForEvent =
  (eventId, page, pageSize) => async (dispatch) => {
    dispatch(setLoading.true("checkIn.loadAllCheckIn"));
    const [error, allcheckIn] = await checkInServices.loadAllCheckInsForEvent(
      eventId,
      page,
      pageSize
    );

    dispatch(setLoading.false("checkIn.loadAllCheckIn"));

    if (error) {
      return dispatch(
        handler({
          ...error,
        })
      );
    }
    dispatch(checkInActions.setAllCheckIn(allcheckIn.results));
  };

export const loadOneCheckIn = (checkInId) => async (dispatch) => {
  dispatch(setLoading.true("checkIn.loadOneCheckIn"));
  const [error, oneCheckIn] = await checkInServices.loadOneCheckIn(checkInId);
  dispatch(setLoading.false("checkIn.loadOneCheckIn"));

  if (error) {
    return dispatch(
      handler({
        ...error,
      })
    );
  }
  dispatch(checkInActions.setSingleCheckIn(oneCheckIn));
};

export const createNewCheckIn =
  (eventId, checkInAll, pageSize = 10) =>
  async (dispatch, getState) => {
    const store = getState();

    const checkIn = without(store.checkIn.single, ["name"]);

    const { document } = checkIn;

    dispatch(setLoading.true("checkIn.createNewCheckIn"));
    const [error, createdCheckIn] = await checkInServices.createCheckIn(
      eventId,
      document
    );
    dispatch(setLoading.false("checkIn.createNewCheckIn"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "No se encuentra el usuario para la validación. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }

    if (checkInAll.length < pageSize) {
      dispatch(checkInActions.pushNewCheckIn(createdCheckIn[0]));
    }

    dispatch(
      alertActions.showFloatingAlert(
        `Se realizo el check in de <b>${createdCheckIn[0].name}</b>.`,
        "info",
        3500
      )
    );
    dispatch(checkInActions.clearSingleCheckIn());
  };

export const deleteCheckIn = (checkAccessId) => async (dispatch) => {
  dispatch(setLoading.true("checkIn.deleteCheckIn"));
  const [error, deleteCheckIn] = await checkInServices.deleteCheckin(
    checkAccessId
  );

  dispatch(setLoading.false("checkIn.deleteCheckIn"));

  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {
          [statusCodes.BAD_REQUEST]: {
            msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
          },
        },
      })
    );
  }
  dispatch(checkInActions.deleteCheckIn(checkAccessId));
  dispatch(
    alertActions.showFloatingAlert(`Se ha eliminado el check in.`, "info", 3500)
  );
};
