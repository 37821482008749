import roles from "./roles";

export default [
  {
    parent_id: null,
    title: "Inicio",
    route: "/organizers/:organizerId/",
    icon: "fa fa-th-large",
    order: 1,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    parent_id: null,
    title: "Empresas",
    route: "/organizers",
    icon: "fa fa-building",
    order: 2,
    roles: [roles.ROLE_OWNER],
  },
  {
    parent_id: null,
    title: "Aplicación",
    route: "/organizers/:organizerId/application",
    icon: "fa fa-mobile",
    order: 3,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    parent_id: null,
    title: "Equipos",
    route: "/organizers/:organizerId/teams",
    icon: "fa fa-group",
    order: 4,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    parent_id: null,
    title: "Jugadores",
    route: "/organizers/:organizerId/players",
    icon: "fa fa-male",
    order: 5,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    parent_id: null,
    title: "Torneos",
    route: "/organizers/:organizerId/tournaments",
    icon: "fa fa-trophy",
    order: 6,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    parent_id: null,
    title: "Partidos",
    route: "/organizers/:organizerId/matches",
    icon: "fa fa-calendar",
    order: 6,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    parent_id: null,
    title: "Ayuda",
    route: "/help",
    icon: "fa fa-question",
    order: 7,
    roles: [roles.ROLE_OWNER],
  },
  {
    parent_id: null,
    title: "Sedes",
    route: "/organizers/:organizerId/sedes",
    icon: "fa fa-group",
    order: 8,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    parent_id: null,
    title: "Usuarios",
    route: "/organizers/:organizerId/users",
    icon: "fa fa-group",
    order: 9,
    roles: [roles.ROLE_OWNER],
  },
  {
    parent_id: null,
    title: "Eventos",
    route: "/organizers/:organizerId/events",
    icon: "fa fa-calendar",
    order: 10,
    roles: [roles.ROLE_OWNER],
  },
  {
    parent_id: null,
    title: "Check In",
    route: "/organizers/:organizerId/checkIn",
    icon: "fa fa-male",
    order: 11,
    roles: [roles.ROLE_OWNER],
  },
];
