import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import TeamList from './TeamList'
import * as teamThunks from '../../redux/thunks/teamThunks'
import teamActions from '../../redux/reducers/teamReducer/teamActions'
import fileDownloader from '../../utils/functions/fileDownloader'

const TeamListContainer = ({
    loadAllTeams,
    clearAllTeams,
    deleteTeam,
    teams,
    organizers,
    loading,
    match
}) => {

    const organizerId = match.params.organizerId
    const organizer = organizers.find(organizer => organizer.id === organizerId)
    const fileName = `${organizer ? `${organizer.name}_` : ''}equipos.csv`

    useEffect(() => {
        clearAllTeams()
        loadAllTeams(organizerId)
    }, [organizerId])

    const deleteTeamHandler = (teamId) => deleteTeam(organizerId, teamId)

    const downloadHandler = async () => await fileDownloader(`/organizers/${organizerId}/teams/csv`, fileName)

    return (
        <TeamList 
            deleteTeam={deleteTeamHandler}
            teams={teams}
            loadingFetchTeams={loading.loadAllTeams}
            loadingDeleteTeam={loading.deleteTeam}
            downloadHandler={downloadHandler}
        />
    )
}

const mapState = state => ({
    organizers: state.organizer.all,
    teams: state.team.all,
    loading: state.loading.team
})

const mapDispatch = dispatch => ({
    loadAllTeams: (organizerId) => dispatch(teamThunks.loadAllTeams(organizerId)),
    clearAllTeams: () => dispatch(teamActions.clearAllTeams()),
    deleteTeam: (organizerId, teamId) => dispatch(teamThunks.deleteTeam(organizerId, teamId))
})

export default withRouter(connect(mapState, mapDispatch)(TeamListContainer))