import React, { useState, useEffect } from "react";
import "./WorksheetOfflineStyles.scss";
import Button from "../../utils/components/Button";
import Table from "../../utils/components/Table";
import moment from "moment-timezone";
import calculateMinsDiff from "../../utils/functions/calculateMinsDiff";

const WorksheetOffline = ({
  worksheetOffline,
  goBack,
  goToCheckIn,
  saveOfflineIncidences,
  loadingSaveOfflineIncidences,
}) => {
  const [offlineIncidencesValue, setOfflineIncidencesValue] = useState([]);
  const [matchStateValue, setMatchStateValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [additionalGoals, setAdditionalGoals] = useState({
    localAdditionalGoals: 0,
    visitingAdditionalGoals: 0,
  });

  useEffect(() => {
    setOfflineIncidencesValue(getOfflineIncidenceInitialValue());
    setMatchStateValue(getMatchStateInitialValue());
    setAdditionalGoals({
      localAdditionalGoals: worksheetOffline.match.localTeam.additionalGoals,
      visitingAdditionalGoals:
        worksheetOffline.match.visitingTeam.additionalGoals,
    });
  }, [worksheetOffline]);

  const getOfflineIncidenceInitialValue = () => {
    if (!worksheetOffline.incidences) return [];

    let offlineIncidences = [];

    worksheetOffline.incidences.map((incidence) => {
      if (incidence.player) {
        const incidenceType = /goal/i.test(incidence.type)
          ? "PlayingGoal"
          : incidence.type;
        const playerId = incidence.player.id;
        const index = offlineIncidences.findIndex(
          (offlineIncidence) =>
            offlineIncidence.incidenceType == incidenceType &&
            offlineIncidence.playerId == playerId
        );
        const lineupId = incidence.lineUp.id;

        if (index === -1)
          offlineIncidences.push({
            incidenceType,
            playerId,
            value: 1,
            lineupId,
          });
        else offlineIncidences[index].value++;
      }
    });

    return offlineIncidences;
  };

  const getMatchStateInitialValue = () => {
    const datetime = moment();
    const timeIncidences = worksheetOffline.incidences?.filter((incidence) =>
      /time/i.test(incidence.type)
    );

    const maxIncidence = timeIncidences?.reduce(
      (acc, incidence) => {
        const diffAcc = calculateMinsDiff(datetime, acc.datetime);
        const diffCurrent = calculateMinsDiff(datetime, incidence.datetime);
        if (diffAcc < diffCurrent) return acc;
        return incidence;
      },
      { type: "" }
    );

    return maxIncidence ? maxIncidence.type : "";
  };

  const onChangeOfflineIncidenceValue = (
    incidenceType,
    playerId,
    value,
    lineupId
  ) => {
    setOfflineIncidencesValue((result) => {
      const index = result.findIndex(
        (offlineIncidence) =>
          offlineIncidence.incidenceType == incidenceType &&
          offlineIncidence.playerId == playerId
      );

      if (index !== -1) {
        result.splice(index, 1);
      }

      return [...result, { incidenceType, playerId, value, lineupId }];
    });
    if (!isEditing) setIsEditing(true);
  };

  const addPlayersAndIncidences = (players, lineupId) =>
    players.map((player) => {
      const values = {
        playingGol:
          offlineIncidencesValue.find(
            (incidence) =>
              incidence.playerId === player.id &&
              /goal/i.test(incidence.incidenceType)
          )?.value || 0,
        yellowCard:
          offlineIncidencesValue.find(
            (incidence) =>
              incidence.playerId === player.id &&
              incidence.incidenceType === "YellowCard"
          )?.value || 0,
        blueCard:
          offlineIncidencesValue.find(
            (incidence) =>
              incidence.playerId === player.id &&
              /bluecard/i.test(incidence.incidenceType)
          )?.value || 0,
        redCard:
          offlineIncidencesValue.find(
            (incidence) =>
              incidence.playerId === player.id &&
              /redcard/i.test(incidence.incidenceType)
          )?.value || 0,
        starPlayer:
          offlineIncidencesValue.find(
            (incidence) =>
              incidence.playerId === player.id &&
              /starplayer/i.test(incidence.incidenceType)
          )?.value || 0,
      };
      return {
        player: `${player.firstName} ${player.lastName}  ${player.shirtNumber}`,
        goal: (
          <InputToogle
            value={values.playingGol}
            onChangeValue={(value) => {
              onChangeOfflineIncidenceValue(
                "PlayingGoal",
                player.id,
                value,
                lineupId
              );
            }}
            id={`${player.id}_PlayingGoal`}
          />
        ),
        yellowCard: (
          <InputToogle
            value={values.yellowCard}
            onChangeValue={(value) => {
              onChangeOfflineIncidenceValue(
                "YellowCard",
                player.id,
                value,
                lineupId
              );
            }}
            id={`${player.id}_YellowCard`}
          />
        ),
        blueCard: (
          <InputToogle
            value={values.blueCard}
            onChangeValue={(value) => {
              onChangeOfflineIncidenceValue(
                "BlueCard",
                player.id,
                value,
                lineupId
              );
            }}
            id={`${player.id}_BlueCard`}
          />
        ),
        redCard: (
          <InputToogle
            value={values.redCard}
            onChangeValue={(value) => {
              onChangeOfflineIncidenceValue(
                "RedCard",
                player.id,
                value,
                lineupId
              );
            }}
            id={`${player.id}_RedCard`}
          />
        ),
        startPlayer: (
          <InputCheckbox
            value={values.starPlayer}
            onChangeValue={(value) => {
              onChangeOfflineIncidenceValue(
                "StarPlayer",
                player.id,
                value,
                lineupId
              );
            }}
          />
        ),
      };
    });

  const InputCheckbox = ({ value, onChangeValue }) => {
    const [checked, setChecked] = useState(value > 0);
    return (
      <input
        type="checkbox"
        defaultChecked={checked}
        onChange={() => {
          const newValue = !checked;
          setChecked(newValue);
          onChangeValue(newValue ? 1 : 0);
        }}
      />
    );
  };

  const InputToogle = ({ onChangeValue, id, value, showZeroValue = false }) => {
    const [isInput, setIsInput] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    const onClickInput = (e) => {
      setIsInput(true);
    };

    const onBlur = (e) => {
      setIsInput(false);
      onChangeValue(currentValue);
    };

    const onChanceHandler = (e) => {
      const value = parseInt(e.target.value);
      setCurrentValue(value);
    };

    return isInput ? (
      <input
        id={id}
        type="number"
        value={currentValue}
        className="tinyInput"
        min="0"
        onChange={onChanceHandler}
        onBlur={onBlur}
        autoFocus
      />
    ) : (
      <div
        style={
          showZeroValue
            ? {
                border: "1px solid",
                padding: "0px 7px",
                borderRadius: "2px",
                height: "auto",
                display: "inline-flex",
                alignItems: "center",
              }
            : {}
        }
        onClick={onClickInput}
      >
        {currentValue === 0 && !showZeroValue ? "-" : currentValue}
      </div>
    );
  };

  const IncidenceGrid = ({ players, lineupId }) => {
    return (
      <Table
        loading={false}
        headers={[
          {
            label: "Jugadores",
            ref: "player",
            className: "col-xs-7",
          },
          {
            iconClassName: "fa fa-futbol-o",
            ref: "goal",
            className: "col-xs-1",
          },
          {
            iconClassName: "fa fa-square yellowCard",
            ref: "yellowCard",
            className: "col-xs-1",
          },
          {
            iconClassName: "fa fa-square blueCard",
            ref: "blueCard",
            className: "col-xs-1",
          },
          {
            iconClassName: "fa fa-square redCard",
            ref: "redCard",
            className: "col-xs-1",
          },
          {
            iconClassName: "fa fa-star bestPlayer",
            ref: "startPlayer",
            className: "col-xs-1",
          },
        ]}
        rows={addPlayersAndIncidences(players, lineupId)}
        notFoundMessage="No se encontraron jugadores..."
      />
    );
  };

  const saveOfflineIncidenceHandler = async () => {
    let incidencesToCreate = [];
    let incidencesToDelete = [];
    const allCurrentIncidences = worksheetOffline.incidences;
    const matchStateCurrent = getMatchStateInitialValue();

    if (matchStateCurrent !== matchStateValue) {
      incidencesToCreate.push({
        datetime: moment().utc(new Date()).format(),
        comment: null,
        player: null,
        substitute: false,
        type: matchStateValue,
      });
    }

    offlineIncidencesValue.map((offlineIncidence) => {
      const offlineIncidenceValue = offlineIncidence.value;
      const currentIncidences = allCurrentIncidences.filter((incidence) => {
        return (
          incidence.player &&
          offlineIncidence.playerId === incidence.player.id &&
          (offlineIncidence.incidenceType === "PlayingGoal"
            ? /goal/i.test(incidence.type)
            : incidence.type === offlineIncidence.incidenceType)
        );
      });
      const currentIncidencesValue = currentIncidences.length || 0;

      if (offlineIncidenceValue - currentIncidencesValue < 0) {
        const qtyIncidencesToDelete =
          currentIncidencesValue - offlineIncidenceValue;
        currentIncidences
          .slice(0, qtyIncidencesToDelete)
          .map((incidence) => incidencesToDelete.push(incidence.id));
      } else if (offlineIncidenceValue - currentIncidencesValue > 0) {
        const qtyIncidencesToCreate =
          offlineIncidenceValue - currentIncidencesValue;

        for (let i = 0; i < qtyIncidencesToCreate; i++) {
          incidencesToCreate.push({
            datetime: moment().utc(new Date()).format(),
            comment: null,
            player: offlineIncidence.playerId,
            substitute: false,
            type: offlineIncidence.incidenceType,
          });
        }
      }
    });

    await saveOfflineIncidences(
      incidencesToCreate,
      incidencesToDelete,
      additionalGoals
    );
    setIsEditing(false);
  };

  const getTotalGoalByLineup = (lineupId) => {
    return offlineIncidencesValue.reduce((sum, incidence) => {
      if (
        incidence.incidenceType !== "DefPenaltyGoal" &&
        /goal/i.test(incidence.incidenceType) &&
        incidence.lineupId === lineupId
      )
        return sum + incidence.value;
      else return sum;
    }, 0);
  };

  const getTimeIncidencesCategories = () => {
    return worksheetOffline.incidenceCategories.find(
      (incidenceCategory) => incidenceCategory.ref === "Time"
    ).types;
  };

  const AdditionalGoals = ({ id, value, onChangeValue }) => {
    return (
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <div className="col-xs-4">
          <label>Goles bonus:</label>
        </div>
        <InputToogle
          value={value}
          onChangeValue={onChangeValue}
          id={id}
          showZeroValue={true}
        />
      </div>
    );
  };

  const formatTotalGoals = (goalsInMatch, additionalGoals) => {
    return isNaN(goalsInMatch + additionalGoals)
      ? 0
      : goalsInMatch + additionalGoals;
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div className="ibox-title">
              <Button
                className="btn btn-info"
                title={
                  <span>
                    <i className="fa fa-arrow-left"></i> Volver
                  </span>
                }
                onClick={() => goBack()}
                withConfirmation={isEditing}
                confirmationTitle="Advertencia"
                onConfirmText="Confirmar"
                onCancelText="Cancelar"
                onCancelClass="btn-danger pull-left"
                confirmationBody="Es posible que tengas incidencias cargadas sin guardar y que podrías perder, ¿Estás seguro que deseas volver?"
              />
              <Button
                className="btn btn-primary pull-right"
                title={"Guardar"}
                loading={loadingSaveOfflineIncidences}
                onClick={() => {
                  saveOfflineIncidenceHandler();
                }}
              />
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className={"form-group"}>
                  <label className="col-md-2 control-label">
                    Estatus del partido:{" "}
                  </label>
                  <div className="col-md-3">
                    <select
                      name="group"
                      id="group"
                      className="form-control"
                      disabled={false}
                      value={matchStateValue}
                      onChange={(e) => {
                        setMatchStateValue(e.target.value);
                      }}
                    >
                      <option value="">Seleccione un estado...</option>
                      {getTimeIncidencesCategories().map((incidence, key) => (
                        <option value={incidence.ref} key={key + "group"}>
                          {incidence.fullname}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="ibox m-t">
                    <div className="ibox-title">
                      <div className="row">
                        <div className="col-md-4 col-sm-6">
                          <h3>
                            Equipo Local:{" "}
                            {worksheetOffline.match.localTeam.name}
                          </h3>
                          <h4>
                            Goles:{" "}
                            {formatTotalGoals(
                              getTotalGoalByLineup(
                                worksheetOffline.match.localTeam.lineupId
                              ),
                              additionalGoals.localAdditionalGoals
                            )}
                          </h4>
                        </div>
                        <div className="col-md-8 col-sm-6">
                          <div className="col-xs-6">
                            <AdditionalGoals
                              id={"localAdditionalGoals"}
                              value={additionalGoals.localAdditionalGoals}
                              onChangeValue={(value) => {
                                setAdditionalGoals({
                                  ...additionalGoals,
                                  localAdditionalGoals: value,
                                });
                                if (!isEditing) setIsEditing(true);
                              }}
                            />
                          </div>
                          <Button
                            className="btn btn-info pull-right"
                            title={
                              <span>
                                <i className="fa fa-plus-circle"></i> Check In
                              </span>
                            }
                            onClick={() => {
                              setIsEditing(false);
                              goToCheckIn(
                                worksheetOffline.match.localTeam.id,
                                worksheetOffline.match.localTeam.lineupId
                              );
                            }}
                            withConfirmation={isEditing}
                            confirmationTitle="Advertencia"
                            onConfirmText="Confirmar"
                            onCancelText="Cancelar"
                            onCancelClass="btn-danger pull-left"
                            confirmationBody="Es posible que tengas incidencias cargadas sin guardar y que podrías perder, ¿Estás seguro que deseas ir al check in?"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ibox-content">
                      <IncidenceGrid
                        players={
                          worksheetOffline.match.localTeam.checkedInPlayers
                        }
                        lineupId={worksheetOffline.match.localTeam.lineupId}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="ibox m-t">
                    <div className="ibox-title">
                      <div className="row">
                        <div className="col-md-4 col-sm-6">
                          <h3>
                            Equipo Visitante:{" "}
                            {worksheetOffline.match.visitingTeam.name}
                          </h3>
                          <h4>
                            Goles:{" "}
                            {formatTotalGoals(
                              getTotalGoalByLineup(
                                worksheetOffline.match.visitingTeam.lineupId
                              ),
                              additionalGoals.visitingAdditionalGoals
                            )}
                          </h4>
                        </div>
                        <div className="col-md-8 col-sm-6">
                          <div className="col-xs-6">
                            <AdditionalGoals
                              id={"visitingAdditionalGoals"}
                              value={additionalGoals.visitingAdditionalGoals}
                              onChangeValue={(value) => {
                                setAdditionalGoals({
                                  ...additionalGoals,
                                  visitingAdditionalGoals: value,
                                });
                                if (!isEditing) setIsEditing(true);
                              }}
                            />
                          </div>
                          <Button
                            className="btn btn-info pull-right"
                            title={
                              <span>
                                <i className="fa fa-plus-circle"></i> Check In
                              </span>
                            }
                            onClick={() => {
                              setIsEditing(false);
                              goToCheckIn(
                                worksheetOffline.match.visitingTeam.id,
                                worksheetOffline.match.visitingTeam.lineupId
                              );
                            }}
                            withConfirmation={isEditing}
                            confirmationTitle="Advertencia"
                            onConfirmText="Confirmar"
                            onCancelText="Cancelar"
                            onCancelClass="btn-danger pull-left"
                            confirmationBody="Es posible que tengas incidencias cargadas sin guardar y que podrías perder, ¿Estás seguro que deseas ir al check in?"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ibox-content">
                      <IncidenceGrid
                        players={
                          worksheetOffline.match.visitingTeam.checkedInPlayers
                        }
                        lineupId={worksheetOffline.match.visitingTeam.lineupId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorksheetOffline;
