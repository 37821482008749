import React, { useEffect, useState } from "react";
import "../worksheet.scss";
import { history } from "../../../redux/store";
import { connect } from "react-redux";
import worksheetActions from "../../../redux/reducers/worksheetReducer/worksheetActions";

const WorksheetWrapper = ({
  children,
  withGoBack,
  isFullScreen,
  setIsFullScreen,
  gridItems,
  gridAction,
  roadmap,
  removeRoadmapLastItem,
}) => {
  const [finalWorksheetHeight, setFinalWorksheetHeight] = useState(0);

  const resizeWorksheetHeight = () => {
    const windowHeight = window.innerHeight;
    const topHeader = document.getElementById("top-header");
    const topHeaderHeight = topHeader.offsetHeight;
    const footer = document.getElementById("footer");
    const footerHeight = footer.offsetHeight;
    document.getElementById("page-wrapper").style.padding = 0;
    document.getElementById("top-header").style.marginLeft = 0;
    document.getElementById("top-header").style.marginRight = 0;

    let worksheetHeight = windowHeight;
    if (!isFullScreen) {
      worksheetHeight = worksheetHeight - topHeaderHeight - footerHeight;
    }

    setFinalWorksheetHeight(worksheetHeight);

    if (withGoBack) {
      const tenPercentOfWorksheetHeight = (worksheetHeight * 10) / 100;
      document.getElementById("expand").style.top = `${
        tenPercentOfWorksheetHeight + 15
      }px`;
      const goBackButton = document.getElementById("go-to-tournament");
      if (goBackButton)
        goBackButton.style.top = `${tenPercentOfWorksheetHeight + 15}px`;
      setFinalWorksheetHeight(worksheetHeight - tenPercentOfWorksheetHeight);
    }

    document.getElementById("worksheet").style.height = `${worksheetHeight}px`;

    const addMoreButton = document.getElementById("add-more-players-button");

    if (addMoreButton) {
      if (isFullScreen) addMoreButton.style.bottom = "20px";
      else addMoreButton.style.bottom = "55px";
    }
  };

  const handleFullScreenMode = (isFullScreen = false) => {
    if (isFullScreen) {
      document.getElementById("worksheet").style.height = `100%`;
    }
    resizeWorksheetHeight();
  };

  useEffect(() => {
    handleFullScreenMode(isFullScreen);
  }, [isFullScreen]);

  useEffect(() => {
    resizeWorksheetHeight();
    window.addEventListener("resize", () => {
      resizeWorksheetHeight();
    });
  }, []);

  const getGrid = () => {
    if (!gridItems) return null;

    let rowCount = gridItems.length;
    gridItems.forEach((_, index) => {
      const windowWidth = window.innerWidth;
      const isOdd = (index + 1) % 2;
      if (windowWidth > 992 && !isOdd) rowCount--;
    });
    const incidenceButtonHeight = finalWorksheetHeight / rowCount;

    return (
      <div id="incidences" className="row incidence-types">
        {gridItems.map((item, index) => {
          const isOdd = (index + 1) % 2;
          let someNext = true;
          if (isOdd) {
            someNext = !!gridItems[index + 1];
          }
          return (
            <div
              style={{
                backgroundColor: item.color,
                height: incidenceButtonHeight,
              }}
              className={`incidence-button col-md-${
                someNext ? "6" : "12"
              } col-sm-12`}
              key={item.ref + index}
              onClick={() => gridAction(item.ref, item.name)}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div id="worksheet" className={`${isFullScreen ? "full-screen" : ""}`}>
      {withGoBack && (
        <div
          className="go-back-button"
          onClick={() => {
            removeRoadmapLastItem();
            return history.goBack();
          }}
        >
          <i className="fa fa-arrow-left"></i>
          <span className="roadmap">
            {roadmap
              .map((elem, idx) => {
                if (roadmap.length !== idx + 1) {
                  return `${elem} / `;
                }
                return elem;
              })
              .join("")}
          </span>
        </div>
      )}
      {getGrid()}
      {children}
      <span
        id="expand"
        className="expand right"
        onClick={() => setIsFullScreen(!isFullScreen)}
      >
        <i className="fa fa-expand"></i>
      </span>
    </div>
  );
};

const mapState = (state) => ({
  isFullScreen: state.worksheet.isFullScreen,
  roadmap: state.worksheet.roadmap,
});

const mapDispatch = (dispatch) => ({
  setIsFullScreen: (isFullScreen) =>
    dispatch(worksheetActions.setIsFullScreen(isFullScreen)),
  removeRoadmapLastItem: () =>
    dispatch(worksheetActions.removeRoadmapLastItem()),
});

export default connect(mapState, mapDispatch)(WorksheetWrapper);
