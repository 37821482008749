const removeEmptyValueFromObject = (obj) => {
    const newObject = Object.keys(obj).reduce(function(ob, key) {
        if (obj[key]) { 
          ob[key] = obj[key];
        }
        return ob;
      }, {});

    return newObject;
}

export default removeEmptyValueFromObject;