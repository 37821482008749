import handler, { statusCodes } from "../../utils/functions/handler";
import participantsActions from "../reducers/participantsReducer/participantsActions";
import participantsService from "../reducers/participantsReducer/participantsService";
import { model as participantsModel } from "../reducers/participantsReducer/participantsState";
import { actions as alertActions } from "../reducers/alertReducer";
import { setLoading } from "../reducers/loadingReducer";
import only from "../../utils/functions/only";
import { history } from "../store";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import without from "../../utils/functions/without";
import { getCurrentDateTime } from "../../utils/functions/currentDateTime";
import def from "../../utils/functions/defaults";

export const loadAllParticipantsEvent =
  (eventId, page, pageSize) => async (dispatch) => {
    dispatch(setLoading.true("participants.loadAllParticipants"));
    const [error, allParticipants] =
      await participantsService.loadAllParticipantsEvent(
        eventId,
        page,
        pageSize
      );
    dispatch(setLoading.false("participants.loadAllParticipants"));
    if (error) {
      return dispatch(
        handler({
          ...error,
        })
      );
    }
    dispatch(participantsActions.setAllParticipants(allParticipants.results));
  };

export const addTournaments =
  (tournamentId, eventId, handleShow = def.fn) =>
  async (dispatch, getState) => {
    dispatch(setLoading.true("participants.addTournament"));
    const [error, addTournament] = await participantsService.addTournament(
      tournamentId,
      eventId
    );
    dispatch(setLoading.false("participants.addTournament"));

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }

    await dispatch(loadAllParticipantsEvent(eventId, 0, 10));

    dispatch(
      alertActions.showFloatingAlert(
        `Se ha añadido el torneo entero!`,
        "info",
        3500
      )
    );

    handleShow(false);
  };

export const loadOneParticipantEvent =
  (eventId) => async (dispatch, getState) => {
    const store = getState();

    const participant = without(store.participant.single, ["name"]);

    if (participant.document === "") return;

    dispatch(setLoading.true("participants.loadAllParticipants"));
    const [error, oneParticipant] =
      await participantsService.loadOneParticipantEvent(
        eventId,
        participant.document
      );
    dispatch(setLoading.false("participants.loadAllParticipants"));

    if (error) {
      return dispatch(
        handler({
          ...error,
        })
      );
    }

    dispatch(
      participantsActions.setSingleParticipant(oneParticipant.results[0])
    );
  };

export const createParticipantsFromPlayersOrSingleParticipant =
  (
    event,
    players,
    handleShow = def.fn,
    pageSize = 10,
    participants = def.arr
  ) =>
  async (dispatch, getState) => {
    const store = getState();

    const createParticipant = async (participantData) => {
      dispatch(setLoading.true("participants.createNewParticipant"));
      const [error, createdParticipant] =
        await participantsService.createParticipant(
          participantData,
          handleShow
        );
      dispatch(setLoading.false("participants.createNewParticipant"));

      if (error) {
        return dispatch(
          handler({
            ...error,
            handler: {
              [statusCodes.BAD_REQUEST]: {
                msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
              },
            },
          })
        );
      }

      if (participants.length < pageSize) {
        dispatch(participantsActions.pushNewParticipant(createdParticipant[0]));
      }

      dispatch(
        alertActions.showFloatingAlert(
          `¡Se ha añadido al participante!`,
          "info",
          3500
        )
      );
      dispatch(participantsActions.clearSingleParticipant());
      handleShow(false);
    };

    const existingDocuments = store.participant.all.map(
      (participant) => participant.document
    );

    const duplicateDocuments = [];

    if (players && players.length > 0) {
      for (const player of players) {
        if (existingDocuments.includes(player.document)) {
          duplicateDocuments.push(player.document);
        }
      }

      if (duplicateDocuments.length > 0) {
        dispatch(
          alertActions.showFloatingAlert(
            `Hay documentos que se repiten: ${duplicateDocuments.join(", ")}`,
            "danger",
            2500
          )
        );
        return;
      }

      for (const player of players) {
        const participantData = {
          document: player.document,
          username: player.user
            ? player.user.username
            : player.firstName + " " + player.lastName,
          user_invite: store.auth.user.id,
          event: event.id,
          user_id: player.user ? player.user.id : null,
          date_invite: getCurrentDateTime(),
          id_event_role: player.roles[0], // Si mando solo string me devuelve si el tipo "Invitado" o "Responsable". Si lo mando como un array me devuelve null.
          event_invite_cant: event.event_invite_cant,
          update_date: getCurrentDateTime(),
          create_date: getCurrentDateTime(),
          status: true,
        };

        await createParticipant(participantData);
      }
    } else {
      const participant = without(store.participant.single, ["name"]);
      const user = store.auth.user;
      const { document, type, firstName, lastName } = participant;

      if (existingDocuments.includes(document)) {
        dispatch(
          alertActions.showFloatingAlert(
            `Documento duplicado: ${document}`,
            "danger",
            2500
          )
        );
        return;
      }

      const objParticipant = {
        document: document,
        username: firstName + " " + lastName,
        event: event.id,
        user_id: user.id ? user.id : null,
        date_invite: getCurrentDateTime(),
        user_invite: store.auth.user.id,
        id_event_role: type,
        event_invite_cant: event.event_invite_cant,
        update_date: getCurrentDateTime(),
        create_date: getCurrentDateTime(),
        status: true,
      };

      await createParticipant(objParticipant, handleShow);
    }
  };

export const deleteParticipant = (participantId) => async (dispatch) => {
  dispatch(setLoading.true("participants.deleteParticipant"));
  const [error, deleteParticipant] =
    await participantsService.deleteParticipant(participantId);

  dispatch(setLoading.false("participants.deleteParticipant"));

  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {
          [statusCodes.BAD_REQUEST]: {
            msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
          },
        },
      })
    );
  }

  dispatch(participantsActions.deleteParticipant(participantId));
  dispatch(
    alertActions.showFloatingAlert(
      `Se ha eliminado al participante`,
      "info",
      3500
    )
  );
};

export const updateParticipant =
  (event, eventId) => async (dispatch, getState) => {
    dispatch(setLoading.true("match.updateParticipant"));
    const store = getState();

    const participant = store.participant.single;
    const user = store.auth.user;

    const { document, type, name, id } = participant;

    const objParticipant = {
      document: document,
      username: name,
      event: event.id,
      user_id: user.id ? user.id : null,
      date_invite: getCurrentDateTime(),
      user_invite: store.auth.user.id,
      id_event_role: [type],
      event_invite_cant: event.event_invite_cant,
      update_date: getCurrentDateTime(),
      create_date: getCurrentDateTime(),
      status: true,
    };
    const [error, updatedParticipant] =
      await participantsService.updateParticipant(eventId, id, objParticipant);

    dispatch(setLoading.false("match.updateParticipant"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }

    dispatch(participantsActions.replaceParticipant(updatedParticipant[0]));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha editado al participante!`,
        "info",
        3500
      )
    );
    dispatch(participantsActions.clearSingleParticipant());
  };

export const loadAllEventsRole = () => async (dispatch) => {
  dispatch(setLoading.true("participants.loadEventsRoles"));
  const [error, eventsRole] = await participantsService.loadAllRoles();
  dispatch(setLoading.false("participants.loadEventsRoles"));

  if (error) {
    return dispatch(
      handler({
        ...error,
      })
    );
  }
  dispatch(participantsActions.setAllEventsRoles(eventsRole));
};
