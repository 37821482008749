import organizerActions from "../reducers/organizerReducer/organizerActions";
import organizerService from "../reducers/organizerReducer/organizerService";
import { setLoading } from "../reducers/loadingReducer";
import { actions as alertActions } from "../reducers/alertReducer";
import handler, { statusCodes } from "../../utils/functions/handler";
import without from "../../utils/functions/without";
import { history } from "../store";
import { patchModel as organizerPatchModel } from "../reducers/organizerReducer/organizerState";
import only from "../../utils/functions/only";

export const loadAllOrganizers = () => async (dispatch, getState) => {
  dispatch(setLoading.true("organizer.loadAllOrganizers"));
  const [error, organizers] = await organizerService.loadAll();
  dispatch(setLoading.false("organizer.loadAllOrganizers"));

  if (error) {
    return dispatch(handler(error));
  }

  dispatch(organizerActions.setAllOrganizers(organizers));
};

export const createNewOrganizer = () => async (dispatch, getState) => {
  dispatch(setLoading.true("organizer.createNewOrganizer"));
  const store = getState();
  const organizer = only(store.organizer.single, [
    "name",
    "tier",
    "backGroundPrimaryColor",
    "backGroundSecondaryColor",
    "backGroundTertiaryColor",
    "inputPrimaryColor",
    "inputSecondaryColor",
    "loginLogoEnabled",
    "textPrimaryColor",
    "textQuaternaryColor",
    "textSecondaryColor",
    "textTertiaryColor",
  ]);
  const [error, createdOrganizer] = await organizerService.create(organizer);
  dispatch(setLoading.false("organizer.createNewOrganizer"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {
          [statusCodes.BAD_REQUEST]: {
            msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
          },
        },
      })
    );
  }
  dispatch(organizerActions.pushNewOrganizer(createdOrganizer));
  dispatch(
    alertActions.showFloatingAlert(
      `Se ha creado la empresa <b>${createdOrganizer.name}</b>.`,
      "info",
      3500
    )
  );
  history.push("/organizers");
};

export const updateOrganizer =
  (organizerId, image) => async (dispatch, getState) => {
    dispatch(setLoading.true("organizer.updateOrganizer"));
    const store = getState();
    const organizer = organizerPatchModel(store.organizer.single);

    console.log("organizer desde thunks", organizer);
    const [error, updatedOrganizer] = await organizerService.update(
      organizerId,
      organizer
    );
    console.log("updatedOrganizer", updatedOrganizer);
    let errorImage = null;
    if (image) {
      [errorImage] = await organizerService.images(organizerId, image);
    }

    dispatch(setLoading.false("organizer.updateOrganizer"));

    if (error || errorImage) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }
    dispatch(organizerActions.replaceOrganizer(updatedOrganizer));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado la empresa <b>${updatedOrganizer.name}</b>.`,
        "info",
        3500
      )
    );
    history.push("/organizers");
  };

export const loadOrganizer = (organizerId) => async (dispatch, getState) => {
  dispatch(setLoading.true("organizer.loadOrganizer"));
  const [error, organizer] = await organizerService.loadOne(organizerId);
  dispatch(setLoading.false("organizer.loadOrganizer"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {},
      })
    );
  }
  dispatch(
    organizerActions.setSingleOrganizer(
      without(organizer, ["id", "stadiums", "teams", "referees", "tournaments"])
    )
  );
};

export const deleteOrganizer = (organizerId) => async (dispatch, getState) => {
  dispatch(setLoading.true("organizer.deleteOrganizer"));
  const store = getState();
  const [error] = await organizerService.delete(organizerId);
  const deletedOrganizer = store.organizer.all.find(
    (organizer) => organizer.id === organizerId
  );
  dispatch(setLoading.false("organizer.deleteOrganizer"));
  if (error) {
    return dispatch(
      handler({
        ...error,
        handler: {},
      })
    );
  }
  dispatch(organizerActions.deleteOrganizer(deletedOrganizer.id));
  dispatch(
    alertActions.showFloatingAlert(
      `Se ha eliminado el equipo <b>${deletedOrganizer.name}</b>.`,
      "info",
      3500
    )
  );
};

export const updateOrganizerAppConfig =
  (organizerId, images) => async (dispatch, getState) => {
    dispatch(setLoading.true("organizer.updateOrganizer"));
    const store = getState();
    const organizer = organizerPatchModel(store.organizer.single);
    const [error, updatedOrganizer] = await organizerService.update(
      organizerId,
      organizer
    );
    const [errorImages] = await organizerService.images(organizerId, images);
    dispatch(setLoading.false("organizer.updateOrganizer"));
    if (error || errorImages) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }
    dispatch(organizerActions.replaceOrganizer(updatedOrganizer));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado la configuración de la aplicación de la empresa <b>${updatedOrganizer.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const clearAllOrganizers = () => (dispatch) =>
  dispatch(organizerActions.clearAllOrganizers());
