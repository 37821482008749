import { connectRouter } from "connected-react-router";
import storage from "redux-persist/lib/storage";
import { persistCombineReducers } from "redux-persist";

import alertReducer from "./alertReducer";
import uiReducer from "./uiReducer";
import authReducer from "./authReducer";
import loadingReducer from "./loadingReducer";
import organizerReducer from "./organizerReducer";
import teamReducer from "./teamReducer";
import tournamentReducer from "./tournamentReducer";
import lineupReducer from "./lineupReducer";
import playerReducer from "./playerReducer";
import groupReducer from "./groupReducer";
import dateReducer from "./dateReducer";
import matchReducer from "./matchReducer";
import sanctionReducer from "./sanctionReducer";
import worksheetReducer from "./worksheetReducer";
import stadium from "./stadiumReducer";
import tableRowColorReducer from "./tableRowColorReducer";
import sedeReducer from "./sedeReducer";
import userReducer from "./userReducer";
import eventReducer from "./eventsReducer";
import participantReducer from "./participantsReducer";
import checkInReducer from "./checkInReducer";

const config = {
  key: "root",
  storage,
  blacklist: ["router", "ui", "loading"],
};

export default (history) =>
  persistCombineReducers(config, {
    router: connectRouter(history),
    auth: authReducer,
    alert: alertReducer,
    ui: uiReducer,
    loading: loadingReducer,
    organizer: organizerReducer,
    team: teamReducer,
    tournament: tournamentReducer,
    lineup: lineupReducer,
    player: playerReducer,
    group: groupReducer,
    date: dateReducer,
    match: matchReducer,
    sanction: sanctionReducer,
    worksheet: worksheetReducer,
    tableRowColor: tableRowColorReducer,
    stadium: stadium,
    sede: sedeReducer,
    user: userReducer,
    event: eventReducer,
    participant: participantReducer,
    checkIn: checkInReducer,
  });
