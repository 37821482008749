import React, { useState, useEffect, memo, useCallback } from "react"
import Button from "../../../../utils/components/Button"
import MyModal from "../../../../utils/components/Modal"

const LineUpBonusModal = memo(
    ({ show, handleShow, lineup, addLineupBonus }) => {
        const [puntosAgregar, setPuntosAgregar] = useState(0)
        const [bonusPointsNotes, setBonusPointsNotes] = useState("")

        useEffect(() => {
            setPuntosAgregar(0)
            setBonusPointsNotes("")
        }, [show])

        useEffect(() => {
            if(lineup){
                setPuntosAgregar(lineup.bonusPoints) 
                setBonusPointsNotes(lineup.bonusPointsNotes)
            }
        }, [lineup])

        const addBonusHandler = useCallback(async () => {
            await addLineupBonus(lineup.id, lineup.team.id, puntosAgregar, bonusPointsNotes)
            handleShow(false)
        }, [puntosAgregar, bonusPointsNotes])

        return (
            <MyModal
                show={show}
                title="Bonus"
                handleShow={handleShow}
                backdrop="static"
                body={
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`alert alert-info`}>
                                Agrega puntos bonus al equipo {" "}
                                <b>
                                    {lineup && lineup.team && lineup.team.name}
                                </b>
                            </div>
                            <div
                                className={'form-group col-md-12'}
                            >
                                <label className="col-md-3 col-sm-2 control-label">
                                    Puntos a agregar
                                </label>
                                <div className="col-md-9 col-sm-10">
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={puntosAgregar}
                                        disabled={false}
                                        onChange={(e) => setPuntosAgregar(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={'form-group col-md-12'}>
                                <label className="col-md-3 col-sm-2 control-label">
                                    Notas 
                                </label>
                                <div className="col-md-9 col-sm-10">
                                <textarea 
                                    className="form-control"
                                    value={bonusPointsNotes}
                                    disabled={false}
                                    onChange={(e) => setBonusPointsNotes(e.target.value)} 
                                >
                                    {bonusPointsNotes}
                                </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                buttons={
                    <Button
                        title="Finalizar"
                        className="btn btn-primary"
                        onClick={addBonusHandler}
                    />
                }
            />
        )
    }
)

export default LineUpBonusModal
