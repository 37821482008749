import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SanctionList from "./SanctionList";
import * as sanctionThunks from "../../../../redux/thunks/sanctionThunks";
import sanctionActions from "../../../../redux/reducers/sanctionReducer/sanctionActions";

const SanctionListContainer = ({
  tournamentEditing,
  organizerId,
  tournamentId,
  loading,
  loadSanctions,
  sanctions,
  clearAllSanctions,
  setSanctionKeyValue,
  deleteSanction,
  show,
}) => {
  useEffect(() => {
    clearAllSanctions();
    if (tournamentEditing) {
      loadSanctions(organizerId, tournamentId);
    }
  }, []);

  const deleteSanctionHandler = (sanctionId) =>
    deleteSanction(organizerId, tournamentId, sanctionId);

  return (
    show && (
      <SanctionList
        sanctions={sanctions}
        setSanctionKeyValue={setSanctionKeyValue}
        deleteSanction={deleteSanctionHandler}
        organizerId={organizerId}
        tournamentId={tournamentId}
        loadingFetchSanctions={loading.sanction.loadSanction}
        loadingDeleteSanction={loading.sanction.deleteSanction}
      />
    )
  );
};

const mapState = (state) => ({
  loading: state.loading,
  sanctions: state.sanction.all,
  sanction: state.sanction.single,
});

const mapDispatch = (dispatch) => ({
  loadSanctions: (organizerId, tournamentId) =>
    dispatch(sanctionThunks.loadSanctions(organizerId, tournamentId)),
  clearAllSanctions: () => dispatch(sanctionActions.clearAllSanctions()),
  setSanctionKeyValue: (key, value) =>
    dispatch(sanctionActions.setSanctionKeyValue(key, value)),
  deleteSanction: (organizerId, tournamentId, sanctionId) =>
    dispatch(
      sanctionThunks.deleteSanction(organizerId, tournamentId, sanctionId)
    ),
});

export default withRouter(
  connect(mapState, mapDispatch)(SanctionListContainer)
);
