import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/reducers/alertReducer";
import CardSettings from "./CardSettings";

const AppCardsSettings = forwardRef(
  (
    {
      cards = [],
      setOrganizerKeyValue,
      validExtensions = [],
      getImage,
      organizer = {},
    },
    ref
  ) => {
    const [state, setState] = useState({});
    const [preview, setPreview] = useState({});
    const dispatch = useDispatch();

    const showAlert = (msg) =>
      dispatch(actions.showFloatingAlert(msg, "danger", 5000));

    const clearInput = (id) => {
      const input = document.getElementById(id);
      input.value = "";
    };

    const onChangeInput = (e) => {
      const [file] = e.target.files;
      const { id } = e.target;
      const { minWidth } = cards.find((input) => input.id === id);
      const isValid = validExtensions.some(
        (ext) => file.type && file.type.includes(ext)
      );
      if (!isValid) {
        clearInput(id);
        showAlert(
          `Sólo se admiten los siguientes formatos: ${validExtensions}`
        );
        return;
      }
      const reader = new FileReader();
      reader.onloadend = function () {
        var image = new Image();
        image.src = reader.result;
        image.onload = function () {
          if (minWidth && image.width < minWidth) {
            clearInput(id);
            showAlert(`El tamaño mínimo admitido es de ${minWidth}px`);
            return;
          }
          setPreview({
            ...preview,
            [id]: reader.result,
          });
          setState({
            ...state,
            [id]: file,
          });
        };
      };
      reader.readAsDataURL(file);
    };

    useImperativeHandle(ref, () => ({
      getFormData() {
        const formData = new FormData();
        Object.keys(state).forEach((fileId) => {
          formData.append(fileId, state[fileId]);
        });
        return formData;
      },
    }));

    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th className="col-md-1">Mostrar card</th>
              <th className="col-md-1">Ancho card</th>
              <th className="col-md-1">Orden card</th>
              <th className="col-md-2">Texto</th>
              <th className="col-md-3">Cargar imagen</th>
              <th className="col-md-4">Preview</th>
            </tr>
          </thead>
          <tbody>
            {cards.map((card, index) => (
              <CardSettings
                key={index}
                index={index}
                setOrganizerKeyValue={setOrganizerKeyValue}
                onChangeInput={onChangeInput}
                getImage={getImage}
                organizer={organizer}
                preview={preview}
                {...card}
              />
            ))}
          </tbody>
        </table>
      </>
    );
  }
);

export default AppCardsSettings;
