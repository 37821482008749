'use-strict'

import constants from './authConstants'
import initialState from './authState'

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_AUTHORIZED_USER: {
            return {
                ...state,
                token: action.payload.token,
                user: {
                    id: action.payload.data.id,
                    username: action.payload.data.username,
                    firstName: action.payload.data.firstName,
                    lastName: action.payload.data.lastName,
                    roles: action.payload.data.roles
                },
                isAuthenticated: true
            }
        }
        case constants.LOGOUT: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default authReducer