import { actions as alertActions } from '../../redux/reducers/alertReducer'

export const statusCodes = {
    CONTINUE: 100,
    SWITCHING_PROTOCOLS: 101,
    PROCESSING: 102,
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NON_AUTHORITATIVE_INFORMATION: 203,
    NO_CONTENT: 204,
    RESET_CONTENT: 205,
    PARTIAL_CONTENT: 206,
    MULTI_STATUS: 207,
    MULTIPLE_CHOICES: 300,
    MOVED_PERMANENTLY: 301,
    MOVED_TEMPORARILY: 302,
    SEE_OTHER: 303,
    NOT_MODIFIED: 304,
    USE_PROXY: 305,
    TEMPORARY_REDIRECT: 307,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    PROXY_AUTHENTICATION_REQUIRED: 407,
    REQUEST_TIME_OUT: 408,
    CONFLICT: 409,
    GONE: 410,
    LENGTH_REQUIRED: 411,
    PRECONDITION_FAILED: 412,
    REQUEST_ENTITY_TOO_LARGE: 413,
    REQUEST_URI_TOO_LARGE: 414,
    UNSUPPORTED_MEDIA_TYPE: 415,
    REQUESTED_RANGE_NOT_SATISFIABLE: 416,
    EXPECTATION_FAILED: 417,
    IM_A_TEAPOT: 418,
    UNPROCESSABLE_ENTITY: 422,
    LOCKED: 423,
    FAILED_DEPENDENCY: 424,
    UNORDERED_COLLECTION: 425,
    UPGRADE_REQUIRED: 426,
    PRECONDITION_REQUIRED: 428,
    TOO_MANY_REQUESTS: 429,
    REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIME_OUT: 504,
    HTTP_VERSION_NOT_SUPPORTED: 505,
    VARIANT_ALSO_NEGOTIATES: 506,
    INSUFFICIENT_STORAGE: 507,
    BANDWIDTH_LIMIT_EXCEEDED: 509,
    NOT_EXTENDED: 510,
    NETWORK_AUTHENTICATION_REQUIRED: 511
}

function ErrorHandler(errors) {
    this.errors = errors

    return (error) => dispatch => {
        const custom = error.handler[error.response.status]
        if (custom) {
            dispatch(baseAlertConf(custom, 'danger'))
            return
        }
        const handler = this.errors[error.response.status]
        if (handler) dispatch(handler(error))
    }
}

const baseAlertConf = (error, defaultAlertStyle) => dispatch => {
    const alertType = error.alertType || 'showFloatingAlert'
    const alertStyle = error.alertStyle || defaultAlertStyle
    const alertDuration = error.alertDuration || 3500
    dispatch(alertActions[alertType](error.msg, alertStyle, alertDuration))
    error.callback && error.callback()
}

export default new ErrorHandler({
    [statusCodes.CONFLICT]: (err) => (dispatch) => {
        const error = err.handler[statusCodes.CONFLICT] ? err.handler[statusCodes.CONFLICT] : { msg: 'Ocurrió un conflicto inesperado.' }
        return dispatch(baseAlertConf(error, 'danger'))
    },
    [statusCodes.NOT_FOUND]: (err) => (dispatch) => {
        const error = err.handler[statusCodes.NOT_FOUND] ? err.handler[statusCodes.NOT_FOUND] : { msg: 'No se encontró el recurso.' }
        return dispatch(baseAlertConf(error, 'danger'))
    },
    [statusCodes.BAD_REQUEST]: (err) => (dispatch) => {
        const error = err.handler[statusCodes.BAD_REQUEST] ? err.handler[statusCodes.BAD_REQUEST] : { msg: 'Ocurrió un error formando los datos.' }
        return dispatch(baseAlertConf(error, 'warning'))
    },
    [statusCodes.INTERNAL_SERVER_ERROR]: (err) => (dispatch) => {
        const error = err.handler[statusCodes.INTERNAL_SERVER_ERROR] ? err.handler[statusCodes.INTERNAL_SERVER_ERROR] : { msg: 'Ocurrió un error inesperado.' }
        return dispatch(baseAlertConf(error, 'danger'))
    },
})