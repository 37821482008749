import React, { useState } from "react";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import Button from "../../utils/components/Button";
import Table from "../../utils/components/Table";
import { history } from "../../redux/store";
import keepOrganizer from "../../utils/functions/keepOrganizer";

const TournamentList = ({
  deleteTournament,
  tournaments,
  archiveTournament,
  loadingArchiveTournament,
  loadingFetchTournaments,
  loadingDeleteTournament,
  downloadHandler,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const getFilteredTournaments = () =>
    tournaments.filter((tournament) => {
      const foundYear =
        tournament.year
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      const foundName =
        tournament.name
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      return foundYear || foundName;
    });

  const addActions = (tournaments) =>
    tournaments.map((tournament) => {
      return {
        ...tournament,
        actions: (
          <div className="btn-separated-group">
            <Button
              className="btn btn-success"
              title={
                <i
                  className={`fa ${
                    tournament.archived ? "fa-eye-slash" : "fa-eye"
                  }`}
                ></i>
              }
              withTooltip={true}
              loading={loadingArchiveTournament}
              tooltipTitle="Esconder torneo"
              onClick={() =>
                archiveTournament(tournament.id, !tournament.archived)
              }
            />
            <Button
              className="btn btn-warning"
              title={<i className="fa fa-pencil"></i>}
              withTooltip={true}
              tooltipTitle="Editar / Ver"
              onClick={() => {
                localStorage.setItem("activeSection", "datos"),
                  history.push(
                    keepOrganizer(`/tournaments/${tournament.id}/edit`)
                  );
              }}
            />
            {/* <Button
                        className="btn btn-danger"
                        title={<i className="fa fa-trash"></i>}
                        withTooltip={true}
                        tooltipTitle='Eliminar'
                        onClick={() => deleteTournament(tournament.id)}
                        loading={loadingDeleteTournament}
                        withConfirmation={true}
                        confirmationTitle='Eliminar torneo'
                        onConfirmText='Confirmar'
                        onCancelText='Cancelar'
                        onCancelClass='btn-danger pull-left'
                        confirmationBody='¿Estás seguro que quieres eliminar este torneo?'
                    /> */}
          </div>
        ),
      };
    });

  return (
    <>
      <Breadcrumbs
        title="Torneos"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Torneos",
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div className="ibox-title">
              <div className="row">
                <div className="col-md-4 col-xs-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar torneos..."
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
                <div className="col-md-8 col-xs-6">
                  <Button
                    className="btn btn-primary pull-right m-l-5"
                    title={
                      <span>
                        <i className="fa fa-download"></i> Planteles
                      </span>
                    }
                    withTooltip={true}
                    tooltipTitle="Descargar planteles"
                    onClick={downloadHandler}
                  />
                  <Button
                    className="btn btn-info pull-right"
                    title={
                      <span>
                        <i className="fa fa-plus-circle"></i> Nuevo
                      </span>
                    }
                    onClick={() => {
                      localStorage.setItem("activeSection", "datos"),
                        history.push(keepOrganizer("/tournaments/create"));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="ibox-content">
              <Table
                loading={loadingFetchTournaments}
                headers={[
                  {
                    label: "Nombre",
                    ref: "name",
                    className: "col-md-4 col-sm-4",
                  },
                  {
                    label: "Categoría",
                    ref: "category",
                    className: "col-md-4 col-sm-3 text-center",
                  },
                  {
                    label: "Año",
                    ref: "year",
                    className: "col-md-2 col-sm-2 text-center",
                  },
                  {
                    label: "Acciones",
                    ref: "actions",
                    className: "col-md-2 col-sm-3 text-center",
                  },
                ]}
                rows={addActions(getFilteredTournaments())}
                notFoundMessage="No se encontraron torneos..."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TournamentList;
