import download from "downloadjs";
import { saveAs } from "file-saver";
import matchActions from "../reducers/matchReducer/matchActions";
import matchService from "../reducers/matchReducer/matchService";
import { setLoading } from "../reducers/loadingReducer";
import { actions as alertActions } from "../reducers/alertReducer";
import handler, { statusCodes } from "../../utils/functions/handler";
import without from "../../utils/functions/without";
import moment from "moment";
import * as env from "../../config/env";
import incidenceCategories from "../reducers/worksheetReducer/hardcoded/incidenceCategories";

export const loadTournamentMatches =
  (organizerId, tournamentId) => async (dispatch) => {
    dispatch(setLoading.true("match.loadTournamentMatches"));
    const [error, matches] = await matchService.loadAll(
      organizerId,
      tournamentId
    );

    //  console.log("matches todos ", matches);

    dispatch(setLoading.false("match.loadTournamentMatches"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(matchActions.setAllMatches(matches));
  };

export const createNewMatch =
  (organizerId, tournamentId, handleCloseModal) =>
  async (dispatch, getState) => {
    dispatch(setLoading.true("match.createNewMatch"));
    const store = getState();
    const match = without(store.match.single, [
      "id",
      "comments",
      "pointsCount",
    ]);
    match.datetime = moment.utc(match.datetime).format(env.DATETIME_FORMAT); //new Date(match.datetime).toISOString()

    const objMatch = {
      ...match,
      group_id: [match.group_id.map((lineup) => lineup.value).join(",")],
    };

    const [error, createdMatch] = await matchService.create(
      organizerId,
      tournamentId,
      objMatch
    );

    dispatch(setLoading.false("match.createNewMatch"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(matchActions.pushNewMatch(createdMatch));
    handleCloseModal();
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha creado el partido: <b>${createdMatch.localTeam.team.name} vs ${createdMatch.visitingTeam.team.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const deleteMatch =
  (organizerId, tournamentId, matchId) => async (dispatch, getState) => {
    dispatch(setLoading.true("match.deleteMatch"));
    const store = getState();
    const matches = store.match.all;
    const torneo = store.tournament.single.name;
    const deletedMatch = matches.find((match) => match.id === matchId);
    const [error] = await matchService.delete(
      organizerId,
      tournamentId,
      matchId
    );
    dispatch(setLoading.false("match.deleteMatch"));

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.CONFLICT]: {
              msg: "Mensaje de error",
              alertStyle: "danger",
            },
          },
        })
      );
    }
    dispatch(matchActions.deleteMatch(matchId));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha eliminado el partido: <b>${deletedMatch.localTeam.team.name} vs ${deletedMatch.visitingTeam.team.name} </b>.`,
        "info",
        3500
      )
    );
  };

export const loadMatch =
  (organizerId, tournamentId, matchId) => async (dispatch, getState) => {
    dispatch(setLoading.true("match.loadMatch"));
    const [error, matchLoaded] = await matchService.loadOne(
      organizerId,
      tournamentId,
      matchId
    );

    dispatch(setLoading.false("match.loadMatch"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }

    const match = {
      date: matchLoaded.date.id,
      datetime: matchLoaded.datetime
        ? matchLoaded.datetime.substring(0, 16)
        : null,
      group_id: matchLoaded.matchsGroups.map((group) => group),
      localTeam: matchLoaded.localTeam ? matchLoaded.localTeam.id : null,
      visitingTeam: matchLoaded.visitingTeam
        ? matchLoaded.visitingTeam.id
        : null,
      stadium: matchLoaded.stadium ? matchLoaded.stadium.id : "",
      comments: matchLoaded.comments ?? "",
      pointsCount: matchLoaded.pointsCount,
      matchsGroups: matchLoaded.matchsGroups,
    };

    dispatch(matchActions.setSingleMatch(match));
  };

export const updateMatch =
  (organizerId, tournamentId, matchId, isPlayoff, handleCloseModal) =>
  async (dispatch, getState) => {
    dispatch(setLoading.true("match.updateMatch"));
    const store = getState();
    const excludeIfitsPlayoff = isPlayoff ? ["date", "group_id"] : [];
    const match = without(store.match.single, [
      "localTeam",
      "visitingTeam",
      "id",
      ...excludeIfitsPlayoff,
    ]);

    //console.log("match en update match thunks", match);

    const objMatchUpdate = {
      ...match,
      group_id: [
        match.group_id.map((lineup) => lineup.value || lineup.id).join(","),
      ],
    };

    //console.log("objMatchUpdate", objMatchUpdate);

    const [error, updatedMatch] = await matchService.update(
      organizerId,
      tournamentId,
      matchId,
      objMatchUpdate
    );

    dispatch(setLoading.false("match.updateMatch"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }

    dispatch(matchActions.replaceMatch(updatedMatch));
    handleCloseModal();
    dispatch(
      alertActions.showFloatingAlert(
        isPlayoff
          ? `Se ha actualizado el partido</b>.`
          : `Se ha actualizado el partido <b>${updatedMatch.localTeam.team.name} vs ${updatedMatch.visitingTeam.team.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const uploadMasiveMatches =
  (organizerId, tournamentId, file) => async (dispatch) => {
    dispatch(setLoading.true("match.uploadMasiveMatches"));
    const [error] = await matchService.uploadMasiveMatches(
      organizerId,
      tournamentId,
      file
    );
    dispatch(setLoading.false("match.uploadMasiveMatches"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: error.response.data.error.exception[0]?.message,
              alertStyle: "danger",
              alertDuration: 8000,
            },
          },
        })
      );
    }

    dispatch(
      alertActions.showFloatingAlert(
        `Se ha procesado correctamente el archivo.`,
        "info",
        3500
      )
    );
  };

export const loadOrganizerMatches =
  (organizerId, page, pageSize, filters) => async (dispatch) => {
    dispatch(setLoading.true("match.loadOrganizerMatches"));
    const [error, matches] = await matchService.loadOrganizerMatches(
      organizerId,
      page,
      pageSize,
      filters
    );
    dispatch(setLoading.false("match.loadOrganizerMatches"));

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error al obtener los partidos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }

    const matchesWithFormat = matches.map((match) => {
      const lastTimeincidence = match.incidencesSummary.lastTimeIncidence;
      const localGoals = match.incidencesSummary.local.goals;
      const visitingGoals = match.incidencesSummary.visiting.goals;
      return {
        id: match.id,
        tournamentId: match.localTeam.tournament.id,
        tournament: match.localTeam.tournament.name,
        localTeam: match.localTeam.team.name,
        visitingTeam: match.visitingTeam.team.name,
        datetime: match.datetime
          ? moment.utc(match.datetime).local().format(env.DATETIME_FORMAT)
          : "-",
        // datetime: match.datetime
        //   ? `${match.datetime.substring(0, 10)} ${match.datetime.substring(
        //       12,
        //       16
        //     )}`
        //   : "-",
        stadium: match.stadium ? match.stadium.name : "-",
        status: !!lastTimeincidence
          ? incidenceCategories
              .find((incidenceCategory) => incidenceCategory.ref === "Time")
              .types.find((type) => type.ref === lastTimeincidence).name
          : "Sin comenzar",
        result: `${localGoals}-${visitingGoals}`,
      };
    });

    dispatch(matchActions.setOrganizerMatches(matchesWithFormat));
  };

export const downloadMatchLineupsList =
  (organizerId, tournamentId, matchId) => async (dispatch) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    dispatch(setLoading.true("match.downloadMatchLineupsList"));
    const [error, pdf] = await matchService.downloadMatchLineupsList(
      organizerId,
      tournamentId,
      matchId,
      timeZone
    );
    dispatch(setLoading.false("match.downloadMatchLineupsList"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }

    download(
      pdf,
      `lineups-match-${matchId}.pdf`,
      "application/pdf;charset=utf-8"
    );

    // var BOM = "\uFEFF";
    // var csvContent = BOM + csvContent;

    // const blob = new Blob([csvContent + pdf], {
    //   type: "application/pdf;charset=utf-8",
    // });
    // saveAs(
    //   blob,
    //   `lineups-match-${matchId}.pdf`,
    //   "application/pdf;charset=utf-8"
    // );

    dispatch(
      alertActions.showFloatingAlert(
        `Se ha descargado correctamente el archivo.`,
        "info",
        3500
      )
    );
  };
