"use-strict";

import constants from "./participantsConstants";
import initialState from "./participantsState";

const participantReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_ALL_PARTICIPANTS: {
      return {
        ...state,
        all: action.payload,
      };
    }
    case constants.SET_ALL_EVENTS_ROLES: {
      return {
        ...state,
        events_roles: action.payload,
      };
    }
    case constants.SET_SINGLE_PARTICIPANT: {
      return {
        ...state,
        single: action.payload,
      };
    }
    case constants.SET_PARTICIPANT_KEY_VALUE: {
      return {
        ...state,
        single: {
          ...state.single,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case constants.CLEAR_ALL_PARTICIPANTS: {
      return {
        ...state,
        all: [],
      };
    }
    case constants.DELETE_PARTICIPANT: {
      const participants = [...state.all];
      const index = participants.findIndex(
        (participant) => participant.document === action.participantId
      );
      participants.splice(index, 1);
      return {
        ...state,
        all: participants,
      };
    }
    case constants.PUSH_NEW_PARTICIPANT: {
      const participants = [...state.all];
      participants.push(action.payload);
      return {
        ...state,
        all: participants,
      };
    }
    case constants.REPLACE_PARTICIPANT: {
      const participants = [...state.all];
      const index = participants.findIndex(
        (participant) => participant.document === action.payload.document
      );
      participants[index] = action.payload;
      return {
        ...state,
        all: participants,
      };
    }
    case constants.CLEAR_SINGLE_PARTICIPANT: {
      return {
        ...state,
        single: initialState.single,
      };
    }
    default: {
      return state;
    }
  }
};

export default participantReducer;
