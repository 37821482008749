"use-strict";

import React from "react";
import { connect } from "react-redux";
import { withRouter, Switch } from "react-router-dom";
import authActions from "./redux/reducers/authReducer/authActions";
import Routes from "./Routes";
import FloatingAlert from "./utils/components/FloatingAlert/FloatingAlert";

const checkRole = (roles) => roles && roles.some((role) => role());

const App = ({ isAuthenticated, logout }) => {
  return (
    <>
      <FloatingAlert />
      <Switch>
        {Routes.map((route, i) => {
          return (
            <route.Router
              key={`nav-${i}`}
              {...route}
              isAuthenticated={isAuthenticated}
              specificRole={checkRole(route.roles)}
              logout={logout}
            />
          );
        })}
      </Switch>
    </>
  );
};

const mapState = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatch = (dispatch) => ({
  logout: () => dispatch(authActions.logout()),
});

export default withRouter(connect(mapState, mapDispatch)(App));
