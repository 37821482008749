// Api Rest Environment
//LOCAL variables
/*
const protocol = 'http'
const host = '127.0.0.1'
const port = '8000'
const prefix = 'api'
*/

const protocol = 'http'
const host = 'prod.fansbury.com'
const port = '80'
const prefix = 'api'


export const API = `${protocol}://${host}:${port}/${prefix}`
export const WORKSHEET_RELOAD_TIME = 10 // minutes
export const DATETIME_CALCULATION_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const PHONE_AREA_CODE_DEFAULT = '+54'