"use-strict";

export default {
  SET_ALL_MATCHES: "@@match/SET_ALL_MATCHES",
  CLEAR_ALL_MATCHES: "@@match/CLEAR_ALL_MATCHES",
  CLEAR_ALL_MATCHES_GROUPS: "@@match/CLEAR_ALL_MATCHES_GROUPS",
  SET_MATCH_KEY_VALUE: "@@match/SET_MATCH_KEY_VALUE",
  CLEAR_SINGLE_MATCH: "@@match/CLEAR_SINGLE_MATCH",
  SET_SINGLE_MATCH: "@@match/SET_SINGLE_MATCH",
  PUSH_NEW_MATCH: "@@match/PUSH_NEW_MATCH",
  DELETE_MATCH: "@@match/DELETE_MATCH",
  REPLACE_MATCH: "@@match/REPLACE_MATCH",
  SET_ORGANIZER_MATCHES: "@@match/SET_ORGANIZER_MATCHES",
  CLEAR_ORGANIZER_MATCHES: "@@match/CLEAR_ORGANIZER_MATCHES",
};
