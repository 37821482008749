'use-strict'

import httpClient from '../../../http/httpClient'

const httpTournament = (organizerId, tournamentId) => {
    return httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}`)
}
const httpTeam = (organizerId, tournamentId, teamId) => {
    return httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}/teams/${teamId}`)
}

export default {
    loadAll: (organizerId, tournamentId) => httpTournament(organizerId, tournamentId).get('/lineups'),
    loadOne: (organizerId, tournamentId, lineupId) => httpTournament(organizerId, tournamentId).get(`/lineups/${lineupId}`),
    create: (organizerId, tournamentId, teamId) => httpTeam(organizerId, tournamentId, teamId).post('/lineups'),
    delete: (organizerId, tournamentId, lineupId) => httpTournament(organizerId, tournamentId).delete(`/lineups/${lineupId}`),
    getOne: (organizerId, tournamentId, lineupId) => httpTournament(organizerId, tournamentId).get(`/lineups/${lineupId}`),
    addLineupCaptain: (organizerId, tournamentId, lineupId, document) => httpTournament(organizerId, tournamentId).post(`/lineups/${lineupId}/captains`, { document }),
    downloadLineupsAndPlayers: (organizerId, tournamentId) => httpTournament(organizerId, tournamentId).get(`/players/csv`),
    uploadMasiveLinupPlayers: (organizerId, tournamentId, file) => {
        const form = new FormData()
        form.append('attachment', file)
        return httpTournament(organizerId, tournamentId).post(`/players/csvs`, form)
    },
    addLineupBonus: (organizerId, tournamentId, lineupId, teamId, bonusPoints, bonusPointsNotes) => httpTournament(organizerId, tournamentId).put(`/teams/${teamId}/lineups/${lineupId}/bonuspoints`, { bonusPoints, bonusPointsNotes }),
}