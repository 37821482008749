'use-strict'

import constants from './tableRowColorConstants'

export default {
    setAllTableRowColors: (payload) => ({
        type: constants.SET_ALL_TABLE_ROW_COLORS,
        payload
    }),
    clearAllTableRowColors: () => ({
        type: constants.CLEAR_ALL_TABLE_ROW_COLORS
    }),
    setTableRowColorKeyValue: (key, value) => ({
        type: constants.SET_TABLE_ROW_COLOR_KEY_VALUE,
        payload: { key, value }
    }),
    clearSingleTableRowColor: () => ({
        type: constants.CLEAR_SINGLE_TABLE_ROW_COLOR
    }),
    setSingleTableColorRow: (payload) => ({
        type: constants.SET_SINGLE_TABLE_COLOR_ROW,
        payload
    }),
    deleteTableRowColor: (tableColorRowId) => ({
        type: constants.DELETE_TABLE_ROW_COLOR,
        tableColorRowId
    }),
    updateTableRowColor: (payload) => ({
        type: constants.UPDATE_TABLE_ROW_COLOR,
        payload
    }),
    addNewTableRowColor: (payload) => ({
        type: constants.ADD_NEW_TABLE_ROW_COLOR,
        payload
    })
}