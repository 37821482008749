"use-strict";

import React from "react";
import Spinner from "./Spinner";
import def from "../functions/defaults";
import TablePagination from "./TablePagination";

const Table = ({
  headers = def.arr,
  rows = def.arr,
  loading = def.false,
  notFoundMessage = def.str,
  defaultPage = def.null,
  defaultPageSize = def.null,
  itemsInPage = def.number,
  onChangePage = def.null,
}) => {
  const valueByRef = (ref = def.str, row = def.obj) => {
    const splitted = ref.split(".");
    let value = row;
    for (const key of splitted) value = value[key];
    return value;
  };

  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            {headers.map((header, key) => (
              <th className={header.className} key={key + "table-header"}>
                {" "}
                {header.iconClassName && (
                  <i className={`${header.iconClassName}`}></i>
                )}{" "}
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!rows.length && !loading && (
            <tr>
              <td className="text-center" colSpan={headers.length}>
                {notFoundMessage}
              </td>
            </tr>
          )}
          {rows.map((row, keyRow) => {
            const columns = headers.map((header, keyColumn) => {
              const value = valueByRef(header.ref, row);
              return (
                <td
                  className={header.className}
                  key={keyRow + keyColumn + "table-column"}
                >
                  {value}
                </td>
              );
            });
            return (
              <tr
                style={{
                  backgroundColor: row.backgroundColor,
                  color: row.color,
                }}
                key={keyRow + "table-row"}
              >
                {columns}
              </tr>
            );
          })}
        </tbody>
        {onChangePage && (
          <tfoot>
            <tr>
              <td colSpan={8} className="footable-visible">
                <TablePagination
                  defaultPage={defaultPage}
                  defaultPageSize={defaultPageSize}
                  itemsInPage={itemsInPage}
                  onChangePage={onChangePage}
                />
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      <Spinner show={loading} />
    </>
  );
};

export default Table;
