'use-strict'

export default {
    fn: () => { },
    str: '',
    number: 0,
    arr: [],
    obj: {},
    false: false,
    true: true,
    null: null
}