"use-strict";

export default {
  SET_ALL_TOURNAMENTS: "@@tournament/SET_ALL_TOURNAMENTS",
  DELETE_TOURNAMENT: "@@tournament/DELETE_TOURNAMENT",
  SET_SINGLE_TOURNAMENT: "@@tournament/SET_SINGLE_TOURNAMENT",
  SET_TOURNAMENT_KEY_VALUE: "@@tournament/SET_TOURNAMENT_KEY_VALUE",
  CLEAR_ALL_TOURNAMENTS: "@@tournament/CLEAR_ALL_TOURNAMENTS",
  CLEAR_SINGLE_TOURNAMENT: "@@tournament/CLEAR_SINGLE_TOURNAMENT",
  SET_TOURNAMENT_REDCARDS: "@@tournament/SET_TOURNAMENT_REDCARDS",
  CLEAR_TOURNAMENT_REDCARDS: "@@tournament/CLEAR_TOURNAMENT_REDCARDS",
  SET_TOURNAMENT_YELLOWCARDS: "@@tournament/SET_TOURNAMENT_YELLOWCARDS",
  CLEAR_TOURNAMENT_YELLOWCARDS: "@@tournament/CLEAR_TOURNAMENT_YELLOWCARDS",
  UPDATE_ARCHIVED_TOURNAMENT: "@@tournament/UPDATE_ARCHIVED_TOURNAMENT",
};
