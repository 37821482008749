import React from "react";
import PropTypes from "prop-types";
import Spinner from "../../../utils/components/Spinner";
import Switch from "react-switch";

const AppFormConfigs = ({
  loading,
  organizer: { loginLogoEnabled },
  setValue,
}) => {
  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        Variables de configuración de la aplicación
      </div>
      <div className="panel-body">
        <div className={`${loading && "hidden"}`}>
          <div className={"form-group"}>
            <label className="col-md-4 col-sm-3 control-label">
              Visibilidad logo
            </label>
            <div className="col-md-8 col-sm-9">
              <Switch
                onChange={() => setValue("loginLogoEnabled", !loginLogoEnabled)}
                checked={loginLogoEnabled}
              />
            </div>
          </div>
        </div>
        <Spinner show={loading} />
      </div>
    </div>
  );
};

AppFormConfigs.propTypes = {
  loading: PropTypes.bool.isRequired,
  organizer: PropTypes.shape({
    loginLogoEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default AppFormConfigs;
