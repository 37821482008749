'use-strict'

import authConstants from '../authReducer/authConstants'
import constants from './stadiumConstants'
import initialState from './stadiumState'

const stadiumReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_ALL_STADIUMS: {
            return {
                ...state,
                all: action.payload
            }
        }
        case constants.CLEAR_ALL_STADIUMS: {
            return {
                ...state,
                all: initialState.all
            }
        }
        case constants.SET_ALL_SEDE_STADIUMS: {
            return {
                ...state,
                allSede: action.payload
            }
        }
        case constants.CLEAR_ALL_SEDE_STADIUMS: {
            return {
                ...state,
                allSede: initialState.allSede
            }
        }
        case constants.SET_STADIUM_KEY_VALUE: {
            return {
                ...state,
                single: {
                    ...state.single,
                    [action.payload.key]: action.payload.value
                }
            }
        }
        case constants.PUSH_NEW_STADIUM:{
            const stadiums = [...state.allSede]
            stadiums.push(action.payload)
            return {
                ...state,
                allSede: stadiums
            }
        }
        case authConstants.LOGOUT: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default stadiumReducer