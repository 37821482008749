"use-strict";

import httpClient from "../../../http/httpClient";

export default {
  loadAll: (
    page,
    pageSize,
    gender,
    deleted,
    hasFansIdConfigurated,
    nationality,
    organizations,
    adminValue,
    searchValue
  ) => {
    const queryParams = {
      page: page,
      pageSize: pageSize,
      gender: gender,
      deleted: deleted,
      hasFansIdConfigurated: hasFansIdConfigurated,
      nationality: nationality,
      organizations: organizations,
      adminValue: adminValue,
      generalFilter: searchValue,
    };

    let url = "/paginated/users";

    const queryString = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined && value !== "")
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    if (queryString) {
      url += `?${queryString}`;
    }

    return httpClient("").get(url);
  },
  create: (user) => httpClient("").post("/users", user),
  loadOne: (organizerId, userId) => httpClient("").get(`/users/${userId}`),
  update: (organizerId, userId, user) =>
    httpClient("").patch(`/users/${userId}`, user),
  delete: (userId) => httpClient("").delete(`/users/${userId}`),
  loadOrganizations: (userId) =>
    httpClient("").get(`/users/${userId}/organizations`),
  images: (userId, data) => httpClient("").post(`/users/${userId}/media`, data),
  loadAllCountries: () => httpClient("").get("/countries"),
  loadAllOrganizers: () => httpClient("").get("/alluserorgs"),
};
