"use-strict";

import httpClient from "../../../http/httpClient";

export default {
  loadAllParticipants: () => httpClient("").get(`/eventusers`),
  loadAllParticipantsEvent: (eventId, page = 0, pageSize = 10) =>
    httpClient("").get(
      `/eventsusers/${eventId}?page=${page}&pageSize=${pageSize}`
    ),
  loadOneParticipantEvent: (eventId, document) =>
    httpClient("").get(
      `eventsusers/${eventId}/events?document=${document}&page=0`
    ),
  createParticipant: (participant) =>
    httpClient("").post(`/eventsusers`, participant),
  addTournament: (tournamentId, eventId) =>
    httpClient("").post(
      `/organizers/${tournamentId}/tournaments/${eventId}/eventscreateds`
    ),
  updateParticipant: (eventId, userId, participant) =>
    httpClient("").patch(
      `/events/${eventId}/events/${userId}/user`,
      participant
    ),
  deleteParticipant: (participant) =>
    httpClient("").delete(`/eventsuser?document=${participant}`),
  loadAllRoles: () => httpClient("").get(`events/role/all`),
};
