'use-strict'

import constants from './sedeConstants'

export default {
    setAllSedes: (payload) => ({
        type: constants.SET_ALL_SEDES,
        payload
    }),
    setSedeKeyValue: (key, value) => ({
        type: constants.SET_SEDE_KEY_VALUE,
        payload: { key, value }
    }),
    setSingleSede: (payload) => ({
        type: constants.SET_SINGLE_SEDE,
        payload
    }),
    pushNewSede: (payload) => ({
        type: constants.PUSH_NEW_SEDE,
        payload
    }),
    replaceSede: (payload) => ({
        type: constants.REPLACE_SEDE,
        payload
    }),
    deleteSede: (sedeId) => ({
        type: constants.DELETE_SEDE,
        sedeId
    }),
    setSelectedSede: (sedeId) => ({
        type: constants.SET_SELECTED_SEDE,
        sedeId
    }),
    clearAllSedes: () => ({
        type: constants.CLEAR_ALL_SEDES
    }),
    clearSingleSede: () => ({
        type: constants.CLEAR_SINGLE_SEDE
    }),
    updateArchivedSede: (sedeId, archived) => ({
        type: constants.UPDATE_ARCHIVED_SEDE,
        payload: {
            sedeId,
            archived
        }
    })
}