import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './floatingAlert.scss'
import def from '../../functions/defaults'
import { actions as alertActions } from '../../../redux/reducers/alertReducer'
import scrollTo from '../../functions/scrollTo'

const FloatingAlert = ({
    show = def.false,
    message = def.str,
    type = def.str,
    hide = def.fn,
    timer = def.number
}) => {

    useEffect(() => {
        if (!timer) return

        setTimeout(hide, timer)
    }, [timer])

    /*useEffect(() => {
        if (!show) return
        scrollTo(window,0)
    }, [show])*/

    return (
        <div className={`toast-alert ${show?'show':'hide'}`}>
            <div className={`alert alert-${type}`}>
                <button 
                    className="close" 
                    type="button"
                    onClick={hide}
                >
                    ×
                </button>
                <span dangerouslySetInnerHTML={{ __html: message }}></span>
            </div>
        </div>
    )
}

const mapState = state => ({
    ...state.alert.floating
})

const mapDispatch = dispatch => ({
    hide: () => dispatch(alertActions.hideFloatingAlert())
})

export default connect(mapState, mapDispatch)(FloatingAlert)