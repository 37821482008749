import React, { useEffect, createRef } from "react";
import Breadcrumbs from "../../../utils/components/Breadcrumbs";
import Button from "../../../utils/components/Button";
import SpecificAlert from "../../../utils/components/SpecificAlert/SpecificAlert";
import AppFormColors from "./AppFormColors";
import AppFormConfigs from "./AppFormConfigs";
import AppCardsSettings from "./AppCardsSettings";

const AppForm = ({
  saveOrganizerAppConfig,
  hideSpecificAlert,
  panelTitle,
  loadingSaveOrganizer,
  loadingLoadOrganizer,
  setColorConfig,
  getColorConfig,
  configColorVariables,
  organizer,
  setOrganizerKeyValue,
}) => {
  const uploaderRef = createRef();
  useEffect(() => {
    hideSpecificAlert();
  }, []);

  const saveOrganizerAppConfigHandler = () => {
    const images = uploaderRef.current.getFormData();
    saveOrganizerAppConfig(images);
  };

  const getImage = (key) => organizer[key] && organizer[key].organizer_card_big;

  const sizes = {
    OPTION_1: ["LARGE", "MEDIUM", "SMALL"],
    OPTION_2: ["XLARGE", "LARGE", "MEDIUM", "SMALL"],
  };

  return (
    <>
      <Breadcrumbs
        title="Configuración de la Aplicación"
        opts={[
          {
            title: "Inicio",
            path: "/",
          },
          {
            title: "Configuración de la Aplicación",
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div className="ibox-content">
              <SpecificAlert />
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-default">
                    <div className="panel-heading">{panelTitle}</div>
                  </div>
                </div>
                <div className="col-md-12">
                  <AppFormColors
                    loading={loadingLoadOrganizer}
                    configColorVariables={configColorVariables}
                    getColorConfig={getColorConfig}
                    setColorConfig={setColorConfig}
                    imageBgNextMatchCard={
                      organizer?.imageNextMatchCard?.organizer_card_big
                    }
                    imageCardsCard={
                      organizer?.imageCardsCard?.organizer_card_big
                    }
                    logo={organizer.image.organizer_logo_big}
                    logoEnabled={organizer.loginLogoEnabled}
                  />
                </div>
                <div className="col-md-12">
                  <AppFormConfigs
                    loading={loadingLoadOrganizer}
                    organizer={organizer}
                    setValue={setOrganizerKeyValue}
                  />
                </div>

                <div className="col-md-12">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      Pantalla principal de la aplicación
                    </div>
                    <div className="panel-body">
                      <AppCardsSettings
                        ref={uploaderRef}
                        setOrganizerKeyValue={setOrganizerKeyValue}
                        validExtensions={["jpg", "jpeg", "png"]}
                        getImage={getImage}
                        organizer={organizer}
                        cards={[
                          {
                            id: "nextMatchCard",
                            editable: true,
                            hideSize: true,
                            text: "Próximos Partidos",
                            imageHeight: "200px",
                            minWidth: 200,
                            textKey: "textNextMatchCard",
                            enabledKey: "enabledNextMatchCard",
                            priorityKey: "priorityNextMatchCard",
                            sizeKey: "sizeNextMatchCard",
                            imageKey: "imageNextMatchCard",
                            availableSizes: sizes.OPTION_1,
                          },
                          {
                            id: "barCard",
                            editable: true,
                            text: "Bar",
                            imageHeight: "100px",
                            minWidth: 200,
                            textKey: "textBarCard",
                            enabledKey: "enabledBarCard",
                            priorityKey: "priorityBarCard",
                            sizeKey: "sizeBarCard",
                            imageKey: "imageBarCard",
                            availableSizes: sizes.OPTION_1,
                          },
                          {
                            id: "cardsCard",
                            editable: true,
                            text: "Tarjetas",
                            imageHeight: "100px",
                            minWidth: 200,
                            textKey: "textCardsCard",
                            enabledKey: "enabledCardsCard",
                            priorityKey: "priorityCardsCard",
                            sizeKey: "sizeCardsCard",
                            imageKey: "imageCardsCard",
                            availableSizes: sizes.OPTION_1,
                          },
                          {
                            id: "fairplayCard",
                            editable: true,
                            text: "Fair Play",
                            imageHeight: "100px",
                            minWidth: 200,
                            textKey: "textFairplayCard",
                            enabledKey: "enabledFairplayCard",
                            priorityKey: "priorityFairplayCard",
                            sizeKey: "sizeFairplayCard",
                            imageKey: "imageFairplayCard",
                            availableSizes: sizes.OPTION_1,
                          },
                          {
                            id: "fixtureCard",
                            editable: true,
                            text: "Fixture",
                            imageHeight: "100px",
                            minWidth: 200,
                            textKey: "textFixtureCard",
                            enabledKey: "enabledFixtureCard",
                            priorityKey: "priorityFixtureCard",
                            sizeKey: "sizeFixtureCard",
                            imageKey: "imageFixtureCard",
                            availableSizes: sizes.OPTION_1,
                          },
                          {
                            id: "goalersCard",
                            editable: true,
                            text: "Goleadores",
                            imageHeight: "100px",
                            minWidth: 200,
                            textKey: "textGoalersCard",
                            enabledKey: "enabledGoalersCard",
                            priorityKey: "priorityGoalersCard",
                            sizeKey: "sizeGoalersCard",
                            imageKey: "imageGoalersCard",
                            availableSizes: sizes.OPTION_1,
                          },
                          {
                            id: "outstandingCard",
                            editable: true,
                            text: "Destacados",
                            imageHeight: "100px",
                            minWidth: 200,
                            textKey: "textOutstandingCard",
                            enabledKey: "enabledOutstandingCard",
                            priorityKey: "priorityOutstandingCard",
                            sizeKey: "sizeOutstandingCard",
                            imageKey: "imageOutstandingCard",
                            availableSizes: sizes.OPTION_1,
                          },
                          {
                            id: "positionsCard",
                            editable: true,
                            text: "Posiciones",
                            imageHeight: "100px",
                            minWidth: 200,
                            textKey: "textPositionsCard",
                            enabledKey: "enabledPositionsCard",
                            priorityKey: "priorityPositionsCard",
                            sizeKey: "sizePositionsCard",
                            imageKey: "imagePositionsCard",
                            availableSizes: sizes.OPTION_1,
                          },
                          {
                            id: "suspendedCard",
                            editable: true,
                            text: "Suspendidos",
                            imageHeight: "100px",
                            minWidth: 200,
                            textKey: "textSuspendedCard",
                            enabledKey: "enabledSuspendedCard",
                            priorityKey: "prioritySuspendedCard",
                            sizeKey: "sizeSuspendedCard",
                            imageKey: "imageSuspendedCard",
                            availableSizes: sizes.OPTION_1,
                          },
                          {
                            id: "teamCard",
                            editable: true,
                            text: "Planteles",
                            imageHeight: "100px",
                            minWidth: 200,
                            textKey: "textTeamCard",
                            enabledKey: "enabledTeamCard",
                            priorityKey: "priorityTeamCard",
                            sizeKey: "sizeTeamCard",
                            imageKey: "imageTeamCard",
                            availableSizes: sizes.OPTION_1,
                          },
                          {
                            id: "ad1",
                            editable: true,
                            text: "Ad1 Link",
                            imageHeight: "100px",
                            minWidth: 200,
                            enabledKey: "ad1Enabled",
                            priorityKey: "ad1Priority",
                            sizeKey: "ad1Size",
                            imageKey: "ad1Image",
                            linkKey: "ad1Link",
                            availableSizes: sizes.OPTION_2,
                          },
                          {
                            id: "ad2",
                            editable: true,
                            text: "Ad2 Link",
                            imageHeight: "100px",
                            minWidth: 200,
                            enabledKey: "ad2Enabled",
                            priorityKey: "ad2Priority",
                            sizeKey: "ad2Size",
                            imageKey: "ad2Image",
                            linkKey: "ad2Link",
                            availableSizes: sizes.OPTION_2,
                          },
                          {
                            id: "ad3",
                            editable: true,
                            text: "Ad3 Link",
                            imageHeight: "100px",
                            minWidth: 200,
                            enabledKey: "ad3Enabled",
                            priorityKey: "ad3Priority",
                            sizeKey: "ad3Size",
                            imageKey: "ad3Image",
                            linkKey: "ad3Link",
                            availableSizes: sizes.OPTION_2,
                          },
                          {
                            id: "loginBackground",
                            text: "Login",
                            imageHeight: "250px",
                            enabledKey: "loginLogoEnabled",
                            imageKey: "imageLoginBackground",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <Button
                    className="btn btn-primary pull-right"
                    title="Guardar"
                    loading={loadingSaveOrganizer}
                    onClick={saveOrganizerAppConfigHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppForm;
