import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as worksheetThunks from "../../../redux/thunks/worksheetThunks";
import { history } from "../../../redux/store";
import worksheetActions from "../../../redux/reducers/worksheetReducer/worksheetActions";
import WorksheetCheckIn from "./WorksheetCheckIn";
import getTeamType from "../../../utils/functions/getTeamType";
import keepOrganizer from "../../../utils/functions/keepOrganizer";

const WorksheetCheckInContainer = ({
  match,
  worksheet,
  loadPlayers,
  updatePlayer,
  loading,
  clearTeamPlayers,
  checkInPlayer,
  checkOutPlayer,
  checkInGuestPlayer,
  deletePlayerImage,
}) => {
  const organizerId = match.params.organizerId;
  const tournamentId = match.params.tournamentId;
  const matchId = match.params.matchId;
  const lineupId = match.params.lineupId;
  const teamId = match.params.teamId;

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!worksheet.isReady) {
      return history.push(
        `/organizers/${organizerId}/tournaments/${tournamentId}/matches/${matchId}/worksheet`
      );
    }
    clearTeamPlayers(teamId);

    loadPlayers(organizerId, tournamentId, lineupId);
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) history.push(keepOrganizer(""));
  }, [organizerId]);

  const getTeam = () => {
    const teamType = getTeamType(worksheet.match, teamId);
    return worksheet.match[teamType];
  };

  const updatePlayerHandler = (playerId, shirtNumber, image) =>
    updatePlayer(organizerId, teamId, playerId, shirtNumber, image);

  const deletePlayerImageHandler = (playerId) =>
    deletePlayerImage(organizerId, playerId, teamId);

  const checkInPlayerHandler = (playerId) => {
    const isLocal = worksheet.match.localTeam.id === teamId;
    const teamType = isLocal ? "locals" : "visitings";
    return checkInPlayer(
      organizerId,
      tournamentId,
      matchId,
      teamId,
      playerId,
      teamType
    );
  };

  const checkInAllPlayersHandler = (team) => {
    const availablePlayers = team.players.filter(
      (player) => !player.isSanctioned && !player.isBlacklisted
    );

    const checkedInPlayerIds = new Set();

    team.checkedInPlayers.forEach((checkedPlayer) => {
      checkedInPlayerIds.add(checkedPlayer.id);
    });
    availablePlayers.forEach((player) => {
      if (!checkedInPlayerIds.has(player.id)) {
        team.checkedInPlayers.push(player);
        checkedInPlayerIds.add(player.id);
        checkInGuestPlayerHandler(player);
      }
    });
  };

  const checkInGuestPlayerHandler = (player) => {
    const isLocal = worksheet.match.localTeam.id === teamId;
    const teamType = isLocal ? "local" : "visiting";
    return checkInGuestPlayer(
      organizerId,
      tournamentId,
      matchId,
      teamId,
      teamType,
      player
    );
  };

  const checkOutPlayerHandler = (playerId) =>
    checkOutPlayer(organizerId, tournamentId, matchId, teamId, playerId);

  return (
    <WorksheetCheckIn
      team={getTeam()}
      updatePlayer={updatePlayerHandler}
      loadingFetchPlayers={loading.loadPlayers}
      checkInPlayer={checkInPlayerHandler}
      checkInAllPlayersHandler={checkInAllPlayersHandler}
      loadingCheckInPlayer={loading.checkInPlayer}
      checkOutPlayer={checkOutPlayerHandler}
      loadingCheckOutPlayer={loading.checkOutPlayer}
      checkInGuestPlayer={checkInGuestPlayerHandler}
      deletePlayerImage={deletePlayerImageHandler}
    />
  );
};

const mapState = (state) => ({
  worksheet: state.worksheet,
  loading: state.loading.worksheet,
});

const mapDispatch = (dispatch) => ({
  loadPlayers: (organizerId, tournamentId, lineupId) =>
    dispatch(worksheetThunks.loadPlayers(organizerId, tournamentId, lineupId)),
  clearTeamPlayers: (teamId) =>
    dispatch(worksheetActions.clearTeamPlayers(teamId)),
  updatePlayer: (organizerId, teamId, playerId, shirtNumber, image) =>
    dispatch(
      worksheetThunks.updatePlayer(
        organizerId,
        teamId,
        playerId,
        shirtNumber,
        image
      )
    ),
  checkInPlayer: (
    organizerId,
    tournamentId,
    matchId,
    teamId,
    playerId,
    teamType
  ) =>
    dispatch(
      worksheetThunks.checkInPlayer(
        organizerId,
        tournamentId,
        matchId,
        teamId,
        playerId,
        teamType
      )
    ),
  checkOutPlayer: (organizerId, tournamentId, matchId, teamId, playerId) =>
    dispatch(
      worksheetThunks.checkOutPlayer(
        organizerId,
        tournamentId,
        matchId,
        teamId,
        playerId
      )
    ),
  checkInGuestPlayer: (
    organizerId,
    tournamentId,
    matchId,
    teamId,
    teamType,
    player
  ) =>
    dispatch(
      worksheetThunks.checkInGuestPlayer(
        organizerId,
        tournamentId,
        matchId,
        teamId,
        teamType,
        player
      )
    ),
  deletePlayerImage: (organizerId, playerId, teamId) =>
    dispatch(worksheetThunks.deletePlayerImage(organizerId, playerId, teamId)),
});

export default withRouter(
  connect(mapState, mapDispatch)(WorksheetCheckInContainer)
);
