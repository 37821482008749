"use-strict";

import authConstants from "../authReducer/authConstants";
import constants from "./worksheetConstants";
import initialState from "./worksheetState";
import getTeamType from "../../../utils/functions/getTeamType";

const worksheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_MATCH: {
      return {
        ...state,
        match: action.match,
      };
    }
    case constants.SET_IS_READY: {
      return {
        ...state,
        isReady: true,
      };
    }
    case constants.PUSH_NEW_ROADMAP_ITEM: {
      const roadmap = [...state.roadmap];
      roadmap.push(action.item);
      return {
        ...state,
        roadmap,
      };
    }
    case constants.REMOVE_ROADMAP_LAST_ITEM: {
      const roadmap = [...state.roadmap];
      roadmap.pop();
      return {
        ...state,
        roadmap,
      };
    }
    case constants.REMOVE_INCIDENCE_ITEM: {
      const incidences = [...state.incidences];
      const index = incidences.findIndex(
        (incidence) => incidence.id === action.incidenceId
      );
      incidences.splice(index, 1);

      return {
        ...state,
        incidences,
      };
    }
    case constants.ADD_TEAM_PLAYERS: {
      const teamType = getTeamType(state.match, action.teamId);
      const team = state.match[teamType];
      team.players = action.players;
      return {
        ...state,
        match: {
          ...state.match,
          [teamType]: team,
        },
      };
    }
    case constants.ADD_TEAM_PLAYER: {
      const teamType = getTeamType(state.match, action.teamId);
      const team = state.match[teamType];
      team.players.push(action.player);
      return {
        ...state,
        match: {
          ...state.match,
          [teamType]: team,
        },
      };
    }
    case constants.SET_PLAYER_KEY_VALUE: {
      const teamType = getTeamType(state.match, action.teamId);
      const team = { ...state.match[teamType] };
      const player = team.players.find(
        (player) => player.id === action.playerId
      );
      player[action.key] = action.value;
      return {
        ...state,
        match: {
          ...state.match,
          [teamType]: team,
        },
      };
    }
    case constants.REPLACE_PLAYER_ON_TEAM: {
      const teamType = getTeamType(state.match, action.teamId);
      const team = { ...state.match[teamType] };
      const index = team.players.findIndex(
        (player) => player.id === action.player.id
      );
      if (index !== -1) {
        team.players[index] = action.player;
      } else {
        const index = team.checkedInPlayers.findIndex(
          (player) => player.id === action.player.id
        );
        team.checkedInPlayers[index] = action.player;
      }
      return {
        ...state,
        match: {
          ...state.match,
          [teamType]: team,
        },
      };
    }
    case constants.ADD_TEAM_CHECKED_IN_PLAYER: {
      const teamType = getTeamType(state.match, action.teamId);
      const team = { ...state.match[teamType] };
      team.checkedInPlayers.push(action.player);
      return {
        ...state,
        match: {
          ...state.match,
          [teamType]: team,
        },
      };
    }
    case constants.REMOVE_TEAM_CHECKED_IN_PLAYER: {
      const teamType = getTeamType(state.match, action.teamId);
      const team = { ...state.match[teamType] };
      const index = team.checkedInPlayers.findIndex(
        (player) => player.id === action.playerId
      );
      team.checkedInPlayers.splice(index, 1);
      return {
        ...state,
        match: {
          ...state.match,
          [teamType]: team,
        },
      };
    }
    case constants.SET_ALL_INCIDENCES: {
      return {
        ...state,
        incidences: action.incidences,
      };
    }
    case constants.PUSH_NEW_INCIDENCE: {
      const incidences = [...state.incidences];
      incidences.push(action.incidence);
      return {
        ...state,
        incidences,
      };
    }
    case constants.PUSH_NEW_INTERVAL_ID: {
      return {
        ...state,
        intervalIds: [...state.intervalIds, action.id],
      };
    }
    case constants.CLEAR_ALL_INTERVAL_IDS: {
      return {
        ...state,
        intervalIds: initialState.intervalIds,
      };
    }
    case constants.CLEAR_ALL_INCIDENCES: {
      return {
        ...state,
        incidences: initialState.incidences,
      };
    }
    case constants.CLEAR_TEAM_PLAYERS: {
      const teamType = getTeamType(state.match, action.teamId);
      const team = { ...state.match[teamType] };
      team.players = [];
      return {
        ...state,
        match: {
          ...state.match,
          [teamType]: team,
        },
      };
    }
    case constants.CLEAR_MATCH: {
      return {
        ...state,
        match: initialState.match,
      };
    }
    case constants.SET_IS_FULL_SCREEN: {
      return {
        ...state,
        isFullScreen: action.isFullScreen,
      };
    }
    case constants.CLEAR_MATCH: {
      return {
        ...state,
        isReady: initialState.isReady,
      };
    }
    case constants.CLEAR_ROADMAP: {
      return {
        ...state,
        roadmap: initialState.roadmap,
      };
    }
    case constants.REPLACE_PLAYER_IMAGE_ON_TEAM: {
      const teamType = getTeamType(state.match, action.payload.teamId);
      const team = { ...state.match[teamType] };
      const index = team.players.findIndex(
        (player) => player.id === action.payload.id
      );
      if (index !== -1) {
        team.players[index].media = action.payload.media;
      } else {
        const index = team.checkedInPlayers.findIndex(
          (player) => player.id === action.payload.id
        );
        team.checkedInPlayers[index].media = action.payload.media;
      }
      return {
        ...state,
        match: {
          ...state.match,
          [teamType]: team,
        },
      };
    }
    case authConstants.LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default worksheetReducer;
