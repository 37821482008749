'use-strict'

import httpClient from '../../../http/httpClient'

const httpOrganizer = (organizerId) => httpClient(`/organizers/${organizerId}`)

export default {
    loadAll: (organizerId) => httpOrganizer(organizerId).get('/stadiums'),
    loadAllSede: (organizerId,sedeId) =>httpOrganizer(organizerId,sedeId).get(`/sedes/${sedeId}/stadiums`),
    update: (organizerId,sedeId,stadiumId,stadium)=>httpOrganizer(organizerId,sedeId,stadiumId).patch(`/sedes/${sedeId}/stadia/${stadiumId}`,stadium),
    create: (organizerId,sedeId,stadium)=>httpOrganizer(organizerId,sedeId).post(`/sedes/${sedeId}/stadia`,stadium),
}