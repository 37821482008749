'use-strict'

const initialState = {
    specific: {
        message: '',
        show: false,
        type: '' // warning, success, danger, info
    },
    floating: {
        message: '',
        show: false,
        timer: null, // time to autoclose
        type: '' // warning, success, danger, info
    },
    blocking: {
        message: '',
        show: false,
        type: '' // warning, success, danger, info
    }
}

const constants = {
    SHOW_SPECIFIC_ALERT: '@@alert/SHOW_SPECIFIC_ALERT',
    HIDE_SPECIFIC_ALERT: '@@alert/HIDE_SPECIFIC_ALERT',
    SHOW_FLOATING_ALERT: '@@alert/SHOW_FLOATING_ALERT',
    HIDE_FLOATING_ALERT: '@@alert/HIDE_FLOATING_ALERT'
}

export const actions = {
    showSpecificAlert: (message, type) => ({
        type: constants.SHOW_SPECIFIC_ALERT,
        payload: { message, type }
    }),
    hideSpecificAlert: () => ({
        type: constants.HIDE_SPECIFIC_ALERT
    }),
    showFloatingAlert: (message, type, timer) => ({
        type: constants.SHOW_FLOATING_ALERT,
        payload: { message, type, timer }
    }),
    hideFloatingAlert: () => ({
        type: constants.HIDE_FLOATING_ALERT
    })
}

export default (state = initialState, action) => {
    switch (action.type) {
        case constants.SHOW_SPECIFIC_ALERT: {
            return {
                ...state,
                specific: {
                    ...state.specific,
                    show: true,
                    message: action.payload.message,
                    type: action.payload.type
                }
            }
        }
        case constants.HIDE_SPECIFIC_ALERT: {
            return {
                ...state,
                specific: initialState.specific
            }
        }
        case constants.SHOW_FLOATING_ALERT: {
            return {
                ...state,
                floating: {
                    ...state.floating,
                    show: true,
                    message: action.payload.message,
                    type: action.payload.type,
                    timer: action.payload.timer
                }
            }
        }
        case constants.HIDE_FLOATING_ALERT: {
            return {
                ...state,
                floating: initialState.floating
            }
        }
        default: {
            return state
        }
    }
}