import React, { useState, useEffect, memo, useCallback } from "react";
import Button from "../../../../utils/components/Button";
import MyModal from "../../../../utils/components/Modal";

const LineUpCaptainModal = memo(
  ({ show, handleShow, lineup, addLineupCaptain }) => {
    const [dniError, setDniError] = useState(false);
    const [dni, setDni] = useState("");

    useEffect(() => {
      setDni("");
    }, [show]);

    useEffect(() => {
      lineup && lineup.captain && setDni(lineup.captain.document);
    }, [lineup]);

    const addCaptainHandler = useCallback(async () => {
      if (dni === "") {
        setDniError(true);
        return;
      }
      setDniError(false);
      await addLineupCaptain(lineup.id, dni);
      handleShow(false);
    }, [dni]);

    return (
      <MyModal
        show={show}
        title="Capitán"
        handleShow={handleShow}
        backdrop="static"
        body={
          <div className="row">
            <div className="col-md-12">
              <div className={`alert alert-info`}>
                Agrega el <b>DNI</b> del Capitán de{" "}
                <b>{lineup && lineup.team && lineup.team.name}</b>
              </div>
              <div className={`form-group ${dniError && "has-error"}`}>
                <label className="col-md-3 col-sm-2 control-label">
                  DNI *{" "}
                </label>
                <div className="col-md-9 col-sm-10">
                  <input
                    className="form-control"
                    value={dni}
                    disabled={false}
                    onChange={(e) => setDni(e.target.value)}
                  />
                  {dniError && (
                    <span className="help-block">El DNI es requerido.</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
        buttons={
          <Button
            title="Siguiente"
            className="btn btn-primary"
            onClick={addCaptainHandler}
          />
        }
      />
    );
  }
);

export default LineUpCaptainModal;
