import React, { useState } from 'react'
import Table from '../../../../utils/components/Table'
import Button from '../../../../utils/components/Button'

const PlayersModalTable = ({
    players,
    editingPlayer,
    setEditingPlayer,
    setSinglePlayer,
    clearSinglePlayer,
    selectedPlayer,
    setSelectedPlayer,
    loadingFetchPlayers,
    loadingDeletePlayer,
    deletePlayerHandler
}) => {

    const evalSelected = () => {
        if (selectedPlayer === '') return players
        return players.filter(player => player.id === selectedPlayer)
    }

    const addActions = (players) => players.map(player => {
        return {
            ...player,
            actions: (
                <div className="btn-separated-group">
                    <Button
                        className="btn btn-warning"
                        title={editingPlayer ? <i className="fa fa-times"></i> : <i className="fa fa-pencil"></i>}
                        withTooltip={true}
                        tooltipTitle={editingPlayer ? 'Cancelar' : 'Editar'}
                        onClick={() => {
                            setEditingPlayer(!editingPlayer)
                            setSinglePlayer(player.id)
                            setSelectedPlayer(editingPlayer ? '' : player.id)
                            if (editingPlayer) clearSinglePlayer()
                        }}
                    />
                    {!editingPlayer && (
                        <Button
                            className="btn btn-danger"
                            title={<i className="fa fa-trash"></i>}
                            withTooltip={true}
                            tooltipTitle='Eliminar'
                            onClick={() => deletePlayerHandler(player.id)}
                            loading={loadingDeletePlayer}
                            withConfirmation={true}
                            confirmationTitle='Eliminar jugador'
                            onConfirmText='Confirmar'
                            onCancelText='Cancelar'
                            onCancelClass='btn-danger pull-left'
                            confirmationBody='¿Estás seguro que quieres eliminar este jugador?'
                        />
                    )}
                </div>
            )
        }
    })

    return (
        <div className="row">
            <div className="col-md-12">
                <Table
                    loading={loadingFetchPlayers}
                    headers={[{
                        label: 'Nombre',
                        ref: 'firstName',
                        className: 'col-md-3 col-sm-3'
                    }, {
                        label: 'Apellido',
                        ref: 'lastName',
                        className: 'col-md-3 col-sm-3'
                    }, {
                        label: 'Documento',
                        ref: 'document',
                        className: 'col-md-3 col-sm-3 text-center'
                    }, {
                        label: 'Acciones',
                        ref: 'actions',
                        className: 'col-md-3 col-sm-3 text-center'
                    }]}
                    rows={addActions(evalSelected())}
                    notFoundMessage='No hay jugadores registrados...'
                />
            </div>
        </div>
    )
}

export default PlayersModalTable