import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CardList from "./CardsList";
import * as tournamentThunks from "../../../../redux/thunks/tournamentThunks";
import tournamentActions from "../../../../redux/reducers/tournamentReducer/tournamentActions";

const CardsListContainer = ({
  tournamentEditing,
  typeCard,
  organizerId,
  tournamentId,
  loading,
  redCards,
  yellowCards,
  loadTournamentRedCards,
  loadTournamentYellowCards,
  clearTournamentRedCards,
  clearTournamentYellowCards,
  show,
}) => {
  useEffect(() => {
    clearTournamentRedCards();
    clearTournamentYellowCards();

    if (tournamentEditing) {
      typeCard === "RedCard" &&
        loadTournamentRedCards(organizerId, tournamentId);
      typeCard === "YellowCard" &&
        loadTournamentYellowCards(organizerId, tournamentId);
    }
  }, []);
  return (
    show && (
      <CardList
        typeCard={typeCard}
        cards={typeCard === "RedCard" ? redCards : yellowCards}
        loadingFetchRedCards={loading.tournament.loadTournamentRedCards}
        organizerId={organizerId}
        tournamentId={tournamentId}
      />
    )
  );
};

const mapState = (state) => ({
  loading: state.loading,
  redCards: state.tournament.redCards,
  yellowCards: state.tournament.yellowCards,
});

const mapDispatch = (dispatch) => ({
  loadTournamentRedCards: (organizerId, tournamentId) =>
    dispatch(
      tournamentThunks.loadTournamentRedCards(organizerId, tournamentId)
    ),
  clearTournamentRedCards: () =>
    dispatch(tournamentActions.clearTournamentRedCards()),
  loadTournamentYellowCards: (organizerId, tournamentId) =>
    dispatch(
      tournamentThunks.loadTournamentYellowCards(organizerId, tournamentId)
    ),
  clearTournamentYellowCards: () =>
    dispatch(tournamentActions.clearTournamentYellowCards()),
});

export default withRouter(connect(mapState, mapDispatch)(CardsListContainer));
