'use-strict'

import authConstants from '../authReducer/authConstants'
import constants from './userConstants'
import initialState from './userState'

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_ALL_USERS: {
            return {
                ...state,
                all: action.payload
            }
        }
        case constants.CLEAR_ALL_USERS: {
            return {
                ...state,
                all: initialState.all
            }
        }
        case constants.SET_USER_KEY_VALUE: {
            return {
                ...state,
                single: {
                    ...state.single,
                    [action.payload.key]: action.payload.value
                }
            }
        }
        case constants.PUSH_NEW_USER: {
            const users = [...state.all]
            users.push(action.payload)
            return {
                ...state,
                all: users
            }
        }
        case constants.SET_SINGLE_USER: {
            return {
                ...state,
                single: action.payload
            }
        }
        case constants.REPLACE_USER: {
            const users = [...state.all]
            const index = users.findIndex(user => user.id === action.payload.id)
            users[index] = action.payload
            return {
                ...state,
                all: users
            }
        }
        case constants.DELETE_USER: {
            const users = [...state.all]
            const index = users.findIndex(user => user.id === action.userId)
            users.splice(index, 1)
            return {
                ...state,
                all: users
            }
        }
        case constants.SET_SELECTED_USER: {
            return {
                ...state,
                selected: action.userId
            }
        }
        case constants.CLEAR_SINGLE_USER: {
            return {
                ...state,
                single: initialState.single
            }
        }
        case constants.UPDATE_ARCHIVED_USER: {
            const users = [...state.all]
            const index = users.findIndex(user => user.id === action.payload.userId)
            users[index] = {
                ...users[index],
                archived: action.payload.archived
            }
            return {
                ...state,
                all: users
            }
        }
        case constants.SET_USER_ORGANIZATIONS: {
            return {
                ...state,
                organizations: action.payload
            }
        }
        case constants.SET_ALL_COUNTRIES: {
            return {
                ...state,
                countries: action.payload
            }
        }
        case constants.CLEAR_ALL_COUNTRIES: {
            return {
                ...state,
                countries: initialState.countries
            }
        }
        case constants.SET_ALL_ORGANIZERS: {
            return {
                ...state,
                organizers: action.payload
            }
        }
        case constants.CLEAR_ALL_ORGANIZERS: {
            return {
                ...state,
                organizers: initialState.organizers
            }
        }
        case authConstants.LOGOUT: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default userReducer