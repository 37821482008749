'use-strict'

import constants from './userConstants'

export default {
    setAllUsers: (payload) => ({
        type: constants.SET_ALL_USERS,
        payload
    }),
    setUserKeyValue: (key, value) => ({
        type: constants.SET_USER_KEY_VALUE,
        payload: { key, value }
    }),
    setSingleUser: (payload) => ({
        type: constants.SET_SINGLE_USER,
        payload
    }),
    pushNewUser: (payload) => ({
        type: constants.PUSH_NEW_USER,
        payload
    }),
    replaceUser: (payload) => ({
        type: constants.REPLACE_USER,
        payload
    }),
    deleteUser: (userId) => ({
        type: constants.DELETE_USER,
        userId
    }),
    setSelectedUser: (userId) => ({
        type: constants.SET_SELECTED_USER,
        userId
    }),
    clearAllUsers: () => ({
        type: constants.CLEAR_ALL_USERS
    }),
    clearSingleUser: () => ({
        type: constants.CLEAR_SINGLE_USER
    }),
    updateArchivedUser: (userId, archived) => ({
        type: constants.UPDATE_ARCHIVED_USER,
        payload: {
            userId,
            archived
        }
    }),
    setUserOrganizations: (payload) => ({
        type: constants.SET_USER_ORGANIZATIONS,
        payload
    }),
    setAllCountries:(payload) =>({
        type: constants.SET_ALL_COUNTRIES,
        payload
    }),
    clearAllCountries: ()=>({
        type: constants.CLEAR_ALL_COUNTRIES
    }),
    setAllOrganizers:(payload) =>({
        type: constants.SET_ALL_ORGANIZERS,
        payload
    }),
    clearAllOrganizers: ()=>({
        type: constants.CLEAR_ALL_ORGANIZERS
    })
}