import tournamentActions from "../reducers/tournamentReducer/tournamentActions";
import tournamentService from "../reducers/tournamentReducer/tournamentService";
import { model as tournamentModel } from "../reducers/tournamentReducer/tournamentState";
import { setLoading } from "../reducers/loadingReducer";
import handler, { statusCodes } from "../../utils/functions/handler";
import { actions as alertActions } from "../reducers/alertReducer";
import { history } from "../store";
import without from "../../utils/functions/without";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import * as env from "../../config/env";
import moment from "moment";
import only from "../../utils/functions/only";

export const loadAllTournaments = (organizerId) => async (dispatch) => {
  dispatch(setLoading.true("tournament.loadAllTournaments"));
  const [error, tournaments] = await tournamentService.loadAll(organizerId);
  dispatch(setLoading.false("tournament.loadAllTournaments"));

  if (error) {
    return dispatch(
      handler({
        ...error,
      })
    );
  }
  dispatch(
    tournamentActions.setAllTournaments(tournaments.map(tournamentModel))
  );
};

export const loadTournament =
  (organizerId, tournamentId) => async (dispatch) => {
    dispatch(setLoading.true("tournament.loadTournament"));
    const [error, tournament] = await tournamentService.loadOne(
      organizerId,
      tournamentId
    );
    dispatch(setLoading.false("tournament.loadTournament"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(
      tournamentActions.setSingleTournament(tournamentModel(tournament))
    );
  };

export const loadTournamentRedCards =
  (organizerId, tournamentId) => async (dispatch) => {
    dispatch(setLoading.true("tournament.loadTournamentRedCards"));
    const [error, tournamentRedCards] = await tournamentService.loadRedCards(
      organizerId,
      tournamentId
    );

    dispatch(setLoading.false("tournament.loadTournamentRedCards"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(tournamentActions.setTournamentRedCards(tournamentRedCards));
  };

export const loadTournamentYellowCards =
  (organizerId, tournamentId) => async (dispatch) => {
    dispatch(setLoading.true("tournament.loadTournamentYellowCards"));
    const [error, tournamentYellowCards] =
      await tournamentService.loadYellowCards(organizerId, tournamentId);

    dispatch(setLoading.false("tournament.loadTournamentYellowCards"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(tournamentActions.setTournamentYellowCards(tournamentYellowCards));
  };

export const createNewTournament =
  (organizerId, isPlayoff) => async (dispatch, getState) => {
    dispatch(setLoading.true("tournament.createNewTournament"));
    const store = getState();
    let comunFields = ["name", "category", "type", "year"];
    let tournament = {};
    if (isPlayoff) {
      tournament = only(store.tournament.single, [
        ...comunFields,
        "daysBetweenDates",
        "teams",
        "firstDate",
      ]);
    } else {
      tournament = only(store.tournament.single, [
        ...comunFields,
        "copyLineUps",
      ]);
    }
    const [error, createdTournament] = await tournamentService.create(
      organizerId,
      tournament
    );
    dispatch(setLoading.false("tournament.createNewTournament"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha creado el tourneo <b>${createdTournament.name}</b>.`,
        "info",
        3500
      )
    );
    history.push(keepOrganizer(`/tournaments/${createdTournament.id}/edit`));
  };

export const updateTournament =
  (organizerId, tournamentId) => async (dispatch, getState) => {
    dispatch(setLoading.true("tournament.updateTournament"));
    const store = getState();

    const tournament = without(store.tournament.single, ["id", "type"]);
    tournament.playerManagementDeadline = moment(
      tournament.playerManagementDeadline
    ).format(env.DATETIME_FORMAT);

    const [error, updatedTournament] = await tournamentService.update(
      organizerId,
      tournamentId,
      tournament
    );
    dispatch(setLoading.false("tournament.updateTournament"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado el torneo <b>${updatedTournament.name}</b>.`,
        "info",
        3500
      )
    );
    history.push(keepOrganizer(`/tournaments/${tournamentId}/edit`));
  };

export const deleteTournament =
  (organizerId, tournamentId) => async (dispatch, getState) => {
    dispatch(setLoading.true("tournament.deleteTournament"));
    const store = getState();
    const [error] = await tournamentService.delete(organizerId, tournamentId);
    const deletedTournament = store.tournament.all.find(
      (tournament) => tournament.id === tournamentId
    );
    dispatch(setLoading.false("tournament.deleteTournament"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(tournamentActions.deleteTournament(tournamentId));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha eliminado el torneo <b>${deletedTournament.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const archiveTournament =
  (organizerId, tournamentId, archiveValue) => async (dispatch, getState) => {
    dispatch(setLoading.true("tournament.archivingTournament"));
    const [error, updatedTournament] = await tournamentService.update(
      organizerId,
      tournamentId,
      { archived: archiveValue }
    );
    dispatch(setLoading.false("tournament.archivingTournament"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }
    dispatch(
      tournamentActions.updateArchivedTournament(
        updatedTournament.id,
        updatedTournament.archived
      )
    );
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado el torneo <b>${updatedTournament.name}</b>.`,
        "info",
        3500
      )
    );
  };
