'use-strict'

import constants from './playerConstants'

export default {
    setAllPlayers: (payload) => ({
        type: constants.SET_ALL_PLAYERS,
        payload
    }),
    setPlayerKeyValue: (key, value) => ({
        type: constants.SET_PLAYER_KEY_VALUE,
        payload: { key, value }
    }),
    pushNewPlayer: (payload) => ({
        type: constants.PUSH_NEW_PLAYER,
        payload
    }),
    setSinglePlayer: (payload) => ({
        type: constants.SET_SINGLE_PLAYER,
        payload
    }),
    replacePlayer: (payload) => ({
        type: constants.REPLACE_PLAYER,
        payload
    }),
    deletePlayer: (playerId) => ({
        type: constants.DELETE_PLAYER,
        playerId
    }),
    clearSinglePlayer: () => ({
        type: constants.CLEAR_SINGLE_PLAYER
    }),
    clearAllPlayers: () => ({
        type: constants.CLEAR_ALL_PLAYERS
    }),
    replacePlayerImage: (payload) => ({
        type: constants.REPLACE_PLAYER_IMAGE,
        payload
    })
}