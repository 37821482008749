import React, { useEffect, useState, createRef } from "react";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import Button from "../../utils/components/Button";
import { history } from "../../redux/store";
import UserImage from "../../assets/images/team.png";
import SpecificAlert from "../../utils/components/SpecificAlert/SpecificAlert";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import BrandImageUploader from "../../utils/components/ImageUploader/BrandImageUploader";
import Table from "../../utils/components/Table";
import only from "../../utils/functions/only";

const UserForm = ({
  user,
  setUserKeyValue,
  hideSpecificAlert,
  loadingFetchUser,
  loadingSaveUser,
  saveUser,
  saveButtonTitle,
  panelTitle,
  isEdit,
  loadingAllUserOrganizations,
  organizations,
  organizers,
  countries,
}) => {
  const brandUploaderRef = createRef();
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [documentoError, setDocumentoError] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [nationalityError, setNationalityError] = useState(false);
  const [locationError, setLocationError] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    hideSpecificAlert();
  }, []);

  const saveUserHandler = () => {
    let hasError = false;
    if (user.firstName === "" || user.firstName.length > 30) {
      hasError = true;
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (user.username === "") {
      hasError = true;
      setUsernameError(true);
    } else {
      setUsernameError(false);
    }
    if (
      user.email === "" ||
      !user.email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      hasError = true;
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (user.lastName === "") {
      hasError = true;
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (user.document === "") {
      hasError = true;
      setDocumentoError(true);
    } else {
      setDocumentoError(false);
    }
    if (user.birthday === "") {
      hasError = true;
      setBirthdayError(true);
    } else {
      setBirthdayError(false);
    }
    if (user.gender === "") {
      hasError = true;
      setGenderError(true);
    } else {
      setGenderError(false);
    }
    if (user.nationality === "") {
      hasError = true;
      setNationalityError(true);
    } else {
      setNationalityError(false);
    }
    if (hasError) return;
    if (isEdit) {
      saveUser();
    } else {
      const brandImage = brandUploaderRef.current.getFormData();
      saveUser(brandImage);
    }
  };

  const addActions = (organizations) =>
    organizations?.map((org) => {
      return {
        ...org,
      };
    });

  const handleChange = (e) => {
    let updatedValue = e.currentTarget.value;

    if (updatedValue === "true" || updatedValue == "false") {
      updatedValue = JSON.parse(updatedValue);
    }

    setUserKeyValue("isVerified", updatedValue);
  };
  const handleChange2 = (e) => {
    let updatedValue = e.currentTarget.value;

    if (updatedValue === "true" || updatedValue == "false") {
      updatedValue = JSON.parse(updatedValue);
    }

    setUserKeyValue("hasFansIdConfigurated", updatedValue);
  };
  const handleChange3 = (e) => {
    let updatedValue = e.currentTarget.value;

    if (updatedValue === "true" || updatedValue == "false") {
      updatedValue = JSON.parse(updatedValue);
    }

    setUserKeyValue("deleted", updatedValue);
  };

  return (
    <>
      <Breadcrumbs
        title="Users"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Users",
            active: false,
            path: keepOrganizer("/users"),
          },
          {
            title: user.username,
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div
              className="ibox-title"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn btn-info"
                title={
                  <span>
                    <i className="fa fa-arrow-left"></i> Volver
                  </span>
                }
                onClick={() => history.push(keepOrganizer("/users"))}
              />
            </div>
            <div className="ibox-content">
              <SpecificAlert />
              <div className="row">
                <div className="col-md-4">
                  {!isEdit && (
                    <BrandImageUploader
                      id="image"
                      ref={brandUploaderRef}
                      enableUploader={true}
                      title={panelTitle}
                      loading={loadingFetchUser}
                      image={
                        user.media && user.media.user_big
                          ? user.media.user_big
                          : UserImage
                      }
                      validExtensions={["jpg", "jpeg", "png"]}
                      minWidth={200}
                      minHeight={200}
                      aspectRatio={1}
                      confirmationTitle={
                        "¿Estás seguro que quieres eliminar la imagen de este jugador?"
                      }
                      confirmationBody={"Eliminar imagen del jugador"}
                    />
                  )}
                  {isEdit && (
                    <div className="col-md-4" style={{ width: "100%" }}>
                      <div className="panel panel-default">
                        <div className="panel-heading"></div>
                        <div className="panel-body text-center">
                          <img
                            id="image"
                            style={{ width: "100%" }}
                            src={
                              user.media && user.media.user_big
                                ? user.media.user_big
                                : UserImage
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-8">
                  <div className="panel panel-default">
                    <div className="panel-heading">Datos Generales</div>
                    <div className="panel-body">
                      <div
                        className={`form-group ${usernameError && "has-error"}`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Username *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={user.username}
                            readOnly={isEdit}
                            disabled={loadingFetchUser}
                            onChange={(e) => {
                              setUserKeyValue("username", e.target.value);
                            }}
                          />
                          {usernameError && (
                            <span className="help-block">
                              El nombre de usuario es requerido.
                            </span>
                          )}
                        </div>
                      </div>
                      {isEdit && (
                        <>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div className={`form-group`}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Fecha de alta
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <input
                                type="text"
                                className="form-control"
                                value={user.creationDate?.split("T")[0]}
                                readOnly={isEdit}
                                disabled={loadingFetchUser}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${emailError && "has-error"}`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Correo electrónico *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={user.email}
                            disabled={loadingFetchUser}
                            onChange={(e) => {
                              setUserKeyValue("email", e.target.value);
                            }}
                          />
                          {emailError && (
                            <span className="help-block">
                              El correo electrónico es requerido.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          firstNameError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Nombre *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={user.firstName}
                            disabled={loadingFetchUser}
                            onChange={(e) => {
                              setUserKeyValue("firstName", e.target.value);
                            }}
                          />
                          {firstNameError && (
                            <span className="help-block">
                              El nombre es requerido.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${lastNameError && "has-error"}`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Apellido *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={user.lastName}
                            disabled={loadingFetchUser}
                            onChange={(e) => {
                              setUserKeyValue("lastName", e.target.value);
                            }}
                          />
                          {lastNameError && (
                            <span className="help-block">
                              El apellido es requerida.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          documentoError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Documento *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={user.document}
                            disabled={loadingFetchUser}
                            onChange={(e) => {
                              setUserKeyValue("document", e.target.value);
                            }}
                          />
                          {documentoError && (
                            <span className="help-block">
                              El documento es requerida.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${birthdayError && "has-error"}`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Fecha nacimiento *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="date"
                            className="form-control"
                            value={user.birthday}
                            disabled={loadingFetchUser}
                            onChange={(e) => {
                              setUserKeyValue("birthday", e.target.value);
                            }}
                          />
                          {birthdayError && (
                            <span className="help-block">
                              La fecha de nacimiento es requerida.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${genderError && "has-error"}`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Género *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <select
                            className="form-control"
                            value={user.gender}
                            disabled={loadingFetchUser}
                            onChange={(e) => {
                              setUserKeyValue("gender", e.target.value);
                            }}
                          >
                            <option>M</option>
                            <option>F</option>
                          </select>
                          {genderError && (
                            <span className="help-block">
                              El género es requerida.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          nationalityError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Nacionalidad *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <select
                            className="form-control"
                            value={
                              user.nationality === null ? "" : user.nationality
                            }
                            disabled={loadingFetchUser}
                            onChange={(e) => {
                              setUserKeyValue("nationality", e.target.value);
                            }}
                          >
                            <option value="">...</option>
                            {countries.map((e) => (
                              <option key={e.name}>{e.name}</option>
                            ))}
                          </select>
                          {nationalityError && (
                            <span className="help-block">
                              La nacionalidad es requerida.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div className={`form-group`}>
                        <label className="col-md-3 col-sm-2 control-label">
                          Email verificado{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <select
                            className="form-control"
                            value={user.isVerified}
                            disabled={loadingFetchUser}
                            onChange={(e) => handleChange(e)}
                          >
                            <option value={false}>No</option>
                            <option value={true}>Si</option>
                          </select>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div className={`form-group`}>
                        <label className="col-md-3 col-sm-2 control-label">
                          Admin{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <select
                            className="form-control"
                            value={user.admin}
                            disabled={loadingFetchUser}
                            onChange={(e) => {
                              setUserKeyValue("admin", e.target.value);
                            }}
                          >
                            <option value="">...</option>
                            {organizers.map((e) => (
                              <option key={e.id} value={e.id}>
                                {e.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {isEdit && (
                        <>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div className={`form-group`}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Pidió borrarse?{" "}
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <select
                                className="form-control"
                                value={user.deleted}
                                disabled={loadingFetchUser}
                                onChange={(e) => handleChange3(e)}
                              >
                                <option value={false}>No</option>
                                <option value={true}>Si</option>
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <Button
                        className="btn btn-primary pull-right"
                        title={saveButtonTitle}
                        loading={loadingSaveUser}
                        onClick={saveUserHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {isEdit && (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel panel-default">
                        <div className="panel-heading">FansID</div>
                        <div className="panel-body">
                          <div className={`form-group`}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Tiene configurado Fans ID
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <select
                                className="form-control"
                                value={user.hasFansIdConfigurated}
                                disabled={loadingFetchUser}
                                onChange={(e) => handleChange2(e)}
                              >
                                <option value={false}>No</option>
                                <option value={true}>Sí</option>
                              </select>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>

                          <div className={`form-group`}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Altura
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <input
                                type="number"
                                className="form-control"
                                value={user.height}
                                disabled={loadingFetchUser}
                                onChange={(e) => {
                                  setUserKeyValue("height", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div className={`form-group`}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Peso
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <input
                                type="number"
                                className="form-control"
                                value={user.weight}
                                disabled={loadingFetchUser}
                                onChange={(e) => {
                                  setUserKeyValue("weight", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div className={`form-group`}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Deporte
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <input
                                type="text"
                                className="form-control"
                                value={user.sport}
                                disabled={loadingFetchUser}
                                onChange={(e) => {
                                  setUserKeyValue("sport", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div className={`form-group`}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Posición
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <input
                                type="text"
                                className="form-control"
                                value={user.position}
                                disabled={loadingFetchUser}
                                onChange={(e) => {
                                  setUserKeyValue("position", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                          <div className={`form-group`}>
                            <label className="col-md-3 col-sm-2 control-label">
                              Pie Hábil
                            </label>
                            <div className="col-md-9 col-sm-10">
                              <select
                                type="text"
                                className="form-control"
                                value={
                                  user.skillfulFoot === null
                                    ? ""
                                    : user.skillfulFoot
                                }
                                disabled={loadingFetchUser}
                                onChange={(e) => {
                                  setUserKeyValue(
                                    "skillfulFoot",
                                    e.target.value
                                  );
                                }}
                              >
                                <option>D</option>
                                <option>I</option>
                              </select>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="hr-line-dashed"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          Organización / Torneo
                        </div>
                        <div className="panel-body">
                          <Table
                            loading={loadingAllUserOrganizations}
                            headers={[
                              {
                                label: "Nombre",
                                ref: "name",
                                className: "col-md-6 col-sm-5",
                              } /*{
                                                    label: 'Acciones',
                                                    ref: 'actions',
                                                    className: 'col-md-2 col-sm-3 text-center'
                                                }*/,
                            ]}
                            rows={addActions(organizations)}
                            notFoundMessage="No se encontraron organizaciones..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserForm;
