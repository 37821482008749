import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as tableRowColorThunks from '../../../../redux/thunks/tableRowColorThunks'
import tableRowColorActions from '../../../../redux/reducers/tableRowColorReducer/tableRowColorActions'
import TableRowColorModal from './tableRowColorModal'


const TableRowColorModalContainer = ({
    show,
    handleShow,
    isEditing,
    organizerId,
    tournamentId,
    clearSingleTableRowColor,
    loadTableRowColor,
    tableRowColor,
    createTableRowColor,
    updateTableRowColor,
    setTableRowColorKeyValue,
    loading,
}) => {
    const [isDataReady, setIsDataReady] = useState(false)

    useEffect(() => {
        if(show){
            setIsDataReady(false)
            clearSingleTableRowColor()
            if(isEditing) loadTableRowColor(organizerId, tournamentId, tableRowColor.id)
            setIsDataReady(true)
        }
    }, [show])

    const handleCloseModal = () => handleShow(false)

    const handlerTableRowSave = 
        () => isEditing ? 
            updateTableRowColor(
                organizerId, 
                tournamentId, 
                tableRowColor.id, 
                handleCloseModal
            ) : 
            createTableRowColor(
                organizerId, 
                tournamentId, 
                handleCloseModal)

    const modalTitle = () => isEditing ? `Modificar color background` : 'Crear color background'
    const buttonSaveTitle = () => isEditing ? 'Guardar' : 'Crear'

    return (
        <TableRowColorModal
            show={show}
            handleShow={handleShow}
            setTableRowColorKeyValue={setTableRowColorKeyValue}
            tableRowColor={tableRowColor}
            handlerTableRowSave={handlerTableRowSave}
            isDataReady={isDataReady}
            modalTitle={modalTitle}
            buttonSaveTitle={buttonSaveTitle}
            loadingSave={isEditing ? loading.updateTableRowColor : loading.createTableRowColor}
        />
    )
}

const mapState = state => ({
    loading: state.loading.tableRowColor,
    tableRowColor: state.tableRowColor.single,
})

const mapDispatch = dispatch => ({
    clearSingleTableRowColor: () => dispatch(tableRowColorActions.clearSingleTableRowColor()),
    loadTableRowColor: (organizerId, tournamentId, tableRowColorId) => dispatch(tableRowColorThunks.loadTableRowColor(organizerId, tournamentId, tableRowColorId)),
    updateTableRowColor: (organizerId, tournamentId, tableRowColorId, handleCloseModal) => dispatch(tableRowColorThunks.updateTableRowColor(organizerId, tournamentId, tableRowColorId, handleCloseModal)),
    createTableRowColor: (organizerId, tournamentId, handleCloseModal) => dispatch(tableRowColorThunks.createTableRowColor(organizerId, tournamentId, handleCloseModal)),
    setTableRowColorKeyValue: (key, value) => dispatch(tableRowColorActions.setTableRowColorKeyValue(key, value)),
}) 

export default withRouter(connect(mapState, mapDispatch)(TableRowColorModalContainer))