'use-strict'

import authConstants from '../authReducer/authConstants'
import constants from './playerConstants'
import initialState from './playerState'

const tournamentReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_ALL_PLAYERS: {
            return {
                ...state,
                all: action.payload
            }
        }
        case constants.SET_PLAYER_KEY_VALUE: {
            return {
                ...state,
                single: {
                    ...state.single,
                    [action.payload.key]: action.payload.value
                }
            }
        }
        case constants.PUSH_NEW_PLAYER: {
            const players = [...state.all]
            players.push(action.payload)
            return {
                ...state,
                all: players
            }
        }
        case constants.SET_SINGLE_PLAYER: {
            return {
                ...state,
                single: action.payload
            }
        }
        case constants.REPLACE_PLAYER: {
            const players = [...state.all]
            const index = players.findIndex(player => player.id === action.payload.id)
            players[index] = action.payload
            return {
                ...state,
                all: players
            }
        }
        case constants.DELETE_PLAYER: {
            const players = [...state.all]
            const index = players.findIndex(player => player.id === action.playerId)
            players.splice(index, 1)
            return {
                ...state,
                all: players
            }
        }
        case constants.CLEAR_SINGLE_PLAYER: {
            return {
                ...state,
                single: initialState.single
            }
        }
        case constants.CLEAR_ALL_PLAYERS: {
            return {
                ...state,
                all: initialState.all
            }
        }
        case constants.REPLACE_PLAYER_IMAGE: {
            const players = [...state.all]
            const index = players.findIndex(player => player.id === action.payload.id)
            players[index].media = action.payload

            return {
                ...state,
                all: players
            }
        }
        case authConstants.LOGOUT: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default tournamentReducer