import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TeamForm from "./TeamForm";
import * as teamThunks from "../../redux/thunks/teamThunks";
import teamActions from "../../redux/reducers/teamReducer/teamActions";
import { actions as alertActions } from "../../redux/reducers/alertReducer";
import { history } from "../../redux/store";
import keepOrganizer from "../../utils/functions/keepOrganizer";

const TeamFormContainer = ({
  location,
  team,
  setTeamKeyValue,
  clearSingleTeam,
  createNewTeam,
  hideSpecificAlert,
  loadTeam,
  match,
  loading,
  updateTeam,
  teamStats,
}) => {
  const organizerId = match.params.organizerId;
  const teamId = match.params.teamId;
  const isEdit = /edit/.test(location.pathname);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    clearSingleTeam();

    if (isEdit) loadTeam(organizerId, teamId);

    setIsReady(true);
  }, []);

  useEffect(() => {
    if (isReady) history.push(keepOrganizer("/teams"));
  }, [organizerId]);

  const updateTeamHandler = (image) => updateTeam(organizerId, teamId, image);

  const createNewTeamHandler = () => createNewTeam(organizerId);

  const panelTitle = () => {
    let title = team.name;
    if (!isEdit && team.name === "") title = "Nuevo equipo";
    return title;
  };

  return (
    <TeamForm
      setTeamKeyValue={setTeamKeyValue}
      saveTeam={isEdit ? updateTeamHandler : createNewTeamHandler}
      hideSpecificAlert={hideSpecificAlert}
      team={team}
      loadingFetchTeam={loading.loadTeam}
      loadingSaveTeam={isEdit ? loading.updateTeam : loading.createNewTeam}
      saveButtonTitle={isEdit ? "Guardar" : "Crear"}
      panelTitle={panelTitle()}
      isEdit={isEdit}
      teamStats={teamStats}
    />
  );
};

const mapState = (state) => ({
  team: state.team.single,
  teamStats: state,
  loading: state.loading.team,
});

const mapDispatch = (dispatch) => ({
  setTeamKeyValue: (key, value) =>
    dispatch(teamActions.setTeamKeyValue(key, value)),
  clearSingleTeam: () => dispatch(teamActions.clearSingleTeam()),
  createNewTeam: (organizerId) =>
    dispatch(teamThunks.createNewTeam(organizerId)),
  hideSpecificAlert: () => dispatch(alertActions.hideSpecificAlert()),
  loadTeam: (organizerId, teamId) =>
    dispatch(teamThunks.loadTeam(organizerId, teamId)),
  updateTeam: (organizerId, teamId, image) =>
    dispatch(teamThunks.updateTeam(organizerId, teamId, image)),
});

export default withRouter(connect(mapState, mapDispatch)(TeamFormContainer));
