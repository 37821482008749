import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PlayerForm from './PlayerForm'
import * as playerThunks from '../../redux/thunks/playerThunks'
import playerActions from '../../redux/reducers/playerReducer/playerActions'
import { actions as alertActions } from '../../redux/reducers/alertReducer'
import { history } from '../../redux/store'
import keepOrganizer from '../../utils/functions/keepOrganizer'

const PlayerFormContainer = ({
    player,
    setPlayerKeyValue,
    clearSinglePlayer,
    hideSpecificAlert,
    loadPlayer,
    match,
    loading,
    updatePlayer,
    deletePlayerImage,
    deletePlayerMedicalRecord
}) => {

    const organizerId = match.params.organizerId
    const playerId = match.params.playerId

    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        clearSinglePlayer()
        loadPlayer(organizerId, playerId)

        setIsReady(true)
    }, [])

    useEffect(() => {
        if (isReady) history.push(keepOrganizer('/players'))
    }, [organizerId])

    const updatePlayerHandler = (image, medicalRecord) => updatePlayer(organizerId, playerId, image, medicalRecord)

    const deletePlayerImageHandler = () => deletePlayerImage(organizerId, playerId)

    const deletePlayerMedicalRecordHandler = () => deletePlayerMedicalRecord(organizerId, playerId)

    const panelTitle = () => {
        let title = `${player.firstName} ${player.lastName}`
        return title
    }

    return (
        <PlayerForm
            setPlayerKeyValue={setPlayerKeyValue}
            savePlayer={updatePlayerHandler}
            hideSpecificAlert={hideSpecificAlert}
            player={player}
            loadingFetchPlayer={loading.loadPlayer}
            loadingSavePlayer={loading.updatePlayer}
            saveButtonTitle={'Guardar'}
            panelTitle={panelTitle()}
            deletePlayerImage={deletePlayerImageHandler}
            deletePlayerMedicalRecord={deletePlayerMedicalRecordHandler}
            loadingDeleteMedicalRecord={loading.deletePlayerMedicalRecord}
        />
    )
}

const mapState = state => ({
    player: state.player.single,
    loading: state.loading.player
})

const mapDispatch = dispatch => ({
    setPlayerKeyValue: (key, value) => dispatch(playerActions.setPlayerKeyValue(key, value)),
    clearSinglePlayer: () => dispatch(playerActions.clearSinglePlayer()),
    hideSpecificAlert: () => dispatch(alertActions.hideSpecificAlert()),
    loadPlayer: (organizerId, playerId) => dispatch(playerThunks.loadPlayer(organizerId, playerId)),
    updatePlayer: (organizerId, playerId, image, medicalRecord) => dispatch(playerThunks.updatePlayer(organizerId, playerId, image, medicalRecord)),
    deletePlayerImage: (organizerId, playerId) => dispatch(playerThunks.deletePlayerImage(organizerId, playerId)),
    deletePlayerMedicalRecord: (organizerId, playerId) => dispatch(playerThunks.deletePlayerMedicalRecord(organizerId, playerId))
})

export default withRouter(connect(mapState, mapDispatch)(PlayerFormContainer))