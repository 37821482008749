'use-strict'

import constants from './stadiumConstants'

export default {
    setAllStadiums: (payload) => ({
        type: constants.SET_ALL_STADIUMS,
        payload
    }),
    setAllSedeStadiums: (payload) => ({
        type: constants.SET_ALL_SEDE_STADIUMS,
        payload
    }),
    clearAllStadiums: () => ({
        type: constants.CLEAR_ALL_STADIUMS
    }),
    setStadiumKeyValue: (key, value) => ({
        type: constants.SET_STADIUM_KEY_VALUE,
        payload: { key, value }
    }),
    clearAllSedeStadiums: (payload) => ({
        type: constants.CLEAR_ALL_SEDE_STADIUMS,
        payload
    }),
    pushNewStadium:(payload) => ({
        type: constants.PUSH_NEW_STADIUM,
        payload
    }),
}