import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PointList from "./PointList";
import tournamentActions from "../../../../redux/reducers/tournamentReducer/tournamentActions";
import * as tournamentThunks from "../../../../redux/thunks/tournamentThunks";

const PointPositionListContainer = ({
  organizerId,
  tournamentId,
  loading,
  tournament,
  setTournamentKeyValue,
  updateTournament,
  show,
}) => {
  const updateTournamentHandler = () =>
    updateTournament(organizerId, tournamentId);

  return (
    show && (
      <PointList
        tournament={tournament}
        loadingFetchTournament={loading.tournament.loadTournament}
        loadingSavePoints={loading.tournament.updateTournament}
        setTournamentKeyValue={setTournamentKeyValue}
        updateTournament={updateTournamentHandler}
        panelTitle="Posiciones"
        rowsMap={[
          {
            id: "pointWinLocal",
            name: "Ganados Local",
          },
          {
            id: "pointsTieLocal",
            name: "Empatados Local",
          },
          {
            id: "pointsLoseLocal",
            name: "Perdidos Local",
          },
          {
            id: "pointsWilVisiting",
            name: "Ganados Visitante",
          },
          {
            id: "pointsTieVisiting",
            name: "Empatados Visitante",
          },
          {
            id: "pointsLoseVisiting",
            name: "Perdidos Visitante",
          },
        ]}
      />
    )
  );
};

const mapState = (state) => ({
  loading: state.loading,
  tournament: state.tournament.single,
  groups: state.group.all,
  group: state.group.single,
});

const mapDispatch = (dispatch) => ({
  setTournamentKeyValue: (key, value) =>
    dispatch(tournamentActions.setTournamentKeyValue(key, value)),
  updateTournament: (organizerId, tournamentId) =>
    dispatch(tournamentThunks.updateTournament(organizerId, tournamentId)),
});

export default withRouter(
  connect(mapState, mapDispatch)(PointPositionListContainer)
);
