import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OrganizerForm from "./OrganizerForm";
import * as organizerThunks from "../../redux/thunks/organizerThunks";
import { actions as alertActions } from "../../redux/reducers/alertReducer";
import organizerActions from "../../redux/reducers/organizerReducer/organizerActions";

const OrganizerFormContainer = ({
  location,
  organizer,
  setOrganizerKeyValue,
  clearSingleOrganizer,
  createNewOrganizer,
  updateOrganizer,
  loadOrganizer,
  hideSpecificAlert,
  match,
  loading,
}) => {
  const organizerId = match.params.organizerId;
  const isEdit = /edit/.test(location.pathname);

  useEffect(() => {
    clearSingleOrganizer();
    if (isEdit) loadOrganizer(organizerId);
  }, []);

  const updateOrganizerHandler = (image) => updateOrganizer(organizerId, image);

  const createNewOrganizerHandler = () => createNewOrganizer();

  const panelTitle = () => {
    let title = organizer.name;
    if (!isEdit && organizer.name === "") title = "Nueva empresa";
    return title;
  };

  return (
    <OrganizerForm
      setOrganizerKeyValue={setOrganizerKeyValue}
      saveOrganizer={
        isEdit ? updateOrganizerHandler : createNewOrganizerHandler
      }
      hideSpecificAlert={hideSpecificAlert}
      organizer={organizer}
      loadingFetchOrganizer={loading.loadOrganizer}
      loadingSaveOrganizer={
        isEdit ? loading.updateOrganizer : loading.createNewOrganizer
      }
      saveButtonTitle={isEdit ? "Guardar" : "Crear"}
      panelTitle={panelTitle()}
      isEdit={isEdit}
    />
  );
};

const mapState = (state) => ({
  organizer: state.organizer.single,
  loading: state.loading.organizer,
});

const mapDispatch = (dispatch) => ({
  hideSpecificAlert: () => dispatch(alertActions.hideSpecificAlert()),
  setOrganizerKeyValue: (key, value) =>
    dispatch(organizerActions.setOrganizerKeyValue(key, value)),
  clearSingleOrganizer: () => dispatch(organizerActions.clearSingleOrganizer()),
  createNewOrganizer: () => dispatch(organizerThunks.createNewOrganizer()),
  loadOrganizer: (organizerId) =>
    dispatch(organizerThunks.loadOrganizer(organizerId)),
  updateOrganizer: (organizerId, image) =>
    dispatch(organizerThunks.updateOrganizer(organizerId, image)),
});

export default withRouter(
  connect(mapState, mapDispatch)(OrganizerFormContainer)
);
