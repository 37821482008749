import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as eventsThunks from "../../redux/thunks/eventsThunks";
import EventsList from "./EventsList";
import eventsActions from "../../redux/reducers/eventsReducer/eventsActions";
import participantsActions from "../../redux/reducers/participantsReducer/participantsActions";
import checkInActions from "../../redux/reducers/checkInReducer/checkInActions";

const EventsListContainer = ({
  loadAllEvents,
  clearAllEvents,
  deleteEvent,
  events,
  loading,
  match,
  clearAllParticipants,
  clearAllCheckIn,
}) => {
  const organizerId = match.params.organizerId;

  useEffect(() => {
    clearAllEvents();
    clearAllParticipants();
    clearAllCheckIn();
    loadAllEvents(organizerId);
  }, []);

  return (
    <EventsList
      loadingFetchEvents={loading.loadAllEvents}
      loadingDeleteEvents={loading.deleteEvent}
      deleteEvent={deleteEvent}
      events={events}
      organizerId={organizerId}
    />
  );
};

const mapState = (state) => ({
  events: state.event.all,
  loading: state.loading.event,
});

const mapDispatch = (dispatch) => ({
  loadAllEvents: (organizerId) =>
    dispatch(eventsThunks.loadAllEvents(organizerId)),
  deleteEvent: (eventId) => dispatch(eventsThunks.deleteEvent(eventId)),
  clearAllEvents: () => dispatch(eventsActions.clearAllEvents()),
  clearAllParticipants: () =>
    dispatch(participantsActions.clearAllParticipants()),
  clearAllCheckIn: () => dispatch(checkInActions.clearAllCheckIn()),
});

export default withRouter(connect(mapState, mapDispatch)(EventsListContainer));
