'use-strict'

export default {
    all: [],
    allSede: [],
    single: {
        id: '',
        name: '',
        archived:''
    }
}