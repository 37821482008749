import authActions from '../reducers/authReducer/authActions'
import authService from '../reducers/authReducer/authService'
import organizerService from '../reducers/organizerReducer/organizerService'
import { setLoading } from '../reducers/loadingReducer'
import { actions as alertActions } from '../reducers/alertReducer'
import handler, { statusCodes } from '../../utils/functions/handler'
import organizerActions from '../../redux/reducers/organizerReducer/organizerActions'
import { history } from '../store'

export const login = (credentials) => async dispatch => {
    dispatch(setLoading.true('auth.login'))
    const [authError, authorized] = await authService.login(credentials)
    dispatch(setLoading.false('auth.login'))
    
    if (authError) {
        return dispatch(handler({
            ...authError,
            handler: {
                [statusCodes.NOT_FOUND]: {
                    msg: 'El usuario ingresado no existe. Por favor verifica y vuelve a intentar.'
                },
                [statusCodes.UNAUTHORIZED]: {
                    msg: 'Los datos ingresados son erróneos. Por favor verifica y vuelve a intentar.'
                }
            }
        }))
    }

    const { roles } = authorized.data

    if (roles.length === 1 && roles[0] === 'ROLE_USER') {
        dispatch(alertActions.showSpecificAlert('No posees permisos suficientes para ingresar', 'danger'))
        return
    }

    dispatch(authActions.setAuthorizedUser(authorized))

    const [orgError, organizers] = await organizerService.loadAll()
    if (orgError) {
        return dispatch(handler(orgError))
    }
    const organizerId = organizers[0].id
    localStorage.setItem('organizerId', organizerId)
    dispatch(organizerActions.setSelectedOrganizer(organizerId))
    dispatch(organizerActions.setAllOrganizers(organizers))
    history.push(`/organizers/${organizerId}`)
}