import React, { useEffect, useState } from "react";
import Button from "../../../../utils/components/Button";
import isPowerOfTwo from "../../../../utils/functions/isPowerOfTwo";
import moment from "moment";
import ReactSwitch from "react-switch";
import Select from "react-select";

const TournamentForm = ({
  tournament,
  allTournament,
  loading,
  setTournamentKeyValue,
  saveTournament,
  tournamentEditing,
  tournamentTypes,
  teams,
  isPlayoff,
}) => {
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [teamsError, setTeamsError] = useState(false);
  const [firstDateError, setFirstDateError] = useState(false);
  const [daysBetweenError, setDaysBetweenError] = useState(false);
  const [priorityError, setPriorityError] = useState(false);

  useEffect(() => {
    setDisabledEdit(tournamentEditing);
  }, []);

  const disable = () => loading || disabledEdit;

  const saveTournamentHandler = () => {
    let hasError = false;
    if (tournament.name === "") {
      hasError = true;
      setNameError(true);
    } else {
      setNameError(false);
    }
    if (tournament.year === "" || !/^\d{4}$/.test(tournament.year)) {
      hasError = true;
      setYearError(true);
    } else {
      setYearError(false);
    }
    if (tournament.type === "") {
      hasError = true;
      setTypeError(true);
    } else {
      setTypeError(false);
      if (isPlayoff && !tournamentEditing) {
        const teamsLen = tournament.teams.length;
        if (!teamsLen || teamsLen < 2 || !isPowerOfTwo(teamsLen)) {
          hasError = true;
          setTeamsError(true);
        } else {
          hasError = false;
          setTeamsError(false);
        }
        if (tournament.firstDate === "") {
          hasError = true;
          setFirstDateError(true);
        } else {
          hasError = false;
          setFirstDateError(false);
        }
        if (!tournament.daysBetweenDates) {
          hasError = true;
          setDaysBetweenError(true);
        } else {
          hasError = false;
          setDaysBetweenError(false);
        }
      }
    }

    if (tournament.priority < 0) {
      hasError = true;
      setPriorityError(true);
    } else {
      setPriorityError(false);
    }

    if (hasError) return;
    saveTournament(isPlayoff);
    setDisabledEdit(true);
  };

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        Datos del torneo
        {tournamentEditing && (
          <Button
            title={
              <span>
                <i
                  className={`fa ${disabledEdit ? "fa-pencil" : "fa-times"}`}
                ></i>
                &nbsp;Torneo
              </span>
            }
            className="btn btn-warning btn-xs pull-right"
            onClick={() => setDisabledEdit(!disabledEdit)}
            withTooltip={true}
            tooltipTitle="Editar torneo"
          />
        )}
      </div>
      <div className="panel-body">
        <div className={`form-group ${nameError && "has-error"}`}>
          <label className="col-md-5 col-sm-5 control-label">Nombre * </label>
          <div className="col-md-7 col-sm-7">
            <input
              type="text"
              className="form-control"
              value={tournament.name}
              disabled={disable()}
              onChange={(e) => {
                setTournamentKeyValue("name", e.target.value);
              }}
            />
            {nameError && (
              <span className="help-block">El nombre es requerido.</span>
            )}
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="hr-line-dashed"></div>
        <div className={`form-group ${yearError && "has-error"}`}>
          <label className="col-md-5 col-sm-5 control-label">Año * </label>
          <div className="col-md-7 col-sm-7">
            <input
              type="number"
              min="2000"
              className="form-control"
              value={tournament.year}
              disabled={disable()}
              onChange={(e) => {
                setTournamentKeyValue("year", e.target.value);
              }}
            />
            {yearError && (
              <span className="help-block">El año es requerido.</span>
            )}
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="hr-line-dashed"></div>
        <div className={`form-group`}>
          <label className="col-md-5 col-sm-5 control-label">Categoría </label>
          <div className="col-md-7 col-sm-7">
            <input
              type="text"
              maxLength={60}
              className="form-control"
              value={tournament.category}
              disabled={disable()}
              onChange={(e) => {
                setTournamentKeyValue("category", e.target.value);
              }}
            />
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="hr-line-dashed"></div>
        <div className={`form-group ${typeError && "has-error"}`}>
          <label className="col-md-5 col-sm-5 control-label">Deporte * </label>
          <div className="col-md-7 col-sm-7">
            <select
              name="type"
              id="type"
              className="form-control"
              disabled={tournamentEditing || disable()}
              value={tournament.type}
              onChange={(e) => {
                setTournamentKeyValue("type", e.target.value);
              }}
            >
              <option value="">Seleccione</option>
              <option value={"EMPTY"}>{tournamentTypes.FUTBOL}</option> /* Los
              values tienen que ser empty */
              {/* <option value={tournamentTypes.PLAYOFF}>
                {tournamentTypes.PLAYOFF}
              </option>
              <option value={tournamentTypes.FRIENDLY}>
                {tournamentTypes.FRIENDLY}
              </option> */}
            </select>
            {typeError && (
              <span className="help-block">El tipo es requerido.</span>
            )}
          </div>
        </div>
        {tournamentEditing && (
          <>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className={`form-group`}>
              <label className="col-md-5 col-sm-5 control-label">
                Mostrar fechas de incidencias
              </label>
              <div className="col-md-7 col-sm-7">
                <ReactSwitch
                  onChange={() =>
                    setTournamentKeyValue("showDates", !tournament.showDates)
                  }
                  disabled={disable()}
                  checked={!!tournament.showDates}
                />
              </div>
            </div>
          </>
        )}
        {tournamentEditing && (
          <>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className={`form-group`}>
              <label className="col-md-5 col-sm-5 control-label">
                Esconder torneo
              </label>
              <div className="col-md-7 col-sm-7">
                <ReactSwitch
                  onChange={() =>
                    setTournamentKeyValue("archived", !tournament.archived)
                  }
                  disabled={disable()}
                  checked={!!tournament.archived}
                />
              </div>
            </div>
          </>
        )}
        {tournamentEditing && (
          <>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className={`form-group ${priorityError && "has-error"}`}>
              <label className="col-md-5 col-sm-5 control-label">
                Orden en buscadores
              </label>
              <div className="col-md-7 col-sm-7">
                <input
                  type="number"
                  className="form-control"
                  min={0}
                  value={tournament.priority}
                  disabled={disable()}
                  onChange={(e) =>
                    setTournamentKeyValue(
                      "priority",
                      Number.parseInt(e.target.value)
                    )
                  }
                />
                {priorityError && (
                  <span className="help-block">
                    El orden debe ser mayor o igual a 0
                  </span>
                )}
              </div>
            </div>
          </>
        )}
        <div className="clearfix"></div>
        <div className="hr-line-dashed"></div>
        {isPlayoff && !tournamentEditing ? (
          <>
            <div className={`form-group ${teamsError && "has-error"}`}>
              <label className="col-md-5 col-sm-5 control-label">
                Equipos participantes *{" "}
              </label>
              <div className="col-md-7 col-sm-7">
                <Select
                  options={teams.map((team) => ({
                    value: team.id,
                    label: team.name,
                  }))}
                  isMulti
                  placeholder="Seleccione"
                  disabled={!teams.length}
                  onChange={(selectedTeams) =>
                    setTournamentKeyValue(
                      "teams",
                      selectedTeams.map((team) => team.value)
                    )
                  }
                />
                {teamsError && (
                  <span className="help-block">
                    La cantidad de equipos debe ser <b>2^n</b> y mayor o igual a{" "}
                    <b>2</b>.
                  </span>
                )}
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className={`form-group ${firstDateError && "has-error"}`}>
              <label className="col-md-5 col-sm-5 control-label">
                Día de la primera jornada *{" "}
              </label>
              <div className="col-md-7 col-sm-7">
                <input
                  type="date"
                  className="form-control"
                  value={moment(
                    tournament.firstDate ? tournament.firstDate : new Date()
                  )
                    .utc()
                    .format("YYYY-MM-DD")}
                  disabled={disable()}
                  onChange={(e) => {
                    setTournamentKeyValue(
                      "firstDate",
                      moment(e.target.value).utc()
                    );
                  }}
                />
                {firstDateError && (
                  <span className="help-block">
                    El día de la jornada es requerido.
                  </span>
                )}
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className={`form-group ${daysBetweenError && "has-error"}`}>
              <label className="col-md-5 col-sm-5 control-label">
                Días entre jornadas *{" "}
              </label>
              <div className="col-md-7 col-sm-7">
                <input
                  type="number"
                  className="form-control"
                  value={tournament.daysBetweenDates}
                  disabled={disable()}
                  onChange={(e) => {
                    setTournamentKeyValue("daysBetweenDates", e.target.value);
                  }}
                />
                {daysBetweenError && (
                  <span className="help-block">
                    La cantidad de días entre jornadas son requeridos.
                  </span>
                )}
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
          </>
        ) : null}
        {!tournamentEditing && !isPlayoff ? (
          <>
            <div className={"form-group"}>
              <label className="col-md-5 col-sm-5 control-label">
                Torneo a copiar
              </label>
              <div className="col-md-7 col-sm-7">
                <select
                  name="copyLineUps"
                  id="copyLineUps"
                  className="form-control"
                  disabled={loading}
                  value={tournament.baseTournament}
                  onChange={(e) => {
                    setTournamentKeyValue("copyLineUps", e.target.value);
                  }}
                >
                  <option value="">Seleccione</option>
                  {allTournament.map((tournament, key) => (
                    <option value={tournament.id} key={key + "copyLineUps"}>
                      {tournament.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
          </>
        ) : null}
        <Button
          className="btn btn-primary pull-right"
          title={tournamentEditing ? "Guardar" : "Crear"}
          loading={loading}
          onClick={saveTournamentHandler}
          disabled={disable()}
        />
      </div>
    </div>
  );
};

export default TournamentForm;
