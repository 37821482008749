"use-strict";

import PrivateRoute from "./inspinia/PrivateRoute";
import { Route } from "react-router-dom";

import roles from "./config/roles";
import Login from "./app/login/Login";
import Home from "./app/home/Home";
import Help from "./app/help/Help";
import WorksheetContainer from "./app/worksheet/WorksheetContainer";
import TeamListContainer from "./app/teams/TeamListContainer";
import TeamFormContainer from "./app/teams/TeamFormContainer";
import TournamentListContainer from "./app/tournaments/TournamentListContainer";
import TournamentViewContainer from "./app/tournaments/TournamentViewContainer";
import NotMatch from "./utils/components/NotMatch";
import OrganizerListContainer from "./app/organizers/OrganizerListContainer";
import OrganizerFormContainer from "./app/organizers/OrganizerFormContainer";
import PlayerListContainer from "./app/players/PlayerListContainer";
import PlayerFormContainer from "./app/players/PlayerFormContainer";
import AppFormContainer from "./app/organizers/appConfig/AppFormContainer";
import WorksheetIncidenceCategoryContainer from "./app/worksheet/incidence-categories/WorksheetIncidenceCategoryContainer";
import WorksheetIncidenceTypeContainer from "./app/worksheet/incidence-types/WorksheetIncidenceTypeContainer";
import WorksheetCreateIncidenceContainer from "./app/worksheet/create-incidence/WorksheetCreateIncidenceContainer";
import WorksheetStatusContainer from "./app/worksheet/status/WorksheetStatusContainer";
import WorksheetCheckInContainer from "./app/worksheet/checkin/WorksheetCheckInContainer";
import WorksheetIncidenceListContainer from "./app/worksheet/incidence-list/WorksheetIncidenceListContainer";
import WorksheetOfflineContainer from "./app/worksheetOffline/WorksheetOfflineContainer";
import MatchesListContainer from "./app/matches/MatchesListContainer";
import SedeListContainer from "./app/sedes/SedeListContainer";
import SedeFormContainer from "./app/sedes/SedeFormContainer";
import UserListContainer from "./app/users/UserListContainer";
import UserFormContainer from "./app/users/UserFormContainer";
import EventsContainer from "./app/events/EventsListContainer";
import EventsViewContainer from "./app/events/EventsViewContainer";
import CheckInContainer from "./app/checkIn/CheckInContainer";
import CheckInViewContainer from "./app/checkIn/checkIn/CheckInViewContainer";

const base = "/organizers/:organizerId";

export default [
  {
    path: "/login",
    component: Login,
    Router: Route,
    exact: true,
  },
  {
    path: "/help",
    component: Help,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: "/organizers",
    component: OrganizerListContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER],
  },
  {
    path: "/organizers/create",
    component: OrganizerFormContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER],
  },
  {
    path: "/organizers/:organizerId/edit",
    component: OrganizerFormContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER],
  },
  {
    path: "/organizers/:organizerId/application",
    component: AppFormContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/teams",
    component: TeamListContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/teams/create",
    component: TeamFormContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/teams/:teamId/edit",
    component: TeamFormContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/players",
    component: PlayerListContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/players/:playerId/edit",
    component: PlayerFormContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/tournaments/:tournamentId/matches/:matchId/worksheet",
    component: WorksheetContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path:
      base + "/tournaments/:tournamentId/matches/:matchId/worksheet/incidences",
    component: WorksheetIncidenceListContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path:
      base +
      "/tournaments/:tournamentId/matches/:matchId/worksheet/lineups/:lineupId/teams/:teamId/incidence-categories",
    component: WorksheetIncidenceCategoryContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path:
      base +
      "/tournaments/:tournamentId/matches/:matchId/worksheet/lineups/:lineupId/teams/:teamId/incidence-categories/:incidenceCategory",
    component: WorksheetIncidenceTypeContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path:
      base +
      "/tournaments/:tournamentId/matches/:matchId/worksheet/lineups/:lineupId/teams/:teamId/incidence-categories/:incidence/create",
    component: WorksheetCreateIncidenceContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path:
      base +
      "/tournaments/:tournamentId/matches/:matchId/worksheet/incidence-categories/:incidence/create",
    component: WorksheetCreateIncidenceContainer,
    Router: PrivateRoute,
    exact: false,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path:
      base +
      "/tournaments/:tournamentId/matches/:matchId/worksheet/lineups/:lineupId/teams/:teamId/checkin",
    component: WorksheetCheckInContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/tournaments/:tournamentId/matches/:matchId/worksheet/status",
    component: WorksheetStatusContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/tournaments",
    component: TournamentListContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/tournaments/create",
    component: TournamentViewContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/tournaments/:tournamentId/edit",
    component: TournamentViewContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/tournaments/:tournamentId/matches/:matchId/worksheetOffline",
    component: WorksheetOfflineContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/matches",
    component: MatchesListContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/sedes",
    component: SedeListContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/sedes/create",
    component: SedeFormContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/sedes/:sedeId/edit",
    component: SedeFormContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/users",
    component: UserListContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/users/create",
    component: UserFormContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },

  {
    path: base + "/users/:userId/edit",
    component: UserFormContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/events",
    component: EventsContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/events/create",
    component: EventsViewContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/events/:eventId/edit",
    component: EventsViewContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/checkIn",
    component: CheckInContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/checkIn/create",
    component: CheckInViewContainer,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: base + "/",
    component: Home,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
  {
    path: "*",
    component: NotMatch,
    Router: PrivateRoute,
    exact: true,
    roles: [roles.ROLE_OWNER, roles.ROLE_STAFF],
  },
];
