import React from 'react'
import DateDisplaceModal from './DateDisplaceModal'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as dateThunks from '../../../../redux/thunks/dateThunks'

const DateDisplaceModalContainer = ({
    show,
    handleShow,
    date,
    saveDateDisplace,
    organizerId,
    tournamentId,
    loading
}) => {
    const closeDateDisplaceModalHandle = () => handleShow(false)

    const saveDateDisplaceHandler = (displace) => {saveDateDisplace(organizerId, tournamentId, date.id, displace, closeDateDisplaceModalHandle)}

    return (
        <DateDisplaceModal
            show = {show}
            handleShow = {handleShow}
            saveDateDisplace = {saveDateDisplaceHandler}
            loading = {loading}
            date = {date}
        />
    )   
}

const mapState = state => ({
    loading: state.loading.date.displaceDate,
    date: state.date.single
})

const mapDispatch = dispatch => ({
    saveDateDisplace: (organizerId, tournamentId, dateId, displace, cbCloseModal) => dispatch(dateThunks.displaceDate(organizerId, tournamentId, dateId, displace, cbCloseModal))
})

export default withRouter(connect(mapState, mapDispatch)(DateDisplaceModalContainer)) 