import playerActions from "../reducers/playerReducer/playerActions";
import playerService from "../reducers/playerReducer/playerService";
import { model as playerModel } from "../reducers/playerReducer/playerState";
import { setLoading } from "../reducers/loadingReducer";
import handler, { statusCodes } from "../../utils/functions/handler";
import { actions as alertActions } from "../reducers/alertReducer";
import without from "../../utils/functions/without";

export const addPlayerToLineup =
  (organizerId, tournamentId) => async (dispatch, getState) => {
    dispatch(setLoading.true("player.addPlayerToLineup"));
    const store = getState();
    const lineupId = store.lineup.selected;
    const teamId = store.team.selected;
    const player = store.player.single;
    const teams = store.team.all;

    const [error, createdPlayer] = await playerService.addPlayerInLineup(
      organizerId,
      tournamentId,
      teamId,
      lineupId,
      player
    );

    const team = teams.find((team) => team.id === teamId);
    dispatch(setLoading.false("player.addPlayerToLineup"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
            [statusCodes.CONFLICT]: {
              msg: "Ya existe un jugador con este documento.",
              alertStyle: "danger",
              alertType: "showFloatingAlert",
            },
          },
        })
      );
    }
    dispatch(playerActions.pushNewPlayer(playerModel(createdPlayer)));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha creado un jugador para <b>${team.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const updatePlayer =
  (organizerId, tournamentId, playerId, image) =>
  async (dispatch, getState) => {
    dispatch(setLoading.true("player.updatePlayer"));
    const store = getState();
    const lineupId = store.lineup.selected;
    const teamId = store.team.selected;
    const player = without(store.player.single, [
      "id",
      "document",
      "isAssociatedToUser",
      "user",
      "media",
    ]);
    const teams = store.team.all;

    let errorImage = null;
    if (image) {
      [errorImage] = await playerService.playerImages(
        organizerId,
        playerId,
        image
      );
    }

    const [error, updatedPlayer] = await playerService.updatePlayerInLineup(
      organizerId,
      tournamentId,
      teamId,
      lineupId,
      playerId,
      player
    );
    const team = teams.find((team) => team.id === teamId);

    dispatch(setLoading.false("player.updatePlayer"));
    if (error || errorImage) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }
    dispatch(playerActions.replacePlayer(playerModel(updatedPlayer)));
    dispatch(
      alertActions.showFloatingAlert(
        `Se actualizó un jugador para <b>${team.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const deletePlayer =
  (organizerId, tournamentId, playerId) => async (dispatch, getState) => {
    dispatch(setLoading.true("player.deletePlayer"));
    const store = getState();
    const lineupId = store.lineup.selected;
    const teamId = store.team.selected;
    const [error] = await playerService.deletePlayerInLineup(
      organizerId,
      tournamentId,
      teamId,
      lineupId,
      playerId
    );
    const deletedPlayer = store.player.all.find(
      (player) => player.id === playerId
    );
    dispatch(setLoading.false("player.deletePlayer"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.CONFLICT]: {
              msg: "Antes de sacar a este jugador del equipo, elimina los partidos en los que haya participado.",
              alertStyle: "danger",
            },
          },
        })
      );
    }
    dispatch(playerActions.deletePlayer(playerId));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha eliminado el jugador <b>${deletedPlayer.firstName} ${deletedPlayer.lastName}</b>.`,
        "info",
        3500
      )
    );
  };

export const setSinglePlayer = (playerId) => (dispatch, getState) => {
  const store = getState();
  const players = store.player.all;
  const player = players.find((player) => player.id === playerId);
  dispatch(playerActions.setSinglePlayer(player));
};

export const deletePlayerImage =
  (organizerId, playerId) => async (dispatch, getState) => {
    dispatch(setLoading.true("player.deletePlayerImage"));
    const [error, updatedPlayer] = await playerService.deletePlayerImage(
      organizerId,
      playerId
    );
    dispatch(setLoading.false("player.deletePlayerImage"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.CONFLICT]: {
              msg: "Error al eliminar la imagen del jugador.",
            },
          },
        })
      );
    }

    const store = getState();
    const player = store.player.single;

    dispatch(
      playerActions.replacePlayerImage({
        media: updatedPlayer.media,
        id: playerId,
      })
    );
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha eliminado la imagen del jugador <b>${player.firstName} ${player.lastName}</b>.`,
        "info",
        3500
      )
    );
  };
