import React, { useState, useEffect } from 'react'
import Button from '../../../utils/components/Button'
import MyModal from '../../../utils/components/Modal'

const WorksheetCheckInAddPlayerModal = ({
    show,
    handleShow,
    checkInGuestPlayer
}) => {

    const [firstNameError, setFirstNameError] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastNameError, setLastNameError] = useState(false)
    const [lastName, setLastName] = useState('')
    const [documentError, setDocumentError] = useState(false)
    const [document, setDocument] = useState('')

    useEffect(() => {
        setFirstName('')
        setLastName('')
        setDocument('')
    }, [show])

    const updatePlayerHandler = () => {
        let hasError = false
        if (firstName === '') {
            setFirstNameError(true)
            hasError = true
        } else {
            setFirstNameError(false)
            hasError = false
        }
        if (lastName === '') {
            setLastNameError(true)
            hasError = true
        } else {
            setLastNameError(false)
            hasError = false
        }
        if (document === '') {
            setDocumentError(true)
            hasError = true
        } else {
            setDocumentError(false)
            hasError = false
        }
        if (hasError) return
        checkInGuestPlayer({
            firstName,
            lastName,
            document
        })
        handleShow(false)
    }

    return (
        <MyModal
            show={show}
            title={`Nuevo jugador`}
            handleShow={handleShow}
            backdrop='static'
            body={
                <div className="row">
                    <div className="col-md-12">
                        <div className={`form-group ${firstNameError && 'has-error'}`}>
                            <label className="col-md-3 col-sm-2 control-label">Nombre * </label>
                            <div className="col-md-9 col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value)
                                    }} />
                                {firstNameError && <span className="help-block">El nombre es requerido.</span>}
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className={`form-group ${lastNameError && 'has-error'}`}>
                            <label className="col-md-3 col-sm-2 control-label">Apellido * </label>
                            <div className="col-md-9 col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={lastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value)
                                    }} />
                                {lastNameError && <span className="help-block">El apellido es requerido.</span>}
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className={`form-group ${documentError && 'has-error'}`}>
                            <label className="col-md-3 col-sm-2 control-label">Documento * </label>
                            <div className="col-md-9 col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={document}
                                    onChange={(e) => {
                                        setDocument(e.target.value)
                                    }} />
                                {documentError && <span className="help-block">El documento es requerido.</span>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            buttons={
                <Button
                    title="Agregar"
                    className="btn btn-primary"
                    onClick={updatePlayerHandler}
                />
            }
        />
    )
}

export default WorksheetCheckInAddPlayerModal