import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as tournamentThunks from "../../../../redux/thunks/tournamentThunks";
import tournamentActions from "../../../../redux/reducers/tournamentReducer/tournamentActions";
import TournamentForm from "./TournamentForm";
import * as teamThunks from "../../../../redux/thunks/teamThunks";
import teamActions from "../../../../redux/reducers/teamReducer/teamActions";

const TournamentFormContainer = ({
  tournamentEditing,
  organizerId,
  tournamentId,
  tournament,
  setTournamentKeyValue,
  createNewTournament,
  updateTournament,
  loading,
  clearSingleTournament,
  loadTournament,
  allTournament,
  loadAllTeams,
  teams,
  clearAllTeams,
  isPlayoff,
  tournamentTypes,
  show,
}) => {
  useEffect(() => {
    clearSingleTournament();
    clearAllTeams();

    if (tournamentEditing) loadTournament(organizerId, tournamentId);
  }, [tournamentId, organizerId]);

  useEffect(() => {
    if (isPlayoff) loadAllTeams(organizerId);
  }, [isPlayoff]);

  const createNewTournamentHandler = (isPlayoff) =>
    createNewTournament(organizerId, isPlayoff);

  const updateTournamentHandler = () =>
    updateTournament(organizerId, tournamentId);

  const loadingFetchTournament = () => loading.tournament.loadTournament;

  const loadingSaveTournament = () =>
    loading.tournament.createNewTournament ||
    loading.tournament.updateTournament;

  return (
    show && (
      <TournamentForm
        tournament={tournament}
        loading={loadingFetchTournament() || loadingSaveTournament()}
        setTournamentKeyValue={setTournamentKeyValue}
        saveTournament={
          tournamentEditing
            ? updateTournamentHandler
            : createNewTournamentHandler
        }
        tournamentEditing={tournamentEditing}
        allTournament={allTournament}
        tournamentTypes={tournamentTypes}
        teams={teams}
        isPlayoff={isPlayoff}
      />
    )
  );
};

const mapState = (state) => ({
  tournament: state.tournament.single,
  allTournament: state.tournament.all,
  loading: state.loading,
  teams: state.team.all,
});

const mapDispatch = (dispatch) => ({
  setTournamentKeyValue: (key, value) =>
    dispatch(tournamentActions.setTournamentKeyValue(key, value)),
  clearSingleTournament: () =>
    dispatch(tournamentActions.clearSingleTournament()),
  createNewTournament: (organizerId, isPlayoff) =>
    dispatch(tournamentThunks.createNewTournament(organizerId, isPlayoff)),
  updateTournament: (organizerId, tournamentId) =>
    dispatch(tournamentThunks.updateTournament(organizerId, tournamentId)),
  loadTournament: (organizerId, tournamentId) =>
    dispatch(tournamentThunks.loadTournament(organizerId, tournamentId)),
  loadAllTeams: (organizerId) => dispatch(teamThunks.loadAllTeams(organizerId)),
  clearAllTeams: () => dispatch(teamActions.clearAllTeams()),
});

export default withRouter(
  connect(mapState, mapDispatch)(TournamentFormContainer)
);
