import download from "downloadjs";
import lineupActions from "../reducers/lineupReducer/lineupActions";
import lineupService from "../reducers/lineupReducer/lineupService";
import { model as lineupModel } from "../reducers/lineupReducer/lineupState";
import { model as playerModel } from "../reducers/playerReducer/playerState";
import { setLoading } from "../reducers/loadingReducer";
import handler, { statusCodes } from "../../utils/functions/handler";
import { actions as alertActions } from "../reducers/alertReducer";
import { actions as uiActions } from "../reducers/uiReducer";
import teamActions from "../reducers/teamReducer/teamActions";
import playerActions from "../reducers/playerReducer/playerActions";

export const loadAllLineups =
  (organizerId, tournamentId) => async (dispatch) => {
    dispatch(setLoading.true("lineup.loadAllLineups"));
    const [error, lineups] = await lineupService.loadAll(
      organizerId,
      tournamentId
    );
    dispatch(setLoading.false("lineup.loadAllLineups"));

    if (error) {
      return dispatch(
        handler({
          ...error,
        })
      );
    }
    dispatch(lineupActions.setAllLineups(lineups.map(lineupModel)));
  };

export const loadLineup =
  (organizerId, tournamentId, lineupId) => async (dispatch) => {
    dispatch(setLoading.true("lineup.loadLineup"));
    const [error, lineup] = await lineupService.getOne(
      organizerId,
      tournamentId,
      lineupId
    );
    dispatch(setLoading.false("lineup.loadLineup"));

    if (error) {
      return dispatch(
        handler({
          ...error,
        })
      );
    }
    dispatch(playerActions.setAllPlayers(lineup.players.map(playerModel)));
  };

export const createNewLineup =
  (organizerId, tournamentId, teamId) => async (dispatch, getState) => {
    dispatch(setLoading.true("lineup.createNewLineup"));
    const [error, createdLineup] = await lineupService.create(
      organizerId,
      tournamentId,
      teamId
    );
    dispatch(setLoading.false("lineup.createNewLineup"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
            [statusCodes.CONFLICT]: {
              msg: "Este equipo ya participa en el torneo.",
              alertType: "showFloatingAlert",
              alertStyle: "danger",
            },
          },
        })
      );
    }
    dispatch(lineupActions.pushNewLineup(lineupModel(createdLineup)));
    dispatch(lineupActions.setSelectedLineup(createdLineup.id));
    dispatch(teamActions.setSelectedTeam(teamId));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha creado un lineup para <b>${createdLineup.team.name}</b>.`,
        "info",
        3500
      )
    );
    // Se permite la apertura del modal de players, si se creó el lineup correctamente
    dispatch(uiActions.showLineupPlayersModal());
  };

export const deleteLineup =
  (organizerId, tournamentId, lineupId) => async (dispatch, getState) => {
    dispatch(setLoading.true("lineup.deleteLineup"));
    const store = getState();
    const [error] = await lineupService.delete(
      organizerId,
      tournamentId,
      lineupId
    );
    const deletedLineup = store.lineup.all.find(
      (lineup) => lineup.id === lineupId
    );
    dispatch(setLoading.false("lineup.deleteLineup"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.CONFLICT]: {
              msg: "Antes de sacar a este equipo del torneo, elimina los partidos en los que haya participado.",
              alertStyle: "danger",
            },
          },
        })
      );
    }
    dispatch(lineupActions.deleteLineup(lineupId));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha eliminado el lineup de <b>${deletedLineup.team.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const addLineupCaptain =
  (organizerId, tournamentId, lineupId, dni) => async (dispatch, getState) => {
    dispatch(setLoading.true("lineup.addLineupCaptain"));
    const [error, captain] = await lineupService.addLineupCaptain(
      organizerId,
      tournamentId,
      lineupId,
      dni
    );
    dispatch(setLoading.false("lineup.addLineupCaptain"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    const lineup = getState().lineup.all.find(
      (lineup) => lineup.id === lineupId
    );
    dispatch(
      lineupActions.updateLineupCaptain(lineupId, {
        document: captain.document,
      })
    );
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha agregado el capitan de <b>${lineup.team.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const uploadMasiveLinupPlayers =
  (organizerId, tournamentId, file) => async (dispatch) => {
    dispatch(setLoading.true("lineup.uploadMasiveLinupPlayers"));
    const [error] = await lineupService.uploadMasiveLinupPlayers(
      organizerId,
      tournamentId,
      file
    );
    dispatch(setLoading.false("lineup.uploadMasiveLinupPlayers"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: error.response.data.error.exception[0]?.message,
              alertStyle: "danger",
              alertDuration: 8000,
            },
          },
        })
      );
    }

    dispatch(
      alertActions.showFloatingAlert(
        `Se ha procesado correctamente el archivo.`,
        "info",
        3500
      )
    );
  };

export const downloadLineupsAndPlayers =
  (organizerId, tournamentId) => async (dispatch) => {
    dispatch(setLoading.true("lineup.downloadLineupsAndPlayers"));
    const [error, csv] = await lineupService.downloadLineupsAndPlayers(
      organizerId,
      tournamentId
    );

    dispatch(setLoading.false("lineup.downloadLineupsAndPlayers"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }

    download(csv, `Lineups_Torneo-${tournamentId}.csv`, "text/csv");

    dispatch(
      alertActions.showFloatingAlert(
        `Se ha procesado correctamente el archivo.`,
        "info",
        3500
      )
    );
  };

export const setSelectedLineup = (lineupId) => (dispatch, getState) => {
  const store = getState();
  const lineups = store.lineup.all;
  const lineup = lineups.find((lineup) => lineup.id === lineupId);
  dispatch(lineupActions.setSelectedLineup(lineupId));
  dispatch(teamActions.setSelectedTeam(lineup.team.id));
};

export const addLineupBonus =
  (
    organizerId,
    tournamentId,
    lineupId,
    teamId,
    bonusPoints,
    bonusPointsNotes
  ) =>
  async (dispatch, getState) => {
    dispatch(setLoading.true("lineup.addLineupBonus"));
    const [error, lineup] = await lineupService.addLineupBonus(
      organizerId,
      tournamentId,
      lineupId,
      teamId,
      bonusPoints,
      bonusPointsNotes
    );
    dispatch(setLoading.false("lineup.addLineupBonus"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }

    dispatch(
      lineupActions.updateLineupBonus(lineupId, {
        bonusPoints: lineup.bonusPoints,
        bonusPointsNotes: lineup.bonusPointsNotes,
      })
    );
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha agregado puntos de bonus al equipo <b>${lineup.team.name}</b>.`,
        "info",
        3500
      )
    );
  };
