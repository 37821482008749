import _ from 'lodash'

const mapIncidenceTypes = (type, incidenceCategories) => {
    const categories = _.cloneDeep(incidenceCategories)
    let category = categories.find(cat => cat.ref === type)
    if (!category) {
        const found = categories.find(cat => (
            !!cat.types.find(elem => elem.ref === type)
        ))
        if (found) {
            category = found.types.find(elem => elem.ref === type)
            if (category) category.name = `${found.name} ${category.name}`
        }
    }
    return category ? category.name : '-'
}

export default mapIncidenceTypes