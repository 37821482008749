import React from "react";
import CheckInView from "./CheckInView";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const CheckInViewContainer = ({ match }) => {
  return <CheckInView />;
};

const mapState = (state) => ({});

const mapDispatch = (dispatch) => ({});

export default withRouter(connect(mapState, mapDispatch)(CheckInViewContainer));
