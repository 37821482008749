import React, { useState } from 'react'
import Breadcrumbs from '../../utils/components/Breadcrumbs'
import Button from '../../utils/components/Button'
import Table from '../../utils/components/Table'
import { history } from '../../redux/store'

const OrganizerList = ({
    deleteOrganizer,
    organizers,
    loadingFetchOrganizers,
    loadingDeleteOrganizer
}) => {

    const [searchValue, setSearchValue] = useState('')

    const getFilteredOrganizers = () => organizers.filter(organizer => {
        const foundTier = organizer.tier.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        const foundName = organizer.name.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        return foundTier || foundName
    })

    const addActions = (organizers) => organizers.map(organizer => {
        return {
            ...organizer,
            actions: (
                <div className="btn-separated-group">
                    <Button
                        className="btn btn-warning"
                        title={<i className="fa fa-pencil"></i>}
                        withTooltip={true}
                        tooltipTitle='Editar'
                        onClick={() => history.push(`/organizers/${organizer.id}/edit`)}
                    />
                    <Button
                        className="btn btn-danger"
                        title={<i className="fa fa-trash"></i>}
                        withTooltip={true}
                        tooltipTitle='Eliminar'
                        onClick={() => deleteOrganizer(organizer.id)}
                        loading={loadingDeleteOrganizer}
                        withConfirmation={true}
                        confirmationTitle='Eliminar empresa'
                        onConfirmText='Confirmar'
                        onCancelText='Cancelar'
                        onCancelClass='btn-danger pull-left'
                        confirmationBody='¿Estás seguro que quieres eliminar esta empresa?'
                    />
                </div>
            )
        }
    })

    return (
        <>
            <Breadcrumbs
                title='Empresas'
                opts={[{
                    title: 'Inicio',
                    path: '/'
                }, {
                    title: 'Empresas',
                    active: true
                }]}
            />
            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox m-t">
                        <div className="ibox-title">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Buscar empresas..."
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <Button
                                        className="btn btn-info pull-right"
                                        title={<span><i className="fa fa-plus-circle"></i> Nuevo</span>}
                                        onClick={() => history.push('/organizers/create')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ibox-content">
                            <Table
                                loading={loadingFetchOrganizers}
                                headers={[{
                                    label: 'Nombre',
                                    ref: 'name',
                                    className: 'col-md-3 col-sm-3'
                                },{
                                    label: 'Tipo',
                                    ref: 'tier',
                                    className: 'col-md-3 col-sm-3'
                                },{
                                    label: 'Acciones',
                                    ref: 'actions',
                                    className: 'col-md-2 col-sm-3 text-center'
                                }]}
                                rows={addActions(getFilteredOrganizers())}
                                notFoundMessage='No se encontraron empresas...'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrganizerList