'use-strict'

export default {
    SET_ALL_LINEUPS: '@@lineup/SET_ALL_LINEUPS',
    PUSH_NEW_LINEUP: '@@lineup/PUSH_NEW_LINEUP',
    DELETE_LINEUP: '@@lineup/DELETE_LINEUP',
    SET_SELECTED_LINEUP: '@@lineup/SET_SELECTED_LINEUP',
    CLEAR_ALL_LINEUPS: '@@lineup/CLEAR_ALL_LINEUPS',
    UPDATE_LINEUP_CAPTAIN: '@@lineup/UPDATE_LINEUP_CAPTAIN',
    UPDATE_LINEUP_BONUS:'@@lineup/UPDATE_LINEUP_BONUS'
}