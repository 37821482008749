"use-strict";

import httpClient from "../../../http/httpClient";

export default {
  loadAllCheckIns: () => httpClient("").get(`/eventssusersaccess`),
  loadAllCheckInsForEvent: (eventId, page = 0, pageSize = 10) =>
    httpClient("").get(
      `/eventsuseraccesses/${eventId}/event?page=${page}&pageSize=${pageSize}`
    ),
  loadOneCheckIn: (checkInId) =>
    httpClient("").get(`/eventsuseraccesses/${checkInId}`),
  createCheckIn: (eventId, checkIn) =>
    httpClient("").post(`/eventsuseraccesses/${eventId}/events`, {
      document: checkIn,
    }),
  updateCheckIn: (checkIn) =>
    httpClient("").patch(`/eventsuseraccesses`, checkIn),
  deleteCheckin: (checkAccessId) =>
    httpClient("").delete(`/eventsuseraccesses/${checkAccessId}`),
};
