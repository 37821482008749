import groupActions from "../reducers/groupReducer/groupActions";
import groupService from "../reducers/groupReducer/groupService";
import { model as groupModel } from "../reducers/groupReducer/groupState";
import { setLoading } from "../reducers/loadingReducer";
import handler, { statusCodes } from "../../utils/functions/handler";
import { actions as alertActions } from "../reducers/alertReducer";
import without from "../../utils/functions/without";

export const loadTournamentGroups =
  (organizerId, tournamentId) => async (dispatch) => {
    dispatch(setLoading.true("group.loadTournamentGroups"));
    const [error, groups] = await groupService.loadTournamentGroups(
      organizerId,
      tournamentId
    );
    dispatch(setLoading.false("group.loadTournamentGroups"));

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(groupActions.setAllGroups(groups.map(groupModel)));
  };

export const createNewGroup =
  (organizerId, tournamentId, handleCloseModal) =>
  async (dispatch, getState) => {
    dispatch(setLoading.true("group.createNewGroup"));

    const store = getState();
    const group = without(store.group.single, ["id"]);

    const objGroup = {
      name: group.name,
      enabled: group.enabled,
      lineups: [group.lineups.map((lineup) => lineup.value).join(",")],
    };

    const [error, createdGroup] = await groupService.create(
      organizerId,
      tournamentId,
      objGroup
    );

    dispatch(setLoading.false("group.createNewGroup"));

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }
    dispatch(groupActions.pushNewGroup(groupModel(createdGroup)));

    handleCloseModal();
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha creado el grupo: <b>${createdGroup.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const deleteGroup =
  (organizerId, tournamentId, groupId) => async (dispatch, getState) => {
    dispatch(setLoading.true("group.deleteGroup"));
    const store = getState();
    const groups = store.group.all;
    const deletedGroup = groups.find((group) => group.id === groupId);

    const [error, deleteGroupsResponse] = await groupService.delete(
      organizerId,
      tournamentId,
      groupId
    );
    dispatch(setLoading.false("group.deleteGroup"));

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.CONFLICT]: {
              msg: "Antes de sacar a este equipo del torneo, elimina los partidos que tenga asociados.",
              alertStyle: "danger",
            },
          },
        })
      );
    }

    if (deleteGroupsResponse.status === "error") {
      return dispatch(
        handler({
          ...deleteGroupsResponse.status,
          handler: {
            [statusCodes.CONFLICT]: {
              msg: "Antes de sacar a este equipo del torneo, elimina los partidos que tenga asociados.",
              alertStyle: "danger",
            },
          },
        })
      );
    }

    dispatch(groupActions.deleteGroup(groupId));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha eliminado el grupo: <b>${deletedGroup.name}</b>.`,
        "info",
        3500
      )
    );
  };

export const loadGroup =
  (organizerId, tournamentId, groupId) => async (dispatch, getState) => {
    if (!groupId) return;

    dispatch(setLoading.true("group.loadGroup"));
    const [error, groupLoaded] = await groupService.loadOne(
      organizerId,
      tournamentId,
      groupId
    );
    dispatch(setLoading.false("group.loadGroup"));

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {},
        })
      );
    }

    // Objeto para almacenar lineups únicos
    const uniqueLineups = {};

    // Filtrar lineups duplicados
    const filteredLineups = groupLoaded.group.lineups.filter((lineup) => {
      if (!uniqueLineups[lineup.id]) {
        uniqueLineups[lineup.id] = true;
        return true;
      }
      return false;
    });

    // Asignar los lineups filtrados al grupo
    groupLoaded.group.lineups = filteredLineups;

    dispatch(groupActions.setSingleGroup(groupLoaded.group));
    dispatch(groupActions.setLineupsByGroup(groupLoaded.group.lineups));
  };

export const updateGroup =
  (organizerId, tournamentId, groupId, handleCloseModal) =>
  async (dispatch, getState) => {
    dispatch(setLoading.true("group.updateSanction"));
    const store = getState();
    const group = without(store.group.single, ["id"]);

    const objGroup = {
      name: group.name,
      enabled: group.enabled,
      lineups: [
        group.lineups.map((lineup) => lineup.value || lineup.id).join(","),
      ],
    };

    const [error, updatedGroup] = await groupService.update(
      organizerId,
      tournamentId,
      groupId,
      objGroup
    );

    dispatch(setLoading.false("group.updateGroup"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
          },
        })
      );
    }

    dispatch(groupActions.replaceGroup(updatedGroup));
    handleCloseModal();
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado el grupo ${updatedGroup.name}`,
        "info",
        3500
      )
    );
  };
