import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as eventsThunks from "../../redux/thunks/eventsThunks";
import eventsActions from "../../redux/reducers/eventsReducer/eventsActions";
import { actions as alertActions } from "../../redux/reducers/alertReducer";
import EventsView from "./EventsView";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import { history } from "../../redux/store";

const EventsViewContainer = ({ match, hideSpecificAlert, event }) => {
  const [isMounted, setIsMounted] = useState(false);

  const eventId = match.params.eventId;
  const organizerId = match.params.organizerId;
  const isEdit = /edit/.test(location.pathname);

  useEffect(() => {
    setIsMounted(true);
    hideSpecificAlert();
  }, []);

  useEffect(() => {
    if (isMounted) history.push(keepOrganizer("/tournaments"));
  }, [organizerId]);

  return (
    <EventsView
      organizerId={organizerId}
      eventIsEdit={isEdit}
      eventId={eventId}
      event={event}
    />
  );
};

const mapState = (state) => ({
  event: state.event.single,
});

const mapDispatch = (dispatch) => ({
  hideSpecificAlert: () => dispatch(alertActions.hideSpecificAlert()),
});

export default withRouter(connect(mapState, mapDispatch)(EventsViewContainer));
