import React, { useState, useImperativeHandle, forwardRef } from "react";
import Spinner from "../Spinner";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/reducers/alertReducer";
import Button from "../Button";
import defaults from "../../functions/defaults";

const BrandImageUploader = forwardRef(
  (
    {
      id,
      title,
      loading,
      image,
      validExtensions,
      minWidth,
      minHeight,
      aspectRatio,
      enableUploader,
      deleteImage,
      showDeleteImageButtom = defaults.false,
      verifiedImage = defaults.false,
      confirmationTitle,
      confirmationBody,
    },
    ref
  ) => {
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const dispatch = useDispatch();

    const showAlert = (msg) =>
      dispatch(actions.showFloatingAlert(msg, "danger", 5000));

    const clearInput = (id) => {
      const input = document.getElementById(id);
      input.value = "";
    };

    const onChangeInput = (e) => {
      const [file] = e.target.files;
      const { id } = e.target;
      const isValid = validExtensions.some(
        (ext) => file.type && file.type.includes(ext)
      );
      if (!isValid) {
        clearInput(id);
        showAlert(
          `Sólo se admiten los siguientes formatos: ${validExtensions}`
        );
        return;
      }
      const reader = new FileReader();
      reader.onloadend = function () {
        var image = new Image();
        image.src = reader.result;
        image.onload = function () {
          if (minWidth && image.width < minWidth) {
            clearInput(id);
            showAlert(`El ancho mínimo admitido es de ${minWidth}px`);
            return;
          }
          if (minHeight && image.width < minHeight) {
            clearInput(id);
            showAlert(`La altura mínima admitida es de ${minHeight}px`);
            return;
          }
          if (
            minWidth &&
            minHeight &&
            aspectRatio === 1 &&
            image.width !== image.height
          ) {
            clearInput(id);
            showAlert(
              `La altura y el ancho de la imagen deben ser proporcionales. 
                        (ancho actual: ${image.height}px, altura actual: ${image.width}px)`
            );
            return;
          }
          setPreview(reader.result);
          setFile(file);
        };
      };
      reader.readAsDataURL(file);
    };

    useImperativeHandle(ref, () => ({
      getFormData() {
        if (!file) return null;
        const formData = new FormData();
        formData.append(id, file);
        return formData;
      },
    }));

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          {title}
          {verifiedImage && (
            <span className="verified-image">
              <i className="fa fa-check-circle"></i>
            </span>
          )}
          {deleteImage && showDeleteImageButtom && (
            <Button
              title={
                <span>
                  <i className={`fa fa-trash`}></i> Eliminar
                </span>
              }
              className="btn btn-danger btn-xs pull-right"
              onClick={() => {
                deleteImage();
              }}
              withTooltip={true}
              tooltipTitle="Eliminar"
              withConfirmation={true}
              confirmationTitle={confirmationTitle}
              onConfirmText="Confirmar"
              onCancelText="Cancelar"
              onCancelClass="btn-danger pull-left"
              confirmationBody={confirmationBody}
            />
          )}
        </div>
        <div className="panel-body team-brand">
          <Spinner show={loading} />
          {!loading && <img src={preview || image} />}
        </div>
        {enableUploader && (
          <div className="panel-footer">
            <input type="file" id={id} onChange={onChangeInput} />
          </div>
        )}
      </div>
    );
  }
);

export default BrandImageUploader;
