'use-strict'

import only from "../../../utils/functions/only"

export default {
    selected: '',
    all: [],
    single: {
        id: '',
        name: '',
        shortName: ''
    }
}

export const model = (entity) => only(entity, ['id','name','shortName', 'image'])