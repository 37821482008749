import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SedeForm from "./SedeForm";
import * as sedeThunks from "../../redux/thunks/sedeThunks";
import sedeActions from "../../redux/reducers/sedeReducer/sedeActions";
import { actions as alertActions } from "../../redux/reducers/alertReducer";
import { history } from "../../redux/store";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import * as stadiumThunks from "../../redux/thunks/stadiumThunks";
import stadiumActions from "../../redux/reducers/stadiumReducer/stadiumActions";

const SedeFormContainer = ({
  location,
  sede,
  setSedeKeyValue,
  clearSingleSede,
  createNewSede,
  hideSpecificAlert,
  loadSede,
  match,
  loading,
  updateSede,
  stadiums,
  loadAllSedeStadiums,
  clearAllSedeStadiums,
  setStadiumKeyValue,
  updateStadium,
  createStadium,
}) => {
  const organizerId = match.params.organizerId;
  const sedeId = match.params.sedeId;
  const isEdit = /edit/.test(location.pathname);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    clearSingleSede();
    if (isEdit) loadSede(organizerId, sedeId);
    setIsReady(true);
  }, []);

  useEffect(() => {
    if (isReady) history.push(keepOrganizer("/sedes"));
  }, [organizerId]);

  useEffect(() => {
    clearAllSedeStadiums();
    loadAllSedeStadiums(organizerId, sedeId);
  }, [sedeId]);

  const updateSedeHandler = (image) => updateSede(organizerId, sedeId, image);
  const updateStadiumHandler = (stadiumId, stad) =>
    updateStadium(organizerId, sedeId, stadiumId, stad);

  const createNewSedeHandler = () => createNewSede(organizerId);
  const createStadiumHandler = (stadiumName) =>
    createStadium(organizerId, sedeId, stadiumName);

  const panelTitle = () => {
    let title = sede.name;
    if (!isEdit && sede.name === "") title = "Nueva sede";
    return title;
  };

  return (
    <SedeForm
      setSedeKeyValue={setSedeKeyValue}
      saveSede={isEdit ? updateSedeHandler : createNewSedeHandler}
      hideSpecificAlert={hideSpecificAlert}
      sede={sede}
      loadingFetchSede={loading.sede.loadSede}
      loadingSaveSede={
        isEdit ? loading.sede.updateSede : loading.sede.createNewSede
      }
      loadingAllSedeStadiums={loading.sede.loadAllSedeStadiums}
      loadingSaveStadiums={loading.stadium.updateStadium}
      saveButtonTitle={isEdit ? "Guardar" : "Crear"}
      panelTitle={panelTitle()}
      isEdit={isEdit}
      stadiums={stadiums}
      updateStadium={updateStadiumHandler}
      loadingSaveNewStadium={loading.stadium.saveNewStadium}
      createStadium={createStadiumHandler}
    />
  );
};

const mapState = (state) => ({
  sede: state.sede.single,
  loading: state.loading,
  stadiums: state.stadium.allSede,
});

const mapDispatch = (dispatch) => ({
  setSedeKeyValue: (key, value) =>
    dispatch(sedeActions.setSedeKeyValue(key, value)),
  clearSingleSede: () => dispatch(sedeActions.clearSingleSede()),
  createNewSede: (organizerId) =>
    dispatch(sedeThunks.createNewSede(organizerId)),
  hideSpecificAlert: () => dispatch(alertActions.hideSpecificAlert()),
  loadSede: (organizerId, sedeId) =>
    dispatch(sedeThunks.loadSede(organizerId, sedeId)),
  updateSede: (organizerId, sedeId, image) =>
    dispatch(sedeThunks.updateSede(organizerId, sedeId, image)),
  loadAllSedeStadiums: (organizerId, sedeId) =>
    dispatch(stadiumThunks.loadAllSedeStadiums(organizerId, sedeId)),
  clearAllSedeStadiums: () => dispatch(stadiumActions.clearAllSedeStadiums()),
  setStadiumKeyValue: (key, value) =>
    dispatch(stadiumActions.setStadiumKeyValue(key, value)),
  updateStadium: (organizerId, sedeId, stadiumId, stad) =>
    dispatch(stadiumThunks.updateStadium(organizerId, sedeId, stadiumId, stad)),
  createStadium: (organizerId, sedeId, stadiumName) =>
    dispatch(stadiumThunks.createStadium(organizerId, sedeId, stadiumName)),
});

export default withRouter(connect(mapState, mapDispatch)(SedeFormContainer));
