import httpClient from "../../http/httpClient";
import FileDownload  from 'js-file-download'
import handler, { statusCodes } from './handler'
import { dispatch } from "react-redux"

const fileDownloader = async (relativeURL, filename = 'reporte.csv') => {
    const [error, result] = await httpClient(relativeURL).get('', { responseType: 'blob' })

    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                [statusCodes.BAD_REQUEST]: {
                    msg: 'Ocurrió un error en la descarga. Por favor intenta de nuevo.'
                },
            }
        }))
    }

    FileDownload(result, filename);
}

export default fileDownloader