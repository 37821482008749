import React from "react";
import WorksheetWrapper from "./components/WorksheetWrapper";
import calculateMinsDiff from "../../utils/functions/calculateMinsDiff";
import moment from "moment";
import mapIncidenceTypes from "../../utils/functions/mapIncidenceTypes";
import { history } from "../../redux/store";
import keepOrganizer from "../../utils/functions/keepOrganizer";

const Worksheet = ({
  showIncidences,
  createNewIncidence,
  visitingTeam,
  localTeam,
  showStatusOptions,
  loadingFetchMatch,
  incidences,
  incidenceCategories,
  location,
  fromMatch,
  setFromMatch,
  fromTournament,
  setFromTournament,
  organizerId,
  tournamentId,
}) => {
  const countGoals = () => {
    let localGoals = 0;
    let visitingGoals = 0;
    const goalRegex = /goal/i;
    const goalIncidences = incidences.filter(
      (incidence) =>
        goalRegex.test(incidence.type) && incidence.type !== "DefPenaltyGoal"
    );
    goalIncidences.forEach((incidence) => {
      const player = incidence.player;
      const isLocalPlayer = localTeam.checkedInPlayers.find(
        (elem) => elem.id === player.id
      );
      if (isLocalPlayer) {
        localGoals++;
        return;
      }
      const isVisitingPlayer = visitingTeam.checkedInPlayers.find(
        (elem) => elem.id === player.id
      );
      if (isVisitingPlayer) {
        visitingGoals++;
        return;
      }
    });
    return [localGoals, visitingGoals];
  };

  const getIncidenceTime = () => {
    const datetime = moment();
    const timeIncidences = incidences.filter((incidence) =>
      /time/i.test(incidence.type)
    );
    const maxIncidence = timeIncidences.reduce(
      (acc, incidence) => {
        const diffAcc = calculateMinsDiff(datetime, acc.datetime);
        const diffCurrent = calculateMinsDiff(datetime, incidence.datetime);
        if (diffAcc < diffCurrent) return acc;
        return incidence;
      },
      { datetime: "" }
    );
    if (!maxIncidence.datetime) return "Sin Comenzar";
    return mapIncidenceTypes(maxIncidence.type, incidenceCategories);
  };

  return (
    <WorksheetWrapper>
      {loadingFetchMatch && (
        <div className="loader-screen">
          <span className="loader">
            <i className="fa fa-spinner fa-spin"></i>
          </span>
        </div>
      )}
      <span
        id="go-to-tournament"
        className="expand left"
        onClick={() => {
          if (fromMatch) {
            history.push(`/organizers/${organizerId}/matches`);
            setFromMatch(false);
          }
          if (fromTournament) {
            history.push(
              `/organizers/${organizerId}/tournaments/${tournamentId}/edit`
            );
            setFromTournament(false);
          }
        }}
      >
        <i className="fa fa-arrow-left"></i>
      </span>
      <div
        className="teams-divider"
        onClick={() =>
          createNewIncidence(localTeam.lineupId, localTeam.id, localTeam.name)
        }
      >
        <span className="team">{localTeam.name}</span>
      </div>
      <div
        className="teams-divider"
        onClick={() =>
          createNewIncidence(
            visitingTeam.lineupId,
            visitingTeam.id,
            visitingTeam.name
          )
        }
      >
        <span className="team">{visitingTeam.name}</span>
      </div>
      <div className="results-table" onClick={showStatusOptions}>
        <span className="marker-circle pull-left">
          {localTeam?.incidencesSummary?.goals}
        </span>
        <span className="incidence-status">{getIncidenceTime()}</span>
        <span className="marker-circle pull-right">
          {visitingTeam?.incidencesSummary?.goals}
        </span>
      </div>
      <div className="incidences-button" onClick={showIncidences}>
        ver incidencias
      </div>
    </WorksheetWrapper>
  );
};

export default Worksheet;
