import React, { useEffect, useState } from "react";
import Button from "../../../../utils/components/Button";
import Table from "../../../../utils/components/Table";
import SanctionModalContainer from "../sanctions/SanctionModalContainer";
import CardDetailModal from "./CardDetailModal";
import moment from "moment";
import * as env from "../../../../config/env";

const CardsList = ({
  cards,
  typeCard,
  loadingFetchRedCards,
  organizerId,
  tournamentId,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [showSanctionModal, setShowSanctionModal] = useState(false);
  const [showRedCardDetailModal, setShowRedCardDetailModal] = useState(false);
  const [redCardSelected, setRedCardSelected] = useState({
    player: {},
    datetime: "",
    comment: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [playerSelected, setPlayerSeleted] = useState("");
  const [teamSelected, setTeamSeleted] = useState();
  const [yellowCardCounts, setYellowCardCounts] = useState({});

  useEffect(() => {
    if (typeCard === "YellowCard") {
      const updatedCounts = {};

      cards.forEach((card) => {
        const playerDocument = card.player.document.trim().toLowerCase();

        if (updatedCounts[playerDocument]) {
          updatedCounts[playerDocument]++;
        } else {
          updatedCounts[playerDocument] = 1;
        }
      });

      setYellowCardCounts(updatedCounts);
    }
  }, [typeCard, cards]);

  const getFilteredCards = () => {
    return cards.filter((card) => {
      const foundPlayerDocument =
        card.player.document
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      const foundPlayerName =
        (card.player.firstName + " " + card.player.lastName)
          .trim()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1;
      const foundDatetime =
        moment(card.datetime)
          .utc()
          .format(env.DATE_FORMAT)
          .indexOf(searchValue) !== -1;

      return foundPlayerDocument || foundPlayerName || foundDatetime;
    });
  };

  const addCard = (cards, yellowCardCounts) =>
    cards.map((card) => {
      const playerDocument = card.player.document.trim().toLowerCase();
      const yellowCardCount = yellowCardCounts[playerDocument] || 0;

      return {
        ...card,
        fullName: card.player.firstName + " " + card.player.lastName,
        dateformat: moment(card.datetime).utc().format(env.DATE_FORMAT),
        yellowCardCount:
          typeCard === "YellowCard" ? yellowCardCount : undefined,
        actions: (
          <div className="btn-separated-group">
            {typeCard !== "YellowCard" && (
              <Button
                title={<i className={`fa fa-plus`}></i>}
                className="btn btn-primary"
                onClick={() => {
                  setIsEditing(false);
                  setTeamSeleted(card.lineUp.team.id);
                  setPlayerSeleted(card.player.id);
                  setShowSanctionModal(true);
                }}
                withTooltip={true}
                tooltipTitle="Nueva sanción"
              />
            )}
            <Button
              title={<i className={`fa fa-eye`}></i>}
              className="btn btn-warning"
              onClick={() => {
                setRedCardSelected(card);
                setShowRedCardDetailModal(true);
              }}
              withTooltip={true}
              tooltipTitle="Detalle de tarjeta"
            />
          </div>
        ),
      };
    });

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        {typeCard === "RedCard" ? "Tarjetas roja" : "Tarjetas Amarillas"}
      </div>
      <div className="panel-body">
        <input
          type="text"
          className="form-control"
          placeholder={
            typeCard === "RedCard" ? "Tarjetas roja" : "Tarjetas Amarillas"
          }
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Table
          loading={loadingFetchRedCards}
          headers={[
            {
              label: "Documento",
              ref: "player.document",
              className: "col-sm-4 col-lg-2 hidden-xs",
            },
            {
              label: "Doc.",
              ref: "player.document",
              className: "col-xs-2 visible-xs",
            },
            {
              label: "Nombre",
              ref: "fullName",
              className: "col-lg-3 visible-lg",
            },
            {
              label: "Equipo",
              ref: "lineUp.team.name",
              className: "col-lg-3 col-xs-2",
            },
            {
              label: typeCard === "YellowCard" ? "Cantidad" : "Fecha",
              ref: typeCard === "YellowCard" ? "yellowCardCount" : "dateformat",
              className: "col-xs-4 col-lg-2",
            },
            {
              label: "Acciones",
              ref: "actions",
              className: "col-xs-4 col-lg-4 text-center",
            },
          ]}
          rows={addCard(getFilteredCards(), yellowCardCounts)}
          notFoundMessage={
            typeCard === "RedCard" ? "Tarjetas roja" : "Tarjetas Amarillas"
          }
        />
        <SanctionModalContainer
          show={showSanctionModal}
          handleShow={setShowSanctionModal}
          isEditing={isEditing}
          organizerId={organizerId}
          tournamentId={tournamentId}
          playerSelected={playerSelected}
          teamSelected={teamSelected}
        />
        <CardDetailModal
          show={showRedCardDetailModal}
          handleShow={setShowRedCardDetailModal}
          redCard={redCardSelected}
        />
      </div>
    </div>
  );
};

export default CardsList;
