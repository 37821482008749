"use-strict";

import only from "../../../utils/functions/only";

export default {
  selected: "",
  all: [],
  single: {
    username: "",
    firstName: "",
    nationality: "",
    email: "",
    gender: "M",
    lastName: "",
    document: "",
    username: "",
    isVerified: false,
    deleted: false,
    birthday: "",
    hasFansIdConfigurated: false,
    skillfulFoot: "",
    position: "",
    sport: "",
    weight: "",
    height: "",
    organizations: [],
    admin: "",
  },
  countries: [],
  organizers: [],
};

export const model = (entity) =>
  only(entity, [
    "id",
    "username",
    "roles",
    "email",
    "phone",
    "firstName",
    "lastName",
    "media",
    "facebookId",
    "emailVerified",
    "phoneVerified",
    "organizations",
    "birthday",
    "isVerified",
    "deleted",
    "document",
    "gender",
    "height",
    "weight",
    "skillfulFoot",
    "sport",
    "position",
    "nationality",
    "hasFansIdConfigurated",
    "twitterId",
    "instagramId",
    "linkedinId",
  ]);
