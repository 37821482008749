'use-strict'

import only from "../../../utils/functions/only"

export default {
    all: [],
    single: {
        firstName: '',
        lastName: '',
        document: '',
        medicalRecordExp: '',
    }
}

export const model = (entity) => only(entity, ['id','firstName','lastName','document', 'media', 'user', 'medicalRecordExp'])