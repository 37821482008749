"use-strict";

import httpClient from "../../../http/httpClient";

export default {
  loadAllEvents: (organizerId) => httpClient("").get(`/events/${organizerId}`),
  loadOneEvent: (eventId) => httpClient("").get(`/eventsingles/${eventId}`),
  loadAllEventsCategory: () => httpClient("").get("/eventscategory"),
  loadAllEventType: () => httpClient("").get("/eventstypeall"),
  loadOneEventCategory: (eventsCategory) =>
    httpClient("").get(`/eventscategory/${eventsCategory}`),
  loadOneEventType: () => httpClient("").get(`/events/${eventsType}/type`),
  createEvent: (event) => httpClient("").post("/events", event),
  updateOneEvent: (eventId, event) =>
    httpClient("").patch(`/events/${eventId}`, event),
  deleteEvent: (eventId) => httpClient("").delete(`/events/${eventId}`),
  createParticipant: (participant) =>
    httpClient("").post(`/eventsusers`, participant),
  uploadImage: (eventId, image) =>
    httpClient("").post(`/events/${eventId}/images`, image),
  uploadImageBg: (eventId, imageBg) =>
    httpClient("").post(`/events/${eventId}/imagesbackgrounds`, imageBg),
  configEvent: (eventId, config) =>
    httpClient("").patch(`/eventsconfigs/${eventId}`, config),
  // createEventCategory: (eventCategory) =>  httpClient("").post("/eventscategories", eventCategory),
  // updateOneEventType: () => httpClient("").patch(`/events/${eventsType}/type`),
  // createEventType: (eventType) =>  httpClient("").post(`/eventstypes`, eventType),
};
