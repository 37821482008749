"use-strict";

export default {
  SET_ALL_CHECK_IN: "@@checkIn/SET_ALL_CHECK_IN",
  PUSH_NEW_CHECK_IN: "@@checkIn/PUSH_NEW_CHECK_IN",
  SET_SINGLE_CHECK_IN: "@@checkIn/SET_SINGLE_CHECK_IN",
  DELETE_CHECK_IN: "@@checkIn/DELETE_CHECK_IN",
  SET_CHECK_IN_KEY_VALUE: "@@checkIn/SET_CHECK_IN_KEY_VALUE",
  CLEAR_ALL_CHECK_IN: "@@checkIn/CLEAR_ALL_CHECK_IN",
  CLEAR_SINGLE_CHECK_IN: "@@checkIn/CLEAR_SINGLE_CHECK_IN",
};
