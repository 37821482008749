"use-strict";

import constants from "./eventsConstants";

export default {
  setAllEvents: (payload) => ({
    type: constants.SET_ALL_EVENTS,
    payload,
  }),
  setAllEventsCategory: (payload) => ({
    type: constants.SET_ALL_EVENTS_CATEGORY,
    payload,
  }),
  setAllEventsType: (payload) => ({
    type: constants.SET_ALL_EVENTS_TYPE,
    payload,
  }),
  setSingleEvent: (payload) => ({
    type: constants.SET_SINGLE_EVENT,
    payload,
  }),
  setEventKeyValue: (key, value) => ({
    type: constants.SET_EVENT_KEY_VALUE,
    payload: { key, value },
  }),
  deleteEvent: (eventId) => ({
    type: constants.DELETE_EVENT,
    eventId,
  }),
  clearAllEvents: () => ({
    type: constants.CLEAR_ALL_EVENTS,
  }),
  clearSingleEvent: () => ({
    type: constants.CLEAR_SINGLE_EVENT,
  }),
};
