"use-strict";

export default {
  all: [],
  single: {
    date: "",
    datetime: "",
    localTeam: "",
    stadium: "",
    group_id: [],
    visitingTeam: "",
    comments: "",
    pointsCount: true,
  },
  organizerMatches: [],
};
