import React from "react";
import { connect } from "react-redux";
import { smoothlyMenu } from "../template/Helpers";
import OrganizerSwitch from "../../app/organizers/OrganizerSwitch";
import "./template.scss";
import organizerActions from "../../redux/reducers/organizerReducer/organizerActions";

class TopHeader extends React.Component {
  toggleNavigation(e) {
    e.preventDefault();
    $("body").toggleClass("mini-navbar").addClass("body-small");
    smoothlyMenu();
  }

  render() {
    return (
      <div id="top-header" className="row border-bottom">
        <nav
          className="navbar navbar-static-top white-bg"
          role="navigation"
          style={{ marginBottom: 0 }}
        >
          <div className="navbar-header">
            <a
              className="navbar-minimalize minimalize-styl-2 btn btn-primary "
              onClick={this.toggleNavigation}
              href="#"
            >
              <i className="fa fa-bars"></i>{" "}
            </a>
          </div>
          <div className="navbar-organizers-switch">
            <OrganizerSwitch
              organizers={this.props.organizers}
              setSelectedOrganizer={this.props.setSelectedOrganizer}
            />
          </div>
          <ul className="nav navbar-top-links navbar-right">
            <li className="pull-right">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.logout();
                }}
              >
                <i className="fa fa-sign-out"></i>{" "}
                <span className="hidden-xs">Cerrar Sesión</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    organizers: state.organizer.all,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedOrganizer: (organizerId) =>
      dispatch(organizerActions.setSelectedOrganizer(organizerId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
