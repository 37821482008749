import React, { useState, useEffect } from "react";
import Button from "../../../../utils/components/Button";
import MyModal from "../../../../utils/components/Modal";
import DatetimePicker from "../../../../utils/components/DatetimePicker";
import { CopyToClipboard } from "react-copy-to-clipboard";

const DateModal = ({
  show,
  handleShow,
  saveDate,
  date,
  setDateKeyValue,
  loading,
  modalTitle,
  buttomSaveTitle,
  isEditing,
  copyToClipboardStateHandle,
}) => {
  const [nameError, setNameError] = useState(false);
  const [datetimeError, setDatetimeError] = useState(false);

  const saveDateHandler = () => {
    let hasError = false;
    if (date.name === "") {
      setNameError(true);
      hasError = true;
    } else {
      setNameError(false);
    }

    if (date.datetime === "") {
      setDatetimeError(true);
      return;
    } else {
      setDatetimeError(false);
    }

    if (hasError) return;

    saveDate();
  };

  return (
    <MyModal
      show={show}
      title={modalTitle()}
      handleShow={handleShow}
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12">
            <div className={`form-group ${nameError && "has-error"}`}>
              <label className="col-md-3 col-sm-2 control-label">
                Nombre *{" "}
              </label>
              <div className="col-md-9 col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={date.name}
                  disabled={loading}
                  onChange={(e) => {
                    setDateKeyValue("name", e.target.value);
                  }}
                />
                {nameError && (
                  <span className="help-block">El nombre es requerido.</span>
                )}
              </div>
              <div className="clearfix m-b-1"></div>
            </div>
            <div className={`form-group ${datetimeError && "has-error"}`}>
              <label className="col-md-3 col-sm-2 control-label">
                Fecha *{" "}
              </label>
              <div className="col-md-9 col-sm-10">
                <DatetimePicker
                  onChange={(value) => setDateKeyValue("datetime", value)}
                  showTime={false}
                  value={date.datetime}
                  disable={loading}
                />
                {datetimeError && (
                  <span className="help-block">La fecha es requerida.</span>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      buttons={
        <>
          {isEditing && (
            <CopyToClipboard
              text={date.id}
              onCopy={() => copyToClipboardStateHandle(true)}
            >
              <Button title="Copiar ID" className="btn btn-light" />
            </CopyToClipboard>
          )}
          <Button
            title={buttomSaveTitle()}
            className="btn btn-primary"
            onClick={saveDateHandler}
          />
        </>
      }
    />
  );
};

export default DateModal;
