import React, { useState, useEffect } from "react";
import WorksheetWrapper from "../components/WorksheetWrapper";
import "../worksheet.scss";
import Clock from "../components/Clock";
import getTeamType from "../../../utils/functions/getTeamType";
import moment from "moment-timezone";
import Calendar from "../../../utils/components/Calendar/Calendar";

const WorksheetCreateIncidence = ({
  allowPlayer,
  allowSubstitute,
  playerTitle,
  substituteTitle,
  createNewIncidence,
  loadingCreateIncidence,
  teamId,
  match,
  showFloatingAlert,
}) => {
  const [player, setPlayer] = useState("");
  const [playerError, setPlayerError] = useState(null);
  const [substitute, setSubstitute] = useState("");
  const [substituteError, setSubstituteError] = useState(null);
  const [hour, setHour] = useState("");
  const [hourError, setHourError] = useState(null);
  const [minutes, setMinutes] = useState("");
  const [minutesError, setMinutesError] = useState(null);
  const [comment, setComment] = useState("");
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setTime();
  }, []);

  const selectPlayerInput = () => {
    const teamType = getTeamType(match, teamId);

    const truncate = (str) => {
      return str?.length > 10 ? str.substring(0, 10) + "..." : str;
    };

    const renderPlayerOption = (player) => (
      <option key={player.id} value={player.id}>
        #{player.shirtNumber} {truncate(player.firstName)}{" "}
        {truncate(player.lastName)}
      </option>
    );

    if (teamType === "localTeam") {
      return match.localTeam.checkedInPlayers.map((player) =>
        renderPlayerOption(player)
      );
    } else {
      return match.visitingTeam.checkedInPlayers.map((player) =>
        renderPlayerOption(player)
      );
    }
  };

  const setTime = () => {
    const date = new Date();
    setDate(date);
    setHour(date.getHours().toString().padStart(2, "0"));
    setMinutes(date.getMinutes().toString().padStart(2, "0"));
  };

  const acceptHandler = () => {
    const errorClass = "with-error";
    let hasError = false;

    if (allowPlayer) {
      if (player === "") {
        hasError = true;
        setPlayerError(errorClass);
      } else setPlayerError(null);
    }

    if (allowSubstitute) {
      if (substitute === "") {
        hasError = true;
        setSubstituteError(errorClass);
      } else setSubstituteError(null);
    }

    if (hour === "" || isNaN(Number(hour))) {
      hasError = true;
      setHourError(errorClass);
    } else setHourError(null);

    if (minutes === "" || isNaN(Number(minutes))) {
      hasError = true;
      setMinutesError(errorClass);
    } else setMinutesError(null);

    if (hasError) return;

    let playerId;
    let substituteId;
    if (teamId) {
      const teamType = getTeamType(match, teamId);
      const checkedInPlayers = match[teamType].checkedInPlayers;

      const foundPlayer = checkedInPlayers.find((elem) => elem.id === player);
      const foundSubstitute = checkedInPlayers.find(
        (elem) => elem.id === substitute
      );

      let playersError = false;
      let playersMessage = "";
      if (allowPlayer) {
        if (foundPlayer) {
          playerId = foundPlayer.id;
        } else {
          playersMessage += `No se encontró en la alineación el jugador con camiseta: ${foundPlayer.shirtNumber}`;
          playersError = true;
        }
      }
      if (allowSubstitute) {
        if (foundSubstitute.id === foundPlayer.id) {
          playersMessage += `<br><br>No se puede reemplazar al mismo jugador seleccionado.`;
          playersError = true;
        } else if (foundSubstitute.id !== foundPlayer.id) {
          substituteId = foundSubstitute.id;
        } else {
          playersMessage += `<br><br>No se encontró en la alineación el jugador con camiseta: ${foundSubstitute.shirtNumber}`;
          playersError = true;
        }
      }
      if (playersError) {
        showFloatingAlert(playersMessage, "danger", 5000);
        return;
      }
    }

    const datetime = `${moment(date).format("YYYY-MM-DD")}T${hour.padStart(
      2,
      "00"
    )}:${minutes.padStart(2, "00")}`;

    createNewIncidence(datetime, playerId, substituteId, comment);

    setPlayer("");
    setSubstitute("");
    setTime();
    setComment("");
  };

  const showPlayerRow = () => allowPlayer || allowSubstitute;

  return (
    <WorksheetWrapper withGoBack>
      <div className="row incidence-types">
        {showPlayerRow() ? (
          <div
            className="create-incidence-row col-md-12"
            style={{ flexDirection: "row" }}
          >
            {allowPlayer && (
              <select
                style={{ maxWidth: "55rem" }}
                type="number"
                name="number"
                id="player"
                className={`incidence-player-number-input ${playerError}`}
                disabled={false}
                value={player}
                onChange={(e) => setPlayer(e.target.value)}
              >
                <option value="">{`# ${playerTitle}`}</option>
                {selectPlayerInput()}
              </select>
            )}
            {allowPlayer && allowSubstitute ? (
              <span className="exchange">
                <i className="fa fa-exchange"></i>
              </span>
            ) : null}
            {allowSubstitute && (
              <select
                style={{ maxWidth: "55rem" }}
                type="number"
                name="number"
                id="player"
                disabled={false}
                value={substitute}
                className={`incidence-player-number-input ${substituteError}`}
                placeholder={`# ${substituteTitle}`}
                onChange={(e) => setSubstitute(e.target.value)}
              >
                <option value="">{`# ${substituteTitle}`}</option>
                {selectPlayerInput()}
              </select>
            )}
          </div>
        ) : null}
        <div
          className="create-incidence-row col-md-12"
          style={{
            height: !showPlayerRow() ? "42%" : undefined,
          }}
        >
          <Calendar
            id="calendar"
            className="btn btn-primary"
            date={date}
            onChange={setDate}
          />
          <div className="incidence-clock">
            <Clock
              hour={hour}
              minutes={minutes}
              setHour={setHour}
              setMinutes={setMinutes}
              hourError={hourError}
              minutesError={minutesError}
            />
          </div>
        </div>
        <div
          className="create-incidence-row col-md-12"
          style={{
            height: !showPlayerRow() ? "42%" : undefined,
          }}
        >
          <textarea
            name="comment"
            id="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Comentarios..."
            className="incidence-comment-textarea"
          />
        </div>
        <div
          className="create-incidence-button col-md-12"
          onClick={acceptHandler}
        >
          {loadingCreateIncidence ? (
            <i className="fa fa-spinner fa-spin"></i>
          ) : (
            "aceptar"
          )}
        </div>
      </div>
    </WorksheetWrapper>
  );
};

export default WorksheetCreateIncidence;
