"use-strict";

import constants from "./checkInConstants";
import initialState from "./checkInState";

const checkInReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_ALL_CHECK_IN: {
      return {
        ...state,
        all: action.payload,
      };
    }
    case constants.SET_SINGLE_CHECK_IN: {
      return {
        ...state,
        single: action.payload,
      };
    }
    case constants.SET_CHECK_IN_KEY_VALUE: {
      return {
        ...state,
        single: {
          ...state.single,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case constants.CLEAR_ALL_CHECK_IN: {
      return {
        ...state,
        all: initialState.all,
      };
    }
    case constants.DELETE_CHECK_IN: {
      const checkIns = [...state.all];
      const index = checkIns.findIndex(
        (checkIn) => checkIn.id === action.checkAccessId
      );
      checkIns.splice(index, 1);

      return {
        ...state,
        all: checkIns,
      };
    }
    case constants.PUSH_NEW_CHECK_IN: {
      const checkIns = [...state.all];
      checkIns.unshift(action.payload);
      return {
        ...state,
        all: checkIns,
      };
    }
    case constants.CLEAR_SINGLE_CHECK_IN: {
      return {
        ...state,
        single: initialState.single,
      };
    }
    default: {
      return state;
    }
  }
};

export default checkInReducer;
