"use-strict";

import only from "../../../utils/functions/only";

export default {
  all: [],
  single: {
    name: "",
    tier: "TIER_GOLD",
    image: {},
    backGroundPrimaryColor: "#262626",
    backGroundSecondaryColor: "#262626",
    backGroundTertiaryColor: "#707070",
    textPrimaryColor: "#03F4CF",
    textSecondaryColor: "#B9B9B9",
    textTertiaryColor: "#03F4CF",
    textQuaternaryColor: "#EDEDED",
    inputPrimaryColor: "#03F4CF",
    inputSecondaryColor: "#262626",
    textNextMatchCard: "",
    textBarCard: "",
    textCardsCard: "",
    textFairplayCard: "",
    textFixtureCard: "",
    textGoalersCard: "",
    textOutstandingCard: "",
    textPositionsCard: "",
    textSuspendedCard: "",
    textTeamCard: "",
    enabledPositionsCard: true,
    enabledGoalersCard: true,
    enabledSuspendedCard: true,
    enabledOutstandingCard: true,
    enabledFairplayCard: true,
    enabledCardsCard: true,
    enabledTeamCard: true,
    enabledBarCard: true,
    enabledNextMatchCard: true,
    enabledFixtureCard: true,
    events_enabled: false,
    priorityFixtureCard: 0,
    priorityPositionsCard: 0,
    priorityGoalersCard: 0,
    prioritySuspendedCard: 0,
    priorityOutstandingCard: 0,
    priorityFairplayCard: 0,
    priorityCardsCard: 0,
    priorityBarCard: 0,
    priorityNextMatchCard: 0,
    priorityTeamCard: 0,
    sizeFixtureCard: "",
    sizePositionsCard: "",
    sizeGoalersCard: "",
    sizeSuspendedCard: "",
    sizeOutstandingCard: "",
    sizeFairplayCard: "",
    sizeCardsCard: "",
    sizeBarCard: "",
    sizeNextMatchCard: "",
    email: "",
    facebookUrl: "",
    twitterUrl: "",
    whatsapp: "",
    rulesPdfUrl: "",
    instagramUrl: "",
    loginLogoEnabled: false,
  },
  selected: "",
};

export const patchModel = (entity) =>
  only(entity, [
    "admins",
    "backGroundPrimaryColor",
    "backGroundSecondaryColor",
    "backGroundTertiaryColor",
    "email",
    "facebookUrl",
    "inputPrimaryColor",
    "inputSecondaryColor",
    "instagramUrl",
    "name",
    "textPrimaryColor",
    "textQuaternaryColor",
    "textSecondaryColor",
    "textTertiaryColor",
    "textNextMatchCard",
    "textBarCard",
    "textCardsCard",
    "textFairplayCard",
    "textFixtureCard",
    "textGoalersCard",
    "textOutstandingCard",
    "textPositionsCard",
    "textSuspendedCard",
    "textTeamCard",
    "enabledPositionsCard",
    "enabledGoalersCard",
    "enabledSuspendedCard",
    "enabledOutstandingCard",
    "enabledFairplayCard",
    "enabledCardsCard",
    "enabledTeamCard",
    "enabledBarCard",
    "enabledNextMatchCard",
    "enabledFixtureCard",
    "events_enabled",
    "ad1Enabled",
    "ad2Enabled",
    "ad3Enabled",
    "priorityFixtureCard",
    "priorityPositionsCard",
    "priorityGoalersCard",
    "prioritySuspendedCard",
    "priorityOutstandingCard",
    "priorityFairplayCard",
    "priorityCardsCard",
    "priorityBarCard",
    "priorityNextMatchCard",
    "priorityTeamCard",
    "ad1Priority",
    "ad2Priority",
    "ad3Priority",
    "ad1Link",
    "ad2Link",
    "ad3Link",
    "ad1Available",
    "ad2Available",
    "ad3Available",
    "sizeFixtureCard",
    "sizePositionsCard",
    "sizeGoalersCard",
    "sizeSuspendedCard",
    "sizeOutstandingCard",
    "sizeFairplayCard",
    "sizeCardsCard",
    "sizeBarCard",
    "sizeTeamCard",
    "ad1Size",
    "ad2Size",
    "ad3Size",
    "tier",
    "twitterUrl",
    "whatsapp",
    "rulesPdfUrl",
    "loginLogoEnabled",
  ]);
