import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import Button from "../../utils/components/Button";
import Table from "../../utils/components/Table";
import { history } from "../../redux/store";
import keepOrganizer from "../../utils/functions/keepOrganizer";

const UserList = ({
  deleteUser,
  users,
  loadingFetchUsers,
  loadingDeleteUser,
  downloadHandler,
  countries,
  organizers,
  defaultPage,
  defaultPageSize,
  onRefreshUsers,
  loadAllUsers,
}) => {
  const DEBOUNCE_DELAY = 700;

  const [searchValue, setSearchValue] = useState("");
  const [searchGenderValue, setSearchGenderValue] = useState("");
  const [searchFansIdValue, setSearchFansIdValue] = useState("");
  const [searchDeletedValue, setSearchDeletedValue] = useState("");
  const [searchOrganizationsValue, setSearchOrganizationsValue] = useState("");
  const [searchNationalityValue, setSearchNationalityValue] = useState("");
  const [searchAdminValue, setSearchAdminValue] = useState("");
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);

  useEffect(() => {
    const timer = setTimeout(() => {
      loadAllUsers(
        currentPage,
        currentPageSize,
        searchGenderValue,
        searchDeletedValue,
        searchFansIdValue,
        searchNationalityValue,
        searchOrganizationsValue,
        searchAdminValue,
        searchValue
      );
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timer);
  }, [
    currentPage,
    currentPageSize,
    searchGenderValue,
    searchDeletedValue,
    searchFansIdValue,
    searchNationalityValue,
    searchOrganizationsValue,
    searchAdminValue,
    searchValue,
  ]);

  const getFilteredUsers = () => users;

  // const getFilteredUsers = () =>
  //   users?.filter((user) => {
  //     const normalizedSearchValue = searchValue.toLowerCase();

  //     const matchesSearchValue =
  //       user.firstName?.toLowerCase().includes(normalizedSearchValue) ||
  //       user.lastName?.toLowerCase().includes(normalizedSearchValue) ||
  //       user.username?.toLowerCase().includes(normalizedSearchValue) ||
  //       user.document?.toLowerCase().includes(normalizedSearchValue) ||
  //       user.email?.toLowerCase().includes(normalizedSearchValue);

  //     const matchesGender =
  //       searchGenderValue === "" || user.gender === searchGenderValue;
  //     const matchesDeleted =
  //       searchDeletedValue === "" ||
  //       (user.deleted !== undefined &&
  //         user.deleted === (searchDeletedValue === "1"));

  //     const userHasFansId = user.hasFansIdConfigurated;
  //     const searchFansIdIsSet = searchFansIdValue === "1";
  //     const matchesFansId =
  //       searchFansIdValue === "" || userHasFansId === searchFansIdIsSet;

  //     const matchesOrganizations =
  //       searchOrganizationsValue === "" ||
  //       (Array.isArray(user.organizations) &&
  //         user.organizations.some((org) => {
  //           if (typeof org === "string") {
  //             return org.includes(searchOrganizationsValue);
  //           } else if (typeof org === "object") {
  //           }
  //           return false;
  //         }));

  //     const matchesAdmin =
  //       searchAdminValue === "" ||
  //       (user.admin !== undefined && user.admin === searchAdminValue);

  //     const matchesNationality =
  //       searchNationalityValue === "" ||
  //       (user.nationality !== undefined &&
  //         user.nationality === searchNationalityValue);

  //     return (
  //       matchesSearchValue &&
  //       matchesGender &&
  //       matchesDeleted &&
  //       matchesFansId &&
  //       matchesOrganizations &&
  //       matchesAdmin &&
  //       matchesNationality
  //     );
  //   });

  const addActions = (users) =>
    users?.map((user) => {
      let fi = "No";
      if (
        user.hasFansIdConfigurated &&
        (user.hasFansIdConfigurated == "1" ||
          user.hasFansIdConfigurated == true)
      )
        fi = "Si";
      return {
        ...user,
        fansId: <span key={`fansId_${user.id}`}>{fi}</span>,
        actions: (
          <div key={`fansId_${user.id}`} className="btn-separated-group">
            <Button
              className="btn btn-warning"
              title={<i className="fa fa-pencil"></i>}
              withTooltip={true}
              tooltipTitle="Editar"
              onClick={() =>
                history.push(keepOrganizer(`/users/${user.id}/edit`))
              }
            />
            <Button
              className="btn btn-danger"
              title={<i className="fa fa-trash"></i>}
              withTooltip={true}
              tooltipTitle="Eliminar"
              onClick={() => deleteUser(user.id)}
              loading={loadingDeleteUser}
              withConfirmation={true}
              confirmationTitle="Eliminar user"
              onConfirmText="Confirmar"
              onCancelText="Cancelar"
              onCancelClass="btn-danger pull-left"
              confirmationBody="¿Estás seguro que quieres eliminar esta user?"
            />
          </div>
        ),
      };
    });

  return (
    <>
      <Breadcrumbs
        title="Users"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Users",
            active: true,
          },
        ]}
      />

      {/* <div
                // style={{ display: "flex", flexDirection: "column", gap: 5 }}
                className="col-md-4 col-xs-3"
              >
                <Button
                  className="btn btn-primary pull-right"
                  title={
                    <span>
                      <i className="fa fa-download"></i> Descargar
                    </span>
                  }
                  withTooltip={true}
                  tooltipTitle="Descargar users"
                  onClick={(e) =>
                    downloadHandler(
                      searchValue,
                      searchGenderValue,
                      searchFansIdValue,
                      searchOrganizationsValue,
                      searchDeletedValue,
                      searchNationalityValue
                    )
                  }
                />
                <Button
                  className="btn btn-info pull-right"
                  title={
                    <span>
                      <i className="fa fa-plus-circle"></i> Nuevo
                    </span>
                  }
                  onClick={() => history.push("/users/create")}
                />
              </div> */}
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div className="ibox-title">
              <div className="row" style={{ marginBottom: "2rem" }}>
                <div
                  className="col-md-4 col-xs-3"
                  style={{ display: "flex", gap: 15 }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar users..."
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  {/* <Button
                    className="btn btn-primary pull-right"
                    title={
                      <span>
                        <i className="fa fa-search"></i> Buscar
                      </span>
                    }
                    onClick={() =>
                      loadAllUsers(
                        currentPage,
                        currentPageSize,
                        searchGenderValue,
                        searchDeletedValue,
                        searchFansIdValue,
                        searchNationalityValue,
                        searchOrganizationsValue,
                        searchAdminValue,
                        searchValue
                      )
                    }
                  /> */}
                </div>
                <div className="col-md-3 col-xs-3">
                  <select
                    className="form-control"
                    onChange={(e) => setSearchGenderValue(e.target.value)}
                  >
                    <option value="">Seleccionar género</option>
                    <option>M</option>
                    <option>F</option>
                  </select>
                </div>
                <div className="col-md-3 col-xs-3">
                  <select
                    className="form-control"
                    onChange={(e) => setSearchFansIdValue(e.target.value)}
                  >
                    <option value="">Filtrar FansId</option>
                    <option value="1">Tiene</option>
                    <option value="0">No tiene</option>
                  </select>
                </div>
                <div style={{ marginRight: 20 }}>
                  <Button
                    className="btn btn-primary pull-right"
                    title={
                      <span>
                        <i className="fa fa-download"></i> Descargar
                      </span>
                    }
                    withTooltip={true}
                    tooltipTitle="Descargar users"
                    onClick={(e) =>
                      downloadHandler(
                        searchValue,
                        searchGenderValue,
                        searchFansIdValue,
                        searchOrganizationsValue,
                        searchDeletedValue,
                        searchNationalityValue
                      )
                    }
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-3 col-xs-3">
                  <select
                    className="form-control"
                    onChange={(e) =>
                      setSearchOrganizationsValue(e.target.value)
                    }
                  >
                    <option value="">Seleccionar organizaciones</option>
                    {organizers.map((e) => (
                      <option value={e.id} key={e.id}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-2 col-xs-3">
                  <select
                    className="form-control"
                    onChange={(e) => setSearchDeletedValue(e.target.value)}
                  >
                    <option value="">Filtrar eliminados</option>
                    <option value="1">Borrados</option>
                    <option value="0">No borrados</option>
                  </select>
                </div>

                <div className="col-md-3 col-xs-3">
                  <select
                    className="form-control"
                    onChange={(e) => setSearchNationalityValue(e.target.value)}
                  >
                    <option value="">Seleccionar nacionalidades</option>
                    {countries.map((e) => (
                      <option key={e.id}>{e.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-2 col-xs-3">
                  <select
                    className="form-control"
                    onChange={(e) => setSearchAdminValue(e.target.value)}
                  >
                    <option value="">Seleccionar administrador</option>
                    {organizers.map((e) => (
                      <option value={e.id} key={e.id}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ marginRight: 20 }}>
                  <Button
                    className="btn btn-info pull-right"
                    title={
                      <span style={{ padding: 13 }}>
                        <i className="fa fa-plus-circle"></i> Nuevo
                      </span>
                    }
                    onClick={() => history.push(keepOrganizer("/users/create"))}
                  />
                </div>
              </div>
            </div>
            <div className="ibox-content">
              <Table
                loading={loadingFetchUsers}
                headers={[
                  {
                    label: "Username",
                    ref: "username",
                    className: "col-sm-2",
                  },
                  {
                    label: "Email",
                    ref: "email",
                    className: "col-sm-2",
                  },
                  {
                    label: "Nombre",
                    ref: "firstName",
                    className: "col-sm-2",
                  },
                  {
                    label: "Apellido",
                    ref: "lastName",
                    className: "col-sm-2",
                  },
                  {
                    label: "FansId",
                    ref: "fansId",
                    className: "col-md-1",
                  },
                  {
                    label: "Acciones",
                    ref: "actions",
                    className: "col-sm-2 text-center",
                  },
                ]}
                rows={addActions(getFilteredUsers())}
                notFoundMessage="No se encontraron users..."
                itemsInPage={users.length}
                defaultPage={currentPage}
                defaultPageSize={currentPageSize}
                onChangePage={(page, pageSize) => {
                  setCurrentPage(page);
                  setCurrentPageSize(pageSize);
                  onRefreshUsers(page, pageSize);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
