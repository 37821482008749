import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import * as participantsThunks from "../../../redux/thunks/participantsThunks";
import * as lineupThunks from "../../../redux/thunks/lineupThunks";
import playerActions from "../../../redux/reducers/playerReducer/playerActions";
import participantsActions from "../../../redux/reducers/participantsReducer/participantsActions";
import { actions } from "../../../redux/reducers/alertReducer";
import MyModal from "../../../utils/components/Modal";
import Button from "../../../utils/components/Button";
import Table from "../../../utils/components/Table";

const TournamentModalContainer = ({
  show,
  handleShow,
  isEditing,
  participant,
  setIsEditing,
  eventId,
  loading,
  event,
  tournaments,
  loadAllLineups,
  organizerId,
  loadLineup,
  addTournaments,
}) => {
  const dispatch = useDispatch();

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTournamentId, setSelectedTournamentId] = useState("");
  const [selectedLineupId, setSelectedLineupId] = useState("");
  const [filteredTournaments, setFilteredTournaments] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedTournamentError, setSelectedTournamentError] = useState(false);

  const disable = () => loading.participants.addTournament;

  useEffect(() => {
    if (show) {
      setSelectedYear("");
      setSelectedCategory("");
      setSelectedTournamentId("");
      setSelectedLineupId("");
    }
  }, [show]);

  useEffect(() => {
    const filtered = tournaments.filter(
      (tournament) =>
        !tournament.archived &&
        (!selectedYear || tournament.year === parseInt(selectedYear))
    );
    setFilteredTournaments(filtered);
    const categories = Array.from(
      new Set(filtered.map((tournament) => tournament.category))
    );
    setFilteredCategories(categories);
  }, [selectedYear, tournaments]);

  useEffect(() => {
    if (selectedTournamentId) {
      loadAllLineups(organizerId, selectedTournamentId);
    }
    if (selectedLineupId) {
      loadLineup(organizerId, selectedTournamentId, selectedLineupId);
    }
  }, [selectedTournamentId, selectedLineupId, organizerId]);

  const handleSubmitTournament = () => {
    let hasError = false;

    if (selectedTournamentId === "") {
      setSelectedTournamentError(true);
      hasError === true;
    } else {
      setSelectedTournamentError(false);
      hasError === false;
    }

    if (hasError) return;

    addTournaments(selectedTournamentId, event.id, handleShow);
  };

  return (
    <MyModal
      show={show}
      title="Importar plantel de invitados"
      handleShow={handleShow}
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12">
            <div className={`alert alert-info`}>
              Podés agregar jugadores del equipo.
            </div>
            <div style={{ display: "flex", gap: 5 }} className={`form-group`}>
              <label className="col-md-5 col-sm-4 control-label">
                Seleccione un año *{" "}
              </label>
              <div className="col-md-7 col-sm-8">
                <select
                  name="type"
                  id="type"
                  className="form-control"
                  disabled={false}
                  value={selectedYear}
                  onChange={(e) => {
                    setSelectedYear(e.target.value);
                    setSelectedCategory("");
                    setSelectedTournamentId("");
                    setSelectedLineupId("");
                  }}
                >
                  <option value="">Seleccione</option>
                  {Array.from(
                    new Set(
                      tournaments
                        .filter((t) => !t.archived)
                        .map((tournament) => tournament.year)
                    )
                  ).map((year, key) => (
                    <option value={year} key={key + "year"}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div style={{ display: "flex", gap: 5 }} className={`form-group`}>
              <label className="col-md-5 col-sm-4 control-label">
                Seleccione una categoria *{" "}
              </label>
              <div className="col-md-7 col-sm-8">
                <select
                  name="type"
                  id="type"
                  className="form-control"
                  disabled={!selectedYear}
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                    setSelectedTournamentId("");
                    setSelectedLineupId("");
                  }}
                >
                  <option value="">Seleccione</option>
                  {filteredCategories.map((category, key) => (
                    <option value={category} key={key + "category"}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              style={{ display: "flex", gap: 5 }}
              className={`form-group  ${
                selectedTournamentError && "has-error"
              } `}
            >
              <label className="col-md-5 col-sm-4 control-label">
                Seleccione un torneo *{" "}
              </label>
              <div className="col-md-7 col-sm-8">
                <select
                  name="type"
                  id="type"
                  className="form-control"
                  disabled={!selectedYear || !selectedCategory}
                  value={selectedTournamentId}
                  onChange={(e) => {
                    setSelectedTournamentId(e.target.value);
                    setSelectedLineupId("");
                  }}
                >
                  <option value="">Seleccione</option>
                  {filteredTournaments
                    .filter(
                      (tournament) => tournament.category === selectedCategory
                    )
                    .map((tournament, key) => (
                      <option value={tournament.id} key={key + "tournament"}>
                        {tournament.name}
                      </option>
                    ))}
                </select>
                {selectedTournamentError && (
                  <span className="help-block">El torneo es requerido.</span>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      buttons={
        <Button
          title="Agregar"
          className="btn btn-primary"
          onClick={handleSubmitTournament}
          loading={disable()}
        />
      }
    />
  );
};

const mapState = (state) => ({
  participant: state.participant.single,
  loading: state.loading,
  lineups: state.lineup.all,
});

const mapDispatch = (dispatch) => ({
  loadAllLineups: (organizerId, tournamentId) =>
    dispatch(lineupThunks.loadAllLineups(organizerId, tournamentId)),
  loadLineup: (organizerId, tournamentId, lineupId) =>
    dispatch(lineupThunks.loadLineup(organizerId, tournamentId, lineupId)),
  addTournaments: (tournamentId, eventId, handleShow) =>
    dispatch(
      participantsThunks.addTournaments(tournamentId, eventId, handleShow)
    ),
});

export default withRouter(
  connect(mapState, mapDispatch)(TournamentModalContainer)
);
