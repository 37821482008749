import React, { useState, useEffect } from 'react'
import Button from '../../../../utils/components/Button'
import MyModal from '../../../../utils/components/Modal'

const LineUpModal = ({
    show,
    handleShow,
    teams,
    createNewLineup
}) => {

    const [teamError, setTeamError] = useState(false)
    const [teamId, setTeamId] = useState('')

    useEffect(() => {
        setTeamId('')
    }, [show])

    const createNewLineupHandler = () => {
        if (teamId === '') {
            setTeamError(true)
            return
        } else {
            setTeamError(false)
        }
        createNewLineup(teamId)
        handleShow(false)
    }

    return (
        <MyModal
            show={show}
            title='Nuevo lineup'
            handleShow={handleShow}
            backdrop='static'
            body={
                <div className="row">
                    <div className="col-md-12">
                        <div className={`alert alert-info`}>
                            Selecciona un equipo para poder crear un lineup de jugadores
                        </div>
                        <div className={`form-group ${teamError && 'has-error'}`}>
                            <label className="col-md-3 col-sm-2 control-label">Equipo * </label>
                            <div className="col-md-9 col-sm-10">
                                <select
                                    name="type"
                                    id="type"
                                    className="form-control"
                                    disabled={false}
                                    value={teamId}
                                    onChange={(e) => {
                                        setTeamId(e.target.value)
                                    }}
                                >
                                    <option value="">Seleccione</option>
                                    {teams.map((team, key) => (
                                        <option value={team.id} key={key + 'team'}>{team.name}</option>
                                    ))}
                                </select>
                                {teamError && <span className="help-block">El equipo es requerido.</span>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            buttons={
                <Button
                    title="Siguiente"
                    className="btn btn-primary"
                    onClick={createNewLineupHandler}
                />
            }
        />
    )
}

export default LineUpModal