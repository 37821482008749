import React, { useState } from "react";
import MyModal from "../../../utils/components/Modal";
import Button from "../../../utils/components/Button";
import Switch from "react-switch";
import DatetimePicker from "../../../utils/components/DatetimePicker";

const ParticipantConfigModal = ({
  show,
  handleShow,
  setEventKeyValue,
  eventId,
  event,
  updateConfigEvent,
}) => {
  const [finishDateError, setFinishDateError] = useState(false);
  const [inviteCantError, setInviteCantError] = useState(false);

  const handleSubmitConfig = () => {
    let hasError = false;

    if (event.event_invite_cant === 0 || event.event_invite_cant === "") {
      hasError = true;
      setInviteCantError(true);
    } else {
      setInviteCantError(false);
    }

    if (event.event_date_finish === "") {
      hasError = true;
      setFinishDateError(true);
    } else {
      setFinishDateError(false);
    }

    if (hasError) return;

    const objConfigEvent = {
      event_inscription_finish: event.event_inscription_finish,
      event_invite_cant: event.event_invite_cant,
    };

    updateConfigEvent(eventId, objConfigEvent, handleShow);
  };

  return (
    <MyModal
      show={show}
      title="Gestión de invitaciones"
      handleShow={handleShow}
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12">
            <div className={`alert alert-info`}>
              Establecer limitaciones para la gestión de invitaciones
            </div>
          </div>

          <div className="col-md-12 m-b-1">
            <div className={`form-group ${finishDateError && "has-error"}`}>
              <label className="col-md-3 col-sm-2 control-label">
                Limite de tiempo *{" "}
              </label>
              <div className="col-md-9 col-sm-10">
                {/* <input
                  type="date"
                  className="form-control"
                  value={event.event_inscription_finish}
                  // disabled={disable()}
                  onChange={(e) => {
                    setEventKeyValue(
                      "event_inscription_finish",
                      e.target.value
                    );
                  }}
                /> */}
                <DatetimePicker
                  className="form-control m-b"
                  selected={new Date(event.event_inscription_finish)}
                  onChange={(date) => {
                    setEventKeyValue("event_inscription_finish", date);
                  }}
                  value={event.event_inscription_finish}
                  timeIntervals={15}
                  dateFormat="yyyy-MM-dd"
                  timeCaption="time"
                  // disable={disable()}
                />
                {finishDateError && (
                  <span className="help-block">La fecha es requerida.</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 m-b-1">
            <div className={`form-group ${inviteCantError && "has-error"} `}>
              <label className="col-md-3 col-sm-2 control-label">
                Limite de cantidad{" "}
              </label>
              <div className="col-md-9 col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={event.event_invite_cant}
                  // disabled={disable()}
                  onChange={(e) => {
                    setEventKeyValue("event_invite_cant", e.target.value);
                  }}
                />
                {inviteCantError && (
                  <span className="help-block">La cantidad es requerida.</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-12 m-b-1">
            <Button
              title={"Guardar"}
              className="btn btn-primary pull-right"
              onClick={() => {
                handleSubmitConfig();
              }}
              // loading={disable()}
            />
          </div>
        </div>
      }
    />
  );
};

export default ParticipantConfigModal;
