"use-strict";

import React, { useState, useEffect } from "react";
import def from "../functions/defaults";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import moment from "moment";
import * as env from "../../config/env";

import "react-datepicker/dist/react-datepicker.css";

const DatetimePicker = ({
  onChange = def.fn,
  showTime = def.false,
  value = def.str,
  disable = def.false,
  minDate,
}) => {
  const [dateTimeValue, setDatetimeValue] = useState("");

  registerLocale("es", es);

  useEffect(() => {
    setDatetimeValue(value ? new Date(moment.utc(value).toString()) : "");
  }, [value]);

  const onChangeHandle = (value) => {
    setDatetimeValue(value);

    if (showTime) onChange(moment(value).utc().format(env.DATETIME_FORMAT));
    else onChange(moment(value).add(21, "hours").utc().format(env.DATE_FORMAT));
  };

  return (
    <div style={{ width: "100%" }}>
      {showTime && (
        <DatePicker
          selected={dateTimeValue}
          onChange={(date) => onChangeHandle(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="Hora"
          dateFormat="yyyy-MM-dd HH:mm"
          locale="es"
          className="form-control"
          disabled={disable}
          minDate={minDate}
        />
      )}
      {!showTime && (
        <DatePicker
          selected={dateTimeValue}
          onChange={(date) => onChangeHandle(date)}
          dateFormat="yyyy-MM-dd"
          locale="es"
          className="form-control"
          disabled={disable}
          minDate={minDate}
        />
      )}
    </div>
  );
};

export default DatetimePicker;
