"use-strict";

import constants from "./groupConstants";

export default {
  setAllGroups: (payload) => ({
    type: constants.SET_ALL_GROUPS,
    payload,
  }),
  setGroupKeyValue: (key, value) => ({
    type: constants.SET_GROUP_KEY_VALUE,
    payload: { key, value },
  }),
  pushNewGroup: (payload) => ({
    type: constants.PUSH_NEW_GROUP,
    payload,
  }),
  removeLineupsGroup: (payload) => ({
    type: constants.REMOVE_LINEUPS_GROUP,
    payload,
  }),
  deleteGroup: (groupId) => ({
    type: constants.DELETE_GROUP,
    groupId,
  }),
  clearAllGroups: () => ({
    type: constants.CLEAR_ALL_GROUPS,
  }),
  clearAllLineupsByGroups: () => ({
    type: constants.CLEAR_ALL_LINEUPS_BY_GROUPS,
  }),
  clearSingleGroup: () => ({
    type: constants.CLEAR_SINGLE_GROUP,
  }),
  setSingleGroup: (payload) => ({
    type: constants.SET_SINGLE_GROUP,
    payload,
  }),
  setLineupsByGroup: (payload) => ({
    type: constants.PUSH_LINEUPS_GROUP,
    payload,
  }),
  replaceGroup: (payload) => ({
    type: constants.REPLACE_GROUP,
    payload,
  }),
};
