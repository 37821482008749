import stadiumActions from "../reducers/stadiumReducer/stadiumActions";
import stadiumService from "../reducers/stadiumReducer/stadiumService";
import { setLoading } from "../reducers/loadingReducer";
import handler, { statusCodes } from "../../utils/functions/handler";
import without from "../../utils/functions/without";
import { actions as alertActions } from "../reducers/alertReducer";

export const loadAllStadiums = (organizerId) => async (dispatch) => {
  dispatch(setLoading.true("stadium.loadAllStadiums"));
  const [error, stadiums] = await stadiumService.loadAll(organizerId);
  dispatch(setLoading.false("stadium.loadAllStadiums"));
  if (error) {
    return dispatch(handler(error));
  }
  dispatch(stadiumActions.setAllStadiums(stadiums));
};
export const loadAllSedeStadiums =
  (organizerId, sedeId) => async (dispatch) => {
    dispatch(setLoading.true("sede.loadAllSedeStadiums"));
    const [error, stadiums] = await stadiumService.loadAllSede(
      organizerId,
      sedeId
    );
    dispatch(setLoading.false("sede.loadAllSedeStadiums"));
    if (error) {
      return dispatch(handler(error));
    }
    dispatch(stadiumActions.setAllSedeStadiums(stadiums));
  };
export const updateStadium =
  (organizerId, sedeId, stadiumId, stad) => async (dispatch, getState) => {
    dispatch(setLoading.true("stadium.updateStadium"));
    //const store = getState()
    //const stadium = without(store.stadium.single, ['id'])
    const stadium = without(stad, ["id"]);
    const [error, updatedStadium] = await stadiumService.update(
      organizerId,
      sedeId,
      stadiumId,
      stadium
    );

    dispatch(setLoading.false("stadium.updateStadium"));

    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
            [statusCodes.CONFLICT]: {
              msg: "Ya existe una cancha con este nombre.",
              alertStyle: "danger",
            },
          },
        })
      );
    }
    //dispatch(sedeActions.replaceSede(sedeModel(updatedStadium)))
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha actualizado la cancha <b>${updatedStadium.name}</b>.`,
        "info",
        3500
      )
    );
    //history.push(keepOrganizer('/sedes'))
  };

export const createStadium =
  (organizerId, sedeId, stadiumName) => async (dispatch, getState) => {
    if (stadiumName === "") {
      return dispatch(
        alertActions.showFloatingAlert(
          `No se puede crear una cancha sin nombre`,
          "danger",
          3500
        )
      );
    }
    dispatch(setLoading.true("stadium.saveNewStadium"));
    const stadium = { name: stadiumName, archived: false };
    const [error, createdStadium] = await stadiumService.create(
      organizerId,
      sedeId,
      stadium
    );
    dispatch(setLoading.false("stadium.saveNewStadium"));
    if (error) {
      return dispatch(
        handler({
          ...error,
          handler: {
            [statusCodes.BAD_REQUEST]: {
              msg: "Ocurrió un error formando los datos. Por favor intenta de nuevo.",
            },
            [statusCodes.CONFLICT]: {
              msg: "Ya existe una cancha con este nombre.",
              alertStyle: "danger",
            },
          },
        })
      );
    }
    dispatch(stadiumActions.pushNewStadium(createdStadium));
    dispatch(
      alertActions.showFloatingAlert(
        `Se ha creado la cancha <b>${createdStadium.name}</b>.`,
        "info",
        3500
      )
    );
    //history.push(keepOrganizer('/sedes'))
  };
