import React, { useState } from 'react'
import def from '../../../utils/functions/defaults'
import Calendar from 'react-calendar'
import moment from 'moment'
import * as env from '../../../config/env'

const Button = ({
    type = 'button',
    className = def.str,
    id = 'button-id',
    disabled = def.false,
    date = new Date,
    onChange = def.fn
}) => {

    const [showCalendar, setShowCalendar] = useState(false)

    return (
        <div className='floating-calendar'>
            <button
                id={id}
                type={type}
                className={`${className}`}
                disabled={disabled}
                onClick={() => setShowCalendar(!showCalendar)}
            >
                <i className="fa fa-calendar" /> {moment(date).format(env.DATE_FORMAT)} <i className="fa fa-caret-down" />
            </button>
            {showCalendar && (
                <div className='calendar'>
                    <Calendar
                        onChange={onChange}
                        value={date}
                    />
                </div>
            )}
        </div>
    )
}

export default Button