import React, { useState } from "react";
import Button from "../../../../utils/components/Button";
import Table from "../../../../utils/components/Table";
import DateModalContainer from "./DateModalContainer";
import DateDisplaceModalContainer from "./DateDisplaceModalContainer";
import dateManager from "../../../../utils/functions/date";
import moment from "moment";
import * as env from "../../../../config/env";

const DateList = ({
  dates,
  loadingFetchDates,
  deleteDate,
  loadingDeleteDate,
  setDateKeyValue,
  organizerId,
  tournamentId,
  showCreateButton,
  showDeleteButton,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [showDateModal, setShowDateModal] = useState(false);
  const [showDateDisplaceModal, setShowDateDisplaceModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const getFilteredDates = () =>
    dates.filter((date) => {
      const foundId =
        date.id.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
      const foundName =
        date.name.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1;
      const foundDatetime =
        moment(date.datetime)
          .utc()
          .format(env.DATE_FORMAT)
          .indexOf(searchValue) !== -1;
      return foundId || foundName || foundDatetime;
    });

  const addActions = (dates) =>
    dates.map((date) => {
      return {
        ...date,
        dateformat: moment.utc(date.datetime).local().format(env.DATE_FORMAT),
        actions: (
          <div className="btn-separated-group">
            <Button
              className="btn btn-warning"
              title={<i className="fa fa-pencil"></i>}
              withTooltip={true}
              tooltipTitle="Editar"
              onClick={() => {
                setDateKeyValue("id", date.id);
                setIsEditing(true);
                setShowDateModal(true);
              }}
            />
            {showDeleteButton && (
              <Button
                className="btn btn-danger"
                title={<i className="fa fa-trash"></i>}
                withTooltip={true}
                tooltipTitle="Eliminar"
                onClick={() => deleteDate(date.id)}
                loading={loadingDeleteDate}
                withConfirmation={true}
                confirmationTitle="Eliminar jornada"
                onConfirmText="Confirmar"
                onCancelText="Cancelar"
                onCancelClass="btn-danger pull-left"
                confirmationBody="¿Estás seguro que quieres eliminar esta jornada?"
              />
            )}
            <Button
              className="btn btn-info"
              title={<i className="fa fa-calendar"></i>}
              withTooltip={true}
              tooltipTitle="Desplazar fecha"
              onClick={() => {
                setDateKeyValue("id", date.id);
                setShowDateDisplaceModal(true);
              }}
            />
          </div>
        ),
      };
    });

  const formatRows = (dates) =>
    dates.map((date) => ({
      ...date,
      datetime: dateManager.ddMMyyyy(date.datetime),
    }));

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        Jornadas
        {showCreateButton && (
          <Button
            title={
              <span>
                <i className={`fa fa-plus`}></i> Jornada
              </span>
            }
            className="btn btn-primary btn-xs pull-right"
            onClick={() => {
              setIsEditing(false);
              setShowDateModal(true);
            }}
            withTooltip={true}
            tooltipTitle="Nueva jornada"
          />
        )}
      </div>
      <div className="panel-body">
        <input
          type="text"
          className="form-control"
          placeholder="Buscar jornadas..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Table
          loading={loadingFetchDates}
          headers={[
            {
              label: "Nombre",
              ref: "name",
              className: "col-md-5 col-sm-4",
            },
            {
              label: "Fecha",
              ref: "dateformat",
              className: "col-md-3 col-sm-3 text-center",
            },
            {
              label: "Acciones",
              ref: "actions",
              className: "col-md-4 col-sm-5 text-center",
            },
          ]}
          rows={formatRows(addActions(getFilteredDates()))}
          notFoundMessage="No se encontraron jornadas..."
        />
      </div>
      <DateModalContainer
        show={showDateModal}
        handleShow={setShowDateModal}
        isEditing={isEditing}
        organizerId={organizerId}
        tournamentId={tournamentId}
      />
      <DateDisplaceModalContainer
        show={showDateDisplaceModal}
        handleShow={setShowDateDisplaceModal}
        organizerId={organizerId}
        tournamentId={tournamentId}
      />
    </div>
  );
};

export default DateList;
