import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as groupThunks from "../../../../redux/thunks/groupThunks";
import groupActions from "../../../../redux/reducers/groupReducer/groupActions";
import GroupList from "./GroupList";

const GroupListContainer = ({
  tournamentEditing,
  organizerId,
  tournamentId,
  loading,
  loadTournamentGroups,
  groups,
  clearAllGroups,
  setGroupKeyValue,
  deleteGroup,
  show,
}) => {
  useEffect(() => {
    clearAllGroups();

    if (tournamentEditing) loadTournamentGroups(organizerId, tournamentId);
  }, []);

  const deleteGroupHandler = (groupId) =>
    deleteGroup(organizerId, tournamentId, groupId);

  return (
    show && (
      <GroupList
        groups={groups}
        loadingFetchGroups={loading.group.loadTournamentGroups}
        deleteGroup={deleteGroupHandler}
        loadingDeleteGroup={loading.group.deleteGroup}
        setGroupKeyValue={setGroupKeyValue}
        organizerId={organizerId}
        tournamentId={tournamentId}
      />
    )
  );
};

const mapState = (state) => ({
  loading: state.loading,
  groups: state.group.all,
  group: state.group.single,
});

const mapDispatch = (dispatch) => ({
  loadTournamentGroups: (organizerId, tournamentId) =>
    dispatch(groupThunks.loadTournamentGroups(organizerId, tournamentId)),
  clearAllGroups: () => dispatch(groupActions.clearAllGroups()),
  setGroupKeyValue: (key, value) =>
    dispatch(groupActions.setGroupKeyValue(key, value)),
  deleteGroup: (organizerId, tournamentId, groupId) =>
    dispatch(groupThunks.deleteGroup(organizerId, tournamentId, groupId)),
});

export default withRouter(connect(mapState, mapDispatch)(GroupListContainer));
