import React, { useEffect, useState } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as worksheetThunks from '../../../redux/thunks/worksheetThunks'
import { history } from '../../../redux/store'
import WorksheetStatus from './WorksheetStatus'
import worksheetActions from '../../../redux/reducers/worksheetReducer/worksheetActions'
import path from 'path'
import keepOrganizer from '../../../utils/functions/keepOrganizer'

const WorksheetStatusContainer = ({
    match,
    worksheet,
    pushNewRoadmapItem
}) => {

    const organizerId = match.params.organizerId
    const tournamentId = match.params.tournamentId
    const matchId = match.params.matchId

    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        if (!worksheet.isReady) {
            history.push(
                `/organizers/${organizerId}/tournaments/${tournamentId}/matches/${matchId}/worksheet`
            )
        }
        setIsMounted(true)
    }, [])

    useEffect(() => {
        if (isMounted) history.push(keepOrganizer(''))
    }, [organizerId])

    const onClickStatusHandler = (ref, name) => {
        pushNewRoadmapItem(name)
        return history.push(path.resolve(match.url, '..', 'incidence-categories/', ref, 'create'))
    }

    const getStatuses = () => {
        return worksheet.incidenceCategories.find(category => category.ref === 'Time').types
    }

    return (
        <WorksheetStatus 
            statuses={getStatuses()}
            onClickStatus={onClickStatusHandler}
        />
    )
}

const mapState = state => ({
    worksheet: state.worksheet
})

const mapDispatch = dispatch => ({
    pushNewRoadmapItem: (item) => dispatch(worksheetActions.pushNewRoadmapItem(item))
})

export default withRouter(connect(mapState, mapDispatch)(WorksheetStatusContainer))