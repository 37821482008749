import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UserForm from "./UserForm";
import * as userThunks from "../../redux/thunks/userThunks";
import userActions from "../../redux/reducers/userReducer/userActions";
import { actions as alertActions } from "../../redux/reducers/alertReducer";
import { history } from "../../redux/store";
import keepOrganizer from "../../utils/functions/keepOrganizer";

const UserFormContainer = ({
  location,
  user,
  setUserKeyValue,
  clearSingleUser,
  createNewUser,
  hideSpecificAlert,
  loadUser,
  match,
  loading,
  updateUser,
  organizations,
  loadUserOrganizations,
  loadAllOrganizers,
  loadAllCountries,
  clearAllCountries,
  clearAllOrganizers,
  organizers,
  countries,
}) => {
  const organizerId = match.params.organizerId;
  const userId = match.params.userId;
  const isEdit = /edit/.test(location.pathname);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    clearSingleUser();
    clearAllOrganizers();
    clearAllCountries();
    if (isEdit) {
      loadUser(organizerId, userId);
      loadUserOrganizations(userId);
    }
    loadAllOrganizers();
    loadAllCountries();
    setIsReady(true);
  }, []);

  useEffect(() => {
    if (isReady) history.push(keepOrganizer("/users"));
  }, [organizerId]);

  const updateUserHandler = () => updateUser(organizerId, userId);

  const createNewUserHandler = (image) => createNewUser(organizerId, image);

  const panelTitle = () => {
    let title = user.name;
    if (!isEdit && user.name === "") title = "Nueva user";
    return title;
  };

  return (
    <UserForm
      setUserKeyValue={setUserKeyValue}
      saveUser={isEdit ? updateUserHandler : createNewUserHandler}
      hideSpecificAlert={hideSpecificAlert}
      user={user}
      loadingFetchUser={loading.user.loadUser}
      loadingSaveUser={
        isEdit ? loading.user.updateUser : loading.user.createNewUser
      }
      saveButtonTitle={isEdit ? "Guardar" : "Crear"}
      panelTitle={panelTitle()}
      isEdit={isEdit}
      loadingAllUserOrganizations={loading.user.loadOrganizations}
      organizations={isEdit ? organizations : []} //user orgs
      organizers={organizers}
      countries={countries}
    />
  );
};

const mapState = (state) => ({
  user: state.user.single,
  loading: state.loading,
  organizations: state.user.organizations,
  organizers: state.user.organizers,
  countries: state.user.countries,
});

const mapDispatch = (dispatch) => ({
  setUserKeyValue: (key, value) =>
    dispatch(userActions.setUserKeyValue(key, value)),
  clearSingleUser: () => dispatch(userActions.clearSingleUser()),
  createNewUser: (organizerId, image) =>
    dispatch(userThunks.createNewUser(organizerId, image)),
  hideSpecificAlert: () => dispatch(alertActions.hideSpecificAlert()),
  loadUser: (organizerId, userId) =>
    dispatch(userThunks.loadUser(organizerId, userId)),
  loadUserOrganizations: (userId) =>
    dispatch(userThunks.loadUserOrganizations(userId)),
  updateUser: (organizerId, userId, image) =>
    dispatch(userThunks.updateUser(organizerId, userId, image)),
  loadAllOrganizers: () => dispatch(userThunks.loadAllOrganizers()),
  loadAllCountries: () => dispatch(userThunks.loadAllCountries()),
  clearAllCountries: () => dispatch(userActions.clearAllCountries()),
  clearAllOrganizers: () => dispatch(userActions.clearAllOrganizers()),
});

export default withRouter(connect(mapState, mapDispatch)(UserFormContainer));
