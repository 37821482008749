import sanctionActions from '../reducers/sanctionReducer/sanctionActions'
import sanctionService from '../reducers/sanctionReducer/sanctionService'
import { setLoading } from '../reducers/loadingReducer'
import { actions as alertActions } from '../reducers/alertReducer'
import handler, { statusCodes } from '../../utils/functions/handler'
import without from '../../utils/functions/without'
import moment from 'moment'
import * as env from '../../config/env'

export const loadSanctions = (organizerId, tournamentId) => async dispatch => {
    dispatch(setLoading.true('sanction.loadSanctions'))
    const [error, sanctions] = await sanctionService.loadAll(organizerId, tournamentId)
    dispatch(setLoading.false('sanction.loadSanctions'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                
            }
        }))
    }
    dispatch(sanctionActions.setAllSanctions(sanctions))
}

export const createNewSanction = (organizerId, tournamentId, handleCloseModal) => async (dispatch, getState) => {
    dispatch(setLoading.true('sanction.createNewSanction'))
    const store = getState()
    const sanction = without(store.sanction.single, ['id', 'remainingMatches', 'dateEnd'])
    //sanction.datetime = new Date(sanction.datetime).toISOString()
    const [error, createdSanction] = await sanctionService.create(organizerId, tournamentId, sanction)
    dispatch(setLoading.false('sanction.createNewSanction'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                
            }
        }))
    }
    dispatch(sanctionActions.pushNewSanction(createdSanction))
    handleCloseModal()
    dispatch(alertActions.showFloatingAlert(
        `Se ha creado la sanción correctamente`, 'info', 3500
    ))
}

export const deleteSanction = (organizerId, tournamentId, sanctionId) => async (dispatch, getState) => {
    dispatch(setLoading.true('sanction.deleteSanction'))
    const [error] = await sanctionService.delete(organizerId, tournamentId, sanctionId)
    dispatch(setLoading.false('sanction.deleteSanction'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                [statusCodes.CONFLICT]: {
                    msg: 'Mensaje de error',
                    alertStyle: 'danger'
                }
            }
        }))
    }
    dispatch(sanctionActions.deleteSanction(sanctionId))
    dispatch(alertActions.showFloatingAlert(
        `Se ha eliminado la sanción`, 'info', 3500
    ))
}

export const loadSanction = (organizerId, tournamentId, sanctionId) => async(dispatch, getState) => {
    dispatch(setLoading.true('sanction.loadSanction'))
    const [error, sanctionLoaded] = await sanctionService.loadOne(organizerId, tournamentId, sanctionId)
    dispatch(setLoading.false('sanction.loadSanction'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {

            }
        }))
    }
    
    sanctionLoaded.player = sanctionLoaded.player.id
    sanctionLoaded.comments = sanctionLoaded.comments ?? ''
    sanctionLoaded.dateEnd = moment(sanctionLoaded.dateEnd).utc().format(env.DATE_FORMAT)
    dispatch(sanctionActions.setSingleSanction({...sanctionLoaded, team: sanctionLoaded.team.id}))
}

export const updateSanction = (organizerId, tournamentId, sanctionId, handleCloseModal) => async(dispatch, getState) => {
    dispatch(setLoading.true('sanction.updateSanction'))
    const store = getState()
    const sanction = without(store.sanction.single, ['player','id', 'dateEnd', 'isActive', 'team'])
    const [error, updatedSanction] = await sanctionService.update(organizerId, tournamentId, sanctionId, sanction)
    dispatch(setLoading.false('sanction.updateSanction'))
    if (error) {
        return dispatch(handler({
            ...error,
            handler: {
                [statusCodes.BAD_REQUEST]: {
                    msg: 'Ocurrió un error formando los datos. Por favor intenta de nuevo.'
                }
            }
        }))
    }

    dispatch(sanctionActions.replaceSanction(updatedSanction))
    handleCloseModal()
    dispatch(alertActions.showFloatingAlert(
        `Se ha actualizado la sanción`, 'info', 3500
    ))
}