import React, { useEffect } from "react";
import Participants from "./Participants";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as participantsThunks from "../../../redux/thunks/participantsThunks";
import * as eventsThunks from "../../../redux/thunks/eventsThunks";
import * as tournamentThunks from "../../../redux/thunks/tournamentThunks";
import participantsActions from "../../../redux/reducers/participantsReducer/participantsActions";
import playerActions from "../../../redux/reducers/playerReducer/playerActions";
import eventsActions from "../../../redux/reducers/eventsReducer/eventsActions";

const ParticipantsContainer = ({
  clearAllParticipants,
  loadAllParticipantsEvent,
  loadAllEventsRole,
  loading,
  participants,
  eventId,
  event,
  deleteParticipant,
  lineups,
  organizerId,
  teams,
  match,
  tournaments,
  clearAllPlayers,
  setParticipantKeyValue,
  setEventKeyValue,
  loadAllTournaments,
  updateConfigEvent,
}) => {
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 0;

  const onRefreshUsers = (page, pageSize) => {
    clearAllParticipants();
    loadAllParticipantsEvent(eventId, page, pageSize);
  };

  useEffect(() => {
    clearAllParticipants();
    clearAllPlayers();
    loadAllTournaments(organizerId);
    loadAllEventsRole();
    loadAllParticipantsEvent(eventId, DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
  }, [eventId]);

  return (
    <Participants
      participants={participants}
      loading={loading.loadAllParticipants}
      eventId={eventId}
      event={event}
      deleteParticipant={deleteParticipant}
      tournaments={tournaments}
      organizerId={organizerId}
      setParticipantKeyValue={setParticipantKeyValue}
      defaultPage={DEFAULT_PAGE}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      onRefreshUsers={onRefreshUsers}
      setEventKeyValue={setEventKeyValue}
      updateConfigEvent={updateConfigEvent}
    />
  );
};

const mapState = (state) => ({
  participants: state.participant.all,
  loading: state.loading.participants,
  event: state.event.single,
  tournaments: state.tournament.all,
});

const mapDispatch = (dispatch) => ({
  loadAllTournaments: (organizerId) =>
    dispatch(tournamentThunks.loadAllTournaments(organizerId)),
  clearAllParticipants: () =>
    dispatch(participantsActions.clearAllParticipants()),
  loadAllParticipantsEvent: (eventId, page, pageSize) =>
    dispatch(
      participantsThunks.loadAllParticipantsEvent(eventId, page, pageSize)
    ),
  deleteParticipant: (participant) =>
    dispatch(participantsThunks.deleteParticipant(participant)),
  loadOneEvent: (eventId) => dispatch(eventsThunks.loadOneEvent(eventId)),
  loadAllEventsRole: () => dispatch(participantsThunks.loadAllEventsRole()),
  loadAllTournaments: (organizerId) =>
    dispatch(tournamentThunks.loadAllTournaments(organizerId)),
  clearAllPlayers: () => dispatch(playerActions.clearAllPlayers()),
  setParticipantKeyValue: (key, value) =>
    dispatch(participantsActions.setParticipantKeyValue(key, value)),
  updateConfigEvent: (eventId, config, handleShow) =>
    dispatch(eventsThunks.updateConfigEvent(eventId, config, handleShow)),
  setEventKeyValue: (key, value) =>
    dispatch(eventsActions.setEventKeyValue(key, value)),
});

export default withRouter(
  connect(mapState, mapDispatch)(ParticipantsContainer)
);
