'use-strict'

export default {
    all: [],
    single: {
        matches: 0,
        remainingMatches: 0,
        player:'',
        comments:'',
        blacklist: false,
        team: '',
    }
}