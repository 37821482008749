import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from '../../../redux/store'
import WorksheetIncidenceCategory from './WorksheetIncidenceCategory'
import worksheetActions from '../../../redux/reducers/worksheetReducer/worksheetActions'
import path from 'path'
import keepOrganizer from '../../../utils/functions/keepOrganizer'

const WorksheetIncidenceCategoryContainer = ({
    match,
    worksheet,
    pushNewRoadmapItem
}) => {

    const organizerId = match.params.organizerId
    const tournamentId = match.params.tournamentId
    const matchId = match.params.matchId

    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        if (!worksheet.isReady) {
            history.push(
                `/organizers/${organizerId}/tournaments/${tournamentId}/matches/${matchId}/worksheet`
            )
        }
        setIsMounted(true)
    }, [])

    useEffect(() => {
        if (isMounted) history.push(keepOrganizer(''))
    }, [organizerId])

    const showIncidenceTypesHandler = (ref, name) => {
        pushNewRoadmapItem(name)
        return history.push(path.resolve(match.url, ref))
    }

    const getIncidenceCategories = () => {
        return [
            ...worksheet.incidenceCategories.filter(category => category.byTeam),
            {
                ref: '../checkin',
                name: 'Plantel'
            }
        ]
    }

    return (
        <WorksheetIncidenceCategory
            incidenceCategories={getIncidenceCategories()}
            showIncidenceTypes={showIncidenceTypesHandler}
        />
    )
}

const mapState = state => ({
    worksheet: state.worksheet
})

const mapDispatch = dispatch => ({
    pushNewRoadmapItem: (item) => dispatch(worksheetActions.pushNewRoadmapItem(item))
})

export default withRouter(connect(mapState, mapDispatch)(WorksheetIncidenceCategoryContainer))