import React, { useState, useEffect } from "react";
import Button from "../../../../utils/components/Button";
import MyModal from "../../../../utils/components/Modal";
import PlayersModalTable from "./PlayersModalTable";
import PlayersModalForm from "./PlayersModalForm";
import { CopyToClipboard } from "react-copy-to-clipboard";

const PlayersModal = ({
  show,
  handleShow,
  lineupIdValue,
  player,
  players,
  clearSinglePlayer,
  setPlayerKeyValue,
  addPlayerToLineup,
  setSinglePlayer,
  loadingAddPlayerToLineup,
  updatePlayer,
  clearAllPlayers,
  loadingFetchPlayers,
  loadingUpdatePlayer,
  loadingDeletePlayer,
  deletePlayerHandler,
  deletePlayerImageHandler,
  floatingAlert,
}) => {
  const [copyToClipboardState, setCopyToClipboardState] = useState(false);
  const [editingPlayer, setEditingPlayer] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState("");

  useEffect(() => {
    if (copyToClipboardState)
      floatingAlert("El ID ha sido copiado al portapapeles", "info", 3500);
  }, [copyToClipboardState]);

  useEffect(() => {
    clearAllPlayers();
    clearSinglePlayer();
    setSelectedPlayer("");
    setEditingPlayer(false);
  }, [show]);

  return (
    <MyModal
      show={show}
      title="Jugadores"
      handleShow={handleShow}
      backdrop="static"
      body={
        <>
          <PlayersModalForm
            clear={show}
            player={player}
            clearSinglePlayer={clearSinglePlayer}
            setPlayerKeyValue={setPlayerKeyValue}
            addPlayerToLineup={addPlayerToLineup}
            editingPlayer={editingPlayer}
            setEditingPlayer={setEditingPlayer}
            loadingAddPlayerToLineup={loadingAddPlayerToLineup}
            updatePlayer={updatePlayer}
            selectedPlayer={selectedPlayer}
            setSelectedPlayer={setSelectedPlayer}
            loadingUpdatePlayer={loadingUpdatePlayer}
            deletePlayerImageHandler={deletePlayerImageHandler}
          />

          <PlayersModalTable
            players={players}
            editingPlayer={editingPlayer}
            setEditingPlayer={setEditingPlayer}
            setSinglePlayer={setSinglePlayer}
            clearSinglePlayer={clearSinglePlayer}
            selectedPlayer={selectedPlayer}
            setSelectedPlayer={setSelectedPlayer}
            loadingFetchPlayers={loadingFetchPlayers}
            loadingDeletePlayer={loadingDeletePlayer}
            deletePlayerHandler={deletePlayerHandler}
          />
        </>
      }
      buttons={
        <>
          <CopyToClipboard
            text={lineupIdValue}
            onCopy={() => setCopyToClipboardState(true)}
          >
            <Button title="Copiar ID" className="btn btn-light" />
          </CopyToClipboard>
          <Button
            title="Finalizar"
            className="btn btn-primary"
            onClick={handleShow}
          />
        </>
      }
    />
  );
};

export default PlayersModal;
