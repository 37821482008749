'use-strict'

export default {
    SET_ALL_USERS: '@@user/SET_ALL_USERS',
    SET_USER_KEY_VALUE: '@@user/SET_USER_KEY_VALUE',
    SET_SINGLE_USER: '@@user/SET_SINGLE_USER',
    PUSH_NEW_USER: '@@user/PUSH_NEW_USER',
    REPLACE_USER: '@@user/REPLACE_USER',
    DELETE_USER: '@@user/DELETE_USER',
    SET_SELECTED_USER: '@@user/SET_SELECTED_USER',
    CLEAR_ALL_USERS: '@@user/CLEAR_ALL_USERS',
    CLEAR_SINGLE_USER: '@@user/CLEAR_SINGLE_USER',
    UPDATE_ARCHIVED_USER:'@@user/UPDATE_ARCHIVED_USER',
    SET_USER_ORGANIZATIONS:'@@user/SET_USER_ORGANIZATIONS',
    SET_ALL_COUNTRIES:'@@user/SET_ALL_COUNTRIES',
    CLEAR_ALL_COUNTRIES:'@@user/CLEAR_ALL_COUNTRIES',
    SET_ALL_ORGANIZERS:'@@user/SET_ALL_ORGANIZERS',
    CLEAR_ALL_ORGANIZERS:'@@user/CLEAR_ALL_ORGANIZERS',
}