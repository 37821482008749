'use-strict'

import only from "../../../utils/functions/only"

export default {
    all: [],
    single: {
        id: '',
        rowNum: 0,
        color: '#000000'
    }
}

export const model = (entity) => only(entity, ['id', 'rowNum', 'color'])