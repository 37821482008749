import React, { useEffect } from "react";
import Main from "./layout/Main";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({
  path,
  component,
  exact,
  specificRole = false,
  isAuthenticated = false,
  logout = () => {},
}) => {
  if (!specificRole || !isAuthenticated) {
    return <Redirect to={{ pathname: "/login" }} />;
  }
  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "";
  });

  return (
    <Route
      path={path}
      exact={exact}
      render={(rest) => (
        <Main {...rest} logout={logout} component={component} />
      )}
    />
  );
};

export default PrivateRoute;
