import React, { useState, useEffect } from "react";
import Button from "../../../../utils/components/Button";
import MyModal from "../../../../utils/components/Modal";
import incidencesCategories from "../../../../redux/reducers/worksheetReducer/hardcoded/incidenceCategories";

const MatchFiltersModal = ({
  show,
  handleShow,
  groups,
  stadiums,
  dates,
  filters,
  setFiltersKeyValue,
  loading,
}) => {
  const closeModalHandle = () => handleShow(false);

  const [filtersValues, setFiltersValues] = useState(filters);

  useEffect(() => {
    setFiltersValues(filters);
  }, [filters]);

  const setFiltersValuesHandle = (filter) => {
    setFiltersValues({
      ...filtersValues,
      ...filter,
    });
  };

  const timeIncidencesTypes = incidencesCategories.filter(
    (category) => category.ref == "Time"
  )[0].types;

  const onClickHandle = () => {
    setFiltersKeyValue(filtersValues);
    closeModalHandle();
  };

  return (
    <MyModal
      show={show}
      title="Filtros de partidos"
      handleShow={handleShow}
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="col-md-3 col-sm-2 control-label">Grupo </label>
              <div className="col-md-9 col-sm-10">
                <select
                  name="group"
                  id="group"
                  className="form-control"
                  disabled={loading}
                  value={filtersValues.group}
                  onChange={(e) => {
                    setFiltersValuesHandle({ group: e.target.value });
                  }}
                >
                  <option value="">Seleccione</option>
                  {groups.map((group, key) => (
                    <option value={group.id} key={key + "group"}>
                      {group.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className="form-group">
              <label className="col-md-3 col-sm-2 control-label">
                Jornada{" "}
              </label>
              <div className="col-md-9 col-sm-10">
                <select
                  name="date"
                  id="date"
                  className="form-control"
                  disabled={loading}
                  value={filtersValues.date}
                  onChange={(e) => {
                    setFiltersValuesHandle({ date: e.target.value });
                  }}
                >
                  <option value="">Seleccione</option>
                  {dates.map((date, key) => (
                    <option value={date.id} key={key + "date"}>
                      {date.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className="form-group">
              <label className="col-md-3 col-sm-2 control-label">Fecha </label>
              <div className="col-md-9 col-sm-10">
                <input
                  name="datetime"
                  id="datetime"
                  type="date"
                  className="form-control"
                  value={filtersValues.datetime}
                  disabled={loading}
                  onChange={(e) => {
                    setFiltersValuesHandle({ datetime: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className="form-group">
              <label className="col-md-3 col-sm-2 control-label">Cancha </label>
              <div className="col-md-9 col-sm-10">
                <select
                  name="stadium"
                  id="stadium"
                  className="form-control"
                  disabled={loading}
                  value={filtersValues.stadium}
                  onChange={(e) => {
                    setFiltersValuesHandle({ stadium: e.target.value });
                  }}
                >
                  <option value="">Seleccione</option>
                  {stadiums.map((stadium, key) => (
                    <option value={stadium.id} key={key + "stadium"}>
                      {stadium.name} - {stadium.sede_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className="form-group">
              <label className="col-md-3 col-sm-2 control-label">Estado </label>
              <div className="col-md-9 col-sm-10">
                <select
                  name="state"
                  id="state"
                  className="form-control"
                  disabled={loading}
                  value={filtersValues.state}
                  onChange={(e) => {
                    setFiltersValuesHandle({ state: e.target.value });
                  }}
                >
                  <option value="">Seleccione</option>
                  <option value="Sin comenzar">Sin comenzar</option>
                  {timeIncidencesTypes.map((type, key) => (
                    <option value={"Tiempo " + type.name} key={key + "date"}>
                      {"Tiempo - " + type.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      }
      buttons={
        <Button
          title="Aplicar"
          className="btn btn-primary"
          onClick={onClickHandle}
        />
      }
    />
  );
};

export default MatchFiltersModal;
