'use-strict'

import constants from './teamConstants'

export default {
    setAllTeams: (payload) => ({
        type: constants.SET_ALL_TEAMS,
        payload
    }),
    setTeamKeyValue: (key, value) => ({
        type: constants.SET_TEAM_KEY_VALUE,
        payload: { key, value }
    }),
    setSingleTeam: (payload) => ({
        type: constants.SET_SINGLE_TEAM,
        payload
    }),
    pushNewTeam: (payload) => ({
        type: constants.PUSH_NEW_TEAM,
        payload
    }),
    replaceTeam: (payload) => ({
        type: constants.REPLACE_TEAM,
        payload
    }),
    deleteTeam: (teamId) => ({
        type: constants.DELETE_TEAM,
        teamId
    }),
    setSelectedTeam: (teamId) => ({
        type: constants.SET_SELECTED_TEAM,
        teamId
    }),
    clearAllTeams: () => ({
        type: constants.CLEAR_ALL_TEAMS
    }),
    clearSingleTeam: () => ({
        type: constants.CLEAR_SINGLE_TEAM
    })
}