'use-strict'

export default {
    SET_ALL_TABLE_ROW_COLORS: '@@tableRowColor/SET_ALL_TABLE_ROW_COLORS',
    SET_TABLE_ROW_COLOR_KEY_VALUE: '@@tableRowColor/SET_TABLE_ROW_COLOR_KEY_VALUE',
    CLEAR_ALL_TABLE_ROW_COLORS: '@@tableRowColor/CLEAR_ALL_TABLE_ROW_COLORS',
    CLEAR_SINGLE_TABLE_ROW_COLOR: '@@tableRowColor/CLEAR_SINGLE_TABLE_ROW_COLOR',
    DELETE_TABLE_ROW_COLOR: '@@tableRowColor/DELETE_TABLE_ROW_COLOR',
    SET_SINGLE_TABLE_COLOR_ROW: '@@tableRowColor/SET_SINGLE_TABLE_COLOR_ROW',
    UPDATE_TABLE_ROW_COLOR: '@@tableRowColor//UPDATE_TABLE_ROW_COLOR',
    ADD_NEW_TABLE_ROW_COLOR: '@@tableRowColor/ADD_NEW_TABLE_ROW_COLOR',
}