import React, { useEffect } from "react";
import NewCheckInModal from "./NewCheckInModal";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as checkInThunks from "../../../redux/thunks/checkInThunks";
import checkInActions from "../../../redux/reducers/checkInReducer/checkInActions";

const CheckInModalContainer = ({
  show,
  handleShow,
  setCheckInKeyValue,
  checkIn,
  loadOneCheckIn,
  createNewCheckIn,
  loading,
  clearSingleCheckIn,
  event,
  checkInAll,
  currentPageSize,
}) => {
  return (
    <NewCheckInModal
      show={show}
      handleShow={handleShow}
      setCheckInKeyValue={setCheckInKeyValue}
      checkIn={checkIn}
      createNewCheckIn={createNewCheckIn}
      checkInAll={checkInAll}
      event={event}
      loading={loading}
      clearSingleCheckIn={clearSingleCheckIn}
      currentPageSize={currentPageSize}
    />
  );
};

const mapState = (state) => ({
  event: state.event.single,
  checkInAll: state.checkIn.all,
  checkIn: state.checkIn.single,
  loading: state.loading,
});

const mapDispatch = (dispatch) => ({
  loadAllCheckInsForEvent: () =>
    dispatch(checkInThunks.loadAllCheckInsForEvent()),
  loadOneCheckIn: (checkInId) =>
    dispatch(checkInThunks.loadOneCheckIn(checkInId)),
  createNewCheckIn: (eventId, checkInAll, currentPageSize) =>
    dispatch(
      checkInThunks.createNewCheckIn(eventId, checkInAll, currentPageSize)
    ),
  deleteParticipant: () => dispatch(checkInThunks.deleteParticipant()),
  setCheckInKeyValue: (key, value) =>
    dispatch(checkInActions.setCheckInKeyValue(key, value)),
  clearSingleCheckIn: () => dispatch(checkInActions.clearSingleCheckIn()),
});

export default withRouter(
  connect(mapState, mapDispatch)(CheckInModalContainer)
);
