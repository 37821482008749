"use-strict";

import constants from "./eventsConstants";
import initialState from "./eventsState";

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_ALL_EVENTS: {
      return {
        ...state,
        all: action.payload.map((event) => ({
          ...event.event,
          matches: event.matches,
          users: event.users,
          usersAccess: event.usersAccess,
        })),
      };
    }
    case constants.SET_ALL_EVENTS_CATEGORY: {
      return {
        ...state,
        eventsCategory: action.payload,
      };
    }
    case constants.SET_ALL_EVENTS_TYPE: {
      return {
        ...state,
        eventsType: action.payload,
      };
    }
    case constants.SET_SINGLE_EVENT: {
      return {
        ...state,
        single: action.payload,
      };
    }
    case constants.SET_EVENT_KEY_VALUE: {
      return {
        ...state,
        single: {
          ...state.single,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case constants.CLEAR_ALL_EVENTS: {
      return {
        ...state,
        all: initialState.all,
      };
    }
    case constants.DELETE_EVENT: {
      const events = [...state.all];
      const index = events.findIndex((event) => event.id === action.eventId);
      events.splice(index, 1);
      return {
        ...state,
        all: events,
      };
    }
    case constants.CLEAR_SINGLE_EVENT: {
      return {
        ...state,
        single: initialState.single,
      };
    }
    default: {
      return state;
    }
  }
};

export default eventReducer;
