import React, { useState, useEffect, createRef } from 'react'
import Button from '../../../utils/components/Button'
import MyModal from '../../../utils/components/Modal'
import UserImage from '../../../assets/images/user.png'
import BrandImageUploader from '../../../utils/components/ImageUploader/BrandImageUploader'


const WorksheetCheckInModal = ({
    show,
    handleShow,
    player,
    updatePlayer,
    deletePlayerImage
}) => {

    const playerUploaderRef = createRef()
    const [shirtNumberError, setShirtNumberError] = useState(false)
    const [shirtNumber, setShirtNumber] = useState('')

    useEffect(() => {
        if (!player) return
        setShirtNumber(player.shirtNumber || '')
    }, [show])

    const updatePlayerHandler = () => {
        if (shirtNumber === '') {
            setShirtNumberError(true)
            return
        } else {
            setShirtNumberError(false)
        }

        const playerImage = playerUploaderRef.current.getFormData()
        updatePlayer(player.id, shirtNumber, playerImage)
        handleShow(false)
    }

    const deletePlayerImageHandler = () => {
        deletePlayerImage(player.id)
        handleShow(false)
    }

    const hasPlayerImage = player && player.media && player.media.user_big 

    const hasUserImage = player && player.user && player.user.media && player.user.media.user_big 


    if (!player) return null

    return (
        <MyModal
            show={show}
            title={`Editando jugador: ${player.firstName} ${player.lastName}`}
            handleShow={handleShow}
            backdrop='static'
            body={
                <div className="row">
                    <div className="col-md-12">
                        <BrandImageUploader 
                            id="image"
                            enableUploader={true}
                            ref={playerUploaderRef}
                            title={`${player.firstName} ${player.lastName}`}
                            loading={false}
                            image={
                                hasPlayerImage
                                    ? player.media.user_big 
                                    : (hasUserImage 
                                        ? player.user.media.user_big 
                                        : UserImage)
                            }
                            validExtensions={["jpg", "jpeg", "png"]}
                            minWidth={200}
                            minHeight={200}
                            aspectRatio={1}
                            deleteImage={deletePlayerImageHandler}
                            showDeleteImageButtom={false}
                            verifiedImage = {hasPlayerImage}
                            enableUploader = {false}
                        />
                    </div>
                    <div className="col-md-12">
                        <div className={`form-group ${shirtNumberError && 'has-error'}`}>
                            <label className="col-md-3 col-sm-2 control-label">Camiseta * </label>
                            <div className="col-md-9 col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={shirtNumber}
                                    onChange={(e) => {
                                        setShirtNumber(e.target.value)
                                    }} />
                                {shirtNumberError && <span className="help-block">El número de camiseta es requerido.</span>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            buttons={
                <Button
                    title="Actualizar"
                    className="btn btn-primary"
                    onClick={updatePlayerHandler}
                />
            }
        />
    )
}

export default WorksheetCheckInModal