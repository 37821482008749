import React, { useEffect, useState } from "react";
import WorksheetWrapper from "../components/WorksheetWrapper";
import "../worksheet.scss";
import Table from "../../../utils/components/Table";
import Button from "../../../utils/components/Button";
import WorksheetCheckInModal from "./WorksheetCheckInModal";
import WorksheetCheckInAddPlayerModal from "./WorksheetCheckInAddPlayerModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import * as env from "../../../config/env";

const WorksheetCheckIn = ({
  team,
  updatePlayer,
  loadingFetchPlayers,
  loadingCheckInPlayer,
  checkInPlayer,
  checkOutPlayer,
  loadingCheckOutPlayer,
  checkInGuestPlayer,
  checkInAllPlayersHandler,
  deletePlayerImage,
}) => {
  const colors = {
    notAssigned: "#cd9694",
    assigned: "#839c84",
    guest: "#d8c153",
    sanction: "#9e6eab",
    blacklist: "#4c4c4c",
  };

  const [editingPlayer, setEditingPlayer] = useState("");
  const [showAddPlayer, setShowAddPlayer] = useState(false);

  useEffect(() => {
    const removeDuplicatesFromCheckedInPlayers = () => {
      const playerIds = new Set();
      team.checkedInPlayers = team.checkedInPlayers.filter((player) => {
        if (playerIds.has(player.id)) {
          return false;
        }
        playerIds.add(player.id);
        return true;
      });
    };

    removeDuplicatesFromCheckedInPlayers();
  }, [team.checkedInPlayers]);

  const isCheckedIn = (playerId) => {
    const existingPlayer = team.checkedInPlayers.find(
      (player) => player.id === playerId
    );

    return !!existingPlayer;
  };

  const getRowColor = (player) => {
    let color = colors.notAssigned;
    const checkedInPlayers = team.checkedInPlayers;
    if (player.isSanctioned) color = colors.sanction;
    if (player.isBlacklisted) color = colors.blacklist;
    const isCheckedIn = checkedInPlayers.find((elem) => elem.id === player.id);
    if (isCheckedIn) color = colors.assigned;
    if (player.isGuest) color = colors.guest;
    return color;
  };

  const MedicalRecordSignal = ({ medicalRecordExp }) => {
    if (!!medicalRecordExp) {
      const medicalRecordExpDate = moment(medicalRecordExp);
      const medicalRecordExpFormat = moment(medicalRecordExp)
        .utc()
        .format(env.DATE_FORMAT);
      const isExpired = medicalRecordExpDate.diff(moment().now, "days") < 0;
      const tooltipMsg = `vencimiento ${medicalRecordExpFormat}`;

      return (
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip id={"medicalRecordTooltip"}>{tooltipMsg}</Tooltip>}
        >
          <i
            className="fa fa-medkit"
            style={{ color: `${isExpired ? "red" : "#FFF"}` }}
          />
        </OverlayTrigger>
      );
    }

    return <i className="fa fa-medkit" />;
  };

  const addActions = (players) =>
    players.map((player) => {
      return {
        ...player,
        medicalRecord: !!player.medicalRecord ? (
          <MedicalRecordSignal medicalRecordExp={player.medicalRecordExp} />
        ) : (
          "-"
        ),
        backgroundColor: getRowColor(player),
        color: "#FFF",
        shirtNumber: player.shirtNumber ? player.shirtNumber : 0,
        actions: (
          <span className="btn-separated-group">
            {isCheckedIn(player.id) ? (
              <Button
                title={<i className="fa fa-times" />}
                className="btn btn-danger"
                withTooltip
                tooltipTitle="Desasignar jugador"
                loading={loadingCheckOutPlayer}
                onClick={() => checkOutPlayer(player.id)}
              />
            ) : (
              <Button
                title={<i className="fa fa-check" />}
                className="btn btn-primary"
                withTooltip
                tooltipTitle="Asignar jugador"
                loading={loadingCheckInPlayer}
                onClick={() => checkInPlayer(player.id)}
              />
            )}
            <Button
              title={<i className="fa fa-pencil" />}
              className="btn btn-warning"
              withTooltip
              tooltipTitle="Editar Jugador"
              onClick={() => setEditingPlayer(player.id)}
            />
          </span>
        ),
      };
    });

  const getGuestPlayers = () => {
    return team.checkedInPlayers
      .filter((player) => {
        const isPlayer = team.players.find((elem) => elem.id === player.id);
        return !isPlayer;
      })
      .map((player) => ({
        ...player,
        isGuest: true,
      }));
  };

  const getEditingPlayer = () => {
    return (
      team.players.find((player) => player.id === editingPlayer) ||
      getGuestPlayers().find((player) => player.id === editingPlayer)
    );
  };

  return (
    <WorksheetWrapper withGoBack>
      <div className="worksheet-white-container">
        <div className="alert alert-warning">
          <span className="statement">
            Agregue o quite los jugadores asignados al partido actual. Si el
            jugador no se encuentra en la lista de buena fe, puede utilizar el
            botón "+" para agregarlo al partido.
          </span>
        </div>
        <ul className="list">
          <li>
            <span
              className="list-color"
              style={{ backgroundColor: colors.notAssigned }}
            ></span>
            No Asignados
          </li>
          <li>
            <span
              className="list-color"
              style={{ backgroundColor: colors.assigned }}
            ></span>
            Asignados
          </li>
          <li>
            <span
              className="list-color"
              style={{ backgroundColor: colors.guest }}
            ></span>
            Asignados - No incluidos en la lista de buena fe
          </li>
          <li>
            <span
              className="list-color"
              style={{ backgroundColor: colors.sanction }}
            ></span>
            Sancionados
          </li>
          <li>
            <span
              className="list-color"
              style={{ backgroundColor: colors.blacklist }}
            ></span>
            En lista negra
          </li>
        </ul>
        <div className="worksheet-table">
          <h2 className="good-faith-list-title">Lista de buena fe</h2>
          <Button
            className="btn btn-primary pull-right"
            title={
              <span>
                <i className="fa fa-plus-circle"></i> Check in all
              </span>
            }
            withTooltip={true}
            tooltipTitle="Check in all"
            onClick={() => checkInAllPlayersHandler(team)}
          />
          <Table
            headers={[
              {
                ref: "document",
                className: "col-sm-2",
                label: "DNI",
              },
              {
                ref: "firstName",
                className: "col-sm-3",
                label: "Nombre",
              },
              {
                ref: "lastName",
                className: "col-sm-3",
                label: "Apellido",
              },
              {
                ref: "medicalRecord",
                className: "col-sm-1 text-center",
                label: "Ficha medica",
              },
              {
                ref: "shirtNumber",
                className: "col-sm-1 text-center hidden-xs",
                label: "Camiseta",
              },
              {
                ref: "actions",
                className: "col-sm-2 text-center",
                label: "Acciones",
              },
            ]}
            rows={addActions([...team.players, ...getGuestPlayers()])}
            notFoundMessage="No se encontraron jugadores para este equipo."
            loading={loadingFetchPlayers}
          />
        </div>
        <Button
          title={<i className="fa fa-plus" />}
          id="add-more-players-button"
          className="btn-primary add-more-players-button"
          onClick={() => {
            setShowAddPlayer(true);
          }}
        />
      </div>
      <WorksheetCheckInModal
        show={!!editingPlayer}
        handleShow={() => setEditingPlayer("")}
        player={getEditingPlayer()}
        updatePlayer={updatePlayer}
        deletePlayerImage={deletePlayerImage}
      />
      <WorksheetCheckInAddPlayerModal
        show={showAddPlayer}
        handleShow={setShowAddPlayer}
        checkInGuestPlayer={checkInGuestPlayer}
      />
    </WorksheetWrapper>
  );
};

export default WorksheetCheckIn;
