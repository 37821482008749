import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as worksheetThunks from "../../../redux/thunks/worksheetThunks";
import { history } from "../../../redux/store";
import WorksheetCreateIncidence from "./WorksheetCreateIncidence";
import { actions as alertActions } from "../../../redux/reducers/alertReducer";
import keepOrganizer from "../../../utils/functions/keepOrganizer";

const WorksheetCreateIncidenceContainer = ({
  match,
  worksheet,
  loading,
  showFloatingAlert,
  createNewIncidence,
}) => {
  const organizerId = match.params.organizerId;
  const tournamentId = match.params.tournamentId;
  const matchId = match.params.matchId;
  const incidenceType = match.params.incidence;
  const teamId = match.params.teamId;

  const [isMounted, setIsMounted] = useState(false);

  const worksheetUrl = `/organizers/${organizerId}/tournaments/${tournamentId}/matches/${matchId}/worksheet`;

  useEffect(() => {
    if (!worksheet.isReady) {
      history.push(worksheetUrl);
    }
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) history.push(keepOrganizer(""));
  }, [organizerId]);

  const createNewIncidenceHandler = async (
    datetime,
    player,
    substitute,
    comment
  ) => {
    const incidence = {
      datetime,
      comment,
      player,
      substitute,
      type: incidenceType,
    };
    await createNewIncidence(organizerId, tournamentId, matchId, incidence);
    // return history.push(worksheetUrl)
  };

  const getPlayerTitle = (player, substitute) => {
    let title = "Jugador";
    if (player && substitute) title = "Sale";
    return title;
  };

  const defineIncidenceProps = () => {
    const incidenceCategories = worksheet.incidenceCategories;
    let category = incidenceCategories.find(
      (category) => category.ref === incidenceType
    );
    if (!category) {
      category = incidenceCategories.find((category) => {
        return category.types.find((type) => type.ref === incidenceType);
      });
    }
    return {
      allowPlayer: category.player,
      allowSubstitute: category.substitute,
      playerTitle: getPlayerTitle(category.player, category.substitute),
      substituteTitle: "Entra",
    };
  };

  return (
    <WorksheetCreateIncidence
      {...defineIncidenceProps()}
      createNewIncidence={createNewIncidenceHandler}
      loadingCreateIncidence={loading.createNewIncidence}
      match={worksheet.match}
      showFloatingAlert={showFloatingAlert}
      teamId={teamId}
    />
  );
};

const mapState = (state) => ({
  worksheet: state.worksheet,
  loading: state.loading.worksheet,
});

const mapDispatch = (dispatch) => ({
  createNewIncidence: (organizerId, tournamentId, matchId, incidence) =>
    dispatch(
      worksheetThunks.createNewIncidence(
        organizerId,
        tournamentId,
        matchId,
        incidence
      )
    ),
  showFloatingAlert: (message, type, timer) =>
    dispatch(alertActions.showFloatingAlert(message, type, timer)),
});

export default withRouter(
  connect(mapState, mapDispatch)(WorksheetCreateIncidenceContainer)
);
