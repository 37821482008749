import React, { useState } from "react";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import { history } from "../../redux/store";
import Button from "../../utils/components/Button";
import ParticipantsContainer from "./participants/ParticipantsContainer";
import EventsFormContainer from "./events/EventsFormContainer";
import CheckInContainer from "./checkIn/CheckInContainer";

const EventsView = ({ organizerId, eventIsEdit, eventId, event }) => {
  const [activeSection, setActiveSection] = useState("datos");

  return (
    <>
      <Breadcrumbs
        title="Eventos"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Eventos",
            active: false,
            path: keepOrganizer("/events"),
          },
          {
            title: event.name,
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <ul className="nav nav-tabs nav-justified">
              <li
                role="presentation"
                style={{
                  backgroundColor: activeSection === "datos" && "#1ab394",
                }}
                className={activeSection === "datos" && "active"}
                onClick={() => setActiveSection("datos")}
              >
                <a
                  style={{
                    color: activeSection === "datos" ? "#ffffff" : "#3a3737",
                  }}
                >
                  Datos
                </a>
              </li>
              {eventIsEdit && (
                <li
                  onClick={() => setActiveSection("participants")}
                  role="presentation"
                  className={activeSection === "participants" && "active"}
                  style={{
                    backgroundColor:
                      activeSection === "participants" && "#1ab394",
                  }}
                >
                  <a
                    style={{
                      color:
                        activeSection === "participants"
                          ? "#ffffff"
                          : "#3a3737",
                    }}
                  >
                    Participantes
                  </a>
                </li>
              )}
              {eventIsEdit && (
                <li
                  onClick={() => setActiveSection("checkIn")}
                  role="presentation"
                  className={activeSection === "checkIn" && "active"}
                  style={{
                    backgroundColor: activeSection === "checkIn" && "#1ab394",
                  }}
                >
                  <a
                    style={{
                      color:
                        activeSection === "checkIn" ? "#ffffff" : "#3a3737",
                    }}
                  >
                    Check In
                  </a>
                </li>
              )}
            </ul>
            <div
              className="ibox-title"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn btn-info"
                title={
                  <span>
                    <i className="fa fa-arrow-left"></i> Volver
                  </span>
                }
                onClick={() => history.push(keepOrganizer("/events"))}
              />
            </div>
          </div>
        </div>
        {activeSection === "datos" && (
          <EventsFormContainer
            organizerId={organizerId}
            eventIsEdit={eventIsEdit}
          />
        )}
        {eventIsEdit && (
          <>
            {activeSection === "participants" && (
              <ParticipantsContainer
                organizerId={organizerId}
                eventId={eventId}
              />
            )}
            {activeSection === "checkIn" && (
              <CheckInContainer organizerId={organizerId} eventId={eventId} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EventsView;
