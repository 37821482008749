import React from 'react'
import WorksheetWrapper from '../components/WorksheetWrapper'

const WorksheetIncidenceType = ({
    incidenceCategories,
    showIncidenceTypes
}) => {
    return (
        <WorksheetWrapper 
            withGoBack 
            gridItems={incidenceCategories}
            gridAction={showIncidenceTypes}
        />
    )
}

export default WorksheetIncidenceType