import React, { useEffect, useState, createRef } from "react";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import Button from "../../utils/components/Button";
import { history } from "../../redux/store";
import SedeImage from "../../assets/images/team.png";
import SpecificAlert from "../../utils/components/SpecificAlert/SpecificAlert";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import BrandImageUploader from "../../utils/components/ImageUploader/BrandImageUploader";
import Table from "../../utils/components/Table";
import only from "../../utils/functions/only";

const SedeForm = ({
  sede,
  setSedeKeyValue,
  hideSpecificAlert,
  loadingFetchSede,
  loadingSaveSede,
  loadingAllSedeStadiums,
  loadingSaveStadiums,
  saveSede,
  saveButtonTitle,
  panelTitle,
  isEdit,
  stadiums,
  updateStadium,
  loadingSaveNewStadium,
  createStadium,
}) => {
  const brandUploaderRef = createRef();
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [linkSedeError, setLinkSedeError] = useState(false);
  const [linkBarError, setLinkBarError] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [editingStadiumId, setEditingStadiumId] = useState("");
  const [editingStadiumName, setEditingStadiumName] = useState("");
  const [newStadiumName, setNewStadiumName] = useState("");

  useEffect(() => {
    hideSpecificAlert();
  }, []);

  const saveSedeHandler = () => {
    let hasError = false;
    if (sede.name === "" || sede.name.length > 30) {
      hasError = true;
      setNameError(true);
    } else {
      setNameError(false);
    }
    if (sede.description === "") {
      hasError = true;
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }
    if (sede.location === "") {
      hasError = true;
      setLocationError(true);
    } else {
      setLocationError(false);
    }
    if (sede.linkSede === "") {
      hasError = true;
      setLinkSedeError(true);
    } else {
      setLinkSedeError(false);
    }
    if (sede.linkBar === "") {
      hasError = true;
      setLinkBarError(true);
    } else {
      setLinkBarError(false);
    }
    if (hasError) return;

    const brandImage = brandUploaderRef.current.getFormData();

    saveSede(brandImage);
  };

  const stadiumValue = (id, value) => {
    if (loadingAllSedeStadiums) return "-";
    if (editingStadiumId === id) {
      return (
        <input
          type="text"
          className="form-control"
          defaultValue={value}
          onChange={(e) => {
            setEditingStadiumName(e.target.value);
          }}
        />
      );
    }
    return value;
  };

  const getFilteredStadiums = () => {
    let rows = [];
    /*for (const row in stadiums) {
            rows.push({
                ...row,
                value: stadiumValue(row, stadiums[row])
            })
            console.log(row.id)
            console.log(stadiumValue(row, stadiums[row].name))
        }
        rows = rows?.filter(stadium => {
            const foundId = stadium.id.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            const foundName = stadium.name.trim().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
            return foundId || foundName 
        })*/
    stadiums.map((e) => {
      rows.push({
        ...e,
        value: stadiumValue(e.id, e.name),
      });
    });
    return rows;
  };

  const updateStadiumHandler = (stadiumId) => {
    let stadium = stadiums.filter((el) => el.id == stadiumId)[0];
    stadium.name = editingStadiumName;
    updateStadium(stadiumId, stadium);
  };

  const archiveStadium = (stadiumId, archived) => {
    let stadium = stadiums.filter((el) => el.id == stadiumId)[0];
    stadium.archived = archived;
    updateStadium(stadiumId, stadium);
  };

  const addActions = (rows) =>
    rows?.map((stadium) => {
      return {
        ...stadium,
        actions: (
          <div className="btn-separated-group">
            <Button
              className="btn btn-success"
              title={
                <i
                  className={`fa ${
                    stadium.archived ? "fa-eye-slash" : "fa-eye"
                  }`}
                ></i>
              }
              withTooltip={true}
              loading={loadingSaveStadiums}
              tooltipTitle="Esconder cancha"
              onClick={() => archiveStadium(stadium.id, !stadium.archived)}
            />
            <Button
              className={`btn btn-${
                editingStadiumId === stadium.id ? "primary" : "warning"
              }`}
              title={
                <i
                  className={`fa ${
                    editingStadiumId === stadium.id ? "fa-save" : "fa-pencil"
                  }`}
                ></i>
              }
              withTooltip={true}
              tooltipTitle={`${
                editingStadiumId === stadium.id ? "Guardar" : "Modificar"
              }`}
              onClick={() => {
                if (editingStadiumId) {
                  updateStadiumHandler(stadium.id);
                  setEditingStadiumId("");
                  return;
                }
                setEditingStadiumId(stadium.id);
              }}
              loading={loadingSaveStadiums}
              disabled={
                loadingAllSedeStadiums ||
                (editingStadiumId && stadium.id !== editingStadiumId)
              }
            />
          </div>
        ),
      };
    });

  return (
    <>
      <Breadcrumbs
        title="Sedes"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Sedes",
            active: false,
            path: keepOrganizer("/sedes"),
          },
          {
            title: sede.name,
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div
              className="ibox-title"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn btn-info"
                title={
                  <span>
                    <i className="fa fa-arrow-left"></i> Volver
                  </span>
                }
                onClick={() => history.push(keepOrganizer("/sedes"))}
              />
            </div>
            <div className="ibox-content">
              <SpecificAlert />
              <div className="row">
                <div className="col-md-4">
                  <BrandImageUploader
                    id="image"
                    enableUploader={isEdit}
                    ref={brandUploaderRef}
                    title={panelTitle}
                    loading={loadingFetchSede}
                    image={
                      sede.image && sede.image.sede_big
                        ? sede.image.sede_big
                        : SedeImage
                    }
                    validExtensions={["jpg", "jpeg", "png"]}
                    minWidth={200}
                    minHeight={200}
                    aspectRatio={1}
                    confirmationTitle={
                      "¿Estás seguro que quieres eliminar la imagen de este jugador?"
                    }
                    confirmationBody={"Eliminar imagen del jugador"}
                  />
                </div>
                <div className="col-md-8">
                  <div className="panel panel-default">
                    <div className="panel-heading">Datos</div>
                    <div className="panel-body">
                      <div className={`form-group ${nameError && "has-error"}`}>
                        <label className="col-md-3 col-sm-2 control-label">
                          Nombre *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={sede.name}
                            disabled={loadingFetchSede}
                            onChange={(e) => {
                              setSedeKeyValue("name", e.target.value);
                            }}
                          />
                          {nameError && (
                            <span className="help-block">
                              El nombre es requerido.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${
                          descriptionError && "has-error"
                        }`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Descripción *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={sede.description}
                            disabled={loadingFetchSede}
                            onChange={(e) => {
                              setSedeKeyValue("description", e.target.value);
                            }}
                          />
                          {descriptionError && (
                            <span className="help-block">
                              La descripción es requerida.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${locationError && "has-error"}`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Ubicación *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={sede.location}
                            disabled={loadingFetchSede}
                            onChange={(e) => {
                              setSedeKeyValue("location", e.target.value);
                            }}
                          />
                          {locationError && (
                            <span className="help-block">
                              La ubicación es requerida.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${linkSedeError && "has-error"}`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Link Sede *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={sede.linkSede}
                            disabled={loadingFetchSede}
                            onChange={(e) => {
                              setSedeKeyValue("linkSede", e.target.value);
                            }}
                          />
                          {linkSedeError && (
                            <span className="help-block">
                              El link de la sede es requerido.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <div
                        className={`form-group ${linkBarError && "has-error"}`}
                      >
                        <label className="col-md-3 col-sm-2 control-label">
                          Link Mapa *{" "}
                        </label>
                        <div className="col-md-9 col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={sede.linkBar}
                            disabled={loadingFetchSede}
                            onChange={(e) => {
                              setSedeKeyValue("linkBar", e.target.value);
                            }}
                          />
                          {linkBarError && (
                            <span className="help-block">
                              La descripción es requerida.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="hr-line-dashed"></div>
                      <Button
                        className="btn btn-primary pull-right"
                        title={saveButtonTitle}
                        loading={loadingSaveSede}
                        onClick={saveSedeHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {isEdit && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <div className="panel-heading">Canchas</div>
                      <div className="panel-body">
                        <div className="row my-3">
                          <div className={`form-group col-md-10`}>
                            <label className="col-md-2 col-sm-2 control-label">
                              Nombre nueva cancha
                            </label>
                            <div className="col-md-10 col-sm-10">
                              <input
                                type="text"
                                className="form-control"
                                disabled={loadingSaveNewStadium}
                                value={newStadiumName}
                                onChange={(e) => {
                                  setNewStadiumName(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div style={{}}>
                            <Button
                              className="btn btn-primary"
                              title={<i className={`fa fa-plus`}></i>}
                              withTooltip={true}
                              loading={loadingSaveNewStadium}
                              tooltipTitle="Agregar cancha"
                              value={newStadiumName}
                              onClick={() => {
                                createStadium(newStadiumName);
                                setNewStadiumName("");
                              }}
                            />
                          </div>
                        </div>

                        <div className="clearfix"></div>
                        <div className="hr-line-dashed"></div>
                        <Table
                          loading={loadingAllSedeStadiums}
                          headers={[
                            {
                              label: "Nombre",
                              ref: "value",
                              className: "col-md-6 col-sm-5",
                            },
                            {
                              label: "Acciones",
                              ref: "actions",
                              className: "col-md-2 col-sm-3 text-center",
                            },
                          ]}
                          rows={addActions(getFilteredStadiums())}
                          notFoundMessage="No se encontraron canchas..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SedeForm;
