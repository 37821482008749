import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as eventsThunks from "../../redux/thunks/eventsThunks";
import eventsActions from "../../redux/reducers/eventsReducer/eventsActions";
import CheckIn from "./CheckIn";

const CheckInContainer = ({ match }) => {
  return <CheckIn />;
};

const mapState = (state) => ({});

const mapDispatch = (dispatch) => ({});

export default withRouter(connect(mapState, mapDispatch)(CheckInContainer));
