'use-strict'

import constants from './organizerConstants'

export default {
    setSelectedOrganizer: (payload) => ({
        type: constants.SET_SELECTED_ORGANIZER,
        payload
    }),
    setAllOrganizers: (payload) => ({
        type: constants.SET_ALL_ORGANIZERS,
        payload
    }),
    clearAllOrganizers: () => ({
        type: constants.CLEAR_ALL_ORGANIZERS
    }),
    setOrganizerKeyValue: (key, value) => ({
        type: constants.SET_ORGANIZER_KEY_VALUE,
        payload: { key, value }
    }),
    clearSingleOrganizer: () => ({
        type: constants.CLEAR_SINGLE_ORGANIZER
    }),
    pushNewOrganizer: (payload) => ({
        type: constants.PUSH_NEW_ORGANIZER,
        payload
    }),
    setSingleOrganizer: (payload) => ({
        type: constants.SET_SINGLE_ORGANIZER,
        payload
    }),
    replaceOrganizer: (payload) => ({
        type: constants.REPLACE_ORGANIZER,
        payload
    }),
    deleteOrganizer: (organizerId) => ({
        type: constants.DELETE_ORGANIZER,
        organizerId
    }),
}