import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MatchesList from "./MatchesList";
import { history } from "../../redux/store";
import path from "path";
import matchActions from "../../redux/reducers/matchReducer/matchActions";
import * as matchThunks from "../../redux/thunks/matchThunks";
import stadiumActions from "../../redux/reducers/stadiumReducer/stadiumActions";
import * as stadiumThunks from "../../redux/thunks/stadiumThunks";

const MatchesListContainer = ({
  stadiums,
  location,
  matches,
  loading,
  clearOrganizerMatches,
  clearAllStadiums,
  loadOrganizerMatches,
  loadAllStadiums,
  match,
}) => {
  const organizerId = match.params.organizerId;
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 0;

  useEffect(() => {
    clearOrganizerMatches();
    clearAllStadiums();
    loadAllStadiums(organizerId);

    const savedFilters = JSON.parse(localStorage.getItem("matchFilters"));

    if (savedFilters) {
      loadOrganizerMatches(
        organizerId,
        DEFAULT_PAGE,
        DEFAULT_PAGE_SIZE,
        savedFilters
      );
    } else {
      loadOrganizerMatches(organizerId, DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
    }
  }, [organizerId]);

  const onClickGoToWorksheetHandler = (
    matchId,
    tournamentId,
    fromMatch = false
  ) => {
    let newPath = path.resolve(
      location.pathname,
      "..",
      "tournaments",
      tournamentId,
      "matches",
      matchId,
      "worksheet"
    );

    if (fromMatch) {
      newPath += "?fromMatch=true";
    }

    history.push(newPath);
  };

  const onClickGotoWorksheetOfflineHandler = (matchId, tournamentId) =>
    history.push(
      path.resolve(
        location.pathname,
        "..",
        "tournaments",
        tournamentId,
        "matches",
        matchId,
        "worksheetOffline"
      )
    );

  const onRefreshMatches = (page, pageSize, filters) => {
    clearOrganizerMatches();
    loadOrganizerMatches(organizerId, page, pageSize, filters);
  };

  return (
    <MatchesList
      onClickGoToWorksheetHandler={onClickGoToWorksheetHandler}
      onClickGotoWorksheetOfflineHandler={onClickGotoWorksheetOfflineHandler}
      loadingMatches={loading.loadOrganizerMatches}
      matches={matches}
      defaultPage={DEFAULT_PAGE}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      onRefreshMatches={onRefreshMatches}
      stadiums={stadiums}
    />
  );
};

const mapState = (state) => ({
  matches: state.match.organizerMatches,
  loading: state.loading.match,
  stadiums: state.stadium.all,
});

const mapDispatch = (dispatch) => ({
  clearOrganizerMatches: () => dispatch(matchActions.clearOrganizerMatches()),
  loadOrganizerMatches: (organizerId, page, pageSize, filters) =>
    dispatch(
      matchThunks.loadOrganizerMatches(organizerId, page, pageSize, filters)
    ),
  loadAllStadiums: (organizerId) =>
    dispatch(stadiumThunks.loadAllStadiums(organizerId)),
  clearAllStadiums: () => dispatch(stadiumActions.clearAllStadiums()),
});

export default withRouter(connect(mapState, mapDispatch)(MatchesListContainer));
