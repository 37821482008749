import React, { useEffect, useState } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from '../../../redux/store'
import WorksheetIncidenceType from './WorksheetIncidenceType'
import worksheetActions from '../../../redux/reducers/worksheetReducer/worksheetActions'
import path from 'path'
import keepOrganizer from '../../../utils/functions/keepOrganizer'

const WorksheetIncidenceTypeContainer = ({
    match,
    worksheet,
    pushNewRoadmapItem
}) => {

    const organizerId = match.params.organizerId
    const tournamentId = match.params.tournamentId
    const matchId = match.params.matchId

    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        if (!worksheet.isReady) {
            history.push(
                `/organizers/${organizerId}/tournaments/${tournamentId}/matches/${matchId}/worksheet`
            )
        }
        setIsMounted(true)
    }, [])

    useEffect(() => {
        if (isMounted) history.push(keepOrganizer(''))
    }, [organizerId])

    const onClickIncidenceHandler = (ref, name) => {
        pushNewRoadmapItem(name)
        return history.push(path.resolve(match.url, '..', ref, 'create'))
    }

    const getComponent = () => {
        const incidenceCategoryRef = match.params.incidenceCategory
        const incidenceCategory = worksheet.incidenceCategories.find(category => category.ref === incidenceCategoryRef)
        if (!incidenceCategory.types.length) {
            return <Redirect to={`${match.url}/create`} />
        }
        return (
            <WorksheetIncidenceType 
                types={incidenceCategory.types}
                onClickIncidence={onClickIncidenceHandler}
            />
        )
    }

    return getComponent()
}

const mapState = state => ({
    worksheet: state.worksheet
})

const mapDispatch = dispatch => ({
    pushNewRoadmapItem: (item) => dispatch(worksheetActions.pushNewRoadmapItem(item))
})

export default withRouter(connect(mapState, mapDispatch)(WorksheetIncidenceTypeContainer))