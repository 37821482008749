import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as lineupThunks from "../../../../redux/thunks/lineupThunks";
import lineupActions from "../../../../redux/reducers/lineupReducer/lineupActions";
import * as teamThunks from "../../../../redux/thunks/teamThunks";
import teamActions from "../../../../redux/reducers/teamReducer/teamActions";
import * as playerInLineupThunks from "../../../../redux/thunks/playerInLineupThunks";
import * as tournamentThunks from "../../../../redux/thunks/tournamentThunks";
import playerActions from "../../../../redux/reducers/playerReducer/playerActions";
import { actions as uiActions } from "../../../../redux/reducers/uiReducer";
import { actions as alertActions } from "../../../../redux/reducers/alertReducer";
import LineUpList from "./LineUpList";

const LineUpListContainer = ({
  tournamentEditing,
  organizerId,
  tournamentId,
  loading,
  loadAllLineups,
  clearAllLineups,
  lineups,
  loadAllTeams,
  clearAllTeams,
  teams,
  createNewLineup,
  deleteLineup,
  uiOptions,
  hideLineupPlayersModal,
  showLineupPlayersModal,
  player,
  clearSinglePlayer,
  setPlayerKeyValue,
  addPlayerToLineup,
  players,
  setSinglePlayer,
  clearAllPlayers,
  loadLineup,
  setSelectedLineup,
  updatePlayer,
  deletePlayer,
  showDeleteButton,
  deletePlayerImage,
  addLineupCaptain,
  updateTournament,
  uploadMasiveLinupPlayers,
  downloadLineupsAndPlayers,
  addLineupBonus,
  show,
  floatingAlert,
}) => {
  useEffect(() => {
    clearAllLineups();
    clearAllTeams();

    if (tournamentEditing) {
      loadAllLineups(organizerId, tournamentId);
      loadAllTeams(organizerId);
    }
  }, []);

  const createNewLineupHandler = (teamId) =>
    createNewLineup(organizerId, tournamentId, teamId);

  const deleteLineupHandler = (lineupId) =>
    deleteLineup(organizerId, tournamentId, lineupId);

  const addPlayerToLineupHandler = () =>
    addPlayerToLineup(organizerId, tournamentId);

  const updatePlayerHandler = (playerId, image) =>
    updatePlayer(organizerId, tournamentId, playerId, image);

  const deletePlayerHandler = (playerId) =>
    deletePlayer(organizerId, tournamentId, playerId);

  const deletePlayerImageHandler = (playerId) =>
    deletePlayerImage(organizerId, playerId);

  const addLineupCaptainHandler = (lineupId, dni) =>
    addLineupCaptain(organizerId, tournamentId, lineupId, dni);

  const uploadMasiveLinupPlayersHandler = (file) =>
    uploadMasiveLinupPlayers(organizerId, tournamentId, file);

  const downloadLineupsAndPlayersHandler = () =>
    downloadLineupsAndPlayers(organizerId, tournamentId);

  const updateTournamentHandler = () =>
    updateTournament(organizerId, tournamentId);

  const openLineupPlayersModal = (lineupId) => {
    showLineupPlayersModal();
    loadLineup(organizerId, tournamentId, lineupId);
    setSelectedLineup(lineupId);
  };

  const addLineupBonusHandler = (lineupId, teamId, bonusPoints, notas) =>
    addLineupBonus(
      organizerId,
      tournamentId,
      lineupId,
      teamId,
      bonusPoints,
      notas
    );

  return (
    show && (
      <LineUpList
        lineups={lineups}
        loadingFetchLineups={loading.lineup.loadAllLineups}
        teams={teams}
        createNewLineup={createNewLineupHandler}
        deleteLineup={deleteLineupHandler}
        showPlayersModal={uiOptions.showLineupPlayersModal}
        hidePlayersModal={hideLineupPlayersModal}
        player={player}
        players={players}
        clearSinglePlayer={clearSinglePlayer}
        setPlayerKeyValue={setPlayerKeyValue}
        addPlayerToLineup={addPlayerToLineupHandler}
        setSinglePlayer={setSinglePlayer}
        loadingDeleteLineup={loading.lineup.deleteLineup}
        loadingAddPlayerToLineup={loading.player.addPlayerToLineup}
        openLineupPlayersModal={openLineupPlayersModal}
        updatePlayer={updatePlayerHandler}
        clearAllPlayers={clearAllPlayers}
        loadingFetchPlayers={loading.lineup.loadLineup}
        loadingUpdatePlayer={loading.player.updatePlayer}
        loadingDeletePlayer={loading.player.deletePlayer}
        deletePlayerHandler={deletePlayerHandler}
        showDeleteButton={showDeleteButton}
        deletePlayerImageHandler={deletePlayerImageHandler}
        addLineupCaptain={addLineupCaptainHandler}
        updateTournament={updateTournamentHandler}
        uploadMasiveLinupPlayersHandler={uploadMasiveLinupPlayersHandler}
        refreshLineups={() => loadAllLineups(organizerId, tournamentId)}
        downloadLineupsAndPlayersHandler={downloadLineupsAndPlayersHandler}
        addLineupBonus={addLineupBonusHandler}
        floatingAlert={floatingAlert}
      />
    )
  );
};

const mapState = (state) => ({
  loading: state.loading,
  lineups: state.lineup.all,
  teams: state.team.all,
  uiOptions: state.ui.tournament,
  player: state.player.single,
  players: state.player.all,
});

const mapDispatch = (dispatch) => ({
  loadAllLineups: (organizerId, tournamentId) =>
    dispatch(lineupThunks.loadAllLineups(organizerId, tournamentId)),
  loadAllTeams: (organizerId) => dispatch(teamThunks.loadAllTeams(organizerId)),
  clearAllTeams: () => dispatch(teamActions.clearAllTeams()),
  createNewLineup: (organizerId, tournamentId, teamId) =>
    dispatch(lineupThunks.createNewLineup(organizerId, tournamentId, teamId)),
  clearAllLineups: () => dispatch(lineupActions.clearAllLineups()),
  deleteLineup: (organizerId, tournamentId, lineupId) =>
    dispatch(lineupThunks.deleteLineup(organizerId, tournamentId, lineupId)),
  hideLineupPlayersModal: () => dispatch(uiActions.hideLineupPlayersModal()),
  showLineupPlayersModal: () => dispatch(uiActions.showLineupPlayersModal()),
  clearSinglePlayer: () => dispatch(playerActions.clearSinglePlayer()),
  setPlayerKeyValue: (key, value) =>
    dispatch(playerActions.setPlayerKeyValue(key, value)),
  addPlayerToLineup: (organizerId, tournamentId) =>
    dispatch(playerInLineupThunks.addPlayerToLineup(organizerId, tournamentId)),
  setSinglePlayer: (playerId) =>
    dispatch(playerInLineupThunks.setSinglePlayer(playerId)),
  clearAllPlayers: () => dispatch(playerActions.clearAllPlayers()),
  loadLineup: (organizerId, tournamentId, lineupId) =>
    dispatch(lineupThunks.loadLineup(organizerId, tournamentId, lineupId)),
  setSelectedLineup: (lineupId) =>
    dispatch(lineupThunks.setSelectedLineup(lineupId)),
  updatePlayer: (organizerId, tournamentId, playerId, image) =>
    dispatch(
      playerInLineupThunks.updatePlayer(
        organizerId,
        tournamentId,
        playerId,
        image
      )
    ),
  deletePlayer: (organizerId, tournamentId, playerId) =>
    dispatch(
      playerInLineupThunks.deletePlayer(organizerId, tournamentId, playerId)
    ),
  deletePlayerImage: (organizerId, playerId) =>
    dispatch(playerInLineupThunks.deletePlayerImage(organizerId, playerId)),
  addLineupCaptain: (organizerId, tournamentId, lineupId, dni) =>
    dispatch(
      lineupThunks.addLineupCaptain(organizerId, tournamentId, lineupId, dni)
    ),
  updateTournament: (organizerId, tournamentId) =>
    dispatch(tournamentThunks.updateTournament(organizerId, tournamentId)),
  uploadMasiveLinupPlayers: (organizerId, tournamentId, file) =>
    dispatch(
      lineupThunks.uploadMasiveLinupPlayers(organizerId, tournamentId, file)
    ),
  downloadLineupsAndPlayers: (organizerId, tournamentId) =>
    dispatch(lineupThunks.downloadLineupsAndPlayers(organizerId, tournamentId)),
  addLineupBonus: (
    organizerId,
    tournamentId,
    lineupId,
    teamId,
    bonusPoints,
    bonusPointsNotes
  ) =>
    dispatch(
      lineupThunks.addLineupBonus(
        organizerId,
        tournamentId,
        lineupId,
        teamId,
        bonusPoints,
        bonusPointsNotes
      )
    ),
  floatingAlert: (message, type, timer) =>
    dispatch(alertActions.showFloatingAlert(message, type, timer)),
});

export default withRouter(connect(mapState, mapDispatch)(LineUpListContainer));
