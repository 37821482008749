'use-strict'

export default {
    SET_ALL_TEAMS: '@@team/SET_ALL_TEAMS',
    SET_TEAM_KEY_VALUE: '@@team/SET_TEAM_KEY_VALUE',
    SET_SINGLE_TEAM: '@@team/SET_SINGLE_TEAM',
    PUSH_NEW_TEAM: '@@team/PUSH_NEW_TEAM',
    REPLACE_TEAM: '@@team/REPLACE_TEAM',
    DELETE_TEAM: '@@team/DELETE_TEAM',
    SET_SELECTED_TEAM: '@@team/SET_SELECTED_TEAM',
    CLEAR_ALL_TEAMS: '@@team/CLEAR_ALL_TEAMS',
    CLEAR_SINGLE_TEAM: '@@team/CLEAR_SINGLE_TEAM'
}