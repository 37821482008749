import React, { createRef, useEffect, useState } from "react";
import Button from "../../../utils/components/Button";
import * as env from "../../../config/env";
import moment from "moment";
import TeamImage from "../../../assets/images/team.png";
import ParticipantConfigModal from "../participants/ParticipantConfigModal";
import BrandImageUploader from "../../../utils/components/ImageUploader/BrandImageUploader";
import DatetimePicker from "../../../utils/components/DatetimePicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EventsForm = ({
  saveEvent,
  loading,
  eventsCategories,
  eventsType,
  sedes,
  setEventKeyValue,
  eventIsEdit,
  event,
  organizerId,
}) => {
  const today = new Date();

  const brandUploaderRef = createRef();
  const brandUploaderBgRef = createRef();

  const [disabledEdit, setDisabledEdit] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [inviteCantError, setInviteCantError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [finishDateError, setFinishDateError] = useState(false);
  const [sedeError, setSedeError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [eventCategoryError, setEventCategoryError] = useState(false);
  const [eventTypeError, setEventTypeError] = useState(false);
  const [isImageDeleted, setIsImageDeleted] = useState(false);
  const [isImageBgDeleted, setIsImageBgDeleted] = useState(false);
  const [valueTextArea, setValueTextArea] = useState(
    event.event_description || ""
  );

  useEffect(() => {
    setDisabledEdit(eventIsEdit);
  }, []);

  const disable = () => loading || disabledEdit;

  const saveEventHandler = () => {
    let hasError = false;

    const isBefore = (date1, date2) =>
      new Date(date1).getTime() < new Date(date2).getTime();

    if (event.name === "") {
      hasError = true;
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (event.event_description === "") {
      hasError = true;
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }

    if (event.event_invite_cant === 0 || event.event_invite_cant === "") {
      hasError = true;
      setInviteCantError(true);
    } else {
      setInviteCantError(false);
    }

    if (event.event_type === "") {
      hasError = true;
      setEventTypeError(true);
    } else {
      setEventTypeError(false);
    }

    if (event.event_category === "") {
      hasError = true;
      setEventCategoryError(true);
    } else {
      setEventCategoryError(false);
    }

    if (event.event_image === "") {
      hasError = true;
      setImageError(true);
    } else {
      setImageError(false);
    }

    if (event.event_sede === "") {
      hasError = true;
      setSedeError(true);
    } else {
      setSedeError(false);
    }

    if (!event.event_date_start) {
      hasError = true;
      setStartDateError(true);
    } else if (isBefore(event.event_date_start, today)) {
      hasError = true;
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }

    if (!event.event_date_finish) {
      hasError = true;
      setFinishDateError(true);
    } else if (!isBefore(event.event_date_finish, event.event_date_start)) {
      setFinishDateError(false);
    } else {
      hasError = true;
      setFinishDateError(true);
    }
    if (hasError) return;

    const formatDateTime = (date) => {
      return new Date(date).toISOString().replace("T", " ").substring(0, 19);
    };

    const eventData = {
      name: event.name,
      event_date_start: formatDateTime(event.event_date_start),
      event_date_finish: formatDateTime(event.event_date_finish),
      event_date: formatDateTime(event.event_date_start),
      event_sede: eventIsEdit ? event.event_sede.id : event.event_sede,
      event_description: event.event_description,
      event_type: eventIsEdit ? event.event_type.id : event.event_type,
      event_category: eventIsEdit
        ? event.event_category.id
        : event.event_category,
      event_image: event?.event_image
        ? event?.event_image?.event_medium
        : { unlink: true },
      event_background: event?.event_background
        ? event?.event_background?.event_medium
        : { unlink: true },
      event_inscription_start: formatDateTime(event.event_date_start),
      event_inscription_finish: formatDateTime(event.event_date_finish),
      event_invite_cant: event.event_invite_cant,
      event_create_date: today.toISOString().replace("T", " ").substring(0, 19),
      organizer: organizerId,
      status: 1,
      is_free: 1,
    };

    if (eventIsEdit) {
      const brandImage = brandUploaderRef.current.getFormData();
      const brandImageBg = brandUploaderBgRef.current.getFormData();
      saveEvent(eventData, brandImage, brandImageBg);
    } else {
      saveEvent(eventData);
    }

    setDisabledEdit(true);
  };

  return (
    <div className="ibox-content">
      <div className="panel panel-default ">
        <div
          className="panel-heading"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p style={{ margin: 0 }}>Datos del evento</p>
          <div
            style={{
              display: "flex",
              gap: "2rem",
              flexDirection: "row-reverse",
            }}
          >
            {eventIsEdit && (
              <Button
                title={
                  <span>
                    <i
                      className={`fa ${
                        disabledEdit ? "fa-pencil" : "fa-times"
                      }`}
                    ></i>
                    &nbsp;Evento
                  </span>
                }
                className="btn btn-warning pull-right"
                onClick={() => setDisabledEdit(!disabledEdit)}
                withTooltip={true}
                tooltipTitle="Editar evento"
              />
            )}
          </div>
        </div>
        <div
          style={{ display: eventIsEdit ? "flex" : "" }}
          className={`panel-body ${eventIsEdit ? "column-direction" : ""}`}
        >
          <div
            style={{ flex: 2 }}
            className={`form-group ${imageError && "has-error"}`}
          >
            {eventIsEdit && (
              <div
                style={{ display: "flex", flexDirection: "column", gap: 25 }}
              >
                <div className="col-md-12">
                  <BrandImageUploader
                    id="image"
                    ref={brandUploaderRef}
                    title={"Imagen de perfil del evento"}
                    image={
                      isImageDeleted
                        ? TeamImage
                        : event?.event_image?.event_medium
                        ? event?.event_image?.event_medium
                        : TeamImage
                    }
                    validExtensions={["jpg", "jpeg", "png"]}
                    deleteImage={() => {
                      setIsImageDeleted(true),
                        setEventKeyValue("event_image", TeamImage);
                    }}
                    minWidth={200}
                    minHeight={200}
                    aspectRatio={1}
                    enableUploader={!disabledEdit}
                    showDeleteImageButtom={!disabledEdit}
                    verifiedImage={
                      event?.event_image?.event_medium &&
                      event?.event_image?.event_medium
                    }
                    confirmationTitle={"Eliminar imagen"}
                    confirmationBody={
                      "¿Estás seguro que quieres eliminar la imagen de perfil de este evento?"
                    }
                  />
                  {!disabledEdit && (
                    <p style={{ color: "gray", textAlign: "center" }}>
                      La altura y el ancho de la imagen deben ser
                      proporcionales.
                    </p>
                  )}
                </div>
                <div className="col-md-12">
                  <BrandImageUploader
                    id="imageBg"
                    ref={brandUploaderBgRef}
                    title={"Imagen de portada del evento"}
                    image={
                      isImageBgDeleted
                        ? TeamImage
                        : event?.event_background?.event_medium
                        ? event?.event_background?.event_medium
                        : TeamImage
                    }
                    validExtensions={["jpg", "jpeg", "png"]}
                    deleteImage={() => {
                      setIsImageBgDeleted(true),
                        setEventKeyValue("event_background", TeamImage);
                    }}
                    minWidth={300}
                    minHeight={100}
                    aspectRatio={16 / 9}
                    enableUploader={!disabledEdit}
                    showDeleteImageButtom={!disabledEdit}
                    verifiedImage={
                      event?.event_background &&
                      event?.event_background?.event_medium
                    }
                    confirmationTitle={"Eliminar imagen"}
                    confirmationBody={
                      "¿Estás seguro que quieres eliminar la imagen de portada de este evento?"
                    }
                  />
                  {!disabledEdit && (
                    <p style={{ color: "gray", textAlign: "center" }}>
                      La altura y el ancho de la imagen deben estar en formato
                      16:9
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div style={{ flex: 3 }}>
            <div className="panel panel-default">
              <div className="panel-heading">Datos</div>
              <div className="clearfix"></div>
              <div className="hr-line-dashed"></div>
              <div className={`form-group ${nameError && "has-error"}`}>
                <label className="col-md-5 col-sm-5 control-label">
                  Nombre *
                </label>
                <div className="col-md-7 col-sm-7">
                  <input
                    type="text"
                    maxLength={60}
                    className="form-control"
                    value={event.name}
                    disabled={disable()}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value.length > 0 && value[0] === " ") {
                        value = value.trimStart();
                      }
                      setEventKeyValue("name", value);
                    }}
                  />
                  {nameError && (
                    <span className="help-block">El nombre es requerido.</span>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="hr-line-dashed"></div>
              <div className={`form-group ${descriptionError && "has-error"}`}>
                <label className="col-md-5 col-sm-5 control-label">
                  Descripcion del evento *
                </label>
                <div className="col-md-7 col-sm-7">
                  <textarea
                    type="text"
                    rows={5}
                    maxLength={400}
                    className="form-control"
                    disabled={disable()}
                    value={event.event_description}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value.length > 0 && value[0] === " ") {
                        value = value.trimStart();
                      }
                      setEventKeyValue("event_description", value);
                    }}
                  />
                  {/* <ReactQuill
                    className="quill-editor"
                    readOnly={disable()}
                    theme="snow"
                    value={valueTextArea}
                    onChange={(content) =>  // Trim the starting space if any
                    if (content.length > 0 && content[0] === " ") {
                      content = content.trimStart();
                    }

                    console.log("content", content);
                    // Update local state and the parent state
                    setValueTextArea(content);
                    setEventKeyValue("event_description", content);}
                  /> */}
                  {descriptionError && (
                    <span className="help-block">
                      La descripcion del evento es requerida.
                    </span>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="hr-line-dashed"></div>
              <div className={`form-group ${sedeError && "has-error"}`}>
                <label className="col-md-5 col-sm-5 control-label">
                  Sede *{" "}
                </label>
                <div className="col-md-7 col-sm-7">
                  <select
                    type="type"
                    min="2000"
                    className="form-control"
                    value={event.event_sede}
                    disabled={disable()}
                    onChange={(e) => {
                      setEventKeyValue("event_sede", e.target.value);
                    }}
                  >
                    <option value="">
                      {eventIsEdit ? `${event.event_sede.name}` : "Seleccione"}
                    </option>
                    {sedes.map((sede) => (
                      <option key={sede.id} value={sede.id}>
                        {sede.name}
                      </option>
                    ))}
                  </select>
                  {sedeError && (
                    <span className="help-block">La sede es requerida.</span>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="hr-line-dashed"></div>
              <div className={`form-group ${startDateError && "has-error"}`}>
                <label className="col-md-5 col-sm-5 control-label">
                  Fecha y hora de comienzo *
                </label>
                <div className="col-md-7 col-sm-7">
                  <DatetimePicker
                    showTime
                    className="form-control m-b"
                    selected={new Date(event.event_date_start)}
                    onChange={(date) => {
                      setEventKeyValue("event_date_start", date);
                    }}
                    value={event.event_date_start}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="yyyy-MM-dd HH:mm"
                    timeCaption="time"
                    disable={disable()}
                  />
                  {startDateError && (
                    <span className={`${startDateError && "help-block"}`}>
                      La fecha de comienzo no puede ser anterior a hoy ni estar
                      vacía.
                    </span>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="hr-line-dashed"></div>
              <div className={`form-group ${finishDateError && "has-error"}`}>
                <label className="col-md-5 col-sm-5 control-label">
                  Fecha y hora de finalización *
                </label>
                <div className="col-md-7 col-sm-7">
                  <DatetimePicker
                    showTime
                    className="form-control m-b"
                    selected={new Date(event.event_date_finish)}
                    onChange={(date) => {
                      setEventKeyValue("event_date_finish", date);
                    }}
                    value={event.event_date_finish}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="yyyy-MM-dd HH:mm"
                    timeCaption="time"
                    disable={disable()}
                  />
                  {finishDateError && (
                    <span className={`${finishDateError && "help-block"}`}>
                      La fecha de comienzo no puede ser anterior a hoy ni estar
                      vacía.
                    </span>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="hr-line-dashed"></div>
              <div className={`form-group ${inviteCantError && "has-error"}`}>
                <label className="col-md-5 col-sm-5 control-label">
                  Cantidad de invitados *
                </label>
                <div className="col-md-7 col-sm-7">
                  <input
                    type="number"
                    maxLength={80}
                    className="form-control"
                    value={event.event_invite_cant}
                    disabled={disable()}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value < 1) {
                        e.target.value = "";
                        setEventKeyValue("event_invite_cant", "");
                      } else {
                        setEventKeyValue("event_invite_cant", value);
                      }
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      if (e.target.value < 1) {
                        e.target.value = "";
                      }
                    }}
                  />
                  {inviteCantError && (
                    <span className="help-block">
                      La cantidad de invitados es requerida.
                    </span>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="hr-line-dashed"></div>
              <div
                className={`form-group ${eventCategoryError && "has-error"}`}
              >
                <label className="col-md-5 col-sm-5 control-label">
                  Categoria del evento *
                </label>
                <div className="col-md-7 col-sm-7">
                  <select
                    type="type"
                    maxLength={60}
                    className="form-control"
                    value={event.event_category}
                    disabled={disable()}
                    onChange={(e) => {
                      setEventKeyValue("event_category", e.target.value);
                    }}
                  >
                    <option value="">
                      {eventIsEdit
                        ? `${event.event_category.name}`
                        : "Seleccione"}
                    </option>
                    {eventsCategories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  {eventCategoryError && (
                    <span className="help-block">
                      La categoria del evento es requerida.
                    </span>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="hr-line-dashed"></div>
              <div className={`form-group ${eventTypeError && "has-error"}`}>
                <label className="col-md-5 col-sm-5 control-label">
                  Tipo de evento *
                </label>
                <div className="col-md-7 col-sm-7">
                  <select
                    type="type"
                    maxLength={60}
                    className="form-control"
                    value={event.event_type}
                    disabled={disable()}
                    onChange={(e) => {
                      setEventKeyValue("event_type", e.target.value);
                    }}
                  >
                    <option value="">
                      {eventIsEdit ? `${event.event_type.name}` : "Seleccione"}
                    </option>
                    {eventsType.map((event) => (
                      <option key={event.id} value={event.id}>
                        {event.name}
                      </option>
                    ))}
                  </select>
                  {eventTypeError && (
                    <span className="help-block">
                      El tipo de evento es requerido.
                    </span>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="hr-line-dashed"></div>
            </div>
            <div style={{ marginTop: 10 }}>
              <Button
                className="btn btn-primary pull-right"
                title={eventIsEdit ? "Guardar" : "Crear"}
                loading={loading}
                onClick={saveEventHandler}
                disabled={disable()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsForm;
