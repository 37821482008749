import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../utils/components/Breadcrumbs";
import Button from "../../utils/components/Button";
import Table from "../../utils/components/Table";
import keepOrganizer from "../../utils/functions/keepOrganizer";
import MatchFiltersModal from "./MatchFiltersModal";
import removeEmptyValueFromObject from "../../utils/functions/removeEmptyValueFromObject";
import * as env from "../../config/env";
import moment from "moment";

const MatchesList = ({
  matches,
  stadiums,
  defaultPage,
  defaultPageSize,
  loadingMatches,
  onClickGoToWorksheetHandler,
  onClickGotoWorksheetOfflineHandler,
  onRefreshMatches,
}) => {
  const [searchText, setSearchText] = useState("");
  const [showMatchFiltersModal, setShowMatchFiltersModal] = useState(false);
  const [hasMatchFilter, setHasMatchFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);
  const [matchFilters, setMatchFilters] = useState({
    datetimeStart: "",
    datetimeEnd: "",
    stadium: "",
  });

  useEffect(() => {
    const storedHasMatchFilter = localStorage.getItem("hasMatchFilter");
    if (storedHasMatchFilter !== null) {
      setHasMatchFilter(JSON.parse(storedHasMatchFilter));
    }
  }, []);

  const applyFilterHandler = (filters) => {
    setHasMatchFilter(true);
    setMatchFilters(filters);
    localStorage.setItem("hasMatchFilter", JSON.stringify(true));
    localStorage.setItem("matchFilters", JSON.stringify(filters));
    setCurrentPage(defaultPage);
    setCurrentPageSize(currentPageSize);
    onRefreshMatches(
      defaultPage,
      currentPageSize,
      removeEmptyValueFromObject(filters)
    );
  };

  const cleanFiltersHandler = () => {
    setHasMatchFilter(false);
    setMatchFilters({
      datetimeStart: "",
      datetimeStart: "",
      stadium: "",
    });
    localStorage.removeItem("hasMatchFilter");
    localStorage.removeItem("matchFilters");

    onRefreshMatches(currentPage, currentPageSize, {});
  };

  const getRowsWithActions = (matches) =>
    matches.map((match) => {
      return {
        ...match,
        datetime: match.datetime,
        actions: (
          <div style={{ display: "flex", gap: 5, justifyContent: "center" }}>
            <Button
              className="btn btn-info"
              title={<i className="fa fa-play"></i>}
              withTooltip={true}
              tooltipTitle="Planillero"
              onClick={() =>
                onClickGoToWorksheetHandler(match.id, match.tournamentId, true)
              }
              disabled={!match.localTeam || !match.visitingTeam}
            />
            <Button
              className="btn btn-success"
              title={<i className="fa fa-file"></i>}
              withTooltip={true}
              tooltipTitle="Planillero offline"
              onClick={() =>
                onClickGotoWorksheetOfflineHandler(match.id, match.tournamentId)
              }
              disabled={!match.localTeam || !match.visitingTeam}
            />
          </div>
        ),
      };
    });

  const getFilteredMatches = () =>
    matches.filter((match) => {
      const foundLocalTeamName =
        match.localTeam
          .trim()
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1;
      const foundVisitingTeamName =
        match.visitingTeam
          .trim()
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1;
      return foundLocalTeamName || foundVisitingTeamName;
    });

  return (
    <>
      <Breadcrumbs
        title="Partidos"
        opts={[
          {
            title: "Inicio",
            path: keepOrganizer("/"),
          },
          {
            title: "Partidos",
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox m-t">
            <div className="ibox-title">
              <div className="row m-b">
                <div className="col-md-12">
                  {hasMatchFilter && (
                    <Button
                      title={
                        <span>
                          <i className={`fa fa-filter`}></i>{" "}
                          <i className={`fa fa-times`}></i>
                        </span>
                      }
                      className="btn btn-danger btn-sm pull-right m-r-xs"
                      onClick={cleanFiltersHandler}
                      withTooltip={true}
                      tooltipTitle="Limpiar filtros"
                    />
                  )}
                  <Button
                    title={
                      <span>
                        <i className={`fa fa-filter`}></i>
                      </span>
                    }
                    className="btn btn-primary btn-sm pull-right m-r-xs"
                    onClick={() => setShowMatchFiltersModal(true)}
                    withTooltip={true}
                    tooltipTitle="Aplicar filtros"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar partidos en esta página..."
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="ibox-content">
              <Table
                loading={loadingMatches}
                headers={[
                  {
                    label: "Torneo",
                    ref: "tournament",
                    className: "col-sm-2 hidden-xs",
                  },
                  {
                    label: "Local",
                    ref: "localTeam",
                    className: "col-sm-2 hidden-xs",
                  },
                  {
                    label: "Local",
                    ref: "localTeam",
                    className: "col-xs-2 visible-xs",
                  },
                  {
                    label: "Visitante",
                    ref: "visitingTeam",
                    className: "col-sm-2 hidden-xs",
                  },
                  {
                    label: "Visit.",
                    ref: "visitingTeam",
                    className: "col-xs-2 visible-xs",
                  },
                  {
                    label: "Fecha y hora",
                    ref: "datetime",
                    className: "col-sm-2 col-xs-3",
                  },
                  {
                    label: "Cancha",
                    ref: "stadium",
                    className: "col-md-1 col-sm-2 text-center hidden-xs",
                  },
                  {
                    label: "Estado",
                    ref: "status",
                    className: "col-md-1 text-center hidden-sm hidden-xs",
                  },
                  {
                    label: "Resultado",
                    ref: "result",
                    className: "col-md-1 text-center hidden-sm hidden-xs",
                  },
                  {
                    label: "Acciones",
                    ref: "actions",
                    className: "col-sm-1 col-xs-3 text-center",
                  },
                ]}
                rows={getRowsWithActions(getFilteredMatches())}
                notFoundMessage="No se encontraron partidos..."
                itemsInPage={matches.length}
                defaultPage={currentPage}
                defaultPageSize={currentPageSize}
                onChangePage={(page, pageSize) => {
                  setCurrentPage(page);
                  setCurrentPageSize(pageSize);
                  onRefreshMatches(
                    page,
                    pageSize,
                    removeEmptyValueFromObject(matchFilters)
                  );
                }}
              />
              <MatchFiltersModal
                show={showMatchFiltersModal}
                handleShow={setShowMatchFiltersModal}
                stadiums={stadiums}
                filters={matchFilters}
                applyFilterHandler={applyFilterHandler}
                loading={loadingMatches}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchesList;
