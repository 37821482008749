import React, { useState } from "react";
import PropTypes from "prop-types";
import Spinner from "../../../utils/components/Spinner";
import InputColor from "react-input-color";
import CustomPhone from "../../../utils/components/CustomPhone";

const AppFormColors = ({
  loading,
  configColorVariables,
  getColorConfig,
  setColorConfig,
  imageBgNextMatchCard,
  imageCardsCard,
  logo,
  logoEnabled,
}) => {
  const [colorState, setColorState] = useState({});

  const handleColorChange = (color, name) => {
    setColorState((prevColors) => ({
      ...prevColors,
      [name]: color?.hex,
    }));
  };
  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        Variables de configuración de color de la aplicación
      </div>
      <div style={{ display: "flex" }} className="panel-body">
        <div className={`${loading && "hidden"} col-lg-8 col-md-6`}>
          {configColorVariables.map((configColorVariable, key) => (
            <div
              style={{ marginBottom: 30 }}
              className="row"
              key={key + configColorVariable.name}
            >
              <div style={{ marginTop: 10 }} className={"form-group"}>
                <label className="col-md-5 col-sm-3 control-label">
                  {configColorVariable.title}
                </label>
                <div className="col-md-6 col-sm-7">
                  <InputColor
                    initialHexColor={getColorConfig(configColorVariable.name)}
                    onChange={(color) => {
                      setColorConfig(color, configColorVariable.name);
                      handleColorChange(color, configColorVariable.name);
                    }}
                    placement="right"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-md-3">
          <CustomPhone
            colorState={colorState}
            imageBgNextMatchCard={imageBgNextMatchCard}
            imageCardsCard={imageCardsCard}
            logo={logo}
            logoEnabled={logoEnabled}
          />
          <div className="clearfix"></div>
          <Spinner show={loading} />
        </div>
      </div>
    </div>
  );
};

AppFormColors.propTypes = {
  loading: PropTypes.bool.isRequired,
  configColorVariables: PropTypes.array,
  getColorConfig: PropTypes.func.isRequired,
  setColorConfig: PropTypes.func.isRequired,
};

AppFormColors.defaultProps = {
  configColorVariables: [],
};

export default AppFormColors;
