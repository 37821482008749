'use-strict'

import httpClient from '../../../http/httpClient'

const httpLineup = (organizerId, tournamentId, teamId, lineupId) => {
    return httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}/teams/${teamId}/lineups/${lineupId}`)
}

const httpTournament = (organizerId, tournamentId) => {
    return httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}`)
}

const httpOrganizer = (organizerId) => httpClient(`/organizers/${organizerId}`)

const httpMatch = (organizerId, tournamentId, matchId) => {
    return httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}/matches/${matchId}`)
}

export default {
    addPlayerInLineup: (organizerId, tournamentId, teamId, lineupId, player) => {
        return httpLineup(organizerId, tournamentId, teamId, lineupId).post('/players', player)
    },
    updatePlayerInLineup: (organizerId, tournamentId, teamId, lineupId, playerId, player) => {
        return httpLineup(organizerId, tournamentId, teamId, lineupId).patch(`/players/${playerId}`, player)
    },
    deletePlayerInLineup: (organizerId, tournamentId, teamId, lineupId, playerId) => {
        return httpLineup(organizerId, tournamentId, teamId, lineupId).delete(`/players/${playerId}`)
    },
    loadAll: (organizerId) => {
        return httpOrganizer(organizerId).get('/players')
    },
    deletePlayer: (organizerId, playerId) => {
        return httpOrganizer(organizerId).delete(`/players/${playerId}`)
    },
    loadOne: (organizerId, playerId) => {
        return httpOrganizer(organizerId).get(`/players/${playerId}`)
    },
    updatePlayer: (organizerId, playerId, player) => {
        return httpOrganizer(organizerId).patch(`/players/${playerId}`, player)
    },
    loadAllInTournament: (organizerId, tournamentId) => {
        return httpTournament(organizerId, tournamentId).get('/players')
    },
    checkIn: (organizerId, tournamentId, matchId, playerId, teamType) => {
        return httpMatch(organizerId, tournamentId, matchId).post(`/players/${playerId}/${teamType}`)
    },
    checkOut: (organizerId, tournamentId, matchId, playerId) => {
        return httpMatch(organizerId, tournamentId, matchId).delete(`/players/${playerId}`)
    },
    checkInGuest: (organizerId, tournamentId, matchId, player) => {
        return httpMatch(organizerId, tournamentId, matchId).post('/players', player)
    },
    createIncidence: (organizerId, tournamentId, matchId, incidence) => {
        return httpMatch(organizerId, tournamentId, matchId).post('/incidences', incidence)
    },
    loadIncidences: (organizerId, tournamentId, matchId) => {
        return httpMatch(organizerId, tournamentId, matchId).get('/incidences')
    },
    deleteIncidence: (organizerId, tournamentId, matchId, incidenceId) => {
        return httpMatch(organizerId, tournamentId, matchId).delete(`/incidences/${incidenceId}`)
    },
    playerImages: (organizerId, playerId, data) => httpOrganizer(organizerId).post(`/players/${playerId}/media`, data),
    playerMedicalRecord: (organizerId, playerId, data) => httpOrganizer(organizerId).post(`/players/${playerId}/medicalrecords`, data),
    deletePlayerImage: (organizerId, playerId) => httpOrganizer(organizerId).delete(`/players/${playerId}/media`),
    deletePlayerMedicalRecord: (organizerId, playerId) => httpOrganizer(organizerId).delete(`/players/${playerId}/medicalrecord`),
}