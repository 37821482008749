import React, { useEffect } from "react";
import EventsForm from "./EventsForm";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import eventsActions from "../../../redux/reducers/eventsReducer/eventsActions";
import * as eventsThunks from "../../../redux/thunks/eventsThunks";
import * as sedeThunks from "../../../redux/thunks/sedeThunks";

const EventsFormContainer = ({
  event,
  sedes,
  eventsCategories,
  eventsType,
  loading,
  loadAllEventsCategory,
  loadAllEventsType,
  loadAllSedes,
  loadOneEvent,
  updateEvent,
  clearSingleEvent,
  organizerId,
  eventIsEdit,
  createNewEvent,
  setEventKeyValue,
  match,
}) => {
  const { eventId } = useParams();

  const createNewEventHandler = (event) => createNewEvent(event);

  const updateEventHandler = (event, image, imageBg) =>
    updateEvent(eventId, event, image, imageBg);

  const loadingFetchEvent = () => loading.event.loadOneEvent;

  const loadingSaveEvent = () =>
    loading.event.createNewEvent || loading.event.updateEvent;

  useEffect(() => {
    clearSingleEvent();
    loadAllEventsCategory();
    loadAllEventsType();
    loadAllSedes(organizerId);

    if (eventIsEdit) {
      loadOneEvent(eventId);
    }
  }, [organizerId, eventId]);

  return (
    <EventsForm
      saveEvent={eventIsEdit ? updateEventHandler : createNewEventHandler}
      loading={loadingFetchEvent() || loadingSaveEvent()}
      eventsCategories={eventsCategories}
      eventsType={eventsType}
      sedes={sedes}
      setEventKeyValue={setEventKeyValue}
      eventIsEdit={eventIsEdit}
      event={event}
      organizerId={organizerId}
    />
  );
};

const mapState = (state) => ({
  eventsCategories: state.event.eventsCategory,
  eventsType: state.event.eventsType,
  event: state.event.single,
  loading: state.loading,
  sedes: state.sede.all,
});

const mapDispatch = (dispatch) => ({
  createNewEvent: (event) => dispatch(eventsThunks.createNewEvent(event)),
  updateEvent: (eventId, event, image, imageBg) =>
    dispatch(eventsThunks.updateEvent(eventId, event, image, imageBg)),
  loadAllEventsCategory: () => dispatch(eventsThunks.loadAllEventsCategory()),
  loadAllEventsType: () => dispatch(eventsThunks.loadAllEventsType()),
  loadOneEvent: (eventId) => dispatch(eventsThunks.loadOneEvent(eventId)),
  loadAllSedes: (organizerId) => dispatch(sedeThunks.loadAllSedes(organizerId)),
  clearSingleEvent: () => dispatch(eventsActions.clearSingleEvent()),
  setEventKeyValue: (key, value) =>
    dispatch(eventsActions.setEventKeyValue(key, value)),
});

export default withRouter(connect(mapState, mapDispatch)(EventsFormContainer));
