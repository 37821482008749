import React, {useState} from 'react'
import Button from '../../../../utils/components/Button'
import MyModal from '../../../../utils/components/Modal'
import InputColor from "react-input-color"

const TableRowColorModal = ({
    show,
    handleShow,
    setTableRowColorKeyValue,
    tableRowColor,
    handlerTableRowSave,
    isDataReady,
    modalTitle,
    buttonSaveTitle,
    loadingSave
}) => {
    const [positionError, setPositionError] = useState(false)
    const [colorError, setColorError] = useState(false)

    const sendToSaveHandler = () => {
        let onError = false

        if (tableRowColor.rowNum === 0) {
            setPositionError(true)
            onError = true
        } else {
            setPositionError(false)
        }

        if(tableRowColor.color === '') {
            setColorError(true)
            onError= true
        } else {
            setColorError(false)
        }

        !onError && handlerTableRowSave ()
    }

    return (
        <MyModal
            show={show}
            title={modalTitle()}
            handleShow={handleShow}
            backdrop='static'
            body={
                <div className="row">
                    <div className="col-md-12">
                        <div className={`form-group ${positionError && 'has-error'}`}>
                            <label className="col-md-4 col-sm-6 control-label">Posición</label>
                            <div className="col-md-8 col-sm-6">
                                <input
                                    type="number"
                                    className="form-control"
                                    min="0"
                                    disabled={!isDataReady}
                                    value={tableRowColor.rowNum.toString()}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value)
                                        setPositionError(value === 0)
                                        setTableRowColorKeyValue('rowNum', value)
                                    }} />
                                {positionError && <span className="help-block">Debe indicar el número de posición.</span>}
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="hr-line-dashed"></div>
                        <div className={`form-group ${colorError && 'has-error'}`}>
                            <label className="col-md-4 col-sm-6 control-label">Color background</label>
                            <div className="col-md-8 col-sm-6">
                                <InputColor
                                    initialHexColor={tableRowColor.color}
                                    onChange={(color) => {
                                        setColorError(!!!color)
                                        setTableRowColorKeyValue('color', color.hex)
                                    }}
                                    placement="right"
                                />
                                {colorError && <span className="help-block">Debe indicar el color de background.</span>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            buttons={
                <Button
                    title={buttonSaveTitle()}
                    className="btn btn-primary"
                    onClick={sendToSaveHandler}
                    loading={loadingSave}
                />
            }
        />
    )
}

export default TableRowColorModal