"use-strict";

import constants from "./checkInConstants";

export default {
  setAllCheckIn: (payload) => ({
    type: constants.SET_ALL_CHECK_IN,
    payload,
  }),
  setCheckInKeyValue: (key, value) => ({
    type: constants.SET_CHECK_IN_KEY_VALUE,
    payload: { key, value },
  }),
  deleteCheckIn: (checkAccessId) => ({
    type: constants.DELETE_CHECK_IN,
    checkAccessId,
  }),
  clearAllCheckIn: () => ({
    type: constants.CLEAR_ALL_CHECK_IN,
  }),
  clearSingleCheckIn: () => ({
    type: constants.CLEAR_SINGLE_CHECK_IN,
  }),
  pushNewCheckIn: (payload) => ({
    type: constants.PUSH_NEW_CHECK_IN,
    payload,
  }),
  setSingleCheckIn: (payload) => ({
    type: constants.SET_SINGLE_CHECK_IN,
    payload,
  }),
};
