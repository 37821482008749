import React, { useEffect, useState } from "react";
import Table from "../../../utils/components/Table";
import Button from "../../../utils/components/Button";
import NewCheckInModal from "./NewCheckInModal";
import CheckInModalContainer from "./CheckInModalContainer";
import moment from "moment";
import * as env from "../../../config/env";

const CheckIn = ({
  checkInAll,
  deleteCheckIn,
  defaultPage,
  defaultPageSize,
  onRefreshUsers,
}) => {
  const DEBOUNCE_DELAY = 700;

  const [showCheckInModal, setShowCheckInModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     checkInAll(currentPage, currentPageSize);
  //   }, DEBOUNCE_DELAY);

  //   return () => clearTimeout(timer);
  // }, [currentPage, currentPageSize]);

  const getRowsWithActions = (checkInAll) => {
    const sortedCheckIns = checkInAll?.sort((a, b) => {
      return new Date(b.date_access) - new Date(a.date_access);
    });

    return sortedCheckIns?.map((checkIn) => {
      return {
        ...checkIn,
        dni: checkIn.document,
        name: checkIn.name,
        type: checkIn.type,
        inviteBy: checkIn.user_invite,
        dateAccess: moment
          .utc(checkIn.date_access)
          .local()
          .format(env.DATETIME_FORMAT),
        actions: (
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              className="btn btn-danger"
              title={<i className="fa fa-trash"></i>}
              withTooltip={true}
              tooltipTitle="Eliminar"
              withConfirmation={true}
              onClick={() => deleteCheckIn(checkIn.id)}
              // loading={loadingDeleteGroup}
              confirmationTitle="Eliminar accesos"
              onConfirmText="Confirmar"
              onCancelText="Cancelar"
              onCancelClass="btn-danger pull-left"
              confirmationBody="Se eliminaran todos los accesos. ¿Estás seguro que quieres eliminar?"
            />
          </div>
        ),
      };
    });
  };

  const getFilteredParticipants = () =>
    checkInAll?.filter((checkIn) => {
      const foundName =
        checkIn.document
          .trim()
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1;

      return foundName;
    });

  return (
    <div>
      <div className="ibox-title">
        <div className="row">
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              placeholder="Buscar registros por DNI ..."
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "1rem",
            }}
            className="col-md-3"
          >
            <Button
              className="btn btn-warning pull-right"
              title={
                <span>
                  <i className="fa fa-plus-circle"></i> Check in
                </span>
              }
              withTooltip={true}
              tooltipTitle="Nuevo check in"
              onClick={() => setShowCheckInModal(true)}
            />
          </div>
          <div className="ibox-content">
            <Table
              // loading={loadingMatches}
              headers={[
                {
                  label: "DNI",
                  ref: "dni",
                  className: "col-sm-2",
                },
                {
                  label: "Nombre",
                  ref: "name",
                  className: "col-sm-2",
                },
                {
                  label: "Tipo",
                  ref: "type",
                  className: "col-xs-2 hidden-xs",
                },
                {
                  label: "Invitado por",
                  ref: "inviteBy",
                  className: "col-sm-2 hidden-xs",
                },
                {
                  label: "Horario",
                  ref: "dateAccess",
                  className: "col-sm-2 col-xs-3",
                },
                {
                  label: "Accion",
                  ref: "actions",
                  className: "col-sm-2 col-xs-3",
                },
              ]}
              rows={getRowsWithActions(getFilteredParticipants())}
              notFoundMessage="No se encontraron invitados en este evento..."
              itemsInPage={checkInAll.length}
              defaultPage={currentPage}
              defaultPageSize={currentPageSize}
              onChangePage={(page, pageSize) => {
                setCurrentPage(page);
                setCurrentPageSize(pageSize);
                onRefreshUsers(page, pageSize);
              }}
            />
          </div>
        </div>
      </div>

      <CheckInModalContainer
        show={showCheckInModal}
        handleShow={setShowCheckInModal}
        currentPageSize={currentPageSize}
      />
    </div>
  );
};

export default CheckIn;
