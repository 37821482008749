'use-strict'

import httpClient from '../../../http/httpClient'

const http = () => httpClient('/organizers')

export default {
    loadAll: () => http().get(''),
    create: (organizer) => http().post('', organizer),
    loadOne: (organizerId) => http().get(`/${organizerId}`),
    update: (organizerId, organizer) => http().patch(`/${organizerId}`, organizer),
    delete: (organizerId) => http().delete(`/${organizerId}`),
    images: (organizerId, data) => http().post(`/${organizerId}/images`, data)
}