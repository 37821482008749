'use-strict'

import httpClient from '../../../http/httpClient'

const httpTournament = (organizerId, tournamentId) => {
    return httpClient(`/organizers/${organizerId}/tournaments/${tournamentId}`)
}

export default {
    loadAll: (organizerId, tournamentId) => httpTournament(organizerId, tournamentId).get('/dates'),
    create: (organizerId, tournamentId, date) => httpTournament(organizerId, tournamentId).post('/dates', date),
    delete: (organizerId, tournamentId, dateId) => httpTournament(organizerId, tournamentId).delete(`/dates/${dateId}`),
    loadOne: (organizerId, tournamentId, dateId) => httpTournament(organizerId, tournamentId).get(`/dates/${dateId}`),
    update: (organizerId, tournamentId, dateId, date) => httpTournament(organizerId, tournamentId).patch(`/dates/${dateId}`, date),
    displace: (organizerId, tournamentId, dateId, displace) => httpTournament(organizerId, tournamentId).post(`/dates/${dateId}/displaces/${displace}`)
}