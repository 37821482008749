'use-strict'

export default {
    SET_ALL_SANCTIONS: '@@sanction/SET_ALL_SANCTIONS',
    CLEAR_ALL_SANCTIONS: '@@sanction/CLEAR_ALL_SANCTIONS',
    SET_SANCTION_KEY_VALUE: '@@sanction/SET_SANCTION_KEY_VALUE',
    CLEAR_SINGLE_SANCTION: '@@sanction/CLEAR_SINGLE_SANCTION',
    SET_SINGLE_SANCTION:'@@sanction/SET_SINGLE_SANCTION',
    PUSH_NEW_SANCTION:'@@sanction/PUSH_NEW_SANCTION',
    DELETE_SANCTION: '@@sanction/DELETE_SANCTION',
    REPLACE_SANCTION: '@@sanction/REPLACE_SANCTION'
}