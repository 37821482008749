'use-strict'

import constants from './authConstants'

export default {
    setAuthorizedUser: (payload) => ({
        type: constants.SET_AUTHORIZED_USER,
        payload
    }),
    logout: () => ({
        type: constants.LOGOUT
    }),
}