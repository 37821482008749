import React, { useState } from 'react'
import Button from '../../../../utils/components/Button'
import Table from '../../../../utils/components/Table'
import only from '../../../../utils/functions/only'

const PointList = ({
    tournament,
    loadingFetchTournament,
    loadingSavePoints,
    setTournamentKeyValue,
    updateTournament,
    rowsMap,
    panelTitle
}) => {

    const [editingPointId, setEditingPointId] = useState('')

    const pointValue = (id, value) => {
        if (loadingFetchTournament) return '-'
        if (editingPointId === id) {
            return (
                <input
                    type="number"
                    className="form-control"
                    value={value}
                    onChange={(e) => {
                        setTournamentKeyValue(id, e.target.value)
                    }} />
            )
        }
        return value
    }

    const hardcodedRows = () => {
        const rows = []
        for (const id in only(tournament, rowsMap.map(elem => elem.id))) {
            const rowMap = rowsMap.find(elem => elem.id === id)
            rows.push({
                ...rowMap,
                value: pointValue(id, tournament[id])
            })
        }
        return rows
    }

    const addActions = (rows) => rows.map(row => {
        return {
            ...row,
            actions: (
                <div className="btn-separated-group">
                    <Button
                        className={`btn btn-${editingPointId === row.id ? 'primary' : 'warning'}`}
                        title={<i className={`fa ${editingPointId === row.id ? 'fa-save' : 'fa-pencil'}`}></i>}
                        withTooltip={true}
                        tooltipTitle={`${editingPointId === row.id ? 'Guardar' : 'Modificar'}`}
                        onClick={() => {
                            if (editingPointId) {
                                updateTournament()
                                setEditingPointId('')
                                return
                            }
                            setEditingPointId(row.id)
                        }}
                        loading={loadingSavePoints}
                        disabled={loadingFetchTournament || editingPointId && row.id !== editingPointId}
                    />
                </div>
            )
        }
    })

    return (
        <div className="panel panel-default">
            <div className="panel-heading">{panelTitle} (Puntos)</div>
            <div className="panel-body">
                <Table
                    headers={[{
                        label: 'Descripción',
                        ref: 'name',
                        className: 'col-md-6 col-sm-5'
                    }, {
                        label: 'Puntos',
                        ref: 'value',
                        className: 'col-md-3 col-sm-3 text-center'
                    }, {
                        label: 'Acciones',
                        ref: 'actions',
                        className: 'col-md-3 col-sm-4 text-center'
                    }]}
                    rows={addActions(hardcodedRows())}
                    notFoundMessage='No se encontraron puntos...'
                />
            </div>
        </div>
    )
}

export default PointList