import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Worksheet from "./Worksheet";
import * as worksheetThunks from "../../redux/thunks/worksheetThunks";
import { history } from "../../redux/store";
import worksheetActions from "../../redux/reducers/worksheetReducer/worksheetActions";
import keepOrganizer from "../../utils/functions/keepOrganizer";

// http://localhost:4005/organizers/4b299bbb-77b5-11ea-b292-0a2b6424056b/tournaments/4f32f842-77b5-11ea-b292-0a2b6424056b/matches/d05916d5-7b4d-11ea-b292-0a2b6424056b/worksheet

const WorksheetContainer = ({
  match,
  loadMatch,
  worksheet,
  clearMatch,
  clearIsReady,
  clearRoadMap,
  pushNewRoadmapItem,
  loading,
  loadIncidences,
  location,
  clearAllIntervalIds,
}) => {
  const organizerId = match.params.organizerId;
  const tournamentId = match.params.tournamentId;
  const matchId = match.params.matchId;

  const [fromMatch, setFromMatch] = useState(false);
  const [fromTournament, setFromTournament] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("fromMatch") === "true") {
      setFromMatch(true);
    }
    if (urlParams.get("fromTournament") === "true") {
      setFromTournament(true);
    }
  }, []);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    clearMatch();
    clearIsReady();
    clearRoadMap();

    loadMatch(organizerId, tournamentId, matchId);
    loadIncidences(organizerId, tournamentId, matchId);
    setIsMounted(true);

    worksheet.intervalIds.forEach(clearInterval);
    clearAllIntervalIds();
  }, []);

  useEffect(() => {
    if (isMounted) history.push(keepOrganizer(""));
  }, [organizerId]);

  const showIncidencesHandler = () => history.push(`${match.url}/incidences`);

  const createNewIncidenceHandler = (lineupId, teamId, teamName) => {
    pushNewRoadmapItem(teamName);
    return history.push(
      `${match.url}/lineups/${lineupId}/teams/${teamId}/incidence-categories`
    );
  };

  const getTeam = (type) => worksheet.match[`${type}Team`];

  const showStatusOptionsHandler = () => history.push(`${match.url}/status`);

  return (
    <Worksheet
      location={location}
      showIncidences={showIncidencesHandler}
      createNewIncidence={createNewIncidenceHandler}
      localTeam={getTeam("local")}
      visitingTeam={getTeam("visiting")}
      showStatusOptions={showStatusOptionsHandler}
      loadingFetchMatch={loading.loadMatch}
      incidences={worksheet.incidences}
      incidenceCategories={worksheet.incidenceCategories}
      fromMatch={fromMatch}
      setFromMatch={setFromMatch}
      fromTournament={fromTournament}
      setFromTournament={setFromTournament}
      organizerId={organizerId}
      tournamentId={tournamentId}
    />
  );
};

const mapState = (state) => ({
  worksheet: state.worksheet,
  loading: state.loading.worksheet,
});

const mapDispatch = (dispatch) => ({
  loadMatch: (organizerId, tournamentId, matchId) =>
    dispatch(worksheetThunks.loadMatch(organizerId, tournamentId, matchId)),
  clearMatch: () => dispatch(worksheetActions.clearMatch()),
  clearIsReady: () => dispatch(worksheetActions.clearIsReady()),
  pushNewRoadmapItem: (item) =>
    dispatch(worksheetActions.pushNewRoadmapItem(item)),
  clearRoadMap: () => dispatch(worksheetActions.clearRoadMap()),
  loadIncidences: (organizerId, tournamentId, matchId) =>
    dispatch(
      worksheetThunks.loadIncidences(organizerId, tournamentId, matchId)
    ),
  clearAllIntervalIds: () => dispatch(worksheetActions.clearAllIntervalIds()),
});

export default withRouter(connect(mapState, mapDispatch)(WorksheetContainer));
