import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as dateThunks from "../../../../redux/thunks/dateThunks";
import dateActions from "../../../../redux/reducers/dateReducer/dateActions";
import DateList from "./DateList";

const DateListContainer = ({
  tournamentEditing,
  organizerId,
  tournamentId,
  loadAllDates,
  dates,
  clearAllDates,
  loading,
  //createNewDate,
  //date,
  //clearSingleDate,
  setDateKeyValue,
  deleteDate,
  showCreateButton,
  showDeleteButton,
  show,
}) => {
  useEffect(() => {
    clearAllDates();

    if (tournamentEditing) loadAllDates(organizerId, tournamentId);
  }, []);

  //const createNewDateHandler = () => createNewDate(organizerId, tournamentId)

  const deleteDateHandler = (dateId) =>
    deleteDate(organizerId, tournamentId, dateId);

  return (
    show && (
      <DateList
        dates={dates}
        loadingFetchDates={loading.date.loadAllDates}
        setDateKeyValue={setDateKeyValue}
        deleteDate={deleteDateHandler}
        loadingDeleteDate={loading.group.deleteDate}
        organizerId={organizerId}
        tournamentId={tournamentId}
        showCreateButton={showCreateButton}
        showDeleteButton={showDeleteButton}
      />
    )
  );
};

const mapState = (state) => ({
  loading: state.loading,
  dates: state.date.all,
  //date: state.date.single
});

const mapDispatch = (dispatch) => ({
  loadAllDates: (organizerId, tournamentId) =>
    dispatch(dateThunks.loadAllDates(organizerId, tournamentId)),
  clearAllDates: () => dispatch(dateActions.clearAllDates()),
  //createNewDate: (organizerId, tournamentId) => dispatch(dateThunks.createNewDate(organizerId, tournamentId)),
  //clearSingleDate: () => dispatch(dateActions.clearSingleDate()),
  setDateKeyValue: (key, value) =>
    dispatch(dateActions.setDateKeyValue(key, value)),
  deleteDate: (organizerId, tournamentId, dateId) =>
    dispatch(dateThunks.deleteDate(organizerId, tournamentId, dateId)),
});

export default withRouter(connect(mapState, mapDispatch)(DateListContainer));
