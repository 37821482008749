'use-strict'

export default {
    SET_ALL_PLAYERS: '@@player/SET_ALL_PLAYERS',
    SET_PLAYER_KEY_VALUE: '@@player/SET_PLAYER_KEY_VALUE',
    PUSH_NEW_PLAYER: '@@player/PUSH_NEW_PLAYER',
    SET_SINGLE_PLAYER: '@@player/SET_SINGLE_PLAYER',
    REPLACE_PLAYER: '@@player/REPLACE_PLAYER',
    DELETE_PLAYER: '@@player/DELETE_PLAYER',
    CLEAR_SINGLE_PLAYER: '@@player/CLEAR_SINGLE_PLAYER',
    CLEAR_ALL_PLAYERS: '@@player/CLEAR_ALL_PLAYERS',
    REPLACE_PLAYER_IMAGE: '@@player/REPLACE_PLAYER_IMAGE'
}