import React, { useState, useEffect } from "react";
import Button from "../../../../utils/components/Button";
import MyModal from "../../../../utils/components/Modal";
import moment from "moment";
import * as env from "../../../../config/env";

const CardDetailModal = ({ redCard, show, handleShow }) => {
  const oncloseModal = () => handleShow(false);
  return (
    <MyModal
      show={show}
      title="Información de tarjeta roja"
      handleShow={handleShow}
      backdrop="static"
      body={
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="col-md-3 col-sm-2 control-label">
                Documento
              </label>
              <div className="col-md-9 col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={`${redCard.player.document}`}
                  disabled={true}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className="form-group">
              <label className="col-md-3 col-sm-2 control-label">Jugador</label>
              <div className="col-md-9 col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={`${redCard.player.firstName} ${redCard.player.lastName}`}
                  disabled={true}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className="form-group">
              <label className="col-md-3 col-sm-2 control-label">
                Fecha finalización
              </label>
              <div className="col-md-9 col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={`${moment(redCard.datetime).format(env.DATE_FORMAT)}`}
                  disabled={true}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="hr-line-dashed"></div>
            <div className="form-group">
              <label className="col-md-3 col-sm-2 control-label">
                Comentario
              </label>
              <div className="col-md-9 col-sm-10">
                <textarea
                  className="form-control"
                  value={redCard.comment ?? ""}
                  disabled={true}
                  onChange={() => {}}
                >
                  {redCard.comment ?? ""}
                </textarea>
              </div>
            </div>
          </div>
        </div>
      }
      buttons={
        <Button
          title="Volver"
          className="btn btn-info"
          onClick={oncloseModal}
        />
      }
    />
  );
};

export default CardDetailModal;
