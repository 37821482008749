'use-strict'

import authConstants from '../authReducer/authConstants'
import constants from './lineupConstants'
import initialState from './lineupState'

const tournamentReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_ALL_LINEUPS: {
            return {
                ...state,
                all: action.payload
            }
        }
        case constants.PUSH_NEW_LINEUP: {
            const lineups = [...state.all]
            lineups.push(action.payload)
            return {
                ...state,
                all: lineups
            }
        }
        case constants.DELETE_LINEUP: {
            const lineups = [...state.all]
            const index = lineups.findIndex(lineup => lineup.id === action.lineupId)
            lineups.splice(index, 1)
            return {
                ...state,
                all: lineups
            }
        }
        case constants.SET_SELECTED_LINEUP: {
            return {
                ...state,
                selected: action.lineupId
            }
        }
        case constants.CLEAR_ALL_LINEUPS: {
            return {
                ...state,
                all: initialState.all
            }
        }
        case constants.UPDATE_LINEUP_CAPTAIN: {
            const lineups = [...state.all]
            const index = lineups.findIndex(lineup => lineup.id === action.payload.lineupId)
            lineups[index] = {
                ...lineups[index],
                captain: action.payload.captain
            }
            return {
                ...state,
                all: lineups
            }
        }
        case constants.UPDATE_LINEUP_BONUS: {
            const lineups = [...state.all]
            const index = lineups.findIndex(lineup => lineup.id === action.payload.lineupId)
            lineups[index] = {
                ...lineups[index],
                ...action.payload.bonusData
            }
            return {
                ...state,
                all: lineups
            }
        }
        case authConstants.LOGOUT: {
            return initialState
        }
        default: {
            return state
        }
    }
}

export default tournamentReducer